import { useState, useEffect } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import FeedbackIcon from '@mui/icons-material/Feedback';
import { Box, Drawer, Typography, Divider, MenuItem, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { useAuthorize } from '~features/authentication';
import { ItemAutocomplete, ItemsInItemGroupService } from '~features/items';
import { OrganisationAutocomplete } from '~features/organisations';

import { useStyles } from './style';
import {
	SearchAutocomplete,
	FormField,
	SelectedItemCard,
	FormFieldSelect,
	Notification,
	Label,
	StyledButton,
} from '../../../components';
import { useWizardFormField } from '../../../shared/hooks';
import {
	isEmptyObject,
	isObject,
	isFullString,
	isArray,
	handleHubReference,
} from '../../../shared/utility';
import * as actions from '../../../store/actions';
import { useSnackbar } from '~hooks';

const service = new ItemsInItemGroupService('none');

const getExtension = (extension) => extension.split('-').pop();

const AddTickets = (props) => {
	const {
		open,
		onClose,
		onFetchPlanboardItemsToAdd,
		planboardItemsToAdd,
		onFetchPlanboardItemDetails,
		planboardItemDetails,
		onFetchTicketDefects,
		ticketDefects,
		onFetchInstanceDetails,
		instanceDetails,
		presetTicketData,
		onAddTicket,
		addedTicket,
	} = props;
	const { isSuperAdmin } = useAuthorize();
	const { t } = useTranslation('general');
	const classes = useStyles();
	const { enqueueSuccessSnackbar } = useSnackbar();

	const [shouldSubmitTicket, setShouldSubmitTicket] = useState(false);
	const [submittingTicket, setSubmittingTicket] = useState(false);
	const [submittedTicket, setSubmittedTicket] = useState(false);
	const [extraFilter] = useState({
		name: 'itemFilters',
		value: isSuperAdmin() ? 'unavailable' : 'unpublished'
	});

	const {
		data: addedTicketData,
		loading: addedTicketLoading,
		error: addedTicketError,
	} = addedTicket;
	const addTicketDone = isObject(addedTicketData) && !addedTicketLoading && !addedTicketError;

	const {
		data: bookingsData,
		loading: bookingsLoading,
		error: bookingsError,
	} = planboardItemsToAdd;
	const bookingsReady = isObject(bookingsData) && !bookingsLoading && !bookingsError;

	const {
		data: itemDetailsData,
		loading: itemDetailsLoading,
		error: itemDetailsError,
	} = planboardItemDetails;
	const itemDetailsReady = isObject(itemDetailsData) && !itemDetailsLoading && !itemDetailsError;

	const {
		data: instanceDetailsData,
		loading: instanceDetailsLoading,
		error: instanceDetailsError,
	} = instanceDetails;
	const instanceDetailsReady =
		isObject(instanceDetailsData) && !instanceDetailsLoading && !instanceDetailsError;

	const [fullResourceList, setFullResourceList] = useState({
		data: null,
		loading: false,
		error: null,
	});

	const { data: defectsData, loading: defectsLoading, error: defectsError } = ticketDefects;
	const defectsReady = isArray(defectsData) && !defectsLoading && !defectsError;

	const [selectedItemDetails, setSelectedItemDetails] = useState({});
	const [selectedInstanceDetails, setSelectedInstanceDetails] = useState({});
	const [selectedItem, setSelectedItem] = useState({});
	const ticketDefect = useWizardFormField('');
	const serviceProvider = useWizardFormField('');
	const description = useWizardFormField('', { maxLength: 200 });

	useEffect(() => {
		if (itemDetailsReady) {
			setSelectedItemDetails(itemDetailsData);
		}
	}, [itemDetailsReady]);

	useEffect(() => {
		if (instanceDetailsReady) {
			setSelectedInstanceDetails(instanceDetailsData);
		}
	}, [instanceDetailsReady]);

	//set serviceProvider value if planboard item details and instance details are set
	useEffect(() => {
		if (!isEmptyObject(selectedItemDetails) && !isEmptyObject(selectedInstanceDetails)) {
			if (
				selectedInstanceDetails.itemReference &&
				selectedInstanceDetails.itemReference.serviceProviderReference
			) {
				serviceProvider.onChange(
					`${selectedInstanceDetails.itemReference.serviceProviderReference.id}`,
				);
			} else {
				serviceProvider.onChange(`${selectedItemDetails.hubReference.organisationReference.id}`);
			}
		}
	}, [selectedItemDetails, selectedInstanceDetails]);

	//fetch ticket defect data
	useEffect(() => {
		if (!isEmptyObject(selectedItem)) {
			onFetchPlanboardItemDetails(selectedItem.mainItemId, selectedItem.itemInstanceId);
			onFetchInstanceDetails(selectedItem.mainItemId, selectedItem.itemInstanceId);
			onFetchTicketDefects(selectedItem.mainItemId);
		}
	}, [selectedItem]);

	/* * * * * * * * * * * * * *
	 * FULL RESOURCES METHODS  *
	 * * * * * * * * * * * * * */
	useEffect(() => {
		if (bookingsLoading) {
			setFullResourceList({ ...fullResourceList, loading: true });
		}
	}, [bookingsLoading]);

	// creating a full resource list (all selectable resources when creating events)
	useEffect(() => {
		if (bookingsReady) {
			const allResources = bookingsData.items.map((item) => ({
				...item,
				...(item?.numberOfChildren === 1 && {
					title: `${item.title} - ${item.children[0].title}`,
					instanceId: Number(getExtension(item.children[0].id)),
				}),
				...(item?.numberOfChildren === 1 && {
					childHub: {
						...item.children[0].hubReference,
					},
					hubReference: item.children[0].hubReference,
				}),
				...(item?.numberOfChildren > 1 && { className: 'parent-track' }),
				mainItemId: item.id,
			}));

			let resources = [];
			resources = allResources;

			const mainResources = resources.filter((resource) => {
				return !(resource.className && resource.className === 'parent-track');
			});

			const parentTracks = resources.filter((resource) => {
				return resource.className && resource.className === 'parent-track';
			});

			let childResources = [];

			for (const element of parentTracks) {
				const children = element.children.map((child) => ({
					id: child.id,
					title: element.title + ' - ' + child.title,
					hubReference: element.hubReference,
					childHub: child.hubReference,
					categoryId: element.categoryId,
					itemInstanceId: child.id.split('-')[1],
					mainItemId: element.id,
				}));

				childResources = childResources.concat(children);
			}

			const newFullResourceList = mainResources.concat(childResources);

			setFullResourceList({
				...planboardItemsToAdd,
				data: {
					hasMore: planboardItemsToAdd.data.hasMore,
					pageNumber: planboardItemsToAdd.data.pageNumber,
					pageSize: planboardItemsToAdd.data.pageSize,
					total: planboardItemsToAdd.data.total,
					results: newFullResourceList,
				},
			});
		}
	}, [bookingsReady, bookingsData, planboardItemsToAdd]);

	//setting booking data if there is preset data
	useEffect(() => {
		if (presetTicketData) {
			setSelectedItem(presetTicketData.resource);
		}
	}, [presetTicketData]);

	// submitting methods
	useEffect(() => {
		if (shouldSubmitTicket && !submittingTicket && !submittedTicket) {
			onAddTicket(
				parseInt(selectedItem.itemInstanceId),
				parseInt(ticketDefect.value),
				null,
				description.value,
			);
			setShouldSubmitTicket(false);
			setSubmittingTicket(true);
		}
	}, [shouldSubmitTicket, submittingTicket, submittedTicket]);

	useEffect(() => {
		if (submittingTicket && addTicketDone) {
			setSubmittingTicket(false);
			setSubmittedTicket(true);
		}
	}, [submittingTicket, addTicketDone]);

	useEffect(() => {
		if (submittedTicket) {
			setSubmittedTicket(false);
			onClose(true);
			enqueueSuccessSnackbar(`${t('ui.successfullyAdded')} ${t('ui.ticket.label')}`);
		}
	}, [submittedTicket]);

	/* * * * * * * *
	 * PAGE CLOSED *
	 * * * * * * * */
	const resetSelectedItem = () => {
		setSelectedItem({});
		setSelectedItemDetails({});
		ticketDefect.resetToInitialValue();
		serviceProvider.resetToInitialValue();
	};

	useEffect(() => {
		if (!open) {
			resetSelectedItem();
			description.resetToInitialValue();
		}
	}, [open]);

	const handleClickCreateOrEdit = () => {
		setShouldSubmitTicket(true);
	};

	/* * * * * *
	 * RENDER  *
	 * * * * * */
	const ticketTypeLabelTypes = {
		missing: 'disabled',
		critical: 'error',
		nonCritical: 'warning',
	};

	const serviceProvidersArray =
		!isEmptyObject(selectedInstanceDetails) && !isEmptyObject(selectedItemDetails) ?
			[
				...((
					selectedInstanceDetails.itemReference &&
					selectedInstanceDetails.itemReference.serviceProviderReference
				) ?
					[
						{
							id: selectedInstanceDetails.itemReference.serviceProviderReference.id,
							name: selectedInstanceDetails.itemReference.serviceProviderReference.name,
							isDefault: true,
						},
					]
				:	[
						{
							id: selectedItemDetails.hubReference.organisationReference.id,
							name: selectedItemDetails.hubReference.organisationReference.name,
							isDefault: true,
						},
					]),
			]
		:	[];

	return (
		<Drawer
			anchor='right'
			classes={{ paper: classes.drawer }}
			onClose={() => onClose()}
			open={open}
			variant='temporary'
		>
			<form>
				<div>
					<div className={classes.pageActionButtons}>
						<StyledButton
							onClick={() => onClose()}
							size='small'
							startIcon={<CloseIcon />}
							variant='inline-default'
						>
							{t('ui.button.inline.close')}
						</StyledButton>
					</div>
					<Typography variant='h2'>{t('views.tickets.addTicket.newTitle')}</Typography>
				</div>
				<Divider className={classes.divider} />
				<div className={classes.formGroup}>
					{/* {authorize.isSuperAdmin() && <OrganisationAutocomplete />} */}
					<Typography className={classes.subHeader} variant='h5'>
						{t('views.planboard.addBooking.itemDetails')}
					</Typography>
					{!isEmptyObject(selectedItem) ?
						<SelectedItemCard
							address={
								!isEmptyObject(selectedItemDetails) ?
									handleHubReference(selectedItemDetails.children[0].hubReference)
								:	''
							}
							handleClose={resetSelectedItem}
							hasCloseButton
							image={
								!isEmptyObject(selectedItemDetails) ?
									selectedItemDetails.imagesReference ?
										selectedItemDetails.imagesReference[0]
									:	null
								:	null
							}
							loading={isEmptyObject(selectedItemDetails)}
							name={
								!isEmptyObject(selectedItemDetails) ?
									`${selectedItemDetails.title} - ${selectedItemDetails.children[0].title}`
								:	null
							}
						/>
					:	<SearchAutocomplete
							dataList={fullResourceList}
							extraFilter={extraFilter}
							listType={'planboard-items'}
							onFetchData={onFetchPlanboardItemsToAdd}
							placeholder={t('ui.label.search.items')}
							popperSize='large'
							setSelected={setSelectedItem}
						/>
					}
				</div>
				{/* <ItemAutocomplete
					value={
						!isEmptyObject(selectedInstanceDetails) ?
							ItemsInItemGroupService.fromResponse({
								...selectedInstanceDetails,
								itemReference: selectedItemDetails
							})
						:	undefined
					}
				/> */}
				<div className={classes.formGroup}>
					<Typography className={classes.subHeader} variant='h5'>
						{t('ui.label.ticketType')}
					</Typography>
					<FormFieldSelect
						className={classes.selectWithLabel}
						disabled={isEmptyObject(selectedItem) || !defectsReady}
						displayEmpty
						label={t('ui.label.defect')}
						name='ticketDefect'
						variable={ticketDefect}
					>
						<MenuItem value={''}>
							<em>{t('views.tickets.addTicket.firstSelectionOption.ticketDefect')}</em>
						</MenuItem>
						{defectsReady ?
							defectsData.map((defect) => (
								<MenuItem
									className={classes.menuItem}
									key={`defect-${defect.id}`}
									value={`${defect.id}`}
								>
									<Typography>{defect.title}</Typography>
									<Label type={ticketTypeLabelTypes[defect.type]}>
										{t(`ui.ticketType.${defect.type}`)}
									</Label>
								</MenuItem>
							))
						:	null}
					</FormFieldSelect>
					{isEmptyObject(selectedItem) ?
						<Notification
							className={classes.notification}
							message={t('views.tickets.addTicket.notification.ticketType.noItemSelected')}
							startIcon={<FeedbackIcon />}
						/>
					:	null}
				</div>
				<div className={classes.formGroup}>
					<Typography className={classes.subHeader} variant='h5'>
						{t('ui.label.serviceProvider')}
					</Typography>
					<FormFieldSelect
						className={classes.selectWithLabel}
						disabled={isEmptyObject(selectedItem)}
						displayEmpty
						label={t('ui.label.organisation')}
						name='serviceProvider'
						variable={serviceProvider}
					>
						<MenuItem value={''}>
							<em>{t('views.tickets.addTicket.firstSelectionOption.serviceProvider')}</em>
						</MenuItem>
						{!isEmptyObject(selectedItemDetails) && !isEmptyObject(selectedInstanceDetails) ?
							serviceProvidersArray.map((serviceProvider, index) => (
								<MenuItem
									className={classes.menuItem}
									key={`service-provider-${index}`}
									value={`${serviceProvider.id}`}
								>
									<Typography>{serviceProvider.name}</Typography>
									{serviceProvider.isDefault ?
										<Label type='default'>{t('ui.default')}</Label>
									:	null}
								</MenuItem>
							))
						:	null}
					</FormFieldSelect>
					{isEmptyObject(selectedItem) ?
						<Notification
							className={classes.notification}
							message={t('views.tickets.addTicket.notification.serviceProvider.noItemSelected')}
							startIcon={<FeedbackIcon />}
						/>
					:	null}
				</div>
				<div className={classes.formGroup}>
					<Box className={classes.subHeader} display='flex' justifyContent='space-between'>
						<Typography variant='h5'>{t('ui.label.description')}</Typography>
						<Typography className={classes.counterText}>
							{description.value.length}/200 {t('ui.characters')}
						</Typography>
					</Box>
					<FormField
						className={classes.textField}
						hideCharacterCounter
						maxLength={200}
						multiline
						name='organisationDescription'
						placeholder={t('views.tickets.addTicket.placeholder.description')}
						rows={5}
						variable={description}
					/>
				</div>
				<Divider className={classes.divider} />
				<StyledButton
					disabled={
						isEmptyObject(selectedItem) ||
						!isFullString(serviceProvider.value) ||
						!isFullString(ticketDefect.value)
					}
					fullWidth
					onClick={handleClickCreateOrEdit}
					size='large'
					variant='contained-primary'
				>
					{submittingTicket ?
						<CircularProgress className={classes.progress} disableShrink size={25} />
					:	t('view.ticketmanagement.button.contained.createticket')}
				</StyledButton>
			</form>
		</Drawer>
	);
};

AddTickets.propTypes = {
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func,
	presetTicketData: PropTypes.object,
	itemsList: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	planboardItemsToAdd: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	planboardItemDetails: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	instanceDetails: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	ticketDefects: PropTypes.shape({
		data: PropTypes.array,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	addedTicket: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),

	onFetchPlanboardItemsToAdd: PropTypes.func.isRequired,
	onFetchTicketDefects: PropTypes.func.isRequired,
	onFetchPlanboardItemDetails: PropTypes.func.isRequired,
	onFetchInstanceDetails: PropTypes.func.isRequired,
	onAddTicket: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
	return {
		itemsList: state.paged.items,
		planboardItemsToAdd: state.planboard.planboardItemsToAdd,
		ticketDefects: state.list.ticketDefects,
		planboardItemDetails: state.details.planboardItemDetails,
		instanceDetails: state.details.fetchInstance,
		addedTicket: state.details.addedTicket,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchPlanboardItemsToAdd: (page, filters) =>
			dispatch(actions.fetchPlanboardItemsToAdd(page, filters)),
		onFetchPlanboardItemDetails: (itemId, instanceId) =>
			dispatch(actions.fetchPlanboardItemDetails(itemId, instanceId)),
		onFetchInstanceDetails: (itemId, instanceId) =>
			dispatch(actions.fetchInstance(itemId, instanceId)),
		onFetchTicketDefects: (itemId) => dispatch(actions.fetchTicketDefects(itemId)),
		onAddTicket: (itemInstanceId, ticketDefectId, serviceOrganisationId, description) =>
			dispatch(
				actions.addTicket(itemInstanceId, description, ticketDefectId, serviceOrganisationId),
			),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AddTickets);
