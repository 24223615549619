import { useState, useEffect } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import {
	Card,
	CardHeader,
	CardContent,
	CardActions,
	Divider,
	Table,
	TableBody,
	TableRow,
	TableCell,
} from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { useAuthorize } from '~features/authentication';

import DevicesDialog from './DevicesDialog';
import { useStyles } from './style';
import { StyledButton, Label } from '../../../../../../components';
import { useError, usePrevious } from '../../../../../../shared/hooks';

const Devices = (props) => {
	const { organisation, className, deviceAccess } = props;
	const { t } = useTranslation('general');
	const { isSuperAdmin } = useAuthorize();
	const [openEdit, setOpenEdit] = useState(false);
	const classes = useStyles();

	const deviceAccessMessage = useError({
		value: deviceAccess,
		message: `${organisation.name} ${organisation.features.includes('devices') ? t('views.organisationManagement.devices.noAccess') : t('views.organisationManagement.devices.access')}`,
	});

	const previousDeviceAccess = usePrevious(deviceAccess.loading);

	useEffect(() => {
		if (deviceAccess.success && previousDeviceAccess) {
			deviceAccessMessage.setStartAction(true);
		}
	}, [deviceAccess.loading]);

	const handleEditOpen = () => {
		setOpenEdit(true);
	};

	const handleClose = () => {
		setOpenEdit(false);
	};

	return (
		<Card className={clsx(classes.root, className)}>
			<CardHeader title={t('ui.label.devices')} />
			<Divider />
			<CardContent className={classes.content}>
				<Table>
					<TableBody>
						<TableRow>
							<TableCell>{t('ui.label.devices')}</TableCell>
							<TableCell>
								{organisation.features.includes('devices') ?
									<Label type='success'>{t('ui.enabled')}</Label>
								:	<Label type='error'>{t('ui.status.disabled')}</Label>}
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</CardContent>
			{isSuperAdmin() ?
				<CardActions className={classes.actions}>
					<StyledButton onClick={handleEditOpen} startIcon={<EditIcon />} variant='inline-default'>
						{t('ui.edit')}
					</StyledButton>
				</CardActions>
			:	null}
			{openEdit ?
				<DevicesDialog
					handleClose={handleClose}
					hasDevicesAccess={organisation?.features.includes('devices')}
					open={openEdit}
					organisationId={organisation.id}
				/>
			:	null}
		</Card>
	);
};

Devices.propTypes = {
	className: PropTypes.string,
	organisation: PropTypes.object.isRequired,

	deviceAccess: PropTypes.shape({
		success: PropTypes.bool,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
};

const mapStateToProps = (state) => {
	return {
		deviceAccess: state.condition.deviceAccess,
	};
};

export default connect(mapStateToProps)(Devices);
