import path from 'path';

import { t } from 'i18next';
import { useAuth } from 'react-oidc-context';
import { Navigate, RouteObject, useSearchParams } from 'react-router-dom';

import { ErrorBoundary } from '~components';
import { pagePathSegments } from '~constants';
import { UserRoleEnum } from '~enums';
import { ProtectedRoute, useAuthorize } from '~features/authentication';
import { MainLayout, PageLayout } from '~layouts';
import { PageNotFoundPage, SandboxPage, EmissionsPage } from '~pages';

import skcAccessRoutes from './access/accessRoutes';
import authRoutes from './auth/authRoutes';
import userManagementRoutes from './userManagement/userManagementRoutes';
import Bookings from '../views/Bookings';
import BookingDetails from '../views/Bookings/Details';
import Contracts from '../views/Contracts';
import AddContract from '../views/Contracts/ActionFlows/AddContract';
import ContractsDetails from '../views/Contracts/ContractsDetails';
import Dashboard from '../views/Dashboard';
import Devices from '../views/Devices';
import DetailsDevices from '../views/Devices/Details';
import TripDetails from '../views/Devices/Details/TripsList/TripDetails';
import ErrorPages from '../views/ErrorPages';
import FinanceManagement from '../views/FinanceManagement';
import BookingsExpenses from '../views/FinanceManagement/Expenses/Bookings';
import OrganisationsExpenses from '../views/FinanceManagement/Expenses/Organisations';
import PartnersExpenses from '../views/FinanceManagement/Expenses/Partners';
import BookingsPersonal from '../views/FinanceManagement/Personal/Bookings';
import PersonalBillings from '../views/FinanceManagement/Personal/PersonalBillings';
import BookingsRevenue from '../views/FinanceManagement/Revenue/Bookings';
import OrganisationsRevenue from '../views/FinanceManagement/Revenue/Organisations';
import PartnersRevenue from '../views/FinanceManagement/Revenue/Partners';
import PersonalBillingsRevenue from '../views/FinanceManagement/Revenue/PersonalBillings';
import UserBookingsRevenue from '../views/FinanceManagement/Revenue/UserBookings';
import FleetManagement from '../views/FleetManagement';
import ItemManagement from '../views/ItemManagement';
import AddItem from '../views/ItemManagement/ActionFlows/AddItem';
import CharacteristicsActionFlow from '../views/ItemManagement/ActionFlows/AddItem/Characteristics/Characteristics';
import AssignItemsToPartner from '../views/ItemManagement/ActionFlows/AssignItemsToPartner';
import AssignItemsToUserGroups from '../views/ItemManagement/ActionFlows/AssignItemsToUserGroups';
import EditManagement from '../views/ItemManagement/ActionFlows/EditManagement';
import InstanceDetails from '../views/ItemManagement/Details/InstanceDetails';
import ItemDetailsOverview from '../views/ItemManagement/Details/ItemDetails';
import SharingDetails from '../views/ItemManagement/Details/SharingDetails';
import Licenses from '../views/Licenses';
import LicenseDetails from '../views/Licenses/LicenseDetails';
import LocationsManagement from '../views/LocationsManagement';
import AddLocation from '../views/LocationsManagement/ActionFlows/AddLocation';
import LocationDetail from '../views/LocationsManagement/Details/LocationDetail';
import Maintenance from '../views/Maintenance';
import TicketsDetails from '../views/Maintenance/Details/TicketsDetails';
import UnavailabilityDetails from '../views/Maintenance/Details/UnavailabilityDetails';
import OrganisationManagement from '../views/OrganisationManagement';
import OrganisationDetailsOverview from '../views/OrganisationManagement/Details/OrganisationDetails';
import AddEmailDomains from '../views/OrganisationManagement/Details/OrganisationDetails/EmailDomains/AddEmailDomains';
import Partnerships from '../views/Partnerships';
import AddPartner from '../views/Partnerships/ActionFlows/AddPartner';
import PartnerDetail from '../views/Partnerships/Details/PartnerDetail';
import Planboard from '../views/Planboard';
import PolicyManagement from '../views/PolicyManagement';
import AddPolicy from '../views/PolicyManagement/ActionFlows/AddPolicy';
import AddPricing from '../views/PolicyManagement/ActionFlows/AddPricing';
import AddTermsAndConditions from '../views/PolicyManagement/ActionFlows/AddTermsAndConditions';
import PolicyDetail from '../views/PolicyManagement/Details/PolicyDetail';
import PricingModelDetail from '../views/PolicyManagement/Details/PricingModelDetail';
import TermDetail from '../views/PolicyManagement/Details/TermDetail';
import EditPolicy from '../views/PolicyManagement/EditPolicies/EditPolicy';
import EditPricingModel from '../views/PolicyManagement/EditPolicies/EditPricingModel';
import EditTerms from '../views/PolicyManagement/EditPolicies/EditTerms';
import Settings from '../views/Settings';
import UserManagement from '../views/UserManagement';
import AddUserGroup from '../views/UserManagement/ActionFlows/AddUserGroup';
import AssignUsersToUserGroup from '../views/UserManagement/ActionFlows/AssignUsersToUserGroup';
import EditUserGroupSettings from '../views/UserManagement/ActionFlows/EditUserGroupSettings';
import InviteUsers from '../views/UserManagement/ActionFlows/InviteUsers';
import UserDetails from '../views/UserManagement/Details/UserDetails';
import UserGroupDetailsOverview from '../views/UserManagement/Details/UserGroupDetails';
import statisticsRoutes from './statisticsRoutes';


const isHigherThanAcceptance = import.meta.env.VITE_APP_ENV === 'accept' || import.meta.env.VITE_APP_ENV === 'production';

// To migrate to react-router v6
const routes = (): RouteObject[] => {
	const adminRoutes: RouteObject[] = [
		{
			path: pagePathSegments.Home,
			element: <Navigate to={pagePathSegments.Dashboard} />,
		},
		{
			path: pagePathSegments.Booking,
			children: [
				{
					// TODO: verification?
					path: `${pagePathSegments.BookingDetail}/:id/:tab`,
					element: <BookingDetails />,
				},
			],
		},
		{
			path: pagePathSegments.UserManagement,
			children: [
				{
					path: ':tab',
					element: <UserManagement />,
				},
				{
					path: `${pagePathSegments.Users}/:id/:tab`,
					element: <UserDetails />,
				},
				{
					path: `${pagePathSegments.Users}/${pagePathSegments.Invite}`,
					element: <InviteUsers />,
				},
				{
					path: `${pagePathSegments.Users}/${pagePathSegments.Assign}`,
					children: [
						{
							path: pagePathSegments.UserGroup,
							element: <AssignUsersToUserGroup />,
						},
					],
				},
				{
					path: `${pagePathSegments.UserGroups}/:id/:tab`,
					element: <UserGroupDetailsOverview />,
				},
				{
					path: `${pagePathSegments.UserGroups}/:id/${pagePathSegments.Items}/${pagePathSegments.Assign}`,
					element: <AssignItemsToUserGroups />,
				},
				{
					path: `${pagePathSegments.UserGroups}/${pagePathSegments.Add}`,
					element: <AddUserGroup />,
				},
				{
					path: `${pagePathSegments.UserGroups}/:id/${pagePathSegments.Settings}/${pagePathSegments.Edit}`,
					element: <EditUserGroupSettings />,
				},
			],
		},
		{
			path: pagePathSegments.Term,
			children: [
				{
					path: `${pagePathSegments.Details}/:id/:tab`,
					element: <TermDetail />,
				},
			],
		},
		{
			path: pagePathSegments.Terms,
			children: [
				{
					path: `:id/${pagePathSegments.Edit}`,
					element: <EditTerms />,
				},
			],
		},
		{
			path: pagePathSegments.LocationManagement,
			children: [
				{
					path: ':tab',
					element: <LocationsManagement />,
				},
				{
					path: `${pagePathSegments.Location}/${pagePathSegments.Add}`,
					element: <AddLocation />,
				},
				{
					path: `${pagePathSegments.Location}/:id/:tab`,
					element: <LocationDetail />,
				},
			],
		},
		{
			path: pagePathSegments.ItemManagement,
			// element: <ItemDetailsOverview />,
			children: [
				{
					path: ':tab',
					element: <ItemManagement />,
				},
				{
					path: `${pagePathSegments.Items}/${pagePathSegments.Add}`,
					element: <AddItem />,
				},
				{
					path: `${pagePathSegments.Items}/:id/:tab`,
					element: <ItemDetailsOverview />,
				},
				{
					path: `${pagePathSegments.Items}/${pagePathSegments.Assign}`,
					children: [
						{
							path: pagePathSegments.UserGroup,
							element: <AssignItemsToUserGroups />,
						},
						{
							path: pagePathSegments.Partner,
							element: <AssignItemsToPartner />,
						},
					],
				},
				{
					path: `:id/${pagePathSegments.Instance}/:instanceId/:tab`,
					element: <InstanceDetails />,
				},
				{
					path: `${pagePathSegments.Item}/:itemId/${pagePathSegments.Characteristics}`,
					element: <CharacteristicsActionFlow />,
				},
				{
					path: `${pagePathSegments.Item}/:itemId/${pagePathSegments.Summary}/:providerId/${pagePathSegments.Sharing}/:sharingType/:id`,
					element: <SharingDetails />,
				},
				{
					path: `${pagePathSegments.Management}/${pagePathSegments.Edit}`,
					element: <EditManagement />,
				},
			],
		},
		{
			path: pagePathSegments.Organisations,
			children: [
				{
					path: ':id/:tab',
					element: <OrganisationDetailsOverview />,
				},
				{
					element: <ProtectedRoute roles={[UserRoleEnum.SuperAdmin]} />,
					children: [
						{
							path: `:id/${pagePathSegments.EmailDomains}/${pagePathSegments.Add}`,
							element: <AddEmailDomains />,
						},
					],
				},
			],
		},
		{
			path: pagePathSegments.Planboard,
			element: <Planboard />,
		},
		{
			path: pagePathSegments.Bookings,
			element: <Bookings />,
		},
		{
			path: pagePathSegments.PolicyManagement,
			children: [
				{
					path: ':tab',
					element: <PolicyManagement />,
				},
				{
					path: `${pagePathSegments.ItemPolicies}/:id/:tab`,
					element: <PolicyDetail />,
				},
				{
					path: `${pagePathSegments.ItemPolicies}/${pagePathSegments.Add}`,
					element: <AddPolicy />,
				},
				{
					path: `${pagePathSegments.Pricing}/${pagePathSegments.Add}`,
					element: <AddPricing />,
				},
				{
					path: `${pagePathSegments.TermAndConditions}/${pagePathSegments.Add}`,
					element: <AddTermsAndConditions />,
				},
			],
		},
		{
			path: `${pagePathSegments.Pricing}/:id/:tab`,
			element: <PricingModelDetail />,
		},
		{
			path: `${pagePathSegments.Pricing}/:id/${pagePathSegments.Edit}`,
			element: <EditPricingModel />,
		},
		{
			path: `${pagePathSegments.Ticketing}/:tab`,
			element: <Maintenance />,
		},
		{
			path: pagePathSegments.TicketDetails,
			children: [
				{
					index: true,
					element: <ServiceTicketsRedirect />,
				},
				{
					path: ':id',
					element: <TicketsDetails />,
				},
			],
		},
		{
			path: `${pagePathSegments.Policy}/:id/${pagePathSegments.Edit}`,
			element: <EditPolicy />,
		},
		{
			path: pagePathSegments.Partnerships,
			children: [
				{
					path: ':tab',
					element: <Partnerships />,
				},
				{
					path: `${pagePathSegments.Partners}/${pagePathSegments.Add}`,
					element: <AddPartner />,
				},
				{
					path: `${pagePathSegments.Partners}/:id/:tab`,
					element: <PartnerDetail />,
				},
			],
		},
		{
			path: pagePathSegments.FinanceManagement,
			children: [
				{
					path: ':tab',
					element: <FinanceManagement />,
				},
				{
					path: `:tab/:date/:title`,
					children: [
						{
							path: pagePathSegments.Partner,
							children: [
								{
									path: `${pagePathSegments.Expenses}/:id/:name`,
									element: <PartnersExpenses />,
								},
								{
									path: `:id/:name`,
									children: [
										{
											index: true,
											element: <PartnersRevenue />,
										},
										{
											path: pagePathSegments.Bookings,
											element: <BookingsRevenue />,
										},
										{
											path: `${pagePathSegments.Expenses}/${pagePathSegments.Bookings}`,
											element: <BookingsExpenses />,
										},
										{
											path: pagePathSegments.Personal,
											element: <PersonalBillingsRevenue />,
										},
										{
											path: `${pagePathSegments.Personal}/${pagePathSegments.Bookings}`,
											element: <UserBookingsRevenue />,
										},
									],
								},
							],
						},
						{
							path: pagePathSegments.Billing,
							children: [
								{
									index: true,
									element: <PersonalBillings />,
								},
								{
									path: `:name/:id`,
									element: <BookingsPersonal />,
								},
							],
						},
					],
				},
			],
		},
		{
			// TODO: organisation has devices feature
			path: pagePathSegments.Devices,
			children: [
				{
					path: ':tab',
					element: <Devices />,
				},
				{
					path: ':id/:tab',
					element: <DetailsDevices />,
				},
			],
		},
		{
			path: pagePathSegments.Contracts,
			children: [
				{
					index: true,
					element: <ConstractsRedirect />,
				},
				{
					path: ':tab',
					element: <Contracts />,
				},
				{
					path: `${pagePathSegments.Contract}/${pagePathSegments.Add}`,
					element: <AddContract />,
				},
				{
					path: ':id/:tab',
					element: <ContractsDetails />,
				},
			],
		},
		{
			path: pagePathSegments.Unavailability,
			children: [
				{
					path: `:id/:tab`,
					element: <UnavailabilityDetails />,
				},
			],
		},
		{
			path: pagePathSegments.MapView,
			element: <FleetManagement />,
		},
		{
			path: `:locality/:id/${pagePathSegments.Trips}/:tripId/:tab`,
			element: <TripDetails />,
		},
		{
			path: pagePathSegments.Dashboard,
			element: <Dashboard />,
		},
		{
			path: pagePathSegments.Statistics,
			element: (
				<PageLayout
					title={t('nav.topic.statistics')}
					pages={[{ label: t('ui.header.co2'), path: pagePathSegments.Co2 }]}
				/>
			),
			children: [
				{
					path: pagePathSegments.Co2,
					element: <EmissionsPage />,
				},
			],
		},
		...statisticsRoutes(),
		...skcAccessRoutes(),
		...(import.meta.env.VITE_APP_ENV === 'develop' || import.meta.env.DEV ?
			userManagementRoutes()
		:	[]),
	];

	const superAdminRoutes: RouteObject[] = [
		{
			path: `${pagePathSegments.OrganisationManagement}/:tab`,
			element: <OrganisationManagement />,
		},
		{
			path: pagePathSegments.Organisations,
			children: [
				{
					path: `:id/${pagePathSegments.EmailDomains}/${pagePathSegments.Add}`,
					element: <AddEmailDomains />,
				},
			],
		},
		{
			path: `${pagePathSegments.Settings}/:tab`,
			element: <Settings />,
		},
		{
			path: pagePathSegments.FinanceManagement,
			children: [
				{
					path: `:tab/:date/:title/${pagePathSegments.Organisations}`,
					children: [
						{
							path: pagePathSegments.Revenue,
							element: <OrganisationsRevenue />,
						},
						{
							path: pagePathSegments.Expenses,
							element: <OrganisationsExpenses />,
						},
					],
				},
			],
		},
		// TODO: organisation has devices feature
		// {
		// 	path: pagePathSegments.Devices,
		// 	children: [
		// 		{
		// 			path: ':tab',
		// 			element: <Devices />,
		// 		},
		// 	],
		// },
		{
			path: pagePathSegments.Licenses,
			children: [
				{
					path: ':tab',
					element: <Licenses />,
				},
				{
					path: `${pagePathSegments.Request}/:id`,
					element: <LicenseDetails />,
				},
			],
		},
	];

	return [
		...authRoutes,
		{
			element: <ProtectedRoute />,
			children: [
				{
					element: <MainLayout />,
					errorElement: <ErrorBoundary />,
					children: [
						...adminRoutes,
						{
							path: 'errors/*',
							element: <ErrorPages />,
						},
						{
							path: '*',
							element:
								import.meta.env.DEV ?
									<PageNotFoundPage />
								:	<Navigate to={pagePathSegments.Home} />,
						},
						...(import.meta.env.DEV ?
							[
								{
									path: pagePathSegments.Sandbox,
									element: <PageLayout title='Sandbox' />,
									children: [
										{
											index: true,
											element: <SandboxPage />,
										},
									],
								},
							]
						:	[]),
					],
				},
			],
		},
		{
			element: <ProtectedRoute roles={[UserRoleEnum.SuperAdmin]} />,
			children: [
				{
					element: <MainLayout />,
					errorElement: <ErrorBoundary />,
					children: [
						...superAdminRoutes,
						{
							path: 'errors/*',
							element: <ErrorPages />,
						},
						{
							path: '*',
							element:
								import.meta.env.DEV ?
									<PageNotFoundPage />
								:	<Navigate to={pagePathSegments.Home} />,
						},
					],
				},
			],
		},
		...(import.meta.env.DEV ?
			[
				{
					path: pagePathSegments.SandboxPublic,
					element: <PageLayout title='Sandbox Public' />,
					children: [
						{
							index: true,
							element: <SandboxPage />,
						},
					],
				},
			]
		:	[]),
	];
};

const ConstractsRedirect = () => {
	const [searchParams] = useSearchParams();

	const contractId = searchParams.get('contractId');
	if (!contractId) {
		return null;
	}

	return (
		<Navigate
			to={`/${path.join(pagePathSegments.Contracts, contractId, pagePathSegments.Summary)}`}
		/>
	);
};

const ServiceTicketsRedirect = () => {
	const [searchParams] = useSearchParams();

	const ticketId = searchParams.get('ticketId');
	if (!ticketId) {
		return null;
	}

	return <Navigate to={`/${path.join(pagePathSegments.TicketDetails, ticketId)}`} />;
};

export default routes;
