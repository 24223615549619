import { Skeleton } from '@mui/material';
import { legendClasses } from '@mui/x-charts';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { PieChart, PieChartCenterLabel } from '~components';
import { colorsFull, colorsSlim } from '~themes/chartColors';

import DeviceStatisticsService from '../../services/deviceStatisticsService';

const service = new DeviceStatisticsService();

interface DeviceCountPieChartProps {
	organisationId?: string;
}

const DeviceCountPieChart = ({ organisationId }: DeviceCountPieChartProps) => {
	const { t } = useTranslation('general');

	const { data, isLoading } = useSWR(
		[service.basePath, { organisationId: organisationId }],
		([_, args]) => {
			if (isLoading) {
				service.abortCurrentRequest('parameter change');
			}
			
			return service.getCounts(args);
		} ,
	);

	if (!data) {
		return 'todo';
	}

	data.results.sort((a, b) => b.total > a.total ? 1 : -1);

	return (
		<PieChart
			colors={data.results.length > 3 ? colorsFull : colorsSlim}
			loading={isLoading}
			series={
				data != null ?
					[
						{
							data: data.results.map((el) => ({ label: t(el.type === 'unlinked' ? 'unlinked' : `ui.label.${el.type}`), value: el.total })),
							outerRadius: '100%',
							innerRadius: '80%',
						},
					]
				:	[]
			}
		>
			<PieChartCenterLabel primary={t('totalResources', { resource: t('devices') })} secondary={data?.total} />
		</PieChart>
	);
};

export default DeviceCountPieChart;
