import { useState, useEffect } from 'react';

import path from 'path';

import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { pagePathSegments } from '~constants';

import PolicyConfiguration from './PolicyConfiguration';
import PolicyDetails from './PolicyDetails';
import { Wizard, AlertDialog } from '../../../../components';
import { useError } from '../../../../shared/hooks';
import {
	isUndefined,
	isEmptyObject,
	isEmptyString,
	isFullString,
	isObject,
	isNull,
	timeConvert,
} from '../../../../shared/utility';
import * as actions from '../../../../store/actions';

const hour = 60;

const minutesInDay = 1440;

const AddPolicy = (props) => {
	const {
		addedPolicy,
		onAddPolicy,

		editing,
		editPolicyData,
		onUpdatePolicy,
		updatePolicy,
		editRedirect,
	} = props;
	const { t } = useTranslation('general');

	const navigate = useNavigate();

	const [name, setName] = useState(editing ? editPolicyData.name : '');
	const [isValidName, setIsValidName] = useState(false);
	const [selectedOrganisation, setSelectedOrganisation] = useState(
		editing ? editPolicyData.organisationReference : {},
	);

	useEffect(() => {
		console.log(selectedOrganisation)
	}, [selectedOrganisation]);

	const [requireHostConfirmation, setRequireHostConfirmation] = useState(
		editing ? editPolicyData.requireHostConfirmation : false,
	);

	const [openHoursRestrictionType, setOpenHoursRestrictionType] = useState(
		editing ? editPolicyData.openHoursRestrictionType : 'none',
	);

	const [openDialog, setOpenDialog] = useState(false);

	const [hasMinDuration, setHasMinDuration] = useState(
		editing && editPolicyData?.minBookingDurationMinutes ? editing : false,
	);
	const editMinDuration =
		isObject(editPolicyData) &&
		timeConvert(
			editPolicyData?.minBookingDurationMinutes ? editPolicyData.minBookingDurationMinutes : 0,
		);
	const [minDurationDay, setMinDurationDay] = useState(editing ? editMinDuration.day : null);
	const [minDurationHour, setMinDurationHour] = useState(editing ? editMinDuration.hours : null);
	const [minDurationMin, setMinDurationMin] = useState(editing ? editMinDuration.minutes : null);
	const minDurationPolicy = minDurationDay * minutesInDay + minDurationHour * hour + minDurationMin;

	const [hasMaxDuration, setHasMaxDuration] = useState(
		editing && editPolicyData?.maxBookingDurationMinutes ? editing : false,
	);
	const editMaxDuration =
		isObject(editPolicyData) &&
		timeConvert(
			editPolicyData?.maxBookingDurationMinutes ? editPolicyData.maxBookingDurationMinutes : 0,
		);
	const [maxDurationDay, setMaxDurationDay] = useState(editing ? editMaxDuration.day : null);
	const [maxDurationHour, setMaxDurationHour] = useState(editing ? editMaxDuration.hours : null);
	const [maxDurationMin, setMaxDurationMin] = useState(editing ? editMaxDuration.minutes : null);
	const maxDurationPolicy = maxDurationDay * minutesInDay + maxDurationHour * hour + maxDurationMin;

	// Booking Upfront
	const [hasMinDurationBeforeStart, setHasMinDurationBeforeStart] = useState(
		editing && editPolicyData?.minDurationBeforeStartMinutes ? editing : false,
	);
	const editMinDurationBeforeStart =
		isObject(editPolicyData) &&
		timeConvert(
			editPolicyData?.minDurationBeforeStartMinutes ?
				editPolicyData.minDurationBeforeStartMinutes
			:	0,
		);
	const [minDurationBeforeStartDay, setMinDurationBeforeStartDay] = useState(
		editing ? editMinDurationBeforeStart.day : null,
	);
	const [minDurationBeforeStartHour, setMinDurationBeforeStartHour] = useState(
		editing ? editMinDurationBeforeStart.hours : null,
	);
	const [minDurationBeforeStartMin, setMinDurationBeforeStartMin] = useState(
		editing ? editMinDurationBeforeStart.minutes : null,
	);
	const minDurationBeforeStartDayPolicy =
		minDurationBeforeStartDay * minutesInDay +
		minDurationBeforeStartHour * hour +
		minDurationBeforeStartMin;

	const [hasMaxDurationBeforeStart, setHasMaxDurationBeforeStart] = useState(
		editing && editPolicyData?.maxDurationBeforeStartMinutes ? editing : false,
	);
	const editMaxDurationBeforeStart =
		isObject(editPolicyData) &&
		timeConvert(
			editPolicyData?.maxDurationBeforeStartMinutes ?
				editPolicyData.maxDurationBeforeStartMinutes
			:	0,
		);
	const [maxDurationBeforeStartDay, setMaxDurationBeforeStartDay] = useState(
		editing ? editMaxDurationBeforeStart.day : null,
	);
	const [maxDurationBeforeStartHour, setMaxDurationBeforeStartHour] = useState(
		editing ? editMaxDurationBeforeStart.hours : null,
	);
	const [maxDurationBeforeStartMin, setMaxDurationBeforeStartMin] = useState(
		editing ? editMaxDurationBeforeStart.minutes : null,
	);
	const maxDurationBeforeStartPolicy =
		maxDurationBeforeStartDay * minutesInDay +
		maxDurationBeforeStartHour * hour +
		maxDurationBeforeStartMin;

	const [requireOvertimeValidation, setRequireOvertimeValidation] = useState(
		editing ? editPolicyData.requireOvertimeValidation : false,
	);

	const [hasBufferPeriod, setHasBufferPeriod] = useState(
		(
			(editing && editPolicyData.warmupBufferMinutes > 0) ||
				(editing && editPolicyData.cooldownBufferMinutes > 0)
		) ?
			editing
		:	false,
	);

	const editBefore = isObject(editPolicyData) && timeConvert(editPolicyData.warmupBufferMinutes);
	const [daysBeforeUse, setDaysBeforeUse] = useState(editing ? editBefore.day : null);
	const [hoursBeforeUse, setHoursBeforeUse] = useState(editing ? editBefore.hours : null);
	const [minBeforeUse, setMinBeforeUse] = useState(editing ? editBefore.minutes : null);
	const beforeUse = daysBeforeUse * minutesInDay + hoursBeforeUse * hour + minBeforeUse;

	const editAfter = isObject(editPolicyData) && timeConvert(editPolicyData.cooldownBufferMinutes);
	const [daysAfterUse, setDaysAfterUse] = useState(editing ? editAfter.day : null);
	const [hoursAfterUse, setHoursAfterUse] = useState(editing ? editAfter.hours : null);
	const [minAfterUse, setMinAfterUse] = useState(editing ? editAfter.minutes : null);
	const afterUse = daysAfterUse * minutesInDay + hoursAfterUse * hour + minAfterUse;

	const [hasGracePeriod, setHasGracePeriod] = useState(
		(
			(editing && editPolicyData.overtimeGracePeriodMinutes > 0) ||
				(editing && editPolicyData.overtimeGracePeriodMinutes > 0)
		) ?
			editing
		:	false,
	);
	const editGracePeriod =
		isObject(editPolicyData) && timeConvert(editPolicyData.overtimeGracePeriodMinutes);
	const [daysGracePeriod, setDaysGracePeriod] = useState(editing ? editGracePeriod.day : null);
	const [hoursGracePeriod, setHoursGracePeriod] = useState(editing ? editGracePeriod.hours : null);
	const [minGracePeriod, setMinGracePeriod] = useState(editing ? editGracePeriod.minutes : null);
	const gracePeriod = daysGracePeriod * minutesInDay + hoursGracePeriod * hour + minGracePeriod;

	const [hasCancellationPeriod, setHasCancellationPeriod] = useState(
		editing && editPolicyData.minDurationBeforeCancelMinutes > 0 ? editing : false,
	);

	const editCancel =
		isObject(editPolicyData) && timeConvert(editPolicyData.minDurationBeforeCancelMinutes);

	const [cancelDay, setCancelDay] = useState(editing ? editCancel.day : null);
	const [cancelHour, setCancelHour] = useState(editing ? editCancel.hours : null);
	const [cancelMin, setCancelMin] = useState(editing ? editCancel.minutes : null);
	const cancellationPolicy = cancelDay * minutesInDay + cancelHour * hour + cancelMin;

	const [allowExtending, setAllowExtending] = useState(
		editing ? editPolicyData.allowExtending : false,
	);

	const [requireDutchLicense, setRequireDutchLicense] = useState(
		editing ? editPolicyData.requireDutchLicense : false,
	);

	const [requiredLicenseStatus, setRequiredLicenseStatus] = useState(
		editing ? editPolicyData.requiredLicenseStatus : 'notApplicable',
	);
	const [requiredParkingLicensePlateNumber, setRequiredParkingLicensePlateNumber] = useState(
		editing ? editPolicyData.requiredParkingLicensePlateNumber : 'notApplicable',
	);

	const [depositAmountRequired, setDepositAmountRequired] = useState(
		editing && editPolicyData.personalBillingDeposit > 0 ? editing : false,
	);
	const [personalBillingDeposit, setPersonalBillingDeposit] = useState(
		editing ? editPolicyData.personalBillingDeposit : 0,
	);
	const [personalBillingDepositValid, setPersonalBillingDepositValid] = useState(true);

	const [allowPersonalBilling, setAllowPersonalBilling] = useState(
		editing ? editPolicyData.allowPersonalBilling : false,
	);

	const [requireReservationComment, setRequireReservationComment] = useState(
		editing ? editPolicyData.requireReservationComment : false,
	);

	// validity
	const durationDifferenceBookingUpfrontValid =
		!hasMaxDurationBeforeStart ||
		!hasMinDurationBeforeStart ||
		(hasMaxDurationBeforeStart && minDurationBeforeStartDayPolicy < maxDurationBeforeStartPolicy);
	const policyDetailsValid =
		!isEmptyString(name) && isValidName && !isEmptyObject(selectedOrganisation);
	const policyConfigurationValid =
		((depositAmountRequired && personalBillingDeposit === 0) ||
			!(depositAmountRequired && !personalBillingDepositValid)) &&
		durationDifferenceBookingUpfrontValid;

	const editValid =
		isObject(editPolicyData) &&
		isFullString(name) &&
		isValidName &&
		durationDifferenceBookingUpfrontValid;

	const [startedFinishWizard, setStartedFinishWizard] = useState(false);

	const [shouldSubmitPolicy, setShouldSubmitPolicy] = useState(false);
	const [submittingPolicy, setSubmittingPolicy] = useState(false);
	const [submittedPolicy, setSubmittedPolicy] = useState(false);

	const {
		data: addedPolicyData,
		loading: addedPolicyLoading,
		error: addedPolicyError,
	} = addedPolicy;
	const addPolicyDone = isObject(addedPolicyData) && !addedPolicyLoading && !addedPolicyError;
	const [addingPolicy, setAddingPolicy] = useState(false);

	const { data: updatePolicyData, error: updatePolicyError } = updatePolicy;

	const updatePolicyMessage = useError(
		isObject(updatePolicy) ?
			{
				value: updatePolicy,
				message: `${t('ui.successfully')} ${t('ui.updated')} ${isObject(updatePolicyData) && updatePolicyData.name}`,
			}
		:	null,
	);

	const { enqueueSnackbar } = useSnackbar();

	const handleAddPolicy = () => {
		if (editing) {
			onUpdatePolicy(
				editPolicyData.id,
				name,
				{
					...{ requireHostConfirmation },
					...{ requireOvertimeValidation },
					...(hasMinDuration && { minBookingDurationMinutes: minDurationPolicy }),
					...(hasMaxDuration && { maxBookingDurationMinutes: maxDurationPolicy }),
					...(hasMinDurationBeforeStart && {
						minDurationBeforeStartMinutes: minDurationBeforeStartDayPolicy,
					}),
					...(hasMaxDurationBeforeStart && {
						maxDurationBeforeStartMinutes: maxDurationBeforeStartPolicy,
					}),
					...(hasBufferPeriod && { warmupBufferMinutes: beforeUse }),
					...(hasBufferPeriod && { cooldownBufferMinutes: afterUse }),
					...(hasGracePeriod && { overtimeGracePeriodMinutes: gracePeriod }),
					...(hasCancellationPeriod && { minDurationBeforeCancelMinutes: cancellationPolicy }),
					...{ allowPersonalBilling },
					...{ allowExtending },
					...{ requireDutchLicense },
					...{ requiredLicenseStatus },
					...{ requiredParkingLicensePlateNumber },
					...(depositAmountRequired && { personalBillingDeposit }),
					...{ requireReservationComment },
					...{ openHoursRestrictionType },
				},
				editRedirect,
			);
			updatePolicyMessage.setStartAction(true);

			// Less than ideal, but if we don't use a timeout we miss the snackbar.
			// It seems that there is also no proper state change to listen to.
			setTimeout(() => navigate(`/${path.join(pagePathSegments.PolicyManagement, pagePathSegments.ItemPolicies)}`), 500);
		} else {
			onAddPolicy(selectedOrganisation.id, name, {
				...{ requireHostConfirmation },
				...{ requireOvertimeValidation },
				...(hasMinDuration && { minBookingDurationMinutes: minDurationPolicy }),
				...(hasMaxDuration && { maxBookingDurationMinutes: maxDurationPolicy }),
				...(hasMinDurationBeforeStart && {
					minDurationBeforeStartMinutes: minDurationBeforeStartDayPolicy,
				}),
				...(hasMaxDurationBeforeStart && {
					maxDurationBeforeStartMinutes: maxDurationBeforeStartPolicy,
				}),
				...(hasBufferPeriod && { warmupBufferMinutes: beforeUse }),
				...(hasBufferPeriod && { cooldownBufferMinutes: afterUse }),
				...(hasGracePeriod && { overtimeGracePeriodMinutes: gracePeriod }),
				...(hasCancellationPeriod && { minDurationBeforeCancelMinutes: cancellationPolicy }),
				...{ allowPersonalBilling },
				...{ allowExtending },
				...{ requireDutchLicense },
				...{ requiredLicenseStatus },
				...{ requiredParkingLicensePlateNumber },
				...(depositAmountRequired && { personalBillingDeposit }),
				...{ requireReservationComment },
				...{ openHoursRestrictionType },
			});
			setAddingPolicy(true);
		}
	};

	useEffect(() => {
		if (!editing && addingPolicy) {
			if (addPolicyDone) {
				setAddingPolicy(false);
				enqueueSnackbar(`${t('ui.successfullyAdded')} ${addedPolicyData.name}`, {
					variant: 'success',
				});
				navigate(`/${path.join(pagePathSegments.PolicyManagement, pagePathSegments.ItemPolicies)}`);
			} else if (addedPolicyError) {
				setAddingPolicy(false);
				enqueueSnackbar(isObject(addedPolicyError) ? addedPolicyError.message : addedPolicyError, {
					variant: 'error',
				});
			}
		}
	}, [addingPolicy, addedPolicyError, addPolicyDone, editing]);

	useEffect(() => {
		if (!isNull(addedPolicyError) || !isNull(updatePolicyError)) {
			setStartedFinishWizard(false);
		}
	}, [addedPolicyError, updatePolicyError]);

	useEffect(() => {
		if (shouldSubmitPolicy && !submittingPolicy && !submittedPolicy) {
			setShouldSubmitPolicy(false);
			setSubmittingPolicy(true);
			handleAddPolicy();
		}
	}, [shouldSubmitPolicy, submittingPolicy, submittedPolicy]);

	useEffect(() => {
		if (submittingPolicy && addPolicyDone) {
			setSubmittingPolicy(false);
			setSubmittedPolicy(true);
		}
	});

	// reset on error
	useEffect(() => {
		if (addedPolicyError || updatePolicyError) {
			setShouldSubmitPolicy(false);
			setSubmittingPolicy(false);
			setSubmittedPolicy(false);
		}
	}, [addedPolicyError, updatePolicyError]);

	const validate = (value, validation, update, negative = false) => {
		if (!negative ? validation(value) : !validation(value)) {
			update(value);
		}
	};

	const callback = (data) => {
		const {
			updatedMinDuration,
			updatedMinDurationDay,
			updatedMinDurationHour,
			updatedMinDurationMin,
			updatedMaxDuration,
			updatedMaxDurationDay,
			updatedMaxDurationHour,
			updatedMaxDurationMin,
			//Booking Upfront
			updatedMinDurationBeforeStart,
			updatedMinDurationBeforeStartDay,
			updatedMinDurationBeforeStartHour,
			updatedMaxDurationBeforeStart,
			updatedMinDurationBeforeStartMin,
			updatedMaxDurationBeforeStartDay,
			updatedMaxDurationBeforeStartHour,
			updatedMaxDurationBeforeStartMin,
			updatedHostConfirmation,
			updatedOpenHoursRestrictionType,
			name,
			selectedOrganisation,
			hasBufferPeriod,
			daysBeforeUse,
			hoursBeforeUse,
			minBeforeUse,
			daysAfterUse,
			hoursAfterUse,
			minAfterUse,
			hasGracePeriod,
			daysGracePeriod,
			hoursGracePeriod,
			minGracePeriod,
			hasCancellationPeriod,
			cancelDays,
			cancelHours,
			cancelMins,
			requireDutchLicense,
			allowExtending,
			requiredLicenseStatus,
			requiredParkingLicensePlateNumber,
			depositAmountRequired,
			personalBillingDeposit,
			requireReservationComment,
			personalBilling,
			updatedOvertimeValidation,
			finishedWizard,
		} = data;

		if (!isUndefined(name)) {
			setName(name.value);
			setIsValidName(name.valid);
		}

		console.log('wiz', selectedOrganisation)
		if (!isUndefined(selectedOrganisation)) {
			setSelectedOrganisation(selectedOrganisation.value);
		}
		validate(updatedHostConfirmation, isUndefined, setRequireHostConfirmation, true);
		validate(updatedOpenHoursRestrictionType, isUndefined, setOpenHoursRestrictionType, true);
		validate(updatedMinDuration, isUndefined, setHasMinDuration, true);
		validate(updatedMinDurationDay, isUndefined, setMinDurationDay, true);
		validate(updatedMinDurationHour, isUndefined, setMinDurationHour, true);
		validate(updatedMinDurationMin, isUndefined, setMinDurationMin, true);
		validate(updatedMaxDuration, isUndefined, setHasMaxDuration, true);
		validate(updatedMaxDurationDay, isUndefined, setMaxDurationDay, true);
		validate(updatedMaxDurationHour, isUndefined, setMaxDurationHour, true);
		validate(updatedMaxDurationMin, isUndefined, setMaxDurationMin, true);

		validate(updatedMinDurationBeforeStart, isUndefined, setHasMinDurationBeforeStart, true);
		validate(updatedMinDurationBeforeStartDay, isUndefined, setMinDurationBeforeStartDay, true);
		validate(updatedMinDurationBeforeStartHour, isUndefined, setMinDurationBeforeStartHour, true);
		validate(updatedMinDurationBeforeStartMin, isUndefined, setMinDurationBeforeStartMin, true);

		validate(updatedMaxDurationBeforeStart, isUndefined, setHasMaxDurationBeforeStart, true);
		validate(updatedMaxDurationBeforeStartDay, isUndefined, setMaxDurationBeforeStartDay, true);
		validate(updatedMaxDurationBeforeStartHour, isUndefined, setMaxDurationBeforeStartHour, true);
		validate(updatedMaxDurationBeforeStartMin, isUndefined, setMaxDurationBeforeStartMin, true);

		validate(updatedOvertimeValidation, isUndefined, setRequireOvertimeValidation, true);
		validate(hasBufferPeriod, isUndefined, setHasBufferPeriod, true);
		validate(daysBeforeUse, isUndefined, setDaysBeforeUse, true);
		validate(hoursBeforeUse, isUndefined, setHoursBeforeUse, true);
		validate(minBeforeUse, isUndefined, setMinBeforeUse, true);
		validate(daysAfterUse, isUndefined, setDaysAfterUse, true);
		validate(hoursAfterUse, isUndefined, setHoursAfterUse, true);
		validate(minAfterUse, isUndefined, setMinAfterUse, true);
		validate(hasGracePeriod, isUndefined, setHasGracePeriod, true);
		validate(daysGracePeriod, isUndefined, setDaysGracePeriod, true);
		validate(hoursGracePeriod, isUndefined, setHoursGracePeriod, true);
		validate(minGracePeriod, isUndefined, setMinGracePeriod, true);
		validate(hasCancellationPeriod, isUndefined, setHasCancellationPeriod, true);
		validate(cancelDays, isUndefined, setCancelDay, true);
		validate(cancelHours, isUndefined, setCancelHour, true);
		validate(cancelMins, isUndefined, setCancelMin, true);
		validate(allowExtending, isUndefined, setAllowExtending, true);
		validate(requireDutchLicense, isUndefined, setRequireDutchLicense, true);
		validate(requiredLicenseStatus, isUndefined, setRequiredLicenseStatus, true);
		validate(
			requiredParkingLicensePlateNumber,
			isUndefined,
			setRequiredParkingLicensePlateNumber,
			true,
		);
		validate(depositAmountRequired, isUndefined, setDepositAmountRequired, true);
		validate(personalBilling, isUndefined, setAllowPersonalBilling, true);
		validate(requireReservationComment, isUndefined, setRequireReservationComment, true);

		if (!isUndefined(personalBillingDeposit)) {
			setPersonalBillingDeposit(personalBillingDeposit.value);
			setPersonalBillingDepositValid(personalBillingDeposit.valid);
		}

		if (finishedWizard && !startedFinishWizard) {
			if (editing) {
				setOpenDialog(true);
			} else {
				setStartedFinishWizard(true);
				setShouldSubmitPolicy(true);
			}
		}
	};

	const handleConfirmDialog = () => {
		setOpenDialog(false);
		setStartedFinishWizard(true);
		setShouldSubmitPolicy(true);
	};

	const handleCancelDialog = () => {
		setOpenDialog(false);
	};

	// wizard steps
	const addPolicySteps = [
		{
			name: t('views.addPolicy.steps.details'),
			content: PolicyDetails,
			valid: editing ? editValid : policyDetailsValid,
			props: {
				name,
				selectedOrganisation,
				editing,
				currentPolicyName: editPolicyData ? editPolicyData.name : null,
			},
		},
		{
			name: t('views.addPolicy.steps.configuration'),
			content: PolicyConfiguration,
			valid: editing ? editValid : policyConfigurationValid,
			props: {
				hasMinDuration,
				minDurationDay,
				minDurationHour,
				minDurationMin,
				hasMaxDuration,
				maxDurationDay,
				maxDurationHour,
				maxDurationMin,
				minDurationBeforeStartDay,
				minDurationBeforeStartHour,
				minDurationBeforeStartMin,
				maxDurationBeforeStartDay,
				maxDurationBeforeStartHour,
				maxDurationBeforeStartMin,
				hasCancellationPeriod,
				cancelMin,
				cancelDay,
				requireHostConfirmation,
				openHoursRestrictionType,
				cancelHour,
				daysBeforeUse,
				hasBufferPeriod,
				hasGracePeriod,
				hoursBeforeUse,
				requireDutchLicense,
				hasMinDurationBeforeStart,
				hasMaxDurationBeforeStart,
				minBeforeUse,
				daysAfterUse,
				hoursAfterUse,
				minAfterUse,
				daysGracePeriod,
				hoursGracePeriod,
				minGracePeriod,
				allowExtending,
				requiredLicenseStatus,
				requiredParkingLicensePlateNumber,
				depositAmountRequired,
				personalBillingDeposit,
				requireReservationComment,
				editing,
				editPolicyData,
				name,
				requireOvertimeValidation,
				allowPersonalBilling,
				setAllowPersonalBilling,
				bookingUpfrontMaxDurationSmallerThanMinDuration: !durationDifferenceBookingUpfrontValid,
			},
		},
	];

	return (
		<>
			<Wizard
				callback={callback}
				loading={startedFinishWizard}
				stepperType={'progress'}
				// activeStartStep={editing ? 1 : 0}
				steps={addPolicySteps}
				title={t('views.addPolicy.page.title')}
			/>
			<AlertDialog
				dialogDescription={`${t('ui.editing')} ${t('ui.policy')} ${name}, ${t('views.addPolicy.page.dialogDescription.impact')}`}
				dialogTitle={t('ui.Warning')}
				handleClose={handleCancelDialog}
				handleConfirm={handleConfirmDialog}
				open={openDialog}
			/>
		</>
	);
};

AddPolicy.propTypes = {
	addedPolicy: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	onAddPolicy: PropTypes.func.isRequired,
	editing: PropTypes.bool,
	editPolicyData: PropTypes.object,
	onUpdatePolicy: PropTypes.func,
	updatePolicy: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	editRedirect: PropTypes.bool,
};

const mapStateToProps = (state) => {
	return {
		addedPolicy: state.details.addedPolicy,
		updatePolicy: state.details.updatePolicy,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onAddPolicy: (organisationId, name, data) =>
			dispatch(actions.addPolicy(organisationId, name, data)),
		onUpdatePolicy: (id, name, data, editRedirect) =>
			dispatch(actions.updatePolicy(id, name, data, editRedirect)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AddPolicy);
