import path from 'path';

import { PagedResults } from '~interfaces';
import { GeneralPagedParameters } from '~interfaces/requests';
import { BaseReferenceResponse, IdReferenceResponse, PagedResponse } from '~interfaces/responses';
import { TopologyService } from '~services';

import UserLicenseValidationStatusEnum from '../enums/userLicenseValidationStatusEnum';
import UserLicenseVerificationStatusEnum from '../enums/userLicenseVerificationStatusEnum';
import UserLicense from '../interfaces/userLicense';

/**
 * A service to get "business logic" users. E.g. user data from
 * the Core service
 */
class UserLicensesService extends TopologyService {
	public readonly path = 'userlicenses';

	constructor() {
		super('v1');
	}

	/**
	 * Get a paginated list of users
	 * @param page The number of the page
	 * @param pageSize The amount of results of the page
	 * @returns
	 */
	async getUserLicenses({
		page = 1,
		pageSize = 10,
		sortBy = 'id',
		descending = true,
		...args
	}: GeneralPagedParameters<'id'> & {
		userGroupId?: string;
	}): Promise<PagedResults<UserLicense>> {
		const { data } = await this._client.get<PagedResponse<UserLicenseResponse>>(this.path, {
			params: {
				pageNumber: page,
				pageSize: pageSize,
				organisationId: args.organisationId,
				userGroupId: args.userGroupId,
				sortBy: sortBy,
				orderDescending: descending,
				...(args.searchQuery && { searchTerm: args.searchQuery }),
			},
		});

		return this.mapPagedResponse(data, UserLicensesService.fromResponse);
	}

	/**
	 * Get details about a specific user
	 * @param id
	 * @returns
	 */
	async getUserLicenseById(id: string): Promise<UserLicense> {
		const { data } = await this._client.get<UserLicenseResponse>(path.join(this.path, id));

		return UserLicensesService.fromResponse(data);
	}
	
	static fromResponse(data: UserLicenseResponse): UserLicense {
		const {
			id,
			userReference,
			dateOfBirth,
			expiryDate,
			validationDate,
			createdDate,
			submitDate,
			lastModifiedDate,
			verificationStatus,
			validationStatus,
			...rest
		} = data;

		return {
			id: id.toString(),
			user: UserLicensesService.fromBaseReferenceResponse(userReference),
			dateOfBirth: new Date(dateOfBirth),
			submitDate: new Date(submitDate),
			expiryDate: new Date(expiryDate),
			validationDate: new Date(validationDate),
			modifiedDate: new Date(lastModifiedDate),
			createdDate: new Date(createdDate),
			verificationStatus: verificationStatus as UserLicenseVerificationStatusEnum,
			validationStatus: validationStatus as UserLicenseValidationStatusEnum,
			...rest,
		};
	}
}

interface UserLicenseResponse extends IdReferenceResponse {
	licenseNumber: string;
	isDuplicate: boolean;
	dateOfBirth: string;
	expiryDate: string;
	countryCode: string;
	status: string;
	verificationStatus: string;
	validationStatus: string;
	validationDate: string;
	userReference: BaseReferenceResponse;
	images: [{ front: string[] }, { back: string[] }, { selfie: string[] }];
	createdDate: string;
	submitDate: string;
	lastModifiedDate: string;
	isOnHold: boolean;
}

export default UserLicensesService;
