import { forwardRef, Ref, useEffect, useImperativeHandle, useMemo, useState } from 'react';

import path from 'path';

import { ajvResolver } from '@hookform/resolvers/ajv';
import { Info as InfoIcon } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';
import { JSONSchemaType } from 'ajv';
import dayjs from 'dayjs';
import { useAtomValue } from 'jotai';
import moment from 'moment';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useSWRImmutable from 'swr/immutable';

import { userInfoAtom } from '~atoms';
import { DatePicker, FormContainer, TextField, Tip } from '~components';
import { useFormContainerState } from '~components/dialogs/formContainerProvider';
import { useAuthorize } from '~features/authentication';
import { Organisation, OrganisationAutocomplete } from '~features/organisations';
import { DateRange } from '~interfaces/dateRanges';
import { FormWrapperRefProps } from '~interfaces/refProps';

import schema from './skcAccessRuleSchema.json';
import selectedOrganisationAtom from '../../atoms/selectedOrganisationAtom';
import DeviceGroupAutocomplete from '../../components/autocompletes/deviceGroupsAutocomplete';
import ScheduleAutocomplete from '../../components/autocompletes/schedulesAutocomplete';
import SkcUserGroupAutocomplete from '../../components/autocompletes/skcUserGroupsAutocomplete';
import { AccessRuleNew } from '../../interfaces/accessRule';
import SkcAccessRulesService from '../../services/skcAccessRulesService';

const service = new SkcAccessRulesService();

interface SkcAccessRuleFormProps {
	id?: string;
	onSubmit?: (value: AccessRuleNew) => void;
}

const SkcAccessRuleForm = forwardRef<FormWrapperRefProps, SkcAccessRuleFormProps>(
	({ id, onSubmit }, ref) => {
		const { t } = useTranslation('general');
		const { isSuperAdmin } = useAuthorize();
		const userInfo = useAtomValue(userInfoAtom);
		const selectedOrganisation = useAtomValue(selectedOrganisationAtom);

		const editMode: boolean = id != null;

		const [datePickerFocus, setDatePickerFocus] = useState({});

		const { data, isLoading } = useSWRImmutable(
			id ? [service.basePath, id] : null,
			([_, args]) => service.getAccessRuleById(args),
			{
				revalidateOnMount: true,
			},
		);

		const { setDisabled } = useFormContainerState();
		const { getValues, control, formState, reset, watch, setValue, resetField } =
			useForm<AccessRuleNew>({
				defaultValues: useMemo<Partial<AccessRuleNew>>(
					() => ({
						label: '',
						description: '',
						period: {
							start: dayjs().startOf('day').toDate(),
						},
						organisation: isSuperAdmin() ? undefined : userInfo.organisation,
						...data,
					}),
					[data],
				),
				mode: 'onChange',
				resolver: ajvResolver(schema as JSONSchemaType<any>),
			});

		const watchOrganisation = watch('organisation');
		const watchPeriod = watch('period');

		useEffect(() => {
			if (data) {
				// Used to reset the useform, otherwise the page won't properly reload
				reset({
					...data,
					organisation: selectedOrganisation,
				});
			}
		}, [data]);

		useImperativeHandle(
			ref,
			() => ({
				onSubmit: () => onSubmit?.(getValues()),
			}),
			[onSubmit],
		);

		useEffect(() => {
			setDisabled?.(!formState.isValid);
		}, [watchPeriod, formState.isValid, setDisabled]);

		/**
		 * Do this through a useEffect? A bit more stable if we change
		 * the organisation programmatically
		 * @param newOrg
		 * @param onChange
		 */
		const handleOrgansationChange = (newOrg: Organisation, onChange: (...event: any[]) => void) => {
			const previousOrg: Organisation | undefined = getValues('organisation');
			if (previousOrg?.id !== newOrg.id && !editMode) {
				resetField('deviceGroup');
				resetField('userGroup');
				resetField('schedule');
			}

			onChange(newOrg);
		};

		const handleDateChange = (newValue, onChange: (period: Partial<DateRange>) => void) => {
			newValue.startDate?.set('hour', 0);
			newValue.startDate?.set('minute', 0);
			newValue.startDate?.set('second', 0);
			newValue.endDate?.set('hour', 23);
			newValue.endDate?.set('minute', 59);
			newValue.endDate?.set('second', 59);

			onChange({
				start: newValue.startDate?.toDate(),
				end: newValue.endDate?.toDate(),
			});
		};

		return (
			<FormContainer loading={isLoading}>
				{isSuperAdmin() && !editMode && (
					<Controller
						name='organisation'
						control={control}
						render={({ field }) => (
							<OrganisationAutocomplete
								value={field.value}
								// onChange={(_, newValue) => field.onChange(newValue)}
								onChange={(_, newValue, reason) =>
									reason !== 'clear' && handleOrgansationChange(newValue, field.onChange)
								}
								required
							/>
						)}
					/>
				)}
				<Controller
					name='label'
					control={control}
					render={({ field }) => (
						<TextField
							{...field}
							label={t('ui.label.name')}
							required
							slotProps={{
								htmlInput: {
									minLength: schema.properties.label.minLength,
									maxLength: schema.properties.label.maxLength,
								},
							}}
						/>
					)}
				/>
				<Controller
					name='description'
					control={control}
					render={({ field }) => (
						<TextField
							{...field}
							label={t('ui.label.description')}
							slotProps={{
								htmlInput: {
									maxLength: schema.properties.description.maxLength,
								},
							}}
						/>
					)}
				/>
				<Controller
					name='period'
					control={control}
					render={({ field }) => (
						<Box
							sx={{
								width: 'fit-content',
								overflow: 'visible',
								zIndex: 10000,
							}}
						>
							<DatePicker
								focused={datePickerFocus?.focusedInput}
								handleDatesChange={(val) => handleDateChange(val, field.onChange)}
								handleFocusChange={(focusedInput) => setDatePickerFocus({ focusedInput })}
								start={field.value?.start != null ? moment(field.value?.start) : null}
								end={field.value?.end != null ? moment(field.value?.end) : null}
								minDate={moment().subtract(1, 'days')}
							/>
						</Box>
					)}
				/>
				<Tip message={t('addAccessRuleTipMessage')} />
				{watchOrganisation == null && isSuperAdmin() ?
					<Stack
						direction='row'
						spacing={1}
						sx={{ pt: '2rem', justifyContent: 'center', alignItems: 'center' }}
					>
						<InfoIcon />
						<Typography>Select an organisation before adding groups and schedules</Typography>
					</Stack>
				:	<>
						<Controller
							name='deviceGroup'
							control={control}
							render={({ field }) => (
								<DeviceGroupAutocomplete
									value={field.value}
									enableInfiniteScroll
									organisation={watchOrganisation}
									onChange={(_, newVal, reason) => reason !== 'clear' && field.onChange(newVal)}
								/>
							)}
						/>
						<Controller
							name='userGroup'
							control={control}
							render={({ field }) => (
								<SkcUserGroupAutocomplete
									value={field.value}
									enableInfiniteScroll
									organisation={watchOrganisation}
									onChange={(_, newVal, reason) => reason !== 'clear' && field.onChange(newVal)}
								/>
							)}
						/>
						<Controller
							name='schedule'
							control={control}
							render={({ field }) => (
								<ScheduleAutocomplete
									value={field.value}
									enableInfiniteScroll
									organisation={watchOrganisation}
									onChange={(_, newVal, reason) => reason !== 'clear' && field.onChange(newVal)}
								/>
							)}
						/>
					</>
				}
			</FormContainer>
		);
	},
);
SkcAccessRuleForm.displayName = 'SkcAccessRuleForm';

export default SkcAccessRuleForm;
