// Based on the figma designs
export const colorsFull = [
	'#002b66',
	'#0053C4',
	'#338AFF',
	'#66a7ff',
	'#ff9500',
	'#FFBF66',
	'#dddddd',
	// '#FFDFB2',
];

// Based on the figma designs
export const colorsSlim = [
	'#338AFF',
	'#ff9500',
	'#dddddd',
];
