/**
 * As quoted from the backend:
 * - The status of the license validation (automatic RDW validation for Dutch licenses) process
 */
enum UserLicenseValidationStatusEnum {
	NotApplicable = 'notApplicable',
	Validated = 'validated',
	Rejected = 'rejected',
	Blocked = 'blocked',
}

export default UserLicenseValidationStatusEnum;
