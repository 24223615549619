import { useState } from 'react';

import AddGroupIcon from '@mui/icons-material/GroupAdd';
import {
	Box,
	Card,
	CardContent,
	CardActions,
	CardHeader,
	Divider,
	Table,
	TableBody,
	TableRow,
	TableCell,
} from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useStyles } from './style';
import { StyledButton } from '../../../../../../components';

const OrganisationInfo = (props) => {
	const { customer, isInternalUser, isSuperAdmin, className } = props;
	const { t } = useTranslation('general');
	const navigate = useNavigate();

	const classes = useStyles();

	const [selectedUsers] = useState([{ ...customer }]);

	const handleAssignUserToGroup = () => {
		navigate('/user-management/users/assign/user-group', {
			state: { selectedUsers },
		});
	};

	return (
		<>
			<Card className={clsx(classes.root, className)}>
				<CardHeader title={t('views.userDetails.summary.cardHeaders.organisation')} />
				<Divider />
				<CardContent>
					<Table>
						<TableBody>
							<TableRow>
								<TableCell>{t('ui.label.organisation')}</TableCell>
								<TableCell>
									<Box alignItems='center' display='flex'>
										{customer.organisationReference.logo ?
											<img className={classes.logo} src={customer.organisationReference.logo} />
										:	null}{' '}
										{customer.organisationReference.name}
									</Box>
								</TableCell>
							</TableRow>
							{isSuperAdmin || isInternalUser ?
								<>
									<TableRow>
										<TableCell>{t('ui.label.userGroup')}</TableCell>
										<TableCell>
											{customer?.userGroupReference && customer?.userGroupReference?.name}
										</TableCell>
									</TableRow>
								</>
							:	null}
						</TableBody>
					</Table>
				</CardContent>
				{(isSuperAdmin || isInternalUser) && (
					<CardActions className={classes.actions}>
						<StyledButton
							onClick={handleAssignUserToGroup}
							startIcon={<AddGroupIcon />}
							variant='inline-default'
						>
							{t('ui.button.inline.assigntousergroup')}
						</StyledButton>
					</CardActions>
				)}
			</Card>
		</>
	);
};

OrganisationInfo.propTypes = {
	className: PropTypes.string,
	customer: PropTypes.object.isRequired,

	isInternalUser: PropTypes.bool,
	isSuperAdmin: PropTypes.bool,
};

export default OrganisationInfo;
