import { createRef, useEffect, useState } from 'react';

import { SnackbarProvider } from 'notistack';
import { useAuth, hasAuthParams } from 'react-oidc-context';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import { StyledButton } from './components';
import routes from './routes/routes';
import AppTheme from './themes/theme';

import './app.css';

function App(props) {
	const auth = useAuth();
	const [hasTriedSignin, setHasTriedSignin] = useState(false);

	useEffect(() => {
		if (
			!hasAuthParams() &&
			!auth.isAuthenticated &&
			!auth.activeNavigator &&
			!auth.isLoading &&
			!hasTriedSignin
		) {
			auth.signinSilent();
			setHasTriedSignin(true);
		}
	}, [auth, hasTriedSignin]);

	const notistackRef = createRef();
	const onClickDismiss = (key) => {
		notistackRef.current.closeSnackbar(key);
	};

	const router = createBrowserRouter(routes());

	return (
		<div className='app'>
			<SnackbarProvider
				ref={notistackRef}
				// action={(key) => (
				// 	<IconButton
				// 		onClick={() => notistackRef.current.closeSnackbar(key)}
				// 		sx={{ color: 'white' }}
				// 	>
				// 		<CloseIcon />
				// 	</IconButton>
				// )}
				action={(key) => (
					<StyledButton
						onClick={() => onClickDismiss(key)}
						style={{
							height: '100%',
							left: 0,
							position: 'absolute',
							top: 0,
							width: '100%',
							backgroundColor: 'transparent',
						}}
					>
						<div></div>
					</StyledButton>
				)}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				autoHideDuration={5000}
				maxSnack={3}
				style={{
					maxWidth: '300px',
					display: 'flex',
					justifyContent: 'flex-start',
					fontFamily: 'Poppins, sans-serif',
					fontSize: '12px',
				}}
			>
				<RouterProvider router={router} />
			</SnackbarProvider>
		</div>
	);
}

export default App;
