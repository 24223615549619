import { Box, Stack, useTheme } from '@mui/material';
import {
	ChartsLegend,
	ChartsText,
	ChartsTooltip,
	legendClasses,
	PieChart as MuiPieChart,
	PieChartProps
} from '@mui/x-charts';

import { colorsSlim } from '~themes/chartColors';

/**
 * A wrapper component for the mui pie chart
 */
const PieChart = ({
	colors = colorsSlim,
	...chartProps
}: PieChartProps) => {
	const theme = useTheme();

	return (
		<MuiPieChart
			{...chartProps}
			colors={colors}
			slots={{
				noDataOverlay: undefined
			}}
			slotProps={{
				noDataOverlay: {
					message: ''
				},
				legend: {
					itemMarkWidth: 8,
					itemMarkHeight: 8,
					direction: 'row',
					itemGap: 15,
					position: {
						horizontal: 'middle',
						vertical: 'bottom',
					},
					...chartProps.slotProps?.legend,
				},
				popper: {
					sx: {
						fontFamily: theme.typography.fontFamily,
						fontSize: theme.typography.body1.fontSize,
						color: theme.palette.text.primary
					}
				},
				...chartProps.slotProps
			}}
			margin={{
				...chartProps.margin,
				left: 10,
				right: 0,
				bottom: 70,
			}}
			sx={{
				[`& .${legendClasses.mark}`]: {
					ry: 10,
				},
			}}
		/>
	);
};

export default PieChart;
