import { useState, useEffect } from 'react';

import {
	TextField,
	MenuItem,
	Link,
	Card,
	CardHeader,
	CardContent,
	Divider,
	Table,
	TableBody,
	TableRow,
	TableCell,
	Box,
	Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { useAuthorize } from '~features/authentication';

import { SelectWithLazyLoading } from '../../../../../components';
import { isInteger, isEmptyString } from '../../../../../shared/utility';
import * as actions from '../../../../../store/actions';
import { useStyles } from '../style';

const AssignedUser = (props) => {
	const {
		onFetchUsers,
		users,
		onAssignUserContract,
		assignUserContract,
		contractData,
		onUpdateDetailsState,

		onResetStateCondition,
		currentUser,
		onBusinessUsage,
		businessUsage,
	} = props;

	const { t } = useTranslation('general');
	const { isSuperAdmin } = useAuthorize();

	const { success: assignUserContractSuccess, error: assignUserContractError } = assignUserContract;

	const { success: businessUsageSuccess, error: businessUsageError } = businessUsage;

	const { data: currentUserData } = currentUser;

	const isProvider =
		currentUserData?.organisationReference?.id !== contractData?.userOrganisation?.id;
	const isInternalContracts = contractData.type === 'internal';

	const [selectedUser, setSelectedUser] = useState('');
	const [userFilter, setUserFilter] = useState('all');

	const [usageType, setUsageType] = useState(contractData?.defaultUsageIsBusiness);

	const classes = useStyles();

	const { enqueueSnackbar } = useSnackbar();

	const handleSelectUser = (user) => {
		if (user.id === userFilter) {
			return;
		}
		if (isInteger(user.id)) {
			setSelectedUser(user.name);
		} else if (isEmptyString(user.id)) {
			setSelectedUser('');
		}
		setUserFilter(user.id);
		onAssignUserContract(contractData.id, { value: user.id });
	};

	useEffect(() => {
		if (assignUserContractSuccess) {
			enqueueSnackbar(`${t('views.contract.details.success.message')}`, { variant: 'success' });
			onResetStateCondition('assignUserContract', false);
			onUpdateDetailsState('contract', {
				...contractData,
				user: {
					name: selectedUser,
					id: userFilter,
				},
			});
		} else if (assignUserContractError) {
			enqueueSnackbar(assignUserContractError.message, { variant: 'error' });
			onResetStateCondition('assignUserContract', false);
		}
	}, [assignUserContract]);

	useEffect(() => {
		if (businessUsageSuccess) {
			enqueueSnackbar(t(`views.contract.usage.success.message.${usageType}`), {
				variant: 'success',
			});
			onResetStateCondition('businessUsage', false);
		} else if (businessUsageError) {
			enqueueSnackbar('error', { variant: 'error' });
			onResetStateCondition('businessUsage', false);
		}
	}, [businessUsage]);

	const onChangeUsageType = (event) => {
		setUsageType(!usageType);
		onBusinessUsage(contractData.id, { value: event.target.value });
	};

	const usageTypes = [
		{ name: 'business', value: true },
		{ name: 'personal', value: false },
	];

	return (
		<Card className={classes.cardRoot}>
			<CardHeader title={t('views.contract.details.assignUser')} />
			<Divider />
			<CardContent className={classes.content}>
				{contractData?.user ?
					<Table>
						<TableBody>
							<TableRow>
								<TableCell>{t('views.contracts.table.assignedUser')}</TableCell>
								<TableCell>
									{isSuperAdmin() || !isProvider || isInternalContracts ?
										<Link
											className={classes.itemGroup}
											color='primary'
											component={RouterLink}
											to={`/user-management/users/${contractData.user.id}/summary`}
											state={{
												from: location.pathname,
												label: t('ui.label.contracts'),
											}}
										>
											{contractData.user.name}
										</Link>
									:	contractData.user.name}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>{t('views.planboard.addBooking.usage.sectionTitle')}</TableCell>
								<TableCell>
									<TextField
										fullWidth
										onChange={onChangeUsageType}
										select
										size='small'
										disabled={contractData.status === 'terminated'}
										// variant="standard"
										// InputProps={{ disableUnderline: true, }}
										value={usageType}
										variant='outlined'
									>
										{usageTypes.map((usage) => (
											<MenuItem key={usage.name} value={usage.value}>
												{t(`ui.label.select.${usage.name}`)}
											</MenuItem>
										))}
									</TextField>
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				:	<Box display='flex' flexDirection='column' p={2}>
						{isProvider ?
							<Typography pb={2}>
								{t('views.planboard.addBooking.notification.noUserSelected')}
							</Typography>
						:	<>
								<Typography pb={2}>{t('ui.label.users')}</Typography>
								<Box pb={2}>
									<SelectWithLazyLoading
										dataList={users}
										listType='users'
										onFetchData={onFetchUsers}
										placeholder={t('ui.placeholders.search.user')}
										setSelected={handleSelectUser}
										value={selectedUser}
									/>
								</Box>
							</>
						}
					</Box>
				}
			</CardContent>
		</Card>
	);
};

AssignedUser.propTypes = {
	className: PropTypes.string,

	onFetchUsers: PropTypes.func,
	onAssignUserContract: PropTypes.func,
	contractData: PropTypes.object,
	onUpdateDetailsState: PropTypes.func,
	users: PropTypes.any,
	onResetStateCondition: PropTypes.func,
	onBusinessUsage: PropTypes.func,

	assignUserContract: PropTypes.shape({
		success: PropTypes.bool,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	currentUser: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	businessUsage: PropTypes.shape({
		success: PropTypes.bool,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
};

const mapStateToProps = (state) => {
	return {
		users: state.paged.users,
		assignUserContract: state.condition.assignUserContract,
		currentUser: state.details.currentUser,

		businessUsage: state.condition.businessUsage,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchUsers: (page, filters, concat) => dispatch(actions.fetchUsers(page, filters, concat)),
		onAssignUserContract: (contractId, bodyData) =>
			dispatch(actions.assignUserContract(contractId, bodyData)),
		onUpdateDetailsState: (state, data) => dispatch(actions.updateDetailsState(state, data)),
		onBusinessUsage: (contractId, bodyData) =>
			dispatch(actions.businessUsage(contractId, bodyData)),
		onResetStateCondition: (state, value) => dispatch(actions.resetStateCondition(state, value)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AssignedUser);
