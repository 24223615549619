import { useState, useEffect } from 'react';

import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useStyles } from './style';
import { Table, SearchBar, EmptyTable } from '../../../../components';
import { gpsLocationIcons } from '../../../../constantsOld';
import { commaTimeStrings } from '../../../../shared/datetime';
import {
	usePeriodFilter,
	useSearchComponent,
	useBasicFilter,
	usePagination,
} from '../../../../shared/hooks';
import {
	isObject,
	isEmptyArray,
	isEmptyString,
	isFullArray,
	isUndefined,
	isBoolean,
	isFullString,
	convertBatteryLevelToEnum,
} from '../../../../shared/utility';
import * as actions from '../../../../store/actions';
import { MyTooltip } from '../../DevicesList/style';

const deviceState = [
	'none',
	'open',
	'closed',
	'inUse',
	'hwError',
	'bootError',
	'updating',
	'charging',
	'available',
];

const HeartbeatsList = (props) => {
	const {
		match,
		onFetchHeartbeats,
		fetchHeartbeats,
		isTrailerLock,
		isVehicleTracker,
		fetchDevice,
		gpsIcons,
		onFetchDevicesUnlockTypes,
		isBoat,
	} = props;
	const { t } = useTranslation('general');

	const { id, tab } = useParams();

	const {
		data: fetchHeartbeatsData,
		loading: fetchHeartbeatsLoading,
		error: fetchHeartbeatsError,
	} = fetchHeartbeats;

	const { data: deviceData } = fetchDevice;

	const thread = isObject(deviceData) && deviceData.communicationType.includes('thread');

	const isPageEmpty =
		isObject(fetchHeartbeatsData) &&
		(isEmptyArray(fetchHeartbeatsData.results) || isUndefined(fetchHeartbeatsData.results));
	const isPageFull = isObject(fetchHeartbeatsData) && isFullArray(fetchHeartbeatsData.results);

	const classes = useStyles();

	const [shouldFilter, setShouldFilter] = useState(false);
	const [shouldSort, setShouldSort] = useState(false);
	const [shouldSearch, setShouldSearch] = useState(false);
	const [order, setOrder] = useState('asc');
	const [loading, setLoading] = useState(true);
	const [orderDescending, setOrderDescending] = useState(true);
	const [sortByProperty, setSortByProperty] = useState('');

	const [ignitionStateFilter, setIgnitionStateFilter] = useState('none');
	const [immobiliserStateFilter, setImmobiliserStateFilter] = useState('none');
	const [keyDetectionStateFilter, setKeyDetectionStateFilter] = useState('none');
	const [doorStateFilter, setDoorStateFilter] = useState('none');
	const [deviceLockFilter, setDeviceLockFilter] = useState('lock');
	const [deviceStateFilter, setDeviceStateFilter] = useState('state');

	const pagination = usePagination('pageNumberHeartbeats', 'pageSizeHeartbeats');
	const heartbeatsPeriodFilter = usePeriodFilter();
	const deviceUnlockTypeFilter = useBasicFilter('deviceUnlockTypeHeartbeatsFilter', 'none');
	const periodStart =
		isObject(heartbeatsPeriodFilter.dates.startDate) ?
			heartbeatsPeriodFilter.dates.startDate.toISOString()
		:	null;
	const periodEnd =
		isObject(heartbeatsPeriodFilter.dates.endDate) ?
			heartbeatsPeriodFilter.dates.endDate.toISOString()
		:	null;

	const deviceLockState = ['none', 'locked', 'unlocked'];
	const ignitionAndImmobiliserStates = ['enabled', 'disabled'];
	const keyDetectionStates = ['true', 'false'];
	const doorStates = ['open', 'closed'];
	const nfcTag = ['true', 'false'];
	const [dateTime, setDateTime] = useState({ startDate: null, endDate: null });
	const search = useSearchComponent(pagination.setPageNumber, setShouldSearch);

	const filters = {
		...(!isEmptyString(search.value) && { firmwareVersion: search.value }),
		...(!isEmptyString(sortByProperty) && { sortBy: sortByProperty }),
		...(deviceStateFilter && deviceStateFilter !== 'state' && { deviceState: deviceStateFilter }),
		...(deviceLockFilter !== 'lock' && { lockState: deviceLockFilter }),
		...(ignitionStateFilter !== 'none' && { ignitionState: ignitionStateFilter }),
		...(immobiliserStateFilter !== 'none' && {
			immobiliserEnabled: immobiliserStateFilter === 'enabled',
		}),
		...(keyDetectionStateFilter !== 'none' && { keyDetection: keyDetectionStateFilter }),
		...(doorStateFilter !== 'none' && { doorsOpen: doorStateFilter === 'open' }),
		...(dateTime?.startDate && { dateAfter: dateTime.startDate }),
		...(dateTime?.endDate && { dateBefore: dateTime.endDate }),
		...(thread &&
			deviceUnlockTypeFilter.value !== 'none' && { hasNfc: deviceUnlockTypeFilter.value }),
		orderDescending,
	};

	const doorLocation = {
		Driver: t('ui.label.driver'),
		DriverRear: t('ui.label.driverRear'),
		Passenger: t('ui.label.passenger'),
		PassengerRear: t('ui.label.passengerRear'),
	};

	useEffect(() => {
		if (
			!fetchHeartbeatsLoading &&
			!fetchHeartbeatsError &&
			(pagination.fetch ||
				tab === 'heartbeat' ||
				shouldSearch ||
				shouldSort ||
				shouldFilter ||
				periodStart ||
				periodEnd)
		) {
			onFetchHeartbeats(pagination.page, filters, id);
		}

		if (shouldSearch) {
			setShouldSearch(false);
		} else if (shouldSort) {
			setShouldSort(false);
		} else if (shouldFilter) {
			setShouldFilter(false);
		} else if (pagination.fetch) {
			pagination.setFatch(false);
		}
	}, [id, shouldSearch, pagination.fetch, order, shouldFilter, periodStart, periodEnd]);

	useEffect(() => {
		onFetchDevicesUnlockTypes();
	}, []);

	useEffect(() => {
		if (isObject(fetchHeartbeatsData) && !fetchHeartbeatsLoading) {
			setLoading(false);
		} else {
			setLoading(true);
		}
	}, [fetchHeartbeatsLoading]);

	const handleRequestSort = (property) => {
		const isDesc = sortByProperty === property && order === 'desc';
		setOrder(isDesc ? 'asc' : 'desc');
		setOrderDescending(!orderDescending);
		setSortByProperty(property);
		setShouldSort(true);
	};

	const handleFilterChange = (event, filterValue, setFilterValue) => {
		const value = event.target.value;
		if (value !== filterValue) {
			setFilterValue(value);
			setShouldFilter(true);
			pagination.setPageNumber(1);
		}
	};

	const handleUnlockTypeFilter = (event) => {
		const value = event.target.value;
		if (value !== deviceUnlockTypeFilter.value) {
			deviceUnlockTypeFilter.setValue(value);
			setShouldFilter(true);
		}
		pagination.setPageNumber(1);
	};

	const mainFilters = [
		{
			events: { onChange: (e) => handleFilterChange(e, deviceLockFilter, setDeviceLockFilter) },
			value: deviceLockFilter,
			selectOptions: [
				{
					value: 'lock',
					label: t('views.devices.heartbeatsList.lockState'),
				},
			].concat(
				deviceLockState.map((type) => ({
					value: type,
					label: t(`ui.label.${type}`),
				})),
			),
		},
		...(isVehicleTracker && !isBoat ?
			[]
		:	[
				{
					events: {
						onChange: (e) => handleFilterChange(e, deviceStateFilter, setDeviceStateFilter),
					},
					value: deviceStateFilter,
					selectOptions: [
						{
							value: 'state',
							label: t('views.devices.heartbeatsList.deviceState'),
						},
					].concat(
						deviceState.map((type) => ({
							value: type,
							label: t(`ui.label.device.state.${type}`),
						})),
					),
				},
			]),
		...(!isVehicleTracker ?
			[]
		:	[
				{
					events: {
						onChange: (e) => handleFilterChange(e, ignitionStateFilter, setIgnitionStateFilter),
					},
					value: ignitionStateFilter,
					selectOptions: [
						{
							value: 'none',
							label: t('ui.label.ignitionStatus'),
						},
					].concat(
						ignitionAndImmobiliserStates.map((type) => ({
							value: type,
							label: t(`ui.${type}`),
						})),
					),
				},
				{
					events: {
						onChange: (e) =>
							handleFilterChange(e, immobiliserStateFilter, setImmobiliserStateFilter),
					},
					value: immobiliserStateFilter,
					selectOptions: [
						{
							value: 'none',
							label: t('ui.label.immobiliserStatus'),
						},
					].concat(
						ignitionAndImmobiliserStates.map((type) => ({
							value: type,
							label: t(`ui.${type}`),
						})),
					),
				},
				...(isBoat ?
					[]
				:	[
						{
							events: {
								onChange: (e) => handleFilterChange(e, doorStateFilter, setDoorStateFilter),
							},
							value: doorStateFilter,
							selectOptions: [
								{
									value: 'none',
									label: t('ui.label.doorStatus'),
								},
							].concat(
								doorStates.map((type) => ({
									value: type,
									label: t(`ui.label.device.state.${type}`),
								})),
							),
						},
						{
							events: {
								onChange: (e) =>
									handleFilterChange(e, keyDetectionStateFilter, setKeyDetectionStateFilter),
							},
							value: keyDetectionStateFilter,
							selectOptions: [
								{
									value: 'none',
									label: t('ui.label.keyDetected'),
								},
							].concat(
								keyDetectionStates.map((type) => ({
									value: type,
									label: t(`ui.${type}`),
								})),
							),
						},
					]),
			]),
		...(thread ?
			[
				{
					events: { onChange: (e) => handleUnlockTypeFilter(e) },
					value: deviceUnlockTypeFilter.value,
					selectOptions: [
						{
							value: 'none',
							label: t('views.devices.heartbeatsList.NFCTag'),
						},
					].concat(
						nfcTag.map((type) => ({
							value: type,
							label: t(`views.devices.heartbeatsList.NFCTagPresent.${type}`),
						})),
					),
				},
			]
		:	[]),
	];

	const handleChangeStart = (event) => {
		const start = event.target.value;
		if (isFullString(start)) {
			setDateTime({ ...dateTime, startDate: new Date(start).toISOString() });
		} else {
			setDateTime({ ...dateTime, startDate: null });
		}
		setShouldFilter(true);
	};

	const handleChangeEnd = (event) => {
		const end = event.target.value;
		if (isFullString(end)) {
			setDateTime({ ...dateTime, endDate: new Date(end).toISOString() });
		} else {
			setDateTime({ ...dateTime, endDate: null });
		}
		setShouldFilter(true);
	};

	const timePickerData = {
		onChangeStart: handleChangeStart,
		onChangeEnd: handleChangeEnd,
	};

	const handleClearFilters = () => {
		search.events.onClear();
		pagination.resetPagination();
	};

	const clearFilters = {
		clear: pagination.pageNumber !== 1 || pagination.pageSize !== 10,
		btnText: 'ui.button.inline.clearfilters',
		action: handleClearFilters,
	};

	const handleDoors = (doors) => {
		const statusOfDoors =
			isVehicleTracker &&
			isObject(fetchHeartbeatsData) &&
			doors?.reduce((acc, status) => acc || status.open, false);
		const openDoors =
			isVehicleTracker &&
			isObject(fetchHeartbeatsData) &&
			doors?.filter((door) => (door.open ? door.location : null));

		return (
			<Box display='flex'>
				{statusOfDoors ?
					<MyTooltip
						arrow
						title={
							isFullArray(openDoors) &&
							openDoors.map((item, index) => (
								<Typography key={index}>{doorLocation[item.location]}</Typography>
							))
						}
					>
						<span>{t('ui.open')}</span>
					</MyTooltip>
				:	t('ui.openStatus.closed')}
			</Box>
		);
	};

	const handleGPSIcons = (heartbeat) => {
		const loaction = heartbeat.location;
		return (
			<Box alignItems='center' display='flex'>
				<a
					className={classes.mapLink}
					href={`https://maps.google.com/?daddr=${loaction.latitude},${loaction.longitude}`}
					target='blank'
				>
					{`${loaction.latitude}, ${loaction.longitude}`}
				</a>
				{gpsIcons && isFullString(heartbeat.locationType) && heartbeat.locationType !== 'none' ?
					<Box display='flex' ml={1}>
						<MyTooltip
							arrow
							title={
								<Typography>
									{t(`views.devices.heartbeatsList.icon.${heartbeat.locationType}`)}
								</Typography>
							}
						>
							{gpsLocationIcons[heartbeat.locationType]}
						</MyTooltip>
					</Box>
				:	null}
			</Box>
		);
	};

	const tableHeaders =
		!loading && isPageEmpty ?
			[]
		:	[
				{
					name: 'lastUpdated',
					content: t('views.devices.heartbeatsList.lastUpdated'),
					hasSorting: true,
				},
				...(isVehicleTracker && !isBoat ?
					[]
				:	[{ name: 'deviceState', content: t('views.devices.heartbeatsList.deviceState') }]),
				{ name: 'lockState', content: t('views.devices.heartbeatsList.lockState') },
				...(isVehicleTracker ?
					[
						{ name: 'ignitionStatus', content: t('ui.label.ignitionStatus') },
						{ name: 'immobiliserStatus', content: t('ui.label.immobiliserStatus') },
						...(isBoat ?
							[{ name: 'temperature', content: t('ui.label.temperature'), hasSorting: true }]
						:	[
								{ name: 'doorStatus', content: t('ui.label.doorStatus') },
								{ name: 'keyDetected', content: t('ui.label.keyDetected') },
							]),
						// { name: 'tankCardDetected', content: 'ui.label.tankCardDetected' },
					]
				:	[]),
				...(!isVehicleTracker ?
					[{ name: 'fwVersion', content: t('ui.label.firmwareVersion') }]
				:	[]),
				...(isBoat ?
					[]
				:	[{ name: 'batteryLevel', content: `${t('ui.label.battery')}`, hasSorting: true }]),
				...(isTrailerLock ?
					[
						{
							name: 'externalBatteryLevel',
							content: `${t('ui.label.secondaryBattery')}`,
							hasSorting: true,
						},
					]
				:	[]),
				...(isVehicleTracker ?
					[
						{ name: 'chargingStatus', content: t('ui.label.chargingStatus') },
						{ name: 'fuelLevel', content: t('ui.label.fuelLevel') },
						{ name: 'reachCombustion', content: t('ui.device.label.reachCombustion') },
						{ name: 'vehicleBattery', content: t('ui.label.vehicleBattery') },
						{ name: 'reachElectric', content: t('ui.device.label.reachElectric') },
						{ name: 'reachTotal', content: t('ui.device.label.reachTotal') },
						...(isBoat ? [] : [{ name: 'mileage', content: t('ui.label.mileage') }]),
					]
				:	[{ name: 'temperature', content: t('ui.label.temperature'), hasSorting: true }]),
				...(!isBoat && (isVehicleTracker || deviceData?.deviceType === 'none') ?
					[{ name: 'event', content: t('ui.label.event') }]
				:	[]),
				{ name: 'gpsLocation', content: t('ui.label.gps') },
				...(thread ? [{ name: 'NFCTag', content: t('views.devices.heartbeatsList.NFCTag') }] : []),
			];

	const handleCharging = (heartbeats) => {
		if (heartbeats?.charging === 'charging') {
			return t(`ui.label.${heartbeats.charging}`);
		} else if (heartbeats?.batteryCharging) {
			return t('ui.label.device.state.charging');
		} else {
			return '-';
		}
	};

	const loadingBody =
		loading ?
			Array(3)
				.fill(Array(tableHeaders?.length).fill())
				.map((arr) => arr.map(() => ({ loading: true })))
		:	null;

	const emptyBody =
		!loading && isPageEmpty ?
			[
				[
					{
						content: <EmptyTable label='views.devices.heartbeatsList.emptyTable' />,
					},
				],
			]
		:	null;

	const tableBody =
		!loading && isPageFull ?
			fetchHeartbeatsData.results.map((heartbeats) => [
				{ content: commaTimeStrings(heartbeats.lastUpdated), classCell: classes.cellStyle },
				...(isVehicleTracker && !isBoat ?
					[]
				:	[{ content: heartbeats.state && t(`ui.label.device.state.${heartbeats.state}`) }]),
				{ content: heartbeats.lockState && t(`ui.label.${heartbeats.lockState}`) },
				...(isVehicleTracker ?
					[
						{ content: heartbeats.ignition && t(`ui.${heartbeats.ignition?.toLowerCase()}`) },
						{
							content:
								isBoolean(heartbeats.immobiliserStateEnabled) &&
								(heartbeats.immobiliserStateEnabled ? t('ui.enabled') : t('ui.disabled')),
						},
						...(isBoat ?
							[{ content: heartbeats.temperature }]
						:	[
								{ content: handleDoors(heartbeats.doors) },
								{
									content:
										isBoolean(heartbeats.keyDetected) && t(`ui.${String(heartbeats.keyDetected)}`),
								},
							]),
					]
				:	[]),
				...(!isVehicleTracker ? [{ content: heartbeats.firmwareVersion }] : []),
				...(isBoat ?
					[]
				:	[
						{
							content:
								deviceData?.deviceType === 'doorLock' ?
									convertBatteryLevelToEnum(heartbeats.batteryLevel)
								:	`${heartbeats.batteryLevel}%`,
						},
					]),
				...(isTrailerLock ? [{ content: `${heartbeats.externalBatteryLevel}%` }] : []),
				...(isVehicleTracker ?
					[
						{
							content:
								(heartbeats?.charging || isBoolean(heartbeats?.batteryCharging)) &&
								handleCharging(heartbeats),
						},
						{
							content:
								heartbeats?.vehicleFuelVolume && heartbeats?.vehicleFuelVolume > 0 ?
									`${heartbeats?.vehicleFuelVolume} L`
								:	heartbeats?.vehicleFuelLevel &&
									heartbeats?.vehicleFuelLevel > 0 &&
									`${heartbeats?.vehicleFuelLevel}%`,
						},
						{ content: heartbeats.combustionRange },
						{ content: heartbeats.vehicleBatteryLevel },
						{ content: heartbeats.electricalRange },
						{ content: heartbeats.range },
						...(isBoat ? [] : [{ content: heartbeats.mileage }]),
					]
				:	[{ content: heartbeats.temperature }]),
				...(!isBoat && (isVehicleTracker || deviceData?.deviceType === 'none') ?
					[
						{
							content: heartbeats?.eventCode && t(`ui.event.event.code.${heartbeats.eventCode}`),
						},
					]
				:	[]),
				{ content: isObject(heartbeats.location) && handleGPSIcons(heartbeats) },
				...(thread ? [{ content: heartbeats?.tokenId && heartbeats.tokenId }] : []),
			])
		:	null;

	return (
		<>
			<SearchBar
				clearFilters={clearFilters}
				hasMainFilter
				mainFilters={mainFilters}
				placeholder={t('views.devices.heartbeatsList.searchHeartbeats')}
				searchEvents={search.events}
				searchValue={search.value}
				timePickerData={timePickerData}
			/>
			<Box pb={3}>
				<Table
					body={loadingBody || emptyBody || tableBody}
					cellStyle={classes.cellStyle}
					data={isPageFull ? fetchHeartbeatsData.results : []}
					handlePageChange={pagination.pageNumberChange}
					handleSorting={handleRequestSort}
					header={tableHeaders}
					isNotPaginate={loading || isPageEmpty || !isPageFull}
					loading={fetchHeartbeatsLoading}
					order={order}
					orderBy={sortByProperty}
					page={pagination.pageNumber}
					rowsPerPage={pagination.pageSize}
					setRowsPerPage={pagination.pageSizeChange}
					title={t('ui.activity')}
					total={fetchHeartbeatsData ? fetchHeartbeatsData.total : 0}
				/>
			</Box>
		</>
	);
};

HeartbeatsList.propTypes = {
	isTrailerLock: PropTypes.bool,
	isVehicleTracker: PropTypes.bool,
	onFetchHeartbeats: PropTypes.func,
	gpsIcons: PropTypes.bool,
	onFetchDevicesUnlockTypes: PropTypes.func,
	vehicleFuelVolume: PropTypes.number,
	vehicleFuelLevel: PropTypes.number,
	isBoat: PropTypes.bool,
	fetchHeartbeats: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	fetchDevice: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	devicesUnlockTypesFilter: PropTypes.PropTypes.shape({
		data: PropTypes.array,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
};

const mapStateToProps = (state) => {
	return {
		fetchHeartbeats: state.paged.fetchHeartbeats,

		fetchDevice: state.details.fetchDevice,
		devicesUnlockTypesFilter: state.list.devicesUnlockTypesFilter,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchHeartbeats: (page, filters, id) => dispatch(actions.fetchHeartbeats(page, filters, id)),
		onFetchDevicesUnlockTypes: () => dispatch(actions.fetchDevicesUnlockTypes()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(HeartbeatsList);
