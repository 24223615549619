import { useEffect, useMemo, useState } from 'react';

import path from 'path';

import { GridCallbackDetails, GridColDef, GridPaginationModel } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { DataGrid } from '~components';
import { ItemCategoryEnum } from '~enums';
import { Hub } from '~features/hub';
import { BaseReference } from '~interfaces';
import { DateRange } from '~interfaces/dateRanges';

import FinanceStatisticsService from '../../services/financeStatisticsService';

interface FinanceStat {
	timestamp: Date;
	revenue: number; // in euros?
	item: BaseReference;
	hub: BaseReference;
	bookingCount: number;
}

const service = new FinanceStatisticsService();

interface ItemRevenueStatisticsDataGridProps {
	organisationId?: string;
	period: DateRange;
	itemId?: string;
	hubId?: string;
}


/**
 * 
 * @param props 
 * @returns 
 * Notice if you change the props to a spread syntax, will create infinite rerender
 */
const ItemRevenueStatisticsDataGrid = (props: ItemRevenueStatisticsDataGridProps) => {
	const { t } = useTranslation('general');

	const columns = useMemo<GridColDef<FinanceStat>[]>(() => [
		{
			field: 'item',
			headerName: t('ui.label.item'),
			flex: 1,
			valueGetter: (_, row) => row?.label,
		},
		{
			field: 'hub',
			headerName: t('ui.label.location'),
			flex: 1,
			valueFormatter: (value: Hub) => value?.address.toCityStreetString(),
		},
		{
			field: 'revenue',
			headerName: t('total'),
			flex: 1,
			valueFormatter: (value: number) => `€${value.toLocaleString()}`
		},
		{
			field: 'bookingCount',
			headerName: t('ui.category.bookings'),
			flex: 1,
		},
		{
			field: 'average',
			headerName: t('averageRevenuePerBooking'),
			flex: 1,
			valueGetter: (_, row) => row.revenue / row.bookingCount,
			valueFormatter: (value: number) => `€${value.toFixed(2).toLocaleString()}`
		},
	], []);

	const [fetchParameters, setFetchParameters] = useState(props); 
	const { data, error, isLoading, isValidating } = useSWR(
		[path.join(service.basePath, 'list'), fetchParameters],
		([_, args]) => service.getItemRevenues(args)
	);
	const [totalCount, setTotalCount] = useState<number>(data?.total || 0);

	useEffect(() => {
		if (data?.total != null) {
			setTotalCount(data.total);
		}
	}, [data]);

	useEffect(() => {
		setFetchParameters((prev) => ({
			...prev,
			...props
		}));
	}, [props]);

	const handlePaginationModelChange = (
		value: GridPaginationModel,
		details: GridCallbackDetails,
	) => {
		if (details.reason === 'setPaginationModel' && (isLoading || isValidating)) {
			service.abortCurrentRequest('pagination change');
		}

		setFetchParameters((prev) => ({
			...prev,
			page: value.page + 1,
			pageSize: value.pageSize,
		}));
	};

	return (
		<DataGrid
			error={error}
			loading={isLoading || isValidating}
			columns={columns}
			rows={data?.results ?? []}
			rowCount={totalCount}
			onPaginationModelChange={handlePaginationModelChange}
			disableColumnSorting
			initialState={{
				pagination: {
					paginationModel: {
						pageSize: 5
					}
				}
			}}
			sx={{ flexGrow: 1, flexBasis: 200 }}
		/>
	);
};

export default ItemRevenueStatisticsDataGrid;
