import { useTranslation } from 'react-i18next';

import UserLicenseStatisticsLayout from '~features/statistics/layouts/userLicenseStatisticsLayout';

import Page from '../page';

const UserLicenseStatisticsPage = () => {
	const { t } = useTranslation('general');

	return (
		<Page title={t('revenue')}>
			<UserLicenseStatisticsLayout />
		</Page>
	);
};

export default UserLicenseStatisticsPage;
