import { useEffect } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import {
	Card,
	CardContent,
	Box,
	Divider,
	Typography,
	TextField,
	IconButton,
	InputAdornment,
} from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useStyles } from './style';
import {
	InfoLabel,
	CustomNumberFormat,
	SwitchButton,
	CustomNumberField,
	StyledButton,
} from '../../../../../components';
import { stringifyTime, modifyDate } from '../../../../../shared/datetime';
import { isFullArray, isEmptyArray, isUndefined, isInteger } from '../../../../../shared/utility';
import Header from '../Header';

const Summary = (props) => {
	const {
		className,
		selectedCategory,
		selectedPricingType,
		save,
		editing,
		data,
		setActiveStep,

		freeKilometers,
		priceFreeKilometers,
		openFreeKilometers,
		setOpenFreeKilometers,
		setFreeKilometers,
		setPriceFreeKilometers,
		fixedPriceModel,
	} = props;
	const { t } = useTranslation('general');
	const classes = useStyles();

	useEffect(() => {
		save({ openFreeKMs: openFreeKilometers });
		if (!openFreeKilometers) {
			setFreeKilometers('');
			setPriceFreeKilometers('');
			save({
				freeKMs: '',
				priceFreeKMs: '',
			});
		}
	}, [openFreeKilometers]);

	const handleFreeKilometers = (event) => {
		const newValue = event.target.value;
		setFreeKilometers(newValue);
		save({ freeKMs: newValue });
	};

	const handlePriceChangeKilometers = (event) => {
		const newValue = event.target.value;
		setPriceFreeKilometers(newValue);
		save({ priceFreeKMs: newValue });
	};

	const handleFreeKilometersSwitch = () => {
		setOpenFreeKilometers(!openFreeKilometers);
	};

	return (
		<>
			<Header editing={editing} />
			<Card className={clsx(classes.root, className)}>
				<CardContent>
					<Typography className={classes.premium} variant='h3'>
						{t('views.addPricing.summary.premiumSetup')}
					</Typography>
					{selectedPricingType === 'fixed' ?
						<Card className={classes.card}>
							<CardContent>
								<Box alignItems='center' display='flex' justifyContent='space-between'>
									<Typography>{`${t('ui.label.fixedPricing')}`}</Typography>
									<Box alignItems='flex-end'>
										<IconButton onClick={() => setActiveStep(1)} size='large'>
											<EditIcon fontSize='small' />
										</IconButton>
									</Box>
								</Box>
								{fixedPriceModel.map((pricingModel) => (
									<>
										<Divider className={classes.divider} />
										<div className={classes.daySection} key={pricingModel.periodType}>
											<Typography>{t(`ui.label.${pricingModel.periodType}FixedPrice`)}</Typography>
											<Box flexBasis='50%' justifyContent='end'>
												<Typography>{`€${pricingModel.price?.toFixed(2)}/${t(`ui.${pricingModel.periodType}`)}`}</Typography>
											</Box>
										</div>
									</>
								))}
							</CardContent>
						</Card>
					:	data
							.filter((model) => !isEmptyArray(model))
							.map((model, index) =>
								isFullArray(model) ?
									<Card className={classes.card} key={index}>
										<CardContent>
											{isFullArray(model) ?
												model
													.sort((first, second) => first.days[0].id - second.days[0].id)
													.map((item, iIndex) => (
														<div key={item.id}>
															{iIndex === 0 ?
																<Box
																	alignItems='center'
																	display='flex'
																	justifyContent='space-between'
																>
																	<Typography>{`${t('views.addPricing.summary.pricePer')} ${item.periodType}`}</Typography>
																	<Box alignItems='flex-end'>
																		<IconButton
																			onClick={() => setActiveStep(2 + index)}
																			size='large'
																		>
																			<EditIcon fontSize='small' />
																		</IconButton>
																	</Box>
																</Box>
															:	null}
															{iIndex === 0 ?
																<Divider className={classes.divider} />
															:	null}
															<div className={classes.daySection}>
																<Box flexBasis='25%'>
																	<Typography>
																		{item.days
																			.map((day) =>
																				isInteger(day.id) ? `${day.name.slice(0, 3)}` : null,
																			)
																			.filter((name) => name !== null)
																			.join(', ')}
																	</Typography>
																</Box>
																{isUndefined(item.start) && isUndefined(item.end) ?
																	<Box flexBasis='25%' justifyContent='center'>
																		<Typography>{t('views.addPricing.summary.allWeek')}</Typography>
																	</Box>
																:	<Box flexBasis='25%' justifyContent='center'>
																		<Typography>
																			{`${stringifyTime(item.start)} - ${stringifyTime(modifyDate(item.end, { ...(item.end.getHours() === 0 && item.end.getMinutes() === 0 && { hours: 23, minutes: 59, date: item.start.getDate() }) }))}`}
																		</Typography>
																	</Box>
																}
																<Box flexBasis='25%' justifyContent='center'>
																	<Typography>
																		{item.price === 0 ?
																			t('ui.free')
																		:	`€${item.price}/${item.periodType}`}
																	</Typography>
																</Box>
															</div>
														</div>
													))
											:	null}
										</CardContent>
									</Card>
								:	null,
							)
					}
				</CardContent>
			</Card>
			{selectedCategory === 3 ?
				<Card className={clsx(classes.root, className)}>
					<CardContent>
						<div className={classes.kilometerSwitch}>
							<Typography variant='h3'>{t('views.addPricing.summary.kilometerPrice')}</Typography>
							<SwitchButton checked={openFreeKilometers} onChange={handleFreeKilometersSwitch} />
						</div>
						{openFreeKilometers ?
							<>
								<Box className={classes.carOption}>
									<TextField
										InputLabelProps={{ shrink: true }}
										InputProps={{ inputComponent: CustomNumberFormat }}
										fullWidth
										id='free-input'
										label={`${t('ui.freeUnits.kilometers')}*`}
										name='free'
										onChange={handleFreeKilometers}
										value={freeKilometers}
										variant='outlined'
									/>
								</Box>
								<Box className={classes.carOption}>
									<TextField
										InputLabelProps={{ shrink: true }}
										InputProps={{
											inputComponent: CustomNumberField,
											startAdornment: <InputAdornment position='start'>{'€'}</InputAdornment>,
										}}
										fullWidth
										id='free-input'
										label={`${t('ui.price')}/km*`}
										name='amountPrKm'
										onChange={handlePriceChangeKilometers}
										value={priceFreeKilometers}
										variant='outlined'
									/>
								</Box>
							</>
						:	null}
					</CardContent>
				</Card>
			:	null}
			{selectedPricingType === 'flex' && (
				<Card className={clsx(classes.root, className)}>
					<CardContent>
						<InfoLabel
							info={t('views.addPricing.summary.infoLabel')}
							name={t('views.addPricing.summary.infoName')}
							paddingBottom={4.5}
							paddingTop={1.5}
							variant='h4'
						/>
						<StyledButton onClick={() => setActiveStep(1)} variant='contained-secondary'>
							{t('ui.button.contained.add')}
						</StyledButton>
					</CardContent>
				</Card>
			)}
		</>
	);
};

Summary.propTypes = {
	className: PropTypes.string,
	name: PropTypes.string,
	selectedName: PropTypes.string,
	save: PropTypes.func,
	editing: PropTypes.bool,
	selectedCategory: PropTypes.number,
	selectedPricingType: PropTypes.string,
	summaryData: PropTypes.array,
	perHourValue: PropTypes.object,
	data: PropTypes.array,
	setActiveStep: PropTypes.func,

	fixedPriceModel: PropTypes.array,
	openFreeKilometers: PropTypes.bool,
	setOpenFreeKilometers: PropTypes.func,
	freeKilometers: PropTypes.string,
	priceFreeKilometers: PropTypes.string,
	setFreeKilometers: PropTypes.func,
	setPriceFreeKilometers: PropTypes.func,
};

export default Summary;
