import path from 'path';

import { PagedResults } from '~interfaces';
import { GeneralPagedParameters } from '~interfaces/requests';
import { BaseReferenceResponse, IdReferenceResponse, PagedResponse } from '~interfaces/responses';
import { TopologyService } from '~services';

import UserInvitation from '../interfaces/userInvitation';

/**
 * A service to get "business logic" users. E.g. user data from
 * the Core service
 */
class UserInvitationsService extends TopologyService {
	public readonly path = 'invitations';

	constructor() {
		super('v1');
	}

	/**
	 * Get a paginated list of users
	 * @param page The number of the page
	 * @param pageSize The amount of results of the page
	 * @returns
	 */
	async getUserInvitations({
		page = 1,
		pageSize = 10,
		sortBy = 'id',
		descending = true,
		...args
	}: GeneralPagedParameters<'id' | 'email' | 'expirtyDate' | 'userGroup' | 'organisation'> & {
		userGroupId?: string;
	}): Promise<PagedResults<UserInvitation>> {
		const { data } = await this._client.get<PagedResponse<UserInvitationResponse>>(this.path, {
			params: {
				pageNumber: page,
				pageSize: pageSize,
				organisationId: args.organisationId,
				userGroupId: args.userGroupId,
				sortBy: sortBy,
				orderDescending: descending,
				...(args.searchQuery && { searchTerm: args.searchQuery }),
			},
		});

		return this.mapPagedResponse(data, UserInvitationsService.fromResponse);
	}

	async createUserInvitations(data: UserInvitationRequest): Promise<null> {
		const response = await this._client.post(this.path, data);

		return response.data;
	}

	async deleteUserInvitation(id: string): Promise<null> {
		const { data } = await this._client.delete(path.join(this.path, id));

		return data;
	}
	
	static fromResponse(data: UserInvitationResponse): UserInvitation {
		const {
			id,
			expiryDate,
			cultureInfo,
			organisationReference,
			userGroupReference,
			userReference,
			...rest
		} = data;

		return {
			id: id.toString(),
			expiryDate: new Date(expiryDate),
			user: UserInvitationsService.fromBaseReferenceResponse(userReference),
			userGroup: UserInvitationsService.fromBaseReferenceResponse(userGroupReference),
			organisation: UserInvitationsService.fromBaseReferenceResponse(organisationReference),
			culture: cultureInfo,
			...rest,
		};
	}
}

interface UserInvitationRequest {
	organisationId: string | number;
	invites: {
		emails: string[];
		userGroupId: string | number;
		culture: string;
	}[];
}

interface UserInvitationResponse extends IdReferenceResponse {
	email: string;
	link: string;
	expiryDate: string;
	seen: boolean;
	status: string;
	cultureInfo?: string;
	organisationReference: BaseReferenceResponse;
	userGroupReference?: BaseReferenceResponse;
	userReference?: BaseReferenceResponse;
}

export default UserInvitationsService;
