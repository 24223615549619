import React, { useEffect, useRef, useState } from 'react';

import { Add as AddIcon, MoreVert, NavigateBefore } from '@mui/icons-material';
import {
	Autocomplete,
	Box,
	Button,
	ButtonGroup,
	CircularProgress,
	IconButton,
	OutlinedInput,
	Stack,
	TextField,
	ToggleButton,
	ToggleButtonGroup,
} from '@mui/material';

import { DatePicker, DatePickerNew, FormDialog, Select } from '~components';
import DefectSelect from '~features/maintenance/components/selects/defectSelect';
import { OrganisationAutocomplete, PartnerAutocomplete } from '~features/organisations';
import { BaseReferenceResponse } from '~interfaces/responses';
import { ItemGroup } from '~features/itemGroups';
import { Item, ItemCategorySelect } from '~features/items';
import { Hub } from '~features/hub';
import { User } from '~features/users';
import { useElementSize, useOverflow } from '~hooks';
import UserCountPieChart from '~features/users/components/charts/userCountPieChart';
import ItemCountBarChart from '~features/items/components/charts/itemCountBarChart';
import DeviceCountPieChart from '~features/devices/components/charts/deviceCountPieChart';
import BookingsLineChart from '~features/bookings/components/charts/bookingsLineChart';
import dayjs from 'dayjs';
import { useAtom } from 'jotai';
import readWriteOrganisationAtom from '~atoms/organisationAtom';
import HubAutocomplete from '~features/hub/components/autocompletes/hubAutocomplete';
import { atomWithHash } from 'jotai-location';
import { fillMissingDatesInArrayOfObjects } from '~utils/dateUtils';
import { PeriodicityEnum } from '~enums';
import { BarChart } from '@mui/x-charts';

const countAtom = atomWithHash('count', 1);

const bla = [
	{ timestamp: new Date('2024-09-09T00:00:00Z'), bla: 1 },
	{ timestamp: new Date('2024-09-10T00:00:00Z'), bla: 1 },
	{ timestamp: new Date('2024-09-12T00:00:00Z'), bla: 1 },
	{ timestamp: new Date('2024-09-13T00:00:00Z'), bla: 1 },
	{ timestamp: new Date('2024-09-17T00:00:00Z'), bla: 1 },
	{ timestamp: new Date('2024-09-18T00:00:00Z'), bla: 1 },
	{ timestamp: new Date('2024-09-21T00:00:00Z'), bla: 1 },
];

const period = {
	start: new Date('2024-09-01T00:00:00Z'),
	end: new Date('2024-09-30T00:00:00Z'),
};
const henk = fillMissingDatesInArrayOfObjects(period, 60 * 24, bla, () => ({ bla: 0 }));

/**
 * This page is meant as a sandbox for a develop to develop or
 * test a component outside of the normal flow
 */
const SandboxPage = () => {
	const [org, setOrg] = useAtom(readWriteOrganisationAtom);
	const [dateVal, setDateVal] = useState<Dayjs | null>(dayjs());
	const [count, setCount] = useAtom(countAtom);
	const [val, setVal] = useState(null);
	const [textVal, setTextVal] = useState('bla');

	const myRef = useRef();
	const size = useElementSize(myRef);
	const overflow = useOverflow(myRef);

	return (
		<BarChart
			xAxis={[{ scaleType: 'band', data: ['1', '2', '3'] }]}
			series={[
				{
					data: [1, 2, 3],
					label: 'henk'
				},
			]}
		/>
	);

	return (
		<>
			<Box sx={{ width: 1, height: 500 }}>
				<Box sx={{ padding: 1 }}>Paddington!</Box>
				<BookingsLineChart
					period={{
						start: new Date('2024-10-31T22:00:00Z'),
						end: new Date('2024-11-31T23:00:00Z'),
					}}
				/>
			</Box>
			<TextField
				value={textVal}
				onChange={(e) => setTextVal(e.target.value)}
				slotProps={{
					htmlInput: {
						sx: {
							textAlign: 'center',
						},
					},
				}}
			/>
		</>
	);

	return (
		// <div>Sandbox</div>
		<Stack
			width={'150px'}
			direction='row'
			sx={{ flexWrap: 'wrap', backgroundColor: 'yellow' }}
			justifyContent='center'
		>
			<Box>Hoi</Box>
			<Box>Hoi2</Box>
			<Box>Hoi3</Box>
			<Box>Hoi4</Box>
			<Box>Hoi5</Box>
			<Box>Hoi6</Box>
			<Box>Hoi7</Box>
		</Stack>
	);

	return (
		<Box width={1} height={1}>
			<HubAutocomplete />
			<Button onClick={() => setCount((prev) => prev + 1)}>+</Button>
			{count}
			<ItemCategorySelect value={val} onChange={(e, newVal) => setVal(newVal)} />
			{val?.label}
			<OrganisationAutocomplete value={org} onChange={(_, newVal) => setOrg(newVal)} />
			<PartnerAutocomplete />
			{org?.label}
			<DatePickerNew
				label='Month'
				views={['month', 'year']}
				value={dateVal}
				onChange={(newDateVal) => setDateVal(newDateVal)}
			/>

			<Autocomplete
				options={[{ label: 'hoi' }, { label: 'piet' }]}
				renderInput={(params) => <TextField {...params} select />}
			/>
		</Box>
	);

	return (
		<Box width={1} height={1}>
			<Box width={300}>
				<DefectSelect value={val} onChange={(e) => setVal(e.target.value)} itemGroupId={'1849'} />
			</Box>
			<OrganisationAutocomplete />
			<Box>
				<CircularProgress size={20} color='inherit' />
			</Box>
			<Stack ref={myRef} direction='row' sx={{ width: 1, justifyContent: 'space-between' }}>
				<Box sx={{ bgcolor: 'lightblue' }}>
					asdfasdjfljasdkfjksldjfalksdfkjdkjfkdjsfksjflkjsdkfjslkdjfkldsjfjklsdf
				</Box>
				{!overflow ?
					<Stack direction='row' sx={{ bgcolor: 'orange' }}>
						<Button>Button2</Button>
						<Button>Button3</Button>
						<Button>Button4</Button>
					</Stack>
				:	<IconButton>
						<MoreVert />
					</IconButton>
				}
			</Stack>
			{`${size.width} ${size.height}`}
		</Box>
	);
};

type Booking = {
	user: User;
	item: Item;
	itemGroup: ItemGroup;
	hub: Hub;
	usage: {
		start: Date;
		end?: Date;
	};

	price: object;

	status: string;
	usageState: string;
	approvalState: string;

	created: Date;
	createdByUser: BaseReferenceResponse;

	start: Date;
	end: Date;

	attentionStates: string[];
} & BaseReferenceResponse;

export default SandboxPage;
