import { useState, useEffect } from 'react';

import DeleteIcon from '@mui/icons-material/DeleteOutline';
import LinkIcon from '@mui/icons-material/Link';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import { colors, Box, Link, CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useNavigate, Link as RouterLink, useLocation } from 'react-router-dom';

import { useAuthorize } from '~features/authentication';

import { useStyles } from './style';
import {
	EmptyState,
	SearchBar,
	Table,
	Label,
	GenericMoreButton,
	AlertDialog,
	ActionDialog,
	Typography,
} from '../../../components';
import {
	useError,
	useCloseTimeout,
	useSearchComponent,
	usePagination,
	useComplexFilter,
} from '../../../shared/hooks';
import {
	isObject,
	isEmptyString,
	isEmptyArray,
	isInteger,
	handleHubReference,
} from '../../../shared/utility';
import * as actions from '../../../store/actions';
import LinkDockingLocation from '../../Actions/LinkDockingLocation';

const Locations = (props) => {
	const {
		onFetchHubs,
		hubsList,

		onDeleteHub,
		deleteHub,
		onFetchOrganisations,
		organisationsList,
		unlinkDockingLocation,
		onUnlinkDockingLocation,
		linkDockingLocation,
		onResetState,
	} = props;
	const { t } = useTranslation('general');
	const navigate = useNavigate();
	const location = useLocation();
	const { isSuperAdmin } = useAuthorize();

	const classes = useStyles();

	const { enqueueSnackbar } = useSnackbar();

	const [shouldSearch, setShouldSearch] = useState(false);

	const [sortingCategory, setSortingCategory] = useState('id');
	const [order, setOrder] = useState('asc');
	const [orderDescending, setOrderDescending] = useState(false);
	const [shouldSort, setShouldSort] = useState(false);

	//const [categoryFilter, setCategoryFilter] = useState('all');
	const [filters] = useState('none');
	// const [locationFilter, setLocationFilter] = useState('all');
	const [shouldFilter, setShouldFilter] = useState(false);

	const { data: hubsListData, loading: hubsListLoading, error: hubsListError } = hubsList;
	const hubsReady = isObject(hubsListData) && !hubsListLoading && !hubsListError;

	const [shouldDoInitialFetch, setShouldDoInitialFetch] = useState(true);
	const [showingInitialResults, setShowingInitialResults] = useState(true);

	const [dialogData, setDialogData] = useState(null);

	const { success: deleteHubSucces, loading: deleteHubLoading, error: deleteHubError } = deleteHub;
	const deleteHubDone = deleteHubSucces && !deleteHubLoading && !deleteHubError;

	const [deletedHubIndex, setDeletedHubIndex] = useState(null);

	const [opeDockingLocationn, setOpeDockingLocationn] = useState(false);

	const [locationDetails, setLocationDetails] = useState(null);

	const [openUnlinkDockingLocation, setOpenUnlinkDockingLocation] = useState(false);

	const [unlinkDockingData, setUnlinkDockingData] = useState(null);

	const {
		data: unlinkDockingLocationData,
		loading: unlinkDockingLocationLoading,
		error: unlinkDockingLocationError,
	} = unlinkDockingLocation;

	const { data: linkDockingLocationData, error: linkDockingLocationError } = linkDockingLocation;

	const locationOrganisationFilter = useComplexFilter(
		'locationOrganisationNameFilter',
		'locationOrganisationIdFilter',
	);

	const pagination = usePagination();

	const errorHandleDelete = useError(
		isObject(deleteHub) ?
			{
				value: deleteHub,
				message: `${t('ui.successfullyDeleted')} ${isObject(dialogData) ? dialogData.name : null}`,
			}
		:	null,
	);

	const dialogAction = useCloseTimeout(
		isObject(deleteHub) ?
			{ loading: deleteHubLoading, error: deleteHubError, actionDone: deleteHubDone }
		:	null,
	);

	const search = useSearchComponent(pagination.setPageNumber, setShouldSearch);

	const hubFilters = {
		...(!isEmptyString(search.value) && { searchTerm: search.value }),
		...(!isEmptyString(sortingCategory) && { sortBy: sortingCategory }),
		...(locationOrganisationFilter.valueId !== 'all' && {
			organisationId: locationOrganisationFilter.valueId,
		}),
		orderDescending,
		filters,
	};

	/* * * * * * *
	 * GET DATA  *
	 * * * * * * */
	useEffect(() => {
		if (deleteHubDone && deletedHubIndex) {
			setDeletedHubIndex(false);
			onFetchHubs(pagination.page, hubFilters);
		}
	}, [
		deleteHubDone,
		deletedHubIndex,
		pagination.pageNumber,
		pagination.pageSize,
		sortingCategory,
		orderDescending,
		hubFilters,
		pagination.page,
		onFetchHubs,
	]);

	useEffect(() => {
		if (isObject(unlinkDockingLocationData)) {
			setShouldDoInitialFetch(true);
			onResetState('unlinkDockingLocation');
			setOpenUnlinkDockingLocation(false);
			enqueueSnackbar(t('views.location.unlink.success.message'), { variant: 'success' });
		} else if (isObject(linkDockingLocationData)) {
			setShouldDoInitialFetch(true);
			onResetState('linkDockingLocation');
			enqueueSnackbar(t('views.location.link.success.message'), { variant: 'success' });
		}
	}, [unlinkDockingLocationData, linkDockingLocationData]);

	useEffect(() => {
		if (unlinkDockingLocationError) {
			enqueueSnackbar('Unlin error', { variant: 'error' });
		} else if (linkDockingLocationError) {
			enqueueSnackbar(linkDockingLocationError.message, { variant: 'error' });
			onResetState('linkDockingLocation');
		}
	}, [linkDockingLocationError, unlinkDockingLocationError]);

	useEffect(() => {
		if (
			!hubsListLoading &&
			(pagination.fetch || shouldSearch || shouldSort || shouldFilter || shouldDoInitialFetch)
		) {
			onFetchHubs(pagination.page, hubFilters);
			if ((shouldFilter || shouldSearch || shouldSort) && showingInitialResults) {
				setShowingInitialResults(false);
			}
		}

		if (shouldSearch) {
			setShouldSearch(false);
		} else if (shouldFilter) {
			setShouldFilter(false);
		} else if (shouldSort) {
			setShouldSort(false);
		} else if (shouldDoInitialFetch) {
			setShouldDoInitialFetch(false);
		} else if (pagination.fetch) {
			pagination.setFatch(false);
		}
	}, [
		shouldSearch,
		shouldFilter,
		shouldSort,
		shouldDoInitialFetch,
		hubFilters,
		pagination,
		unlinkDockingLocationData,
	]);

	useEffect(() => {
		return () => {
			setShouldDoInitialFetch(true);
			setShowingInitialResults(true);
		};
	}, [setShouldDoInitialFetch, setShowingInitialResults]);

	/* * * * * *
	 * FILTER  *
	 * * * * * */
	const handleOrganisationFilter = (item) => {
		if (item.id === locationOrganisationFilter.valueId) {
			return;
		}
		if (isInteger(item.id)) {
			locationOrganisationFilter.setValueName(item.name);
		} else if (isEmptyString(item.id)) {
			locationOrganisationFilter.setValueName('');
		}
		locationOrganisationFilter.setValueId(item.id);
		setShouldFilter(true);
	};

	const mainFilters = [
		...(isSuperAdmin() ?
			[
				{
					isSelectWithLazyLoading: true,
					events: {
						onChange: handleOrganisationFilter,
						searchHandle: locationOrganisationFilter.setValueName,
						filter: 'verified',
					},
					value: locationOrganisationFilter.valueName,
					dataList: organisationsList,
					placeholder: t('ui.filter.organisations.all'),
					onFetchData: onFetchOrganisations,
					listType: 'organisations',
					defaultListItem: { id: '', name: t('ui.filter.organisations.all') },
				},
			]
		:	[]),
	];

	const handleClearFilters = () => {
		locationOrganisationFilter.setValueName('');
		locationOrganisationFilter.setValueId('all');
		search.events.onClear();
		pagination.resetPagination();
	};

	const clearFilters = {
		clear:
			pagination.pageNumber !== 1 ||
			pagination.pageSize !== 10 ||
			locationOrganisationFilter.valueId !== 'all',
		btnText: 'ui.button.inline.clearfilters',
		action: handleClearFilters,
	};
	/* * * * * *
	 * SORTING *
	 * * * * * */
	const handleRequestSort = (property) => {
		const isDesc = sortingCategory === property && order === 'desc';
		setOrder(isDesc ? 'asc' : 'desc');
		setOrderDescending(isDesc);
		setSortingCategory(property);
		setShouldSort(true);
	};

	/* * * * * * * * *
	 * TABLE ACTIONS *
	 * * * * * * * * */
	const deleteSingleHub = (data) => {
		setDialogData(data);
		dialogAction.setAction(true);
	};

	/* * * * * * * * *
	 * TABLE CONTENT *
	 * * * * * * * * */

	const licenseStatusColors = {
		pending: colors.grey[600],
		verified: colors.green[600],
		rejected: colors.red[600],
	};

	const handleLinkDockingLocation = (data) => {
		setOpeDockingLocationn(true);
		setLocationDetails(data);
	};

	const handleCloseDockingLocation = () => {
		setOpeDockingLocationn(false);
	};

	const handleUnlinkDockingLocation = (data) => {
		setOpenUnlinkDockingLocation(true);
		setUnlinkDockingData(data);
	};

	const handleConfirmUnlink = () =>
		onUnlinkDockingLocation(
			unlinkDockingData?.dockingLocationReference?.slug,
			unlinkDockingData.id,
		);

	const handleCloseUnlink = () => setOpenUnlinkDockingLocation(false);

	const createMenuItems = (data) => [
		...(isSuperAdmin() && !data?.dockingLocationReference ?
			[
				{
					icon: <LinkIcon />,
					text: t('ui.button.linkDockingLocation'),
					action: () => handleLinkDockingLocation(data),
				},
			]
		:	[]),
		...(isSuperAdmin() && data?.dockingLocationReference ?
			[
				{
					icon: <LinkOffIcon />,
					text: t('ui.button.unlinkDockingLocation'),
					action: () => handleUnlinkDockingLocation(data),
				},
			]
		:	[]),
		{
			icon: <DeleteIcon />,
			text: t('ui.button.inline.delete'),
			action: () => deleteSingleHub(data),
			isRed: true,
		},
	];

	const extraButtons = [
		{
			onClick: () => navigate('/location-management/location/add'),
			variant: 'contained-primary',
			text: t('view.locationmanagement.locations.button.contained.addlocation'),
		},
	];

	const handleCancelDialog = () => dialogAction.setAction(false);

	const handleConfirmDialog = () => {
		if (isObject(dialogData)) {
			onDeleteHub(dialogData.id);
			setDeletedHubIndex(dialogData.id);
			errorHandleDelete.setStartAction(true);
		}
	};

	const tableHeader = [
		{ name: 'id', content: 'ID', hasSorting: true },
		{ name: 'name', content: t('ui.label.name'), hasSorting: true },
		{ name: 'address ', content: t('ui.label.address') },
		...(isSuperAdmin() ?
			[{ name: 'organisation', content: t('ui.label.organisation'), hasSorting: true }]
		:	[]),
		...(isSuperAdmin() ?
			[{ name: 'dockingLocation', content: t('views.location.tableHeader.dockingLocation') }]
		:	[]),
		{ name: 'items', content: 'Items' },
		{ name: '', content: '' },
	];

	const handleLink = (path, label) => (
		<Link
			className={classes.itemGroup}
			color='inherit'
			component={RouterLink}
			to={path}
			state={{ from: location.pathname }}
		>
			{label}
		</Link>
	);

	const tableBody =
		hubsReady ?
			hubsListData.results.map((item) => [
				{ content: handleLink(`/location-management/location/${item.id}/summary`, item.id) },
				{ content: handleLink(`/location-management/location/${item.id}/summary`, item.name) },
				{ content: item?.address && handleHubReference(item) },
				...(isSuperAdmin() ?
					[
						{
							content: handleLink(
								`/organisations/${item.organisationReference.id}/summary`,
								item.organisationReference.name,
							),
						},
					]
				:	[]),
				...(isSuperAdmin() ?
					[{ content: item?.dockingLocationReference?.name }]
				:	[]),
				{
					content: (
						<Label type={item.hasItems ? 'success' : 'error'}>
							{item.hasItems ? t('ui.category.items') : t('ui.label.noItems')}
						</Label>
					),
				},
				{
					content:
						item.licenseStatus ?
							<Label
								color={licenseStatusColors[item.licenseStatus.toLowerCase()]}
								variant='outlined'
							>
								{item.licenseStatus}
							</Label>
						:	<Box display='flex' flexDirection='row-reverse'>
								<Box pl={5.5}>
									<GenericMoreButton
										className={classes.deletLoc}
										menuItems={createMenuItems(item)}
									/>
								</Box>
							</Box>,
				},
			])
		:	Array(4)
				.fill(Array(tableHeader.length).fill())
				.map((arr) => arr.map(() => ({ loading: true })));
	const emptyStateProps = {
		callToAction: '/location-management/location/add',
		callToActionText: t('view.locationmanagement.locations.button.contained.addlocation'),
		image: 'booking',
		title: t('views.location.empty.title'),
	};
	/* * * * * * * *
	 * EMPTY STATE *
	 * * * * * * * */
	const itemsListEmpty =
		isObject(hubsListData) && !hubsListLoading && isEmptyArray(hubsListData.results) ?
			showingInitialResults ?
				<EmptyState
					{...emptyStateProps}
					subTitle={t('views.location.empty.description.zeroInSystem')}
				/>
			:	<EmptyState
					{...emptyStateProps}
					subTitle={t('views.location.empty.description.zeroMatching')}
				/>
		:	null;
	const searchBarPops = {
		clearFilters,
		extraButtons,
		mainFilters,
		hasExtraButtons: true,
		hasMainFilter: true,
		hideMoreFilters: true,
		searchEvents: search.events,
		searchValue: search.value,
		placeholder: t('views.location.placeholder.search'),
	};

	const tableProps = {
		order,
		body: tableBody,
		handlePageChange: pagination.pageNumberChange,
		handleSorting: handleRequestSort,
		header: tableHeader,
		loading: hubsListLoading,
		orderBy: sortingCategory,
		page: pagination.pageNumber,
		rowsPerPage: pagination.pageSize,
		setRowsPerPage: pagination.pageSizeChange,
		total: hubsListData ? hubsListData.total : 0,
		title: t('views.locations.table.title'),
	};

	const linkDocking = {
		locationDetails,
		setLocationDetails,
		open: opeDockingLocationn,
		onClose: handleCloseDockingLocation,
	};

	return (
		<div className={classes.root}>
			<SearchBar {...searchBarPops} />
			{itemsListEmpty ? itemsListEmpty : <Table {...tableProps} />}
			{dialogAction.action ?
				<AlertDialog
					dialogDescription={`${t('ui.pleaseConfirmThatYouWantTo')} ${t('ui.delete.lowerText')} ${isObject(dialogData) ? `${dialogData.name}` : null}`}
					dialogTitle={t('ui.delete')}
					handleClose={handleCancelDialog}
					handleConfirm={handleConfirmDialog}
					loading={deleteHubLoading && !deleteHubDone}
					open={dialogAction.action}
					textDialog={{
						title: t('views.locations.textDialog.title'),
						description: `${t('views.locations.textDialog.description')}`,
						notAllowed: isObject(dialogData) && dialogData.hasItems,
					}}
				/>
			:	null}
			{openUnlinkDockingLocation ?
				<ActionDialog
					actionButtonProps={{
						action: handleConfirmUnlink,
						text:
							unlinkDockingLocationLoading ?
								<CircularProgress disableShrink size={24} />
							:	t('ui.confirm'),
					}}
					handleClose={handleCloseUnlink}
					loading={false}
					open={openUnlinkDockingLocation}
					title={t('views.location.dialog.unlinkDockingLocation.title')}
				>
					<Typography variant='body2'>{`${t('views.location.dialog.unlinkDockingLocation.description')} ${unlinkDockingData.name}`}</Typography>
				</ActionDialog>
			:	null}
			<LinkDockingLocation {...linkDocking} />
		</div>
	);
};

Locations.propTypes = {
	onFetchHubs: PropTypes.func,
	onSetFilter: PropTypes.func.isRequired,
	onResetState: PropTypes.func,
	hubsList: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),

	onDeleteHub: PropTypes.func,
	deleteHub: PropTypes.shape({
		success: PropTypes.bool,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	onFetchOrganisations: PropTypes.func,
	organisationsList: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	onUnlinkDockingLocation: PropTypes.func,
	unlinkDockingLocation: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	linkDockingLocation: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
};

const mapStateToProps = (state) => {
	return {
		hubsList: state.paged.hubs,
		filters: state.filters,
		deleteHub: state.condition.deleteHub,

		linkDockingLocation: state.details.linkDockingLocation,
		unlinkDockingLocation: state.details.unlinkDockingLocation,

		organisationsList: state.paged.organisations,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchHubs: (page, filters) => dispatch(actions.fetchHubs(page, filters)),
		onSetFilter: (identifier, value) => dispatch(actions.setFilter(identifier, value)),
		onDeleteHub: (hubId) => dispatch(actions.deleteHub(hubId)),
		onFetchOrganisations: (page, filters, concat) =>
			dispatch(actions.fetchOrganisations(page, filters, concat)),
		onUnlinkDockingLocation: (slug, locationId) =>
			dispatch(actions.unlinkDockingLocation(slug, locationId)),
		onResetState: (state) => dispatch(actions.resetState(state)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Locations);
