import { useMemo } from 'react';

import { AxisValueFormatterContext } from '@mui/x-charts/internals';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { ChartsLegendWithValues, LineChart } from '~components';
import { ItemCategoryEnum, PeriodicityEnum } from '~enums';
import { DateRange } from '~interfaces/dateRanges';
import {
	generateDateArray,
	longMonthAndYearFormatter,
	shortMonthAndYearFormatter,
} from '~utils/dateUtils';

import CustomAxisTooltip from './customAxisTooltip';
import BookingStatisticsService from '../../services/bookingStatisticsService';

const service = new BookingStatisticsService();

interface BookingsLineChartProps {
	organisationId?: string;
	period: DateRange;
	itemId?: string;
	hubId?: string;
	categoryId?: string;
	partnerId?: string;
	periodicity?: PeriodicityEnum;
}

dayjs.extend(weekOfYear);

const now = dayjs();

const BookingsLineChart = ({
	period = {
		start: now.subtract(3, 'month').toDate(),
		end: now.toDate(),
	},
	...props
}: BookingsLineChartProps) => {
	const { t } = useTranslation('general');

	const fetchParameters = useMemo(
		() => ({
			period: period,
			...props,
		}),
		[props, period],
	);

	const { data, isLoading } = useSWR([service.basePath, fetchParameters], ([_, args]) => {
		if (isLoading) {
			service.abortCurrentRequest('parameters change');
		}

		return service.getCounts2(args);
	});

	/**
	 * Scope the results for the current selected periodicity.
	 */
	const selectedPeriodResults = useMemo(
		() =>
			data?.results.filter(
				(result) =>
					result.data.length > 0 &&
					result.timestamp <= period.end &&
					result.timestamp >
						dayjs(period.end)
							.startOf(props.periodicity === PeriodicityEnum.Monthly ? 'month' : 'year')
							.toDate(),
			),
		[data],
	);

	/**
	 * Generate an array of dates, so we don't have any missing gaps
	 * within the charts.
	 */
	const dates = useMemo(
		() => generateDateArray(period, props.periodicity),
		[period, props.periodicity],
	);

	/**
	 * We may have missing data where the value is 0. So fill
	 * in the missing data points where the value.
	 * So e.g. use this as the data results
	 */
	const results = useMemo(
		() =>
			dates?.map(
				(el) =>
					data?.results.find((result) => dayjs(result.timestamp).isSame(el, props.periodicity === PeriodicityEnum.Monthly ? 'day' : 'month')) ?? {
						timestamp: el,
						data: [],
					},
			) ?? [],
		[dates, data],
	);

	const xAxisValueFormatter = (value: Date, context: AxisValueFormatterContext) => {
		if (context.location !== 'tick' && props.periodicity === PeriodicityEnum.Monthly) {
			return value.toLocaleDateString();
		} else if (context.location !== 'tick') {
			return longMonthAndYearFormatter.format(value);
		}

		return shortMonthAndYearFormatter.format(value);
	};

	const sumDataFromArray = (data: any[], key: string): number =>
		data.reduce((total: number, el) => (total += el[key]), 0);

	return (
		<LineChart
			loading={isLoading}
			xAxis={[
				{
					min: period.start,
					// max: period.end,
					max: dayjs(period.end).startOf(
						props.periodicity === PeriodicityEnum.Yearly ? 'month' : 'day'
					).toDate(),
					scaleType: 'time',
					data: dates,
					valueFormatter: xAxisValueFormatter,
					tickNumber: 3,
				},
			]}
			series={[
				{
					id: 'bookingSeries',
					label: t('totalBookings'),
					data: results.map((result) => sumDataFromArray(result.data, 'bookingCount')),
					showMark: false,
					// area: true,
				},
				{
					id: 'uniqueUsersSeries',
					label: t('uniqueUsers'),
					data: results.map((result) => sumDataFromArray(result.data, 'uniqueUsers') ?? 0),
					showMark: false,
				},
			]}
			slots={{
				axisContent: CustomAxisTooltip,
				legend: ChartsLegendWithValues,
			}}
			slotProps={{
				legend: {
					values: [
						selectedPeriodResults?.reduce(
							(total, el) => (total += sumDataFromArray(el.data, 'bookingCount')),
							0,
						),
						selectedPeriodResults?.reduce(
							(total, el) => (total += sumDataFromArray(el.data, 'uniqueUsers')),
							0,
						),
					],
					hidden: true,
				},
				axisContent: {
					subSeries: Object.values(ItemCategoryEnum).map((itemCategory) => ({
						parentSeriesId: 'bookingSeries',
						label: t(`ui.label.${itemCategory}`),
						data: results.map(
							(el) => el.data.find((el2) => el2.categoryType === itemCategory)?.bookingCount ?? 0,
						),
					})),
				},
			}}
		/>
	);
};

export default BookingsLineChart;
