import { StrictMode, Suspense } from 'react';

import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { configureStore } from '@reduxjs/toolkit';
import { createRoot } from 'react-dom/client';
import { AuthProvider } from 'react-oidc-context';
import { Provider } from 'react-redux';
import { SWRConfig } from 'swr';

import { oidcConfig } from '~features/authentication';
import { GenericLayout } from '~layouts';
import { LoadingPage } from '~pages';

import App from './app';
import reducer from './store/reducers/index';
// import theme from './themes/theme';
import theme from './theme';

import './index.css';
import './lib/axiosInterceptors';
import './lib/i18n';
import './lib/date';

if (import.meta.env.DEV) {
	import('./lib/viteErrorOverlay');
}

export const store = configureStore({
	reducer,
	devTools: import.meta.env.NODE_ENV !== 'production',
});

console.log(import.meta.env.VITE_BUILD_DATETIME);
console.log(import.meta.env.VITE_BUILD_NUMBER);

// TODO: setting up the theme should be move to the app.tsx over time
const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
	<StrictMode>
		<Suspense
			fallback={
				<GenericLayout>
					<LoadingPage />
				</GenericLayout>
			}
		>
			<Provider store={store}>
				<AuthProvider {...oidcConfig}>
					<SWRConfig
						value={{
							refreshInterval: 60 * 60 * 1000,
							onError: (error) => console.error(error)
						}}
					>
						<StyledEngineProvider injectFirst>
							<ThemeProvider theme={theme}>
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<App />
								</LocalizationProvider>
							</ThemeProvider>
						</StyledEngineProvider>
					</SWRConfig>
				</AuthProvider>
			</Provider>
		</Suspense>
	</StrictMode>,
);
