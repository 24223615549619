import { useEffect, useState } from 'react';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import FlagIcon from '@mui/icons-material/FlagOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { Link, Box } from '@mui/material';
import { useAtomValue } from 'jotai';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { connect } from 'react-redux';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';

import { userInfoAtom } from '~atoms';
import { useAuthorize } from '~features/authentication';
import { BookingStatusChip } from '~features/bookings';
import { useDebounce } from '~hooks';

import WarningIcon from '../../../assets/icons/label-filled-icon-warning@3x.png';
import {
	EmptyState,
	SearchBar,
	Table,
	DatePicker,
	GenericMoreButton,
	IconInfoLabel,
} from '../../../components';
import { exportType } from '../../../constantsOld';
import { commaTimeStrings } from '../../../shared/datetime';
import { useExport } from '../../../shared/downloadHooks';
import {
	useSearch,
	useError,
	usePrevious,
	useBasicFilter,
	useComplexFilter,
	usePeriodFilter,
	usePagination,
} from '../../../shared/hooks';
import {
	isObject,
	isEmptyArray,
	isEmptyString,
	isArray,
	isNull,
	isFullString,
	decimalBase,
	handleHubReference,
	isInteger,
	isFullArray,
} from '../../../shared/utility';
import * as actions from '../../../store/actions';
import AddTicket from '../../Maintenance/AddTickets';
import SideDrawer from '../../Planboard/SideDrawer';
import { useStyles } from '../style';

const BookingList = (props) => {
	const {
		onFetchBookings,
		bookings,
		categoriesList,
		hubsList,
		organisationsList,
		onFetchCategories,
		onFetchHubs,

		onFetchOrganisations,
		canceledBooking,

		onConfirmBooking,
		onRejectBooking,
		confirmBooking,
		rejectBooking,
		updatedBookingDetails,
		stopBooking,
		onExportBooking,
		exportBooking,
		addedPlanboardBooking,
		userId,
		organisationId,
	} = props;
	const { t } = useTranslation('general');
	const location = useLocation();
	const userInfo = useAtomValue(userInfoAtom);
	const { isSuperAdmin } = useAuthorize();

	const [openAddOrEditBooking, setOpenAddOrEditBooking] = useState(false);
	const [presetBookingData, setPresetBookingData] = useState(null);
	const previousUpdatedBookingDetails = usePrevious(updatedBookingDetails.loading);
	const [openAddTicket, setOpenAddTicket] = useState(false);
	const [presetTicketData, setPresetTicketData] = useState(null);

	const classes = useStyles();

	const [shouldDoInitialFetch, setShouldDoInitialFetch] = useState(true);
	const [showingInitialResults, setShowingInitialResults] = useState(true);

	const [order, setOrder] = useState('asc');
	const [sortingBy, setSortingBy] = useState('id');
	const [shouldSort, setShouldSort] = useState(false);
	const [shouldFilter, setShouldFilter] = useState(false);
	const [shouldSearch, setShouldSearch] = useState(false);

	const { data: bookingsData, loading: bookingsLoading, error: bookingsError } = bookings;
	const bookingsReady = isObject(bookingsData) && !bookingsLoading && !bookingsError;

	const {
		data: categoriesData,
		loading: categoriesLoading,
		error: categoriesError,
	} = categoriesList;
	const categoriesReady = isArray(categoriesData) && !categoriesLoading && !categoriesError;

	const { success: canceledSuccess, loading: canceledLoading } = canceledBooking;

	const { loading: exportBookingLoading } = exportBooking;

	const { data: stopBookingData } = stopBooking;

	const { success: confirmBookingSuccess } = confirmBooking;
	const { success: rejectBookingSuccess } = rejectBooking;

	const { data: addedPlanboardBookingData } = addedPlanboardBooking;

	const [orderDescending, setOrderDescending] = useState(true);

	const statusFilterValue = [
		{ value: 'none', label: t('ui.filter.status.all') },
		{ value: 'active', label: t('ui.status.active') },
		{ value: 'upcoming', label: t('ui.status.upcoming') },
		{ value: 'cancelled', label: t('ui.status.cancelled') },
		{ value: 'completed', label: t('ui.status.completed') },
		{ value: 'expired', label: t('ui.status.expired') },
		{ value: 'pending', label: t('ui.status.pending') },
		{ value: 'rejected', label: t('ui.status.rejected') },
		{ value: 'overtime', label: t('ui.status.overtime') },
		...(isSuperAdmin() ?
			[{ value: 'attention', label: t('ui.status.needsAttention') }]
		:	[]),
	];

	const pagination = usePagination();
	const exportBookings = useExport({ label: t('ui.category.bookings'), exportData: exportBooking });
	const confirmBookingMessage = useError({
		value: confirmBooking,
		message: t('views.bookings.successfullyConfirmedBooking'),
	});
	const rejectBookingMessage = useError({
		value: rejectBooking,
		message: t('views.bookings.successfullyRejectedBooking'),
	});
	const search = useSearch(pagination.setPageNumber, setShouldSearch, 'bookingSearch');
	const bookingCategoryFilter = useBasicFilter('bookingCategoryFilter');
	const bookingStatusFilter = useBasicFilter('bookingStatusFilter', 'none');
	const bookingOrganisationFilter = useComplexFilter(
		'bookingOrganisationNameFilter',
		'bookingOrganisationIdFilter',
	);
	const bookingLocationFilter = useComplexFilter(
		'bookingLocationNameFilter',
		'bookingLocationIdFilter',
	);
	const bookingPerioFilter = usePeriodFilter({
		setSorting: setSortingBy,
		onOrderDescending: setOrderDescending,
	});

	const periodStart =
		isObject(bookingPerioFilter.dates.startDate) ?
			bookingPerioFilter.dates.startDate.toISOString()
		:	null;
	const periodEnd = useDebounce(
		isObject(bookingPerioFilter.dates.endDate) ?
			bookingPerioFilter.dates.endDate.toISOString()
		:	null,
		500,
	);

	const filters = {
		...(isFullString(search.value) && { searchTerm: search.value }),
		...(!isEmptyString(sortingBy) && { sortBy: sortingBy }),
		...(bookingCategoryFilter.value !== 'all' && { categoryId: bookingCategoryFilter.value }),
		...(bookingLocationFilter.valueId !== 'all' && { hubId: bookingLocationFilter.valueId }),
		...(bookingOrganisationFilter.valueId !== 'all' && {
			organisationId: bookingOrganisationFilter.valueId,
		}),
		...(bookingStatusFilter.value === 'pending' ?
			{ approvalFilters: 'pending' }
		:	{ filters: `${bookingStatusFilter.value}` }),
		...(isInteger(organisationId) && { organisationId: organisationId }),
		...(!isNull(periodStart) && { dateAfter: periodStart }),
		...(!isNull(periodEnd) && { dateBefore: periodEnd }),
		...(isInteger(userId) && { userId: userId }),
		orderDescending,
		slim: true,
	};

	const fetchData = () => {
		onFetchBookings(pagination.page, filters);
		if (shouldSearch) {
			setShouldSearch(false);
		}
		if (shouldFilter) {
			setShouldFilter(false);
		}
		if (shouldSort) {
			setShouldSort(false);
		}
		if (shouldDoInitialFetch) {
			setShouldDoInitialFetch(false);
		}
	};

	useEffect(() => {
		if (
			!bookingsLoading &&
			(shouldSort ||
				shouldSearch ||
				pagination.pageNumber ||
				pagination.pageSize ||
				shouldFilter ||
				periodStart ||
				periodEnd ||
				confirmBookingSuccess ||
				rejectBookingSuccess ||
				stopBookingData ||
				isObject(addedPlanboardBookingData))
		) {
			if ((shouldFilter || shouldSearch || shouldSort) && showingInitialResults) {
				setShowingInitialResults(false);
			}
			onFetchBookings(pagination.page, filters);
			if (shouldFilter) {
				setShouldFilter(false);
			}
			if (shouldSearch) {
				setShouldSearch(false);
			}
			if (shouldSort) {
				setShouldSort(false);
			}
		}
	}, [
		addedPlanboardBookingData,
		shouldSort,
		shouldSearch,
		pagination.pageNumber,
		pagination.pageSize,
		shouldFilter,
		periodStart,
		periodEnd,
		confirmBookingSuccess,
		rejectBookingSuccess,
		stopBookingData,
	]);

	useEffect(() => {
		return () => {
			setShouldDoInitialFetch(true);
			setShowingInitialResults(true);
		};
	}, [setShowingInitialResults, shouldDoInitialFetch]);

	useEffect(() => {
		if (!categoriesLoading && isNull(categoriesData)) {
			onFetchCategories();
		}
	}, [categoriesData]);

	useEffect(() => {
		if (canceledSuccess) {
			fetchData();
		}
	}, [canceledLoading, canceledSuccess]);

	const handleRequestSort = (property) => {
		setOrder(order === 'desc' ? 'asc' : 'desc');
		setOrderDescending(!orderDescending);
		setSortingBy(property);
		setShouldSort(true);
	};

	/* * * * * *
	 * FILTER  *
	 * * * * * */
	const handleOrganisationFilter = (item) => {
		bookingOrganisationFilter.setValueId(item.id);
		setShouldFilter(true);
		if (isInteger(item.id)) {
			bookingOrganisationFilter.setValueName(item.name);
		}
		if (isEmptyString(item.id)) {
			bookingOrganisationFilter.setValueName('');
		}
		if (item.id === bookingOrganisationFilter.valueId) {
			return;
		}
	};

	const handleCategoryFilter = (event) => {
		const value = event.target.value;
		bookingCategoryFilter.setValue(value);
		setShouldFilter(true);
		if (value === bookingCategoryFilter.value) {
			return;
		}
	};

	const handleHubFilter = (item) => {
		bookingLocationFilter.setValueId(item.id);
		setShouldFilter(true);
		if (isInteger(item.id)) {
			bookingLocationFilter.setValueName(item.name);
		}
		if (isEmptyString(item.id)) {
			bookingLocationFilter.setValueName('');
		}
		if (item.id === bookingLocationFilter.valueId) {
			return;
		}
	};

	const handleStatusFilters = (event) => {
		const value = event.target.value;
		if (value !== bookingStatusFilter.value) {
			bookingStatusFilter.setValue(value);
			setShouldFilter(true);
		}
	};

	const mainFilters = [
		{
			isSelectWithLazyLoading: true,
			events: {
				onChange: handleOrganisationFilter,
				searchHandle: bookingOrganisationFilter.setValueName,
				filter: 'verified',
			},
			value: bookingOrganisationFilter.valueName,
			dataList: organisationsList,
			placeholder: t('ui.filter.organisations.all'),
			onFetchData: onFetchOrganisations,
			listType: 'organisations',
			defaultListItem: { id: '', name: t('ui.filter.organisations.all') },
		},
		{
			isSelectWithLazyLoading: true,
			events: { onChange: handleHubFilter, searchHandle: bookingLocationFilter.setValueName },
			value: bookingLocationFilter.valueName,
			dataList: hubsList,
			placeholder: t('ui.filter.locations.all'),
			onFetchData: onFetchHubs,
			listType: 'hubs',
			defaultListItem: { id: '', name: t('ui.filter.locations.all') },
		},
		{
			events: { onChange: (e) => handleCategoryFilter(e) },
			value: bookingCategoryFilter.value,
			selectOptions: [
				{ value: 'all', label: t('views.assets.items.mainFilters.category.all') },
			].concat(
				categoriesReady ?
					categoriesData.map((category) => ({
						value: category.id.toString(),
						label: category.name,
					}))
				:	[],
			),
		},
		{
			events: { onChange: (e) => handleStatusFilters(e) },
			value: bookingStatusFilter.value,
			selectOptions: [].concat(statusFilterValue),
		},
	];

	const handleEdit = (info) => {
		setPresetBookingData({
			bookingId: Number(info.id),
			itemId: Number(info.instance.item.id),
			itemSelected: true,
		});
		setOpenAddOrEditBooking(true);
	};

	const handleEditClose = () => {
		setOpenAddOrEditBooking(false);
		if (updatedBookingDetails.success && previousUpdatedBookingDetails) {
			fetchData();
		}
	};

	const handleClickAddTicket = (info) => {
		setPresetTicketData({
			resource: {
				hubReference: info.hub,
				categoryId: info.instance.item.categoryId,
				id: info.instance.item.id,
				itemInstanceId: info.instance.id,
				mainItemId: info.instance.item.id,
				numberOfChildren: 1,
				title: info.instance.item.name,
			},
		});
		setOpenAddTicket(true);
	};

	const handleCloseAddTicket = () => setOpenAddTicket(false);

	const handleAddBookingOpen = () => {
		setOpenAddOrEditBooking(true);
		setPresetBookingData(undefined);
	};

	const handleApproveBookingRequest = (value) => {
		onConfirmBooking(value.id);
		confirmBookingMessage.setStartAction(true);
	};

	const handleRejectBookingRequest = (value) => {
		onRejectBooking(value.id);
		rejectBookingMessage.setStartAction(true);
	};

	const handleExport = (type) => {
		onExportBooking({ ...filters, exportType: type });
		exportBookings.setExportType(type);
		exportBookings.setDownload(true);
	};

	const downloadData = {
		disabled: exportBookingLoading,
		text: t('ui.button.contained.exportBookings'),
		action: () => [
			{
				icon: <InsertDriveFileOutlinedIcon />,
				text: t('ui.button.export.xlsx'),
				action: () => handleExport(exportType.XLSX),
			},
			{
				icon: <InsertDriveFileOutlinedIcon />,
				text: t('ui.button.export.csv'),
				action: () => handleExport(exportType.CSV),
			},
		],
	};

	const extraButtons = [
		{
			text: t('ui.button.contained.newbooking'),
			onClick: handleAddBookingOpen,
			variant: 'contained-primary',
		},
	];

	const statusColors = {
		upcoming: 'default',
		active: 'success',
		overtime: 'error',
		cancelled: 'error',
		completed: 'disabled',
		pending: 'error',
	};

	const handleClearFilters = () => {
		bookingCategoryFilter.setValue('all');
		bookingStatusFilter.setValue('none');
		bookingOrganisationFilter.setValueName('');
		bookingOrganisationFilter.setValueId('all');
		bookingLocationFilter.setValueName('');
		bookingLocationFilter.setValueId('all');
		bookingPerioFilter.setDates({ startDate: null, endDate: null });
		search.events.onClear();
		setSortingBy('id');
		setShouldFilter(true);
		setOrderDescending(true);
		pagination.resetPagination();
	};

	const clearFilters = {
		clear:
			pagination.pageNumber !== 1 ||
			pagination.pageSize !== 10 ||
			bookingCategoryFilter.value !== 'all' ||
			bookingStatusFilter.value !== 'none' ||
			periodStart ||
			periodEnd ||
			bookingOrganisationFilter.valueId !== 'all' ||
			bookingLocationFilter.valueId !== 'all',
		btnText: 'ui.button.inline.clearfilters',
		action: handleClearFilters,
	};

	const createMenuItems = (item) => [
		...(!item.isCanceled ?
			[{ icon: <EditIcon />, text: t('ui.button.inline.edit'), action: () => handleEdit(item) }]
		:	[]),
		{
			icon: <FlagIcon />,
			text: t('ui.button.inline.reportproblem'),
			action: () => handleClickAddTicket(item),
		},
		...((
			item.approvalState === 'pending' &&
			item.status !== 'cancelled' &&
			(userInfo.organisation.label === item.instance.item.organisation.name ||
				isSuperAdmin())
		) ?
			[
				{
					icon: <CheckIcon />,
					text: t('ui.approveRequest'),
					action: () => handleApproveBookingRequest(item),
				},
				{
					icon: <CloseIcon />,
					text: t('ui.rejectRequest'),
					action: () => handleRejectBookingRequest(item),
					isRed: true,
				},
			]
		:	[]),
	];

	const tableHeaders = [
		{ name: 'id', content: 'ID', hasSorting: true },
		{ name: 'startDate', content: t('views.ItemDetail.activities.start'), hasSorting: true },
		{ name: 'endDate', content: t('views.ItemDetail.activities.end'), hasSorting: true },
		...(!isInteger(userId) ? [{ name: 'userName', content: t('ui.label.user') }] : []),
		{ name: 'category', content: t('ui.label.category') },
		{ name: 'itemName', content: t('ui.label.itemGroup') },
		{ name: 'item', content: t('ui.label.item') },
		{ name: 'reservationType', content: t('views.bookings.details.tableHeaders.reservationType') },
		{ name: 'price', content: t('ui.price') },
		{ name: 'hub', content: t('ui.label.location') },
		{ name: 'provider', content: t('ui.provider') },
		{ name: 'status', content: t('ui.label.status') },
		{ name: '', content: '' },
	];

	const handleLinks = (path, label, returnButton = 'ui.category.bookings') => (
		<Link
			className={classes.itemGroup}
			color='primary'
			component={RouterLink}
			to={path}
			state={{
				from: location.pathname,
				label: t(returnButton),
			}}
		>
			{label}
		</Link>
	);

	const handleBilling = (billing) => {
		const billingOrganisation = billing?.user?.group?.organisation;
		if (billing.billingType === 'business') {
			if (isSuperAdmin()) {
				return handleLinks(
					`/organisations/${billingOrganisation?.id}/summary`,
					billingOrganisation?.name,
				);
			} else {
				return billingOrganisation?.name;
			}
		} else {
			return t('ui.label.personalFinance');
		}
	};

	const tableBody =
		bookingsReady && isFullString(userInfo.organisation.label) ?
			bookingsData.results.map((item) => {
				const bookingItem = item.instance.item;
				const organisation =
					isSuperAdmin() ?
						handleLinks(
							`/organisations/${bookingItem.organisation.id}/summary`,
							bookingItem.organisation.name,
						)
					:	bookingItem.organisation.name;
				const pending =
					(item.approvalState === 'pending' && item.status !== 'cancelled') ||
					item.approvalState === 'declined';

				const infoProps = {
					icon: <img alt='warning-icon' height='16' src={WarningIcon} width='16' />,
					showIcon: !item.attentionStates.includes('none'),
					infoTitle: t('views.bookings.details.label.requiresAttention'),
				};

				return [
					{ content: handleLinks(`/booking/booking-detail/${item.id}/summary`, item.id) },
					{ content: commaTimeStrings(item.start) },
					{
						content:
							bookingItem.bookingTypes.includes('scanNfc') && item.status !== 'completed' ?
								'-'
							:	commaTimeStrings(item.end),
					},
					...(!isInteger(userId) ?
						[
							{
								content:
									isObject(item.user) ?
										handleLinks(`/user-management/users/${item.user.id}/summary`, item.user.name)
									:	'-',
							},
						]
					:	[]),
					{ content: t(`ui.label.${bookingItem.type}`) },
					{
						content: handleLinks(
							`/item-management/items/${bookingItem.id}/summary`,
							bookingItem.name,
						),
					},
					{
						content: handleLinks(
							`/item-management/${bookingItem.id}/instance/${item.instance.id}/summary`,
							item.instance.name,
						),
					},
					{ content: handleBilling(item) },
					{
						content:
							item.price.price === 0 ?
								t('ui.free')
							:	`${decimalBase(item.price.price, item.price.currencySymbol)}`,
					},
					{ content: handleHubReference(item.hub) },
					{ content: organisation },
					{
						content: (
							<Box alignItems='center' display='flex'>
								<BookingStatusChip
									status={item.status}
									approvalState={item.approvalState}
									usageState={item.usageState}
									itemCategory={bookingItem.type}
								/>
								{isSuperAdmin() ?
									<IconInfoLabel {...infoProps} />
								:	null}
							</Box>
						),
					},
					{
						content: (
							<div>
								{' '}
								<GenericMoreButton menuItems={createMenuItems(item)} />{' '}
							</div>
						),
					},
				];
			})
		:	Array(4)
				.fill(Array(tableHeaders.length).fill())
				.map((arr) => arr.map(() => ({ loading: true })));

	const emptyStateProps = {
		image: 'booking',
		callToActionText: t('ui.button.contained.newbooking'),
		handleAction: handleAddBookingOpen,
		title: t('views.bookings.empty.title'),
	};

	const bookingsEmpty =
		isObject(bookingsData) && !bookingsLoading && isEmptyArray(bookingsData.results) ?
			showingInitialResults ?
				<EmptyState
					{...emptyStateProps}
					subTitle={t('views.bookings.empty.description.zeroInSystem')}
				/>
			:	<EmptyState
					{...emptyStateProps}
					subTitle={t('views.bookings.empty.description.zeroMatching')}
				/>
		:	null;

	const datePickerProps = {
		end: bookingPerioFilter.dates.endDate,
		start: bookingPerioFilter.dates.startDate,
		focused: bookingPerioFilter.focused,
		handleDatesChange: bookingPerioFilter.datesChange,
		handleFocusChange: bookingPerioFilter.focusAction,
	};

	const searchBarProps = {
		clearFilters,
		extraButtons,
		mainFilters,
		downloadData: isFullArray(bookingsData?.results) ? downloadData : null,
		hasExtraButtons: true,
		hasMainFilter: true,
		hideMoreFilters: true,
		periodFilter: <DatePicker {...datePickerProps} />,
		placeholder: t('ui.placeholder.search.booking'),
		searchEvents: search.events,
		searchValue: search.value,
	};

	const tableProps = {
		body: tableBody,
		cellStyle: classes.cellStyle,
		handlePageChange: pagination.pageNumberChange,
		handleSorting: handleRequestSort,
		header: tableHeaders,
		loading: bookingsLoading,
		order,
		orderBy: sortingBy,
		page: pagination.pageNumber,
		rowsPerPage: pagination.pageSize,
		setRowsPerPage: pagination.pageSizeChange,
		title: t('nav.category.bookings'),
		total: bookingsData ? bookingsData.total : 0,
	};

	const sideDrawerProps = {
		newBooking: true,
		setPresetBookingData,
		onClose: handleEditClose,
		presetData: presetBookingData,
		open: openAddOrEditBooking,
	};

	const addTicketProps = { onClose: handleCloseAddTicket, open: openAddTicket, presetTicketData };

	return (
		<div className={classes.listWrap}>
			<SearchBar {...searchBarProps} />
			{bookingsEmpty ? bookingsEmpty : <Table {...tableProps} />}
			<SideDrawer {...sideDrawerProps} />
			<AddTicket {...addTicketProps} />
		</div>
	);
};

BookingList.propTypes = {
	userId: PropTypes.number,
	bookings: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	onFetchBookings: PropTypes.func,
	categoriesList: PropTypes.shape({
		data: PropTypes.array,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	hubsList: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	organisationsList: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	onFetchCategories: PropTypes.func,
	onFetchHubs: PropTypes.func,
	onFetchOrganisations: PropTypes.func,
	canceledBooking: PropTypes.shape({
		success: PropTypes.bool,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	confirmBooking: PropTypes.shape({
		success: PropTypes.bool,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	rejectBooking: PropTypes.shape({
		success: PropTypes.bool,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	updatedBookingDetails: PropTypes.shape({
		success: PropTypes.bool,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	onConfirmBooking: PropTypes.func,
	onRejectBooking: PropTypes.func,
	onExportBooking: PropTypes.func,
	stopBooking: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	exportBooking: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	addedPlanboardBooking: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	organisationId: PropTypes.any,
};

const mapStateToProps = (state) => {
	return {
		bookings: state.paged.bookings,

		categoriesList: state.list.categories,
		hubsList: state.paged.hubs,
		organisationsList: state.paged.organisations,
		canceledBooking: state.condition.canceledBooking,
		confirmBooking: state.condition.confirmBooking,
		rejectBooking: state.condition.rejectBooking,
		updatedBookingDetails: state.condition.updatedBookingDetails,
		stopBooking: state.details.stopBooking,
		exportBooking: state.details.exportBooking,
		addedPlanboardBooking: state.details.addedPlanboardBooking,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchBookings: (page, filters) => dispatch(actions.fetchBookings(page, filters)),
		onFetchHubs: (page, filters, concat) => dispatch(actions.fetchHubs(page, filters, concat)),
		onFetchCategories: () => dispatch(actions.fetchCategories()),
		onFetchOrganisations: (page, filters, concat) =>
			dispatch(actions.fetchOrganisations(page, filters, concat)),
		onConfirmBooking: (bookingId) => dispatch(actions.confirmBooking(bookingId)),
		onRejectBooking: (bookingId) => dispatch(actions.rejectBooking(bookingId)),
		onExportBooking: (filters) => dispatch(actions.exportBooking(filters)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingList);
