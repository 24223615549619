import dayjs, { Dayjs } from 'dayjs';
import { PeriodicityEnum } from '~enums';
import { WeeklySchedule } from '~interfaces';
import { DateRange } from '~interfaces/dateRanges';

// With optional milliseconds
const timeRegExp = /^\d{2}:\d{2}:\d{2}(?:[.,]\d+)?$/;

/**
 * For performance
 * See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleTimeString
 */
export const datetimeWithoutSecondsFormatter = new Intl.DateTimeFormat('nl-NL', {
	year: 'numeric',
	month: 'numeric',
	day: 'numeric',
	hour: '2-digit',
	minute: '2-digit',
});

export const time24Formatter = new Intl.DateTimeFormat('nl-NL', {
	hour: '2-digit',
	minute: '2-digit',
	second: '2-digit',
});

export const time24WithoutSecondsFormatter = new Intl.DateTimeFormat('nl-NL', {
	hour: '2-digit',
	minute: '2-digit',
});

export const longMonthAndYearFormatter = new Intl.DateTimeFormat('nl', {
	month: 'long',
	year: 'numeric',
});

export const shortMonthAndYearFormatter = new Intl.DateTimeFormat('nl', {
	month: 'short',
	year: 'numeric',
});

/**
 * Parse a time to a date
 * @param time The time in the format HH:mm:ss
 * @param date The date to use. Default to the now
 * @returns
 */
const parseTime = (time: string, date = new Date()): Date | null => {
	const isTimeFormat = timeRegExp.test(time);
	if (!isTimeFormat) {
		console.warn('Unsupported time format', time);
		return null;
	}

	const timeElements = time.split(':');

	return new Date(
		date.getFullYear(),
		date.getMonth(),
		date.getDate(),
		parseInt(timeElements[0]),
		parseInt(timeElements[1]),
		parseInt(timeElements[2]),
	);
};

/**
 *
 * @param period
 * @param stepInMinutes
 * @param array An array of object include a date that are ascending sorted
 * @param func
 */
const fillMissingDatesInArrayOfObjects2 = <T>(
	period: DateRange,
	stepInMinutes: number,
	array: {
		timestamp: Date;
	}[],
	func: <T extends object>() => T,
): { timestamp: Date } => {
	const stepInMiliSeconds = stepInMinutes * 1000 * 60;

	const anchorEpochDateInMs = array[0].timestamp.getTime();
	
	const iterations = Math.ceil((period.end.getTime() - period.start.getTime()) / stepInMiliSeconds);
	const startIterationDifference = Math.ceil((anchorEpochDateInMs - period.start.getTime()) / stepInMiliSeconds);

	let currentIndex = 0;

	const newArray = [];

	let date: Date;
	let epochDate: number;
	let currentEpochDate: number | undefined;
	for (let i = -startIterationDifference; i < iterations - startIterationDifference; i++) {
		date = new Date(anchorEpochDateInMs + stepInMiliSeconds * i);
		epochDate = anchorEpochDateInMs + stepInMiliSeconds * i;
		currentEpochDate = array[currentIndex]?.timestamp.getTime();

		if (currentEpochDate && epochDate === currentEpochDate) {
			newArray.push(array[currentIndex]);
			currentIndex += 1;
		} else {
			newArray.push({
				...func(),
				timestamp: date,
			});
		}
	}

	return newArray;
};

/**
 *
 * @param period
 * @param stepInMinutes
 * @param array An array of object include a date that are ascending sorted
 * @param func
 */
const fillMissingDatesInArrayOfObjects = <T extends object>(
	period: DateRange,
	periodicity: PeriodicityEnum,
	array: {
		timestamp: Date;
	}[],
	func: () => T,
): { timestamp: Date } => {
	const anchorDate = dayjs(array[0].timestamp);

	const step = periodicity === PeriodicityEnum.Monthly ? 'day' : 'month';
	// const step = 'day';

	const startIterations = anchorDate.diff(dayjs(period.start), step);
	const endIterations = dayjs(period.end).diff(anchorDate, step);

	const newArray = [];
	let currentIndex = 0;

	let date: Dayjs;
	for (let i = -startIterations; i < endIterations; i++) {
		date = anchorDate.add(i, step);

		if (array[currentIndex] && dayjs(array[currentIndex].timestamp).isSame(date, step)) {
			newArray.push(array[currentIndex]);
			currentIndex += 1;
		} else {
			newArray.push({
				...func(),
				timestamp: date.toDate()
			});
		}
	}

	return newArray;
};

const generateDateArray = (
	period: DateRange,
	periodicity: PeriodicityEnum
) => {
	const startDate = dayjs(period.start);
	const step = periodicity === PeriodicityEnum.Monthly ? 'day' : 'month';
	// const step = 'day';
	const iterations = dayjs(period.end).diff(startDate, step);

	const array: Date[] = [];
	for (let i = 0; i < iterations + 1; i++) {
		array.push(startDate.add(i, step).toDate());
	}

	return array;
};

// const tryMergeOverlappingSchedules = (schedules: WeeklySchedule[]): WeeklySchedule[] => {
// 	const openAllDays = schedules.filter(el => el.isOpenAllDay)

// 	return schedules;
// };

// const getOverlappingDateRanges = (
// 	dateRange
// )

export {
	parseTime,
	fillMissingDatesInArrayOfObjects,
	generateDateArray,
};
