import path from 'path';

import { AxiosResponse } from 'axios';

import { IdReference, PagedResults } from '~interfaces';
import { SearchPagedParameters } from '~interfaces/requests';
import {
	BaseReferenceResponse,
	IdReferenceResponse,
	SkcPagedResponse,
	SkcSingleResponse,
} from '~interfaces/responses';
import { TopologyProxiedSkopeiConnectService } from '~services';

import AccessRule, { AccessRuleNew, AccessRuleUpdate } from '../interfaces/accessRule';

interface $ { ___SIGIL___: true; }

// Set a date at which it is considered "never ending"
const unlimitedDateMark = new Date(8999, 12, 12);

/**
 * A service that does calls to the Skopei Connect API through
 * the Topology backend
 */
class SkcAccessRulesService extends TopologyProxiedSkopeiConnectService {
	public readonly path = 'access-rules';

	/**
	 * Get a paginated list of access rules
	 * @param page The number of the page
	 * @param pageSize The amount of results of the page
	 * @returns
	 */
	async getAccessRules({
		page = 1,
		pageSize = 10,
		...args
	}: SearchPagedParameters): Promise<PagedResults<AccessRule> | null> {
		const { data } = await this._client.get<SkcPagedResponse<SkcAccessRuleResponse>>(this.path, {
			params: {
				'page-number': page,
				'page-size': pageSize,
				organisationId: args.organisationId,
				textQuery: args.searchQuery || undefined,
			},
		});

		return this.mapPagedResponse(data, SkcAccessRulesService.fromResponse);
	}

	/**
	 * Get the details of a single access rule
	 * @param id
	 * @returns
	 */
	async getAccessRuleById(id: string): Promise<AccessRule> {
		const response = await this._client.get<SkcSingleResponse<SkcAccessRuleResponse>>(
			path.join(this.path, id),
		);

		return SkcAccessRulesService.fromResponse(response.data.data);
	}

	/**
	 * Create an access rule
	 * @param data
	 * @returns Reference to the access rule
	 */
	async createAccessRule(data: AccessRuleNew): Promise<IdReference> {
		const content = SkcAccessRulesService.toRequest(data);

		const response = await this._client.post<
			IdReference,
			AxiosResponse<IdReference, SkcAccessRuleRequest>,
			SkcAccessRuleRequest
		>(this.path, content, {
			params: {
				organisationId: data.organisation.id,
			},
		});

		return response.data;
	}

	/**
	 * 
	 * @param id 
	 * @param data 
	 * @returns 
	 */
	async updateAccessRule(id: string, data: AccessRuleUpdate): Promise<null> {
		const content = SkcAccessRulesService.toRequest(data);

		const response = await this._client.put<
			null,
			AxiosResponse<SkcSingleResponse<null>>,
			SkcAccessRuleRequest
		>(path.join(this.path, id), content);

		return response.data.data;
	}

	/**
	 * Delete an access rule
	 * @param id
	 * @returns
	 */
	async deleteAccessRule(id: string): Promise<IdReference> {
		const { data } = await this._client.delete<IdReference>(path.join(this.path, id));

		return data;
	}

	static fromResponse(data: SkcAccessRuleResponse): AccessRule {
		const {
			id,
			name,
			startDate,
			endDate: endDateString,
			schedule,
			userGroup,
			deviceGroup,
			...rest
		} = data;

		const endDate = endDateString ? new Date(endDateString) : null;

		return {
			...rest,
			...SkcAccessRulesService.fromBaseReferenceResponse({
				id: id,
				name: name,
			}),
			period: {
				start: new Date(startDate),
				end: endDate && endDate < unlimitedDateMark ? endDate : null,
			},
			schedule: SkcAccessRulesService.fromBaseReferenceResponse(schedule),
			deviceGroup: SkcAccessRulesService.fromBaseReferenceResponse(deviceGroup),
			userGroup: SkcAccessRulesService.fromBaseReferenceResponse(userGroup),
		};
	}

	static toRequest(data: AccessRuleUpdate): SkcAccessRuleRequest {
		const { label, period, schedule, userGroup, deviceGroup, description } = data;

		return {
			name: label,
			description: description || undefined,
			startDate: period.start.toISOString(),
			endDate: period.end?.toISOString(),
			schedule: schedule.id,
			deviceGroup: deviceGroup.id,
			userGroup: userGroup.id,
		};
	}
}

interface BaseAccessRuleDto {
	name: string;
	startDate: string;
	endDate?: string;
	description?: string;
}

interface SkcAccessRuleRequest extends BaseAccessRuleDto {
	schedule: number | string;
	userGroup: number | string;
	deviceGroup: number | string;
}

interface SkcAccessRuleResponse extends BaseAccessRuleDto, BaseReferenceResponse {
	schedule: BaseReferenceResponse;
	userGroup: BaseReferenceResponse;
	deviceGroup: BaseReferenceResponse;
}

export default SkcAccessRulesService;
