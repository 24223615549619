import { useMemo, useState } from 'react';

import { GridPaginationModel } from '@mui/x-data-grid';
import { useSearchParams } from 'react-router-dom';

import { queryParameters } from '~constants';

/**
 * A hook to use pagination that keeps its state within the url
 * Notice that the first page for the grid = 0, but the first page
 * for the url = 1
 * @param defaultPageSize 
 * @param disableUrlSync A boolean to turn off synchronization with the url 
 * @returns 
 */
const useUrlSearchParamsPagination = (defaultPageSize = 10, disableUrlSync = false): [
	GridPaginationModel,
	(model: GridPaginationModel) => void,
] => {
	const [searchParams, setSearchParams] = useSearchParams();

	const page = Number(searchParams.get(queryParameters.PageNumber));
	const pageSize = Number(searchParams.get(queryParameters.PageSize));
	/**
	 * The pagination model that is synced with the url
	 */
	const urlPaginationModel = useMemo<GridPaginationModel>(
		() => ({
			page: page > 1 ? page - 1 : 0,
			pageSize: pageSize > 0 ? pageSize : defaultPageSize,
		}),
		[page, pageSize],
	);

	/**
	 * A pagination model that just lives on its own
	 */
	const [internalPaginationModel, setInternalPaginationModel] = useState<GridPaginationModel>({
		page: 0,
		pageSize: defaultPageSize
	});

	const setPaginationModel = (model: GridPaginationModel) => {
		if (disableUrlSync) {
			setInternalPaginationModel(model);
			return;
		}

		if (model.page > 0) {
			searchParams.set(queryParameters.PageNumber, (model.page + 1).toString());
		} else {
			searchParams.delete(queryParameters.PageNumber);
		}

		if (model.pageSize > 0) {
			searchParams.set(queryParameters.PageSize, model.pageSize.toString());
		}

		setSearchParams(searchParams, { replace: true });
	};

	return [
		!disableUrlSync ? urlPaginationModel : internalPaginationModel,
		setPaginationModel
	];
};

export default useUrlSearchParamsPagination;
