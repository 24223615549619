import palette from '../palette';
import typography from '../typography';

export default {
	styleOverrides: {
		root: {
			...typography.input,
			'&.Mui-error': {
				color: palette.error.main,
			},
			variants: [
				{
					props: { size: 'small' },
					style: { height: '44px' }
				},
			]
		},
		input: {
			'&::placeholder': {
				opacity: 0.4,
				color: palette.text.primary,
			},
		},
	},
};
