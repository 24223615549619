import { useRef } from 'react';

import useSWR from 'swr';

import UsersService from '../services/usersService';

const service = new UsersService();

const useUserData = (id: string) => {
	const response = useSWR([service.basePath, id], ([_, userId]) => service.getUserById(userId));

	return response;
};

export default useUserData;
