import React, { useEffect, useState } from 'react';

import {
	Check as CheckIcon,
	Edit as EditIcon,
	Error as ErrorIcon,
	PriorityHigh as PriorityHighIcon,
} from '@mui/icons-material';
import {
	Avatar,
	Card,
	CardContent,
	IconButton,
	Link,
	Stack,
	SvgIcon,
	Tooltip,
	Typography,
	useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import useSWR from 'swr';

import { InfoCard, TooltipNew } from '~components';
import { UserRoleEnum } from '~enums';
import { useAuthorize } from '~features/authentication';

import useUserData from '../../hooks/useUserData';
import UserIdentitiesService from '../../services/userIdentitiesService';
import { getInitials } from '../../utils/userUtils';

// TODO: eventually through a theme
const colors = {
	success: {
		bgColor: '#CFF5E7',
		color: 'success',
	},
	warning: {
		// bgColor: '#c98171',
		bgColor: '#ffffff',
		color: 'warning',
	},
};

interface UserGeneralInfoCardProps {
	userId: string;
	onEditClick?: () => void;
}

const userIdentitiesService = new UserIdentitiesService();

const UserGeneralInfoCard = ({ userId, ...props }: UserGeneralInfoCardProps) => {
	const { t } = useTranslation('general');
	const { isSuperAdmin } = useAuthorize();
	const theme = useTheme();
	const [searchParams] = useSearchParams();

	const { data: userData, isLoading: isUserLoading, error: userError } = useUserData(userId);

	const {
		data: userIdentityData,
		isLoading: isUserIdentityLoading,
		error: userIdentityError,
	} = useSWR(
		isSuperAdmin() && [userIdentitiesService.basePath, userId],
		([_, args]) => userIdentitiesService.getUserIdentityById(args),
		{
			shouldRetryOnError: false,
		},
	);

	const isLoading = isUserLoading || isUserIdentityLoading;
	const error = userError || userIdentityError;

	const rows =
		!userData ?
			[]
		:	[
				{
					headerName: t('ui.label.email'),
					value: userData.email,
					renderCell: (value) =>
						!value ? '-' : (
							<Stack
								direction='row'
								spacing={0.5}
								sx={{
									alignItems: 'center',
								}}
							>
								<Link href={`mailto:${value}`}>{value}</Link>
								<TooltipNew
									title={
										userData?.isEmailConfirmed ? t('ui.emailVerified') : t('ui.emailNotVerified')
									}
								>
									<Avatar
										sx={{
											width: 16,
											height: 16,
											bgcolor: colors[userData.isEmailConfirmed ? 'success' : 'warning'].bgColor,
										}}
									>
										<SvgIcon
											component={userData.isEmailConfirmed ? CheckIcon : PriorityHighIcon}
											color={userData.isEmailConfirmed ? 'success' : 'warning'}
											sx={{ fontSize: 12 }}
										/>
									</Avatar>
								</TooltipNew>
							</Stack>
						),
				},
				...(userData.dateOfBirth ?
					[
						{
							headerName: t('ui.label.dateOfBirth'),
							value: userData.dateOfBirth.toLocaleDateString(),
						},
					]
				:	[]),
				{
					headerName: t('ui.label.phoneNumber'),
					value: userData.phoneNumber,
					renderCell: (value) => (value ? <Link href={`tel:${value}`}>{value}</Link> : '-'),
				},
				// {
				// 	headerName: t('ui.label.address'),
				// 	value: userData.address,
				// 	renderCell: (value) => !userData.address ? '-' : (
				// 		<Stack sx={{ fontSize: 'inherit' }}>
				// 			<Typography fontSize='inherit'>{userData.address.toStreetString()}</Typography>
				// 			<Typography fontSize='inherit'>{userData.address.toPostalCodeAndUpString()}</Typography>
				// 		</Stack>
				// 	),
				// },
				{
					headerName: t('ui.label.address'),
					value: userData.address?.toStreetString(),
					// value: `${data.address.street} ${data.address.number}${data.address.addition ? ` ${data.address.addition}` : ''}`,
				},
				{
					headerName: t('ui.label.postalCode'),
					value: userData.address?.postalCode,
				},
				{
					headerName: t('ui.label.city'),
					value: userData.address?.city,
				},
				{
					headerName: t('ui.label.country'),
					value: userData.address?.country,
				},
				...(isSuperAdmin() ?
					[
						{
							headerName: t('views.userDetails.summary.language'),
							value: userData.preferences.preferredCultureInfo,
						},
						{
							headerName: t('loginProvider'),
							value: userIdentityData?.provider ?? 'Topology',
						},
						{
							headerName: t('lastLogin'),
							value: userIdentityData?.LatestLogin?.toLocaleString(),
						},
						{
							headerName: t('lastUsedUserAgent'),
							value: userIdentityData?.LatestUserAgent,
						},
					]
				:	[]),
			];

	return (
		<InfoCard
			title={t('views.userDetails.summary.cardHeaders.userInformation')}
			rows={rows}
			slots={{
				contentTop:
					userData?.firstName != null || userData?.lastName != null ?
						<Card sx={{ m: 1.5, '& .MuiCardContent-root:last-child': { pb: 1.5 } }}>
							<CardContent>
								<Stack
									direction='row'
									spacing={1}
									sx={{
										alignItems: 'center',
									}}
								>
									<Avatar src={userData.imageUrl}>{getInitials(userData)}</Avatar>
									<Typography>
										{`${userData.firstName ?? ''} ${userData.lastName ?? ''}`}
									</Typography>
								</Stack>
							</CardContent>
						</Card>
					:	undefined,
			}}
			actions={[
				{
					label: t('ui.edit'),
					icon: <EditIcon />,
					onClick: props.onEditClick,
				},
			]}
			loading={isUserLoading}
			error={userError != null}
		/>
	);
};

export default UserGeneralInfoCard;
