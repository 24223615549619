import { useState, useEffect } from 'react';

import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import StopIcon from '@mui/icons-material/Stop';
import { Typography, Hidden, Box, CircularProgress } from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAuthorize } from '~features/authentication';
import { DeviceCommandButtonGroup } from '~features/devices';

import AttentionDialog from './AttentionDialog';
import RefundDialog from './RefundDialog';
import { useStyles } from './style';
import { AdjustedSkeleton, AlertDialog, StyledButton } from '../../../../components';
import { useDetailPageBackButton, useError, useWizardFormField } from '../../../../shared/hooks';
import { isObject, isFullString } from '../../../../shared/utility';
import * as actions from '../../../../store/actions';
import { StopBookingDialog } from '../../../Actions';
import SideDrawer from '../../../Planboard/SideDrawer';

const Header = (props) => {
	const {
		className,
		bookingData,
		loading,
		canceledBooking,
		onCancelBooking,
		confirmBooking,
		rejectBooking,
		onConfirmBooking,
		onRejectBooking,
		currentUser,
		refundBooking,
		onUpdateDetailsState,
		refundPrice,
		onResetState,
	} = props;

	const { t } = useTranslation('general');
	const navigate = useNavigate();
	const location = useLocation();
	const { isSuperAdmin } = useAuthorize();

	const [openDialog, setOpenDialog] = useState(false);
	const [openRefundDialog, setOpenRefundDialog] = useState(false);

	const [openStopBooking, setOpenStopBooking] = useState(false);
	const kilometres = useWizardFormField('', {
		required: true,
		minLength: 1,
		maxLength: 10,
		isNumeric: true,
	});

	const { data: currentUserData } = currentUser;
	const presentUser = isObject(currentUserData) && currentUserData.organisationReference.name;
	const isProvider =
		isFullString(presentUser) &&
		presentUser === bookingData?.hubReference.organisationReference.nameconst;
	const isRejected =
		isObject(bookingData) &&
		bookingData.approvalState === 'pending' &&
		bookingData.status !== 'cancelled';

	const { data: refundPriceData } = refundPrice;
	const classes = useStyles();

	const [openAddOrEditBooking, setOpenAddOrEditBooking] = useState(false);
	const [presetBookingData, setPresetBookingData] = useState(null);

	const [openAttentionDialog, setOpenAttentionDialog] = useState(false);

	const itemReference = isObject(bookingData) && bookingData.itemReference;

	const returnButton = useDetailPageBackButton(location);

	const isVisibleLockUnlock =
		['bmwCar', 'vehicleTrackerCan', 'cpacBoat'].includes(bookingData?.itemInstance?.deviceType) &&
		bookingData.status !== 'completed' &&
		(isProvider || isSuperAdmin());

	const [approveRequestLoading, setApproveRequestLoading] = useState(false);
	const [rejectRequestLoading, setRejectRequestLoading] = useState(false);

	const handleLabel = () => {
		if (returnButton.isBackButton) {
			return t('ui.back');
		} else if (isObject(location) && isFullString(location?.state?.start)) {
			return t('ui.finance');
		} else if (location?.state?.status || location?.state?.label) {
			return location?.state.label;
		} else {
			return t('views.bookings.page.title');
		}
	};

	const handleBackButtonChange = () => {
		if (location?.state?.backPathname) {
			navigate(location.state.backPathname);
		} else if (location?.state?.status) {
			navigate(location.state.from, { state: location?.state });
		} else {
			navigate('/bookings');
			// handleClickBackButton(
			// 	'state' in location ? location : '/bookings',
			// 	'state' in location ? '/bookings' : undefined,
			// );
		}
	};

	const {
		success: canceledSuccess,
		loading: canceledLoading,
		error: canceledError,
	} = canceledBooking;
	const canceledDone = canceledSuccess && !canceledLoading && !canceledError;
	const { success: refundSuccess, loading: refundLoading } = refundBooking;

	const refundProps = {
		openRefundDialog,
		setOpenRefundDialog,
		priceTotal: bookingData?.price?.total?.total,
		price: bookingData?.price,
		bookingId: bookingData?.id,
	};

	useEffect(() => {
		if (refundSuccess) {
			setOpenRefundDialog(false);
			if (isObject(refundPriceData)) {
				onUpdateDetailsState('fetchBooking', {
					...bookingData,
					price: {
						...bookingData?.price,
						price: refundPriceData?.totalPriceIncludingVat,
						total: { ...bookingData?.price.total, total: refundPriceData?.totalPriceIncludingVat },
					},
				});
			}
			onResetState('refundPrice');
		}
	}, [refundLoading]);

	const cancelMessage = useError({
		value: canceledBooking,
		message: `${t('ui.successfully')} ${t('ui.canceled')}  ${t('ui.booking')} `,
	});

	const confirmBookingMessage = useError({
		value: confirmBooking,
		message: t('views.bookings.successfullyConfirmedBooking'),
	});

	const rejectBookingMessage = useError({
		value: rejectBooking,
		message: t('views.bookings.successfullyRejectedBooking'),
	});

	const handleCancelBooking = () => {
		setOpenDialog(true);
	};

	const handleCancelDialog = () => {
		setOpenDialog(false);
	};

	const handleConfirmDialogDisable = () => {
		onCancelBooking(bookingData.id, true);
		cancelMessage.setStartAction(true);
	};

	const handleEdit = () => {
		setPresetBookingData({
			bookingId: Number(bookingData.id),
			itemId: Number(itemReference.id),
			itemSelected: true,
		});
		setOpenAddOrEditBooking(true);
	};

	const handleEditClose = () => {
		setOpenAddOrEditBooking(false);
	};

	const handleStopBooking = () => {
		setOpenStopBooking(true);
		kilometres.resetToInitialValue();
	};

	const handleOpenRefundBooking = () => {
		setOpenRefundDialog(true);
	};

	const carBooking =
		isObject(bookingData) &&
		isFullString(bookingData.usageState) &&
		bookingData.usageState === 'started';

	const handleApproveBookingRequest = async () => {
		setApproveRequestLoading(true);
		await onConfirmBooking(bookingData.id, true);
		confirmBookingMessage.setStartAction(true);
		setApproveRequestLoading(false);
	};

	const handleRejectBookingRequest = async () => {
		setRejectRequestLoading(true);
		await onRejectBooking(bookingData.id, true);
		rejectBookingMessage.setStartAction(true);
		setRejectRequestLoading(false);
	};

	const attentionDialogProps = {
		openAttentionDialog,
		setOpenAttentionDialog,
		data: bookingData,
	};

	const readyToStart =
		bookingData?.isCancellable &&
		bookingData.status === 'active' &&
		bookingData.usageState === 'ready';

	const attention =
		(bookingData?.attentionStates?.includes('startMileage') ||
			bookingData?.attentionStates?.includes('stopMileage')) &&
		isSuperAdmin();

	const buttons = [
		{
			name: 'refund',
			icon: <ArrowCircleDownIcon />,
			label: t('views.bookings.bookingDetails.button.inline.refund'),
			visible:
				isSuperAdmin() &&
				isObject(bookingData) &&
				bookingData.status === 'completed' &&
				!bookingData.price.isFree &&
				bookingData.attentionStates.includes('none'),
			className: classes.buttons,
			onClick: handleOpenRefundBooking,
			variant: 'inline-default',
		},
		{
			name: 'stop',
			icon: <StopIcon />,
			label: t('view.bookings.bookingDetails.button.inline.stop'),
			visible:
				isObject(bookingData) &&
				!bookingData.isCancellable &&
				(bookingData.status === 'active' || bookingData.status === 'overtime'),
			className: classes.buttonsCancel,
			onClick: handleStopBooking,
			variant: 'inline-delete',
		},
		{
			name: 'cancel',
			icon: <CancelIcon />,
			label: t('view.bookings.bookingDetails.button.inline.cancel'),
			visible:
				isObject(bookingData) &&
				((bookingData.isCancellable && !carBooking && bookingData.status !== 'active') ||
					readyToStart),
			className: classes.buttonsCancel,
			onClick: handleCancelBooking,
			variant: 'inline-delete',
		},
		{
			name: 'edit',
			icon: <EditIcon />,
			label: t('view.bookings.bookingDetails.button.inline.edit'),
			visible:
				isObject(bookingData) &&
				((bookingData.status !== 'completed' && bookingData.status !== 'cancelled') ||
					(bookingData.status === 'completed' && attention) ||
					(bookingData.approvalState !== 'declined' &&
						bookingData.approvalState !== 'notApplicable')),
			className: classes.buttons,
			variant: 'inline-default',
			onClick: handleEdit,
		},
		...((
			isRejected &&
			isFullString(presentUser) &&
			(presentUser === bookingData.hubReference.organisationReference.name ||
				isSuperAdmin())
		) ?
			[
				{
					name: 'approve',
					icon:
						!approveRequestLoading ? <CheckIcon /> : <CircularProgress color='inherit' size={18} />,
					disabled: approveRequestLoading,
					label: t('ui.approveRequest'),
					visible: isObject(bookingData) && bookingData.status !== 'completed',
					className: classes.buttons,
					variant: 'inline-default',
					onClick: handleApproveBookingRequest,
				},
				{
					name: 'reject',
					icon:
						!rejectRequestLoading ? <CloseIcon /> : <CircularProgress color='inherit' size={18} />,
					disabled: rejectRequestLoading,
					label: t('ui.rejectRequest'),
					visible: isObject(bookingData) && bookingData.status !== 'completed',
					className: classes.buttonsCancel,
					variant: 'inline-default',
					onClick: handleRejectBookingRequest,
				},
			]
		:	[]),
		{
			name: 'approve booking',
			icon: <CheckIcon />,
			label: t('views.bookings.bookingDetails.button.attention'),
			visible:
				isSuperAdmin() &&
				!bookingData?.attentionStates.includes('none') &&
				bookingData?.status === 'completed',
			className: classes.attention,
			variant: 'inline-default',
			onClick: () => setOpenAttentionDialog(true),
		},
	];

	return (
		<div className={clsx(classes.root, className)}>
			<Typography component='h2' gutterBottom variant='overline'>
				<Hidden only={['xs', 'sm']}>
					<StyledButton
						className={classes.backButton}
						onClick={handleBackButtonChange}
						startIcon={<KeyboardArrowLeftOutlinedIcon />}
						variant='inline-default'
					>
						{handleLabel()}
					</StyledButton>
				</Hidden>
			</Typography>
			<Typography component='h1' variant='h3'>
				<Box display='flex' justifyContent='space-between'>
					{loading ?
						<AdjustedSkeleton animation='wave' width={200} />
					:	`${t('ui.label.booking')} ${bookingData.id}`}
					{loading ?
						<AdjustedSkeleton animation='wave' width={360} />
					:	<Box
							display='flex'
							flexDirection={
								(
									isSuperAdmin() &&
									!bookingData?.attentionStates.includes('none')
								) ?
									'row-reverse'
								:	'row'
							}
						>
							{buttons.map((button) =>
								button.visible ?
									<StyledButton
										className={button.className}
										key={button.name}
										onClick={button.onClick}
										size='small'
										startIcon={button.icon}
										variant={button.variant}
										disabled={button.disabled}
									>
										{button.label}
									</StyledButton>
								:	null,
							)}
							{isVisibleLockUnlock ?
								<Box pl={1}>
									<DeviceCommandButtonGroup
										device={{ id: bookingData.itemInstance.deviceId }}
										type={bookingData.itemInstance.deviceType}
									/>
								</Box>
							:	null}
						</Box>
					}
				</Box>
			</Typography>
			{openDialog ?
				<AlertDialog
					dialogDescription={t('views.planboard.editBooking.cancelAlert.description')}
					dialogTitle={t('views.planboard.editBooking.cancelAlert.title')}
					handleClose={handleCancelDialog}
					handleConfirm={handleConfirmDialogDisable}
					loading={canceledLoading && !canceledDone}
					open={openDialog}
				/>
			:	null}
			<SideDrawer
				onClose={handleEditClose}
				open={openAddOrEditBooking}
				presetData={presetBookingData}
				setPresetBookingData={setPresetBookingData}
			/>
			{openStopBooking ?
				<StopBookingDialog
					data={bookingData}
					end={bookingData.periodEnd}
					itemInstance={bookingData.itemInstance}
					kilometres={kilometres}
					open={openStopBooking}
					setOpenStopBooking={setOpenStopBooking}
					start={bookingData.periodStart}
				/>
			:	null}
			{openRefundDialog ?
				<RefundDialog {...refundProps} />
			:	null}
			{openAttentionDialog ?
				<AttentionDialog {...attentionDialogProps} />
			:	null}
		</div>
	);
};

Header.propTypes = {
	className: PropTypes.string,
	bookingData: PropTypes.object,
	loading: PropTypes.bool,
	canceledBooking: PropTypes.shape({
		success: PropTypes.bool,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	confirmBooking: PropTypes.shape({
		success: PropTypes.bool,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	rejectBooking: PropTypes.shape({
		success: PropTypes.bool,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	refundBooking: PropTypes.shape({
		success: PropTypes.bool,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	currentUser: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	refundPrice: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	onCancelBooking: PropTypes.func,
	userData: PropTypes.object,
	onConfirmBooking: PropTypes.func,
	onRejectBooking: PropTypes.func,
	onUpdateDetailsState: PropTypes.func,
	onResetState: PropTypes.func,
};

const mapStateToProps = (state) => {
	return {
		canceledBooking: state.condition.canceledBooking,
		confirmBooking: state.condition.confirmBooking,
		rejectBooking: state.condition.rejectBooking,
		refundBooking: state.condition.refundBooking,
		currentUser: state.details.currentUser,
		refundPrice: state.details.refundPrice,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onCancelBooking: (id, redirect) => dispatch(actions.cancelBooking(id, redirect)),
		onConfirmBooking: (bookingId, fetch) => dispatch(actions.confirmBooking(bookingId, fetch)),
		onRejectBooking: (bookingId, fetch) => dispatch(actions.rejectBooking(bookingId, fetch)),
		onUpdateDetailsState: (state, data) => dispatch(actions.updateDetailsState(state, data)),
		onResetState: (identifier) => dispatch(actions.resetState(identifier)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
