import React, { useRef, useState } from 'react';

import { LoadingButton } from '@mui/lab';
import {
	Button,
	DialogActions,
	DialogContent,
	DialogProps,
	DialogTitle,
	Divider,
	Dialog as MuiDialog,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { FormWrapperRefProps } from '~interfaces/refProps';
import i18n from '~lib/i18n';

import FormContainerContext from './formContainerProvider';

interface FormDialogProps extends Omit<DialogProps, 'children'> {
	children: JSX.Element;
	loading?: boolean;
	variant?: 'new' | 'legacy';
	saveLabel?: string;
}

// This component has been through multiple iterations. The core issue, the child component
// component has the form data, but this dialog has the submit button. And we need close
// interaction between these components
// Moving data back and forth through context, atoms or props causes unnecessary renders and
// updates. The current setup, where the form holds a reference to the child form seems to
// most effecient. This way the child on needs to expose and onSubmit function to trigger the
// onSubmit function in the child. This way we can also provide a return type within the child.
/**
 * A dialog to contain a form. Needs a single component which holds a ref
 */
const FormDialog = ({
	children,
	title,
	loading = false,
	variant = 'new',
	saveLabel = i18n.t('ui.save'),
	...dialogProps
}: FormDialogProps) => {
	const { t } = useTranslation('general');
	const theme = useTheme();
	const largerThanMd = useMediaQuery(theme.breakpoints.up('md'));

	const [disabled, setDisabled] = useState(true);

	// Intercept the children, attach a ref, and use it to
	// fire the onSubmit
	const childRef = useRef<FormWrapperRefProps>(null);

	const handleClose = () => {
		dialogProps.onClose?.({}, 'escapeKeyDown');
	};

	const handleSubmit = () => {
		// Trigger the child forms onSumbit if it is provided
		if (childRef.current?.onSubmit) {
			childRef.current.onSubmit();
		} else {
			console.warn('No onSubmit handler, did you forget it?')
		}
	};

	return (
		<MuiDialog fullScreen={!largerThanMd} {...dialogProps}>
			<DialogTitle variant='h4'>{title}</DialogTitle>
			<DialogContent>
				<FormContainerContext.Provider
					value={{
						disabled: disabled,
						setDisabled: setDisabled,
					}}
				>
					{React.cloneElement(children, { ref: childRef })}
				</FormContainerContext.Provider>
			</DialogContent>
			{variant === 'legacy' && <Divider sx={{ mx: 3}} />}
			<DialogActions
				sx={variant === 'legacy' ? {
					justifyContent: 'space-evenly',
					px: 4,
					py: 2
				} : {}}
			>
				<Button
					onClick={handleClose}
					{...(variant === 'legacy' && {
						fullWidth: true,
						variant: 'text',
						size: 'large'
					})}
				>
					{t('ui.close')}
				</Button>
				<LoadingButton
					variant='contained'
					onClick={handleSubmit}
					disabled={disabled}
					loading={loading}
					{...(variant === 'legacy' && {
						fullWidth: true,
						size: 'large'
					})}
				>
					{saveLabel}
				</LoadingButton>
			</DialogActions>
		</MuiDialog>
	);
};

export default FormDialog;
