import { Chip, ChipOwnProps, ChipProps } from '@mui/material';
import { useTranslation } from 'react-i18next';

import DefectSeverityEnum from '../../enum/defectSeverityEnum';

interface TicketSeverityChipProps extends ChipProps {
	status: DefectSeverityEnum;
}

const DefectSeverityChip = ({
	status,
	...chipProps
}: TicketSeverityChipProps) => {
	const { t } = useTranslation('general');

	const getStatusLabel = (): string => {
		let translationKey: string;

		switch (status) {
			case DefectSeverityEnum.Critical:
				translationKey = 'Critical';
				break;
			case DefectSeverityEnum.NonCritical:
				translationKey = 'NonCritical';
				break;
			case DefectSeverityEnum.Missing:
				translationKey = 'Missing';
				break;
			default:
				translationKey = 'unknown';
				break;
		}

		return t(`views.tableResults.serviceTickets.type${translationKey}`);
	};

	const getStatusColor = (): ChipOwnProps['color'] => {
		let color: ChipOwnProps['color'];

		switch (status) {
			case DefectSeverityEnum.Critical:
				color = 'error';
				break;
			case DefectSeverityEnum.NonCritical:
				color = 'warning';
				break;
			default:
				color = 'info';
				break;
		}

		return color;
	};

	return (
		<Chip
			{...chipProps}
			label={getStatusLabel()}
			color={getStatusColor()}
		/>
	);
};

export default DefectSeverityChip;
