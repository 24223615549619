import { Typography, Box } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { BillingsService } from '~features/finances';

import { useStyles } from './style';
import { Table } from '../../../../../../components';
import { localizeDateTime } from '../../../../../../shared/datetime';
import { isObject, decimalAmount, getFinanceTableHeaders } from '../../../../../../shared/utility';

const localizeTime = (value) =>
	localizeDateTime(value, undefined, { day: '2-digit', month: '2-digit', year: 'numeric' });

const service = new BillingsService();

const BookingsFinance = (props) => {
	const classes = useStyles();
	const { data, loading } = props;
	const { t } = useTranslation('general');
	const finance = [data];

	const tableHeader = getFinanceTableHeaders(false, true, true);

	const tableBody =
		isObject(data) ?
			finance.map((item) => [
				{
					content: (
						<Box width='250px'>
							<Typography className={classes.link}>
								{`${localizeTime(item.start)} ${t('ui.till')} ${localizeTime(item.end)}`}
							</Typography>
						</Box>
					),
				},
				{ content: decimalAmount(item.amount.subtotal, item.amount) },
				{ content: decimalAmount(item.amount.discount, item.amount) },
				{ content: decimalAmount(item.amount.subtotal - item.amount.discount, item.amount) },
				{ content: decimalAmount(item.amount.priceVat, item.amount) },
				{ content: decimalAmount(item.amount.refund, item.amount) },
				{ content: decimalAmount(item.amount.totalPrice, item.amount) },
			])
		:	Array(1)
				.fill(Array(tableHeader.length).fill())
				.map((arr) => arr.map(() => ({ loading: false })));

	const titleWithInformation = {
		header: t('ui.label.personalBilling'),
	};

	return (
		<Table
			body={tableBody}
			cellStyle={classes.cellStyle}
			header={tableHeader}
			isNotPaginate={true}
			loading={loading}
			titleWithInformation={titleWithInformation}
			withoutPages={true}
		/>
	);
};

BookingsFinance.propTypes = {
	id: PropTypes.string,
	date: PropTypes.string,
	onFetchBusinessRevenue: PropTypes.func,
	data: PropTypes.object,
	loading: PropTypes.bool,
};

export default BookingsFinance;
