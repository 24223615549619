import path from 'path';

import { GroupAdd as GroupAddIcon, MoreVert as MoreVertIcon } from '@mui/icons-material';
import { Avatar, Button, IconButton, Link as MuiLink, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link as ReactRouterLink, useLocation } from 'react-router-dom';

import { InfoCard } from '~components';
import { pagePathSegments } from '~constants';

import useUserData from '../../hooks/useUserData';

interface UserOrganisationInfoCardProps {
	userId: string;
}

/**
 * TODO: unfinished
 * @returns
 */
const UserOrganisationInfoCard = ({ userId, ...props }: UserOrganisationInfoCardProps) => {
	const { t } = useTranslation('general');
	const location = useLocation();

	const { data, isLoading, error } = useUserData(userId);

	const rows = data != null && [
		{
			headerName: t('ui.label.userGroup'),
			value: data.userGroup.label,
			renderCell: () => (
				<MuiLink
					component={ReactRouterLink}
					to={`/${path.join(pagePathSegments.UserManagement, pagePathSegments.UserGroups, data.userGroup.id, pagePathSegments.Summary)}`}
					state={{ from: location.pathname }}
				>
					{data.userGroup.label}
				</MuiLink>
			),
		},
	];

	return (
		<InfoCard
			title={t('organisation')}
			rows={rows ?? []}
			loading={isLoading}
			slots={{
				contentTop: (
					<Stack
						direction='row'
						spacing={1}
						sx={{
							alignItems: 'center',
						}}
					>
						<Avatar src={data?.organisation.logo} alt={data?.organisation.label} />
						<Typography>{data?.organisation.label}</Typography>
					</Stack>
				),
			}}
			actions={[
				{
					label: t('ui.button.inline.assigntousergroup'),
					icon: <GroupAddIcon />,
					component: ReactRouterLink,
					to: `/${path.join(pagePathSegments.UserManagement, pagePathSegments.Users, pagePathSegments.Assign, pagePathSegments.UserGroup)}`,
					state: [data],
				},
			]}
			error={error != null}
		/>
	);
};

export default UserOrganisationInfoCard;
