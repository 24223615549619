import {
	legendClasses,
	LineChart as MuiLineChart,
	LineChartProps
} from '@mui/x-charts';

import { colorsSlim } from '~themes/chartColors';

/**
 * A wrapper component for the mui pie chart
 */
const LineChart = ({
	colors = colorsSlim,
	...chartProps
}: LineChartProps) => {
	return (
		<MuiLineChart
			{...chartProps}
			colors={colors}
			slotProps={{
				legend: {
					position: {
						horizontal: 'left',
						vertical: 'top'
					},
					itemMarkWidth: 8,
					itemMarkHeight: 8,
					...chartProps.slotProps?.legend
				},
				...chartProps.slotProps
			}}
			grid={{ horizontal: true }}
			bottomAxis={{
				disableLine: true,
				...chartProps.bottomAxis,
			}}
			leftAxis={{
				disableLine: true,
				disableTicks: true,
				...chartProps.leftAxis,
			}}
			margin={{
				top: 80,
				left: 50,
				bottom: 30,
				right: 20,
				...chartProps.margin
			}}
			sx={{
				...chartProps.sx,
				[`& .${legendClasses.mark}`]: {
					ry: 10,
				},
			}}
		/>
	);
};

export default LineChart;
