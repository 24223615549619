import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { SkcDeviceGroupDetailsLayout } from '~features/access';

import PageNotFoundPage from '../feedbacks/pageNotFoundPage';
import Page from '../page';

const SkcDeviceGroupDetailsPage = () => {
	const { t } = useTranslation('general');
	const { id } = useParams();

	if (!id) {
		return <PageNotFoundPage />;
	}

	return (
		<Page title={`${t('deviceGroupDetails')} ${id}`}>
			<SkcDeviceGroupDetailsLayout id={id} />
		</Page>
	);
};

export default SkcDeviceGroupDetailsPage;
