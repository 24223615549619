import { Box, Stack } from '@mui/material';
import { Outlet } from 'react-router-dom';

import PageHeader, { Page } from './headers/pageHeader';

interface PageLayoutProps {
	title?: string;
	pages?: Page[];
	tempEl?: JSX.Element;
}

const PageLayout = ({ title, pages, tempEl }: PageLayoutProps) => {
	return (
		<Stack direction='column' width={1} height={1} spacing={3} px={{ xs: 1, md: 3 }} py={2}>
			<PageHeader title={title} pages={pages} />
			<Box flexGrow={1}>
				<Outlet />
			</Box>
		</Stack>
	);
};

export default PageLayout;
