import { useState } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
	Card,
	CardHeader,
	CardContent,
	Divider,
	Table,
	TableBody,
	TableRow,
	TableCell,
	CardActions,
} from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useAuthorize } from '~features/authentication';

import { StyledButton } from '../../../../../../components';
import {
	isObject,
	isFullArray,
	isArray,
	isString,
	isBoolean,
} from '../../../../../../shared/utility';
import { useStyles } from '../style';

const Characteristics = (props) => {
	const { className, itemData, isOwnItem } = props;
	const { t } = useTranslation('general');
	const navigate = useNavigate();
	const { isSuperAdmin } = useAuthorize();

	const classes = useStyles();
	const [viewAll, setViewAll] = useState(false);

	const handleChange = () => {
		setViewAll(!viewAll);
	};

	return (
		<Card className={clsx(classes.card, className)}>
			<CardHeader title={t('ui.characteristics.title')} />
			<Divider />
			<CardContent className={classes.content}>
				<Table>
					<TableBody>
						{isObject(itemData) && isFullArray(itemData.characteristics) && !viewAll ?
							itemData.characteristics.map((item, index) =>
								index <= 7 ?
									<TableRow key={index}>
										<TableCell>{t(`ui.characteristics.${item.name}`)}</TableCell>
										<TableCell>
											{isString(item.value) ?
												t(`ui.characteristics.${item.value}`)
											: !isBoolean(item.value) ?
												item.value
											: item.value ?
												t('ui.yes')
											:	t('ui.no')}
										</TableCell>
									</TableRow>
								:	null,
							)
						: isObject(itemData) && isFullArray(itemData.characteristics) && viewAll ?
							itemData.characteristics.map((item, index) => (
								<TableRow key={index}>
									<TableCell>{t(`ui.characteristics.${item.name}`)}</TableCell>
									<TableCell>
										{isString(item.value) ?
											t(`ui.characteristics.${item.value}`)
										: !isBoolean(item.value) ?
											item.value
										: item.value ?
											t('ui.yes')
										:	t('ui.no')}
									</TableCell>
								</TableRow>
							))
						:	null}
					</TableBody>
				</Table>
			</CardContent>
			<CardActions className={classes.actions}>
				{isOwnItem || isSuperAdmin() ?
					<StyledButton
						onClick={() => navigate(`/item-management/item/${itemData.id}/characteristics`)}
						startIcon={<EditIcon />}
						variant='inline-default'
					>
						{t('ui.button.inline.edit')}
					</StyledButton>
				:	null}
				{isArray(itemData.characteristics) && itemData.characteristics.length > 8 ?
					<StyledButton
						className={classes.pushRight}
						onClick={handleChange}
						startIcon={viewAll ? <VisibilityOffIcon /> : <VisibilityIcon />}
						variant='contained-tertiary'
					>
						{viewAll ? t('ui.button.contained.viewless') : t('ui.button.contained.viewall')}
					</StyledButton>
				:	null}
			</CardActions>
		</Card>
	);
};

Characteristics.propTypes = {
	className: PropTypes.string,

	itemData: PropTypes.object,

	isOwnItem: PropTypes.bool,
};

export default Characteristics;
