import { useEffect } from 'react';

import path from 'path';

import { Link as MuiLink } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link as ReactRouterLink, useLocation } from 'react-router-dom';
import useSWR from 'swr';

import { InfoCard, InfoCardRowProps } from '~components';
import { pagePathSegments } from '~constants';
import { useAuthorize } from '~features/authentication';

import SkcUserGroupsService from '../../services/skcUserGroupsService';

interface AccessRuleGeneralInfoCardProps {
	id: string;
}

const service = new SkcUserGroupsService();

const AccessUserGroupGeneralInfoCard = ({ id }: AccessRuleGeneralInfoCardProps) => {
	const { t } = useTranslation('general');
	const { isSuperAdmin } = useAuthorize();
	const location = useLocation();

	const { data, isLoading, error } = useSWR([service.basePath, id], ([_, args]) =>
		service.getUserGroupById(args),
	);

	const rows: InfoCardRowProps[] | undefined = data && [
		{
			headerName: 'ID',
			value: data.id,
		},
		{
			headerName: t('ui.label.description'),
			value: data.description,
		},
	];

	return (
		<InfoCard
			title={`${t('userGroup')} ${t('info').toLowerCase()}`}
			rows={rows ?? []}
			loading={isLoading}
			error={error != null}
		/>
	);
};

export default AccessUserGroupGeneralInfoCard;
