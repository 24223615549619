import { colors } from '@mui/material';

import palette from '../palette';

export default {
	styleOverrides: {
		styleOverrides: {
			root: {
				'& .Mui-selected': {
					backgroundColor: 'orange',
				},
				'.MuiToggleButtonGroup-grouped': {
					backgroundColor: 'white',
					'&.Mui-selected': {
						backgroundColor: 'yellow',
					},
				},
			},
		}
	},
};
