import path from 'path';

import { Link as MuiLink } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link as ReactRouterLink, useLocation } from 'react-router-dom';
import useSWR from 'swr';

import { InfoCard, InfoCardRowProps } from '~components';
import { pagePathSegments } from '~constants';
import { useAuthorize } from '~features/authentication';

import SkcAccessRulesService from '../../services/skcAccessRulesService';

interface RuleGeneralInfoCardProps {
	id: string;
}

const service = new SkcAccessRulesService();

const AccessRuleGeneralInfoCard = ({ id }: RuleGeneralInfoCardProps) => {
	const { t } = useTranslation('general');
	const { isSuperAdmin } = useAuthorize();
	const location = useLocation();

	const { data, isLoading, error } = useSWR([service.basePath, id], ([_, args]) =>
		service.getAccessRuleById(args),
	);

	const rows: InfoCardRowProps[] | undefined = data && [
		{
			headerName: 'ID',
			value: data.id,
		},
		{
			headerName: t('ui.label.description'),
			value: data.description,
		},
		{
			headerName: t('userGroup'),
			value: data.userGroup,
			renderCell: (value) => (
				<MuiLink
					component={ReactRouterLink}
					to={`/${path.join(pagePathSegments.Access, pagePathSegments.UserGroups, value.id)}`}
					state={{ from: location.pathname }}
				>
					{value.label}
				</MuiLink>
			),
		},
		{
			headerName: t('deviceGroup'),
			value: data.deviceGroup,
			renderCell: (value) => (
				<MuiLink
					component={ReactRouterLink}
					to={`/${path.join(pagePathSegments.Access, pagePathSegments.DeviceGroups, value.id)}`}
					state={{ from: location.pathname }}
				>
					{value.label}
				</MuiLink>
			),
		},
		{
			headerName: t('schedule'),
			value: data.schedule,
			renderCell: (value) => (
				<MuiLink
					component={ReactRouterLink}
					to={`/${path.join(pagePathSegments.Access, pagePathSegments.Schedules, value.id)}`}
					state={{ from: location.pathname }}
				>
					{value.label}
				</MuiLink>
			),
		},
		{
			headerName: t('ui.label.startDate'),
			value: data.period.start,
			valueFormatter: (value: Date) => value.toLocaleDateString(),
		},
		{
			headerName: t('ui.label.endDate'),
			value: data.period.end,
			valueFormatter: (value: Date | null | undefined) => value?.toLocaleDateString() ?? '-',
		},
	];

	return (
		<InfoCard
			title={`${t('rule')} ${t('info').toLowerCase()}`}
			rows={rows ?? []}
			loading={isLoading}
			error={error != null}
		/>
	);
};

export default AccessRuleGeneralInfoCard;
