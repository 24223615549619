import { useEffect } from 'react';

import RefreshIcon from '@mui/icons-material/Refresh';
import { Paper, TextField, MenuItem } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { useAuthorize } from '~features/authentication';

import Search from './Search';
import { Box, SelectWithLazyLoading, StyledButton } from '../../../components';
import Switch from '../../../components/elements/SearchBar/Switch';
import {
	isFullArray,
	isInteger,
	isEmptyString,
	handleLockType,
	isObject,
} from '../../../shared/utility';
import * as actions from '../../../store/actions';
import { useMainContext } from '../FleetManagement';
import { useStyles } from '../style';

const FilterBar = (props) => {
	const {
		onFetchCategories,
		categoriesList,

		onFetchOrganisations,
		organisationsList,
		devicesTypesFilter,
		onFetchDevicesTypes,
		onFetchFleetsInstancesListSearch,
		fetchFleetsInstancesListSearch,
		clearFilters,
		onResetListState,
	} = props;
	const { t } = useTranslation('general');
	const { isSuperAdmin } = useAuthorize();

	const classes = useStyles();

	const {
		data: categoriesData,
		loading: categoriesLoading,
		error: categoriesError,
	} = categoriesList;
	const categoriesReady = isFullArray(categoriesData) && !categoriesLoading && !categoriesError;

	const {
		data: typesFilterData,
		loading: typesFilterLoading,
		error: typesFilterError,
	} = devicesTypesFilter;
	const typesFilterReady = isFullArray(typesFilterData) && !typesFilterLoading && !typesFilterError;

	const {
		categories,
		setCategoiries,
		selectedOrganisation,
		setSelectedOrganisation,
		organisationFilter,
		setOrganisationFilter,
		hideInstanceless,
		setHideInstanceless,
		deviceType,
		setDeviceType,
		resetOptions,
		filters,
		setSelectedIndex,
		setSelectedFleet,
		fleetsDataList,
		onOpenDrawer,
	} = useMainContext();

	useEffect(() => {
		if (!categoriesLoading && !isFullArray(categoriesData)) {
			onFetchCategories();
		}
	}, [categoriesData]);

	useEffect(() => {
		if (!isFullArray(typesFilterData) && !typesFilterLoading) {
			onFetchDevicesTypes(isSuperAdmin() ? null : 'assign');
		}
	}, [typesFilterData]);

	const categoryOptions = [
		{
			value: 'all',
			label: t('views.planboard.filterbar.filters.items.all'),
		},
		{
			value: 'devices',
			label: t('nav.category.devices'),
		},
	].concat(
		categoriesReady ?
			categoriesData.map((category) => ({
				value: category.type,
				label: category.name,
			}))
		:	[],
	);

	const deviceFilter = [
		{
			value: 'all',
			label: t('ui.label.allDevices'),
		},
	].concat(
		typesFilterReady ?
			typesFilterData.map((types) => ({
				value: types,
				label: handleLockType(types),
			}))
		:	[],
	);

	const handleCategory = (event) => {
		setCategoiries(event.target.value);
	};

	const handleDevice = (event) => {
		setDeviceType(event.target.value);
	};

	const onChangeOrganisation = (item) => {
		if (item.id === organisationFilter) {
			return;
		}
		if (isInteger(item.id)) {
			setSelectedOrganisation(item.name);
		} else if (isEmptyString(item.id)) {
			setSelectedOrganisation('');
		}
		setOrganisationFilter(item.id);
	};

	const extraSwitch = [
		{
			switchLabel: t('views.mapWiew.filter.showDevices'),
			actions: (event) => {
				setHideInstanceless(event.target.checked);
				sessionStorage.setItem('hideInstanceles', !event.target.checked);
			},
			checked: hideInstanceless,
		},
	];

	const handleChange = (values) => {
		let index = null;
		if (values) {
			resetOptions({ lat: values.latitude, lng: values.longitude }, 16);
			onOpenDrawer();
		}

		if (values?.type === 'devices') {
			index =
				!isInteger(index) &&
				isObject(values) &&
				fleetsDataList.reduce(
					(prev, current, valIndex) =>
						(
							current.latitude === values.latitude &&
							current.longitude === values.longitude &&
							current.deviceId === values.deviceId
						) ?
							valIndex
						:	prev,
					null,
				);
		} else {
			index =
				!isInteger(index) &&
				isObject(values) &&
				fleetsDataList.reduce(
					(prev, current, valIndex) =>
						(
							current.latitude === values.latitude &&
							current.longitude === values.longitude &&
							current.instanceId === values.instanceId
						) ?
							valIndex
						:	prev,
					null,
				);
		}

		setSelectedIndex(index);
		setSelectedFleet(values);
	};

	const resetState = () =>
		onResetListState('fetchFleetsInstancesListSearch', { data: null, loading: false, error: null });

	return (
		<Paper className={classes.filterBarRoot}>
			<Box alignItems='center' display='flex'>
				<Search
					data={fetchFleetsInstancesListSearch}
					fetchData={onFetchFleetsInstancesListSearch}
					filters={filters}
					onChange={handleChange}
					resetState={resetState}
				/>
				<TextField
					className={classes.menuItem}
					onChange={handleCategory}
					select
					value={categories}
					variant='outlined'
				>
					{categoryOptions.map((option, index) => (
						<MenuItem
							key={option.value}
							sx={{ fontWeight: index ? 400 : 500 }}
							value={option.value}
						>
							{option.label ? option.label : option.value}
						</MenuItem>
					))}
				</TextField>
				<Box pl={2}>
					<TextField
						className={classes.menuItem}
						onChange={handleDevice}
						select
						value={deviceType}
						variant='outlined'
					>
						{deviceFilter.map((option, index) => (
							<MenuItem
								key={option.value}
								sx={{ fontWeight: index ? 400 : 500 }}
								value={option.value}
							>
								{option.label ? option.label : option.value}
							</MenuItem>
						))}
					</TextField>
				</Box>
				{isSuperAdmin() ?
					<SelectWithLazyLoading
						className={classes.filterInput}
						dataList={organisationsList}
						defaultListItem={{
							id: '',
							name: t('views.planboard.filterbar.filters.organisations.all'),
						}}
						events={{ filter: 'verified' }}
						listType='organisations'
						onFetchData={onFetchOrganisations}
						placeholder={t('views.planboard.filterbar.filters.organisations.all')}
						searchHandle={setSelectedOrganisation}
						setSelected={onChangeOrganisation}
						value={selectedOrganisation}
					/>
				:	null}
				<Box pl={2}>
					<Switch extraSwitch={extraSwitch} />
				</Box>
				{isObject(clearFilters) && clearFilters.clear ?
					<StyledButton
						onClick={clearFilters.action}
						size='medium'
						startIcon={<RefreshIcon />}
						variant='inline-default'
					>
						{t(clearFilters.btnText)}
					</StyledButton>
				:	null}
			</Box>
		</Paper>
	);
};

FilterBar.propTypes = {
	onFetchOrganisations: PropTypes.func,
	onFetchDevicesTypes: PropTypes.func,
	clearFilters: PropTypes.object,
	onFetchCategories: PropTypes.func,

	onFetchFleetsInstancesListSearch: PropTypes.func,
	onResetListState: PropTypes.func,
	categoriesList: PropTypes.shape({
		data: PropTypes.array,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	organisationsList: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	devicesTypesFilter: PropTypes.PropTypes.shape({
		data: PropTypes.array,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	fetchFleetsInstancesListSearch: PropTypes.PropTypes.shape({
		data: PropTypes.array,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
};

const mapStateToProps = (state) => {
	return {
		categoriesList: state.list.categories,
		organisationsList: state.paged.organisations,
		devicesTypesFilter: state.list.devicesTypesFilter,
		fetchFleetsInstancesListSearch: state.list.fetchFleetsInstancesListSearch,

		hubsList: state.paged.hubs,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchOrganisations: (page, filters, concat) =>
			dispatch(actions.fetchOrganisations(page, filters, concat)),
		onFetchCategories: () => dispatch(actions.fetchCategories()),
		onFetchDevicesTypes: (filters) => dispatch(actions.fetchDevicesTypes(filters)),
		onFetchFleetsInstancesListSearch: (filters) =>
			dispatch(actions.fetchFleetsInstancesListSearch(filters)),
		onResetListState: (state, data) => dispatch(actions.updateListState(state, data)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterBar);
