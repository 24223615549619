import { useTranslation } from 'react-i18next';

import { useAuthorize } from '~features/authentication';
import { NfcTagsDataGrid } from '~features/nfc';
import NfcTagsOverviewLayout from '~features/nfc/layouts/nfcTagsOverviewLayout';

import Page from '../page';

const NfcTagsPage = () => {
	const { t } = useTranslation('general');
	const { isSuperAdmin } = useAuthorize();

	return (
		<Page title={t('nfcTags')}>
			<NfcTagsOverviewLayout enableCreate={isSuperAdmin()} enableDelete />
		</Page>
	);
};

export default NfcTagsPage;
