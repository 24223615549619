/* eslint-disable indent */

import { atom } from 'jotai';
import { atomWithDefault } from 'jotai/utils';
import { atomWithLocation } from 'jotai-location';

import { queryParameters } from '~constants';
import { UserRoleEnum } from '~enums';
import { Organisation, OrganisationsService } from '~features/organisations';
import { User } from '~features/users';

import userInfoAtom from './userInfoAtom';

const service = new OrganisationsService();

const organisationLocationAtom = atomWithLocation({
	replace: true
});

const primitiveOrganisationAtom = atom<Organisation | undefined>();
const organisationReadWriteAtom = atom<Organisation | undefined, (Organisation | undefined)[], unknown>(
	(get) => {
		return get(primitiveOrganisationAtom);

		const organisationId = get(organisationLocationAtom).searchParams?.get(queryParameters.Organisation);
		if (!organisationId) {
			return undefined;
		}

		const savedOrganisation = get(primitiveOrganisationAtom);
		if (savedOrganisation != null && savedOrganisation?.id === organisationId) {
			return savedOrganisation;
		}

		// TODO: async get org
		return undefined;
	},
	(_get, set, args) => {
		set(primitiveOrganisationAtom, args);

		set(organisationLocationAtom, (prev) => {
			const searchParams = prev.searchParams;

			if (args?.id == undefined) {
				searchParams?.delete(queryParameters.Organisation);
			} else {
				searchParams?.set(queryParameters.Organisation, args.id);
			}

			return {
				...prev,
				searchParams: searchParams,
			};
		});
	}
);



/**
 * An atom for a shared filter state
 */
// const locationAtom = atomWithLocation({
// 	replace: true
// });
// const primitiveOrganisationAtom = atomWithDefault<Promise<Organisation | undefined>>(
// 	async (get) => {
// 		const organisationId = get(locationAtom).searchParams?.get(queryParameters.Organisation);

// 		if (!organisationId) {
// 			return undefined;
// 		}

// 		return await service.getOrganisationById(organisationId);
// 	},
// );

// const organisationAtom = atom<Organisation | undefined, (Organisation | undefined)[], unknown>(
// 	(get) => {
// 		get(primitiveOrganisationAtom);
// 	},
// 	(get, set, args) => {
// 		console.log(args)
// 		set(primitiveOrganisationAtom, args);

// 		set(locationAtom, (prev) => {
// 			const searchParams = prev.searchParams;

// 			if (args?.id == undefined) {
// 				searchParams?.delete(queryParameters.Organisation);
// 			} else {
// 				searchParams?.set(queryParameters.Organisation, args.id);
// 			}

// 			return {
// 				...prev,
// 				searchParams: searchParams,
// 			};
// 		});
// 	},
// );

export default organisationReadWriteAtom;
