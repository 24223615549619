import { useEffect, useRef, useState } from 'react';

import { Stack } from '@mui/material';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import useSWRMutation from 'swr/mutation';

import { ConfirmationDialog, FormDialog } from '~components';
import { useAuthorize } from '~features/authentication';
import { useSnackbar } from '~hooks';

import selectedOrganisationAtom from '../atoms/selectedOrganisationAtom';
import AccessRulesDataGrid from '../components/dataGrids/accessRulesDataGrid';
import SkcAccessRuleForm from '../forms/skcRuleForm/skcAccessRuleForm';
import SkcAccessRulesService from '../services/skcAccessRulesService';

type UserAction =
	| {
			type: 'delete' | 'edit';
			label?: string;
			id: string;
	  }
	| {
			type: 'create';
			label?: never;
			id?: never;
	  };

const service = new SkcAccessRulesService();

const AccessRulesOverviewLayout = () => {
	const { t } = useTranslation('general');
	const { enqueueSuccessSnackbar, enqueueErrorSnackbar } = useSnackbar();
	const [userAction, setUserAction] = useState<UserAction | null>(null);
	const dataGridRef = useRef<{ mutate: () => void }>(null);

	const { isSuperAdmin } = useAuthorize();
	const selectedOrganisation = useAtomValue(selectedOrganisationAtom);

	const { trigger: triggerCreate, isMutating: isCreateMutating } = useSWRMutation(
		service.basePath,
		(_, { arg }) => service.createAccessRule(arg),
		{
			onSuccess: () => {
				enqueueSuccessSnackbar(`${t('ui.successfullyAdded')} ${t('rule').toLowerCase()}`);
				setUserAction(null);
				dataGridRef.current?.mutate();
			},
			onError: (error) => enqueueErrorSnackbar(error),
		},
	);

	const { trigger: triggerEdit, isMutating: isEditMutating } = useSWRMutation(
		userAction?.id ? [service.basePath, userAction.id] : null,
		([_, id], { arg }) => service.updateAccessRule(id, arg),
		{
			onSuccess: () => {
				enqueueSuccessSnackbar(`${t('ui.success.message.updated')} ${t('rule').toLowerCase()}`);
				setUserAction(null);
				dataGridRef.current?.mutate();
			},
			onError: (error) => enqueueErrorSnackbar(error),
		},
	);

	const { trigger: triggerDelete, isMutating: isDeleteMutating } = useSWRMutation(
		userAction?.id ? [service.basePath, userAction.id] : null,
		([_, id]) => service.deleteAccessRule(id),
		{
			onSuccess: () => {
				enqueueSuccessSnackbar(`${t('ui.successfullyDeleted')} ${t('rule').toLowerCase()}`);
				setUserAction(null);
				dataGridRef.current?.mutate();
			},
			onError: (error) => enqueueErrorSnackbar(error),
		},
	);

	useEffect(() => {
		// Should be temporary
		if (selectedOrganisation) {
			service.organisation = selectedOrganisation;
		}
	}, [selectedOrganisation]);

	return (
		<Stack
			sx={{
				height: 1,
			}}
		>
			<AccessRulesDataGrid
				ref={dataGridRef}
				onEdit={(value) => setUserAction({ type: 'edit', ...value })}
				onDelete={(value) => setUserAction({ type: 'delete', ...value })}
				onCreate={() => setUserAction({ type: 'create' })}
			/>
			<FormDialog
				title={t(userAction?.type === 'edit' ? 'editResource' : 'addResource', {
					resource: t('rule'),
				})}
				open={userAction?.type === 'create' || userAction?.type === 'edit'}
				loading={isCreateMutating || isEditMutating}
				onClose={() => setUserAction(null)}
				maxWidth='md'
				fullWidth
			>
				<SkcAccessRuleForm
					id={userAction?.id}
					onSubmit={async (data) => {
						if (userAction?.type === 'create') {
							await triggerCreate(data);
						} else if (userAction?.type === 'edit') {
							await triggerEdit(data);
						} else {
							console.error('Useraction not supported', userAction);
						}
					}}
				/>
			</FormDialog>
			<ConfirmationDialog
				loading={isDeleteMutating}
				open={userAction?.type === 'delete'}
				subTitle={t('thisWillDeleteResourceWithName', {
					resource: t('rule'),
					name: userAction?.label ?? userAction?.id,
				})}
				onConfirm={async () => await triggerDelete()}
				onClose={() => setUserAction(null)}
			/>
		</Stack>
	);
};

export default AccessRulesOverviewLayout;
