import { useState, useEffect } from 'react';

import { Link } from '@mui/material';
import { useAtomValue } from 'jotai';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';

import { userInfoAtom } from '~atoms';
import { useAuthorize } from '~features/authentication';

import { SearchBar, Table, EmptyTable, Label, DatePicker } from '../../../components';
import { commaTimeStrings } from '../../../shared/datetime';
import {
	useSearchComponent,
	useBasicFilter,
	useComplexFilter,
	usePeriodFilter,
	usePagination,
} from '../../../shared/hooks';
import {
	isObject,
	isUndefined,
	isEmptyArray,
	isFullArray,
	isEmptyString,
	isInteger,
	isArray,
	isNull,
} from '../../../shared/utility';
import * as actions from '../../../store/actions';
import { contractTypes, contractStatus } from '../constants';
import { useStyles } from '../style';

const ContractsList = (props) => {
	const classes = useStyles();

	const {
		sharedContracts,
		onContracts,
		contracts,
		onFetchOrganisations,
		organisationsList,
		onFetchCategories,
		categoriesList,
	} = props;

	const { t } = useTranslation('general');
	const navigate = useNavigate();
	const location = useLocation();
	const userInfo = useAtomValue(userInfoAtom);
	const { isSuperAdmin } = useAuthorize();

	const { data: contractsData, loading: contractsLoading } = contracts;

	const {
		data: categoriesData,
		loading: categoriesLoading,
		error: categoriesError,
	} = categoriesList;
	const categoriesReady = isArray(categoriesData) && !categoriesLoading && !categoriesError;

	const isPageEmpty =
		isObject(contractsData) &&
		(isEmptyArray(contractsData.results) || isUndefined(contractsData.results));
	const isPageFull = isObject(contractsData) && isFullArray(contractsData.results);

	const [loading, setLoading] = useState(true);

	const [order, setOrder] = useState('asc');

	const [sortByProperty, setSortByProperty] = useState('');
	const [orderDescending, setOrderDescending] = useState(true);

	const [shouldSort, setShouldSort] = useState(false);
	const [shouldFilter, setShouldFilter] = useState(false);
	const [shouldSearch, setShouldSearch] = useState(false);

	const pagination = usePagination(
		`pageNumber${sharedContracts ? 'sharedContracts' : ''}`,
		`pageSize${sharedContracts ? 'sharedContracts' : ''}`,
	);
	const contractPerioFilter = usePeriodFilter(setShouldFilter);
	const contractCategoryFilter = useBasicFilter('contractCategoryFilter');
	const contractStatusFilter = useBasicFilter('contractStatusFilter');
	const contractOrganisationFilter = useComplexFilter(
		'contractOrganisationNameFilter',
		'contractOrganisationIdFilter',
	);
	const search = useSearchComponent(pagination.setPageNumber, setShouldSearch, 'contractSearch');

	const periodStart =
		isObject(contractPerioFilter.dates.startDate) ?
			contractPerioFilter.dates.startDate.toISOString()
		:	null;
	const periodEnd =
		isObject(contractPerioFilter.dates.endDate) ?
			contractPerioFilter.dates.endDate.toISOString()
		:	null;

	const filters = {
		orderDescending,
		...(sharedContracts && { type: 'external' }),
		...(!sharedContracts && !isSuperAdmin() && { hasUser: true }),
		...(contractOrganisationFilter.valueId !== 'all' && {
			organisationId: contractOrganisationFilter.valueId,
		}),
		...(contractCategoryFilter.value !== 'all' && { categoryId: contractCategoryFilter.value }),
		...(contractStatusFilter.value !== 'all' && { status: contractStatusFilter.value }),
		...(sortByProperty && { sortBy: sortByProperty }),
		...(!isEmptyString(search.value) && { searchTerm: search.value }),
		...(!isNull(periodStart) && { dateAfter: periodStart }),
		...(!isNull(periodEnd) && { dateBefore: periodEnd }),
	};

	useEffect(() => {
		if (isObject(contractsData) && !contractsLoading) {
			setLoading(false);
		} else {
			setLoading(true);
		}
	}, [contractsLoading]);

	useEffect(() => {
		if (!categoriesLoading && isNull(categoriesData)) {
			onFetchCategories();
		}
	}, [categoriesData]);

	useEffect(() => {
		if (
			(!contractsLoading && loading && userInfo) ||
			pagination.fetch ||
			shouldSort ||
			shouldSearch ||
			shouldFilter
		) {
			onContracts(pagination.page, filters);
		}

		if (shouldSearch) {
			setShouldSearch(false);
		} else if (shouldFilter) {
			setShouldFilter(false);
		} else if (shouldSort) {
			setShouldSort(false);
		} else if (pagination.fetch) {
			pagination.setFatch(false);
		}
	}, [pagination.fetch, loading, order, shouldFilter, shouldSearch]);

	const handleRequestSort = (property) => {
		const isDesc = sortByProperty === property && order === 'desc';
		setOrder(isDesc ? 'asc' : 'desc');
		setOrderDescending(!isDesc);
		setSortByProperty(property);
		setShouldSort(true);
	};

	const handleChangeOrganisationFilterSelect = (item) => {
		if (item.id === contractOrganisationFilter.valueId) {
			return;
		}
		if (isInteger(item.id)) {
			contractOrganisationFilter.setValueName(item.name);
		} else if (isEmptyString(item.id)) {
			contractOrganisationFilter.setValueName('');
		}
		contractOrganisationFilter.setValueId(item.id);
		setShouldFilter(true);
	};

	const handleChangeCategoryFilterSelect = (event) => {
		const value = event.target.value;
		if (value === contractCategoryFilter.value) {
			return;
		}
		contractCategoryFilter.setValue(value);
		setShouldFilter(true);
	};

	const handleChangeStatusFilterSelect = (event) => {
		const value = event.target.value;
		if (value === contractStatusFilter.value) {
			return;
		}
		contractStatusFilter.setValue(value);
		setShouldFilter(true);
	};

	const handleLinks = (path, label, returnButton = 'ui.label.contracts') => (
		<Link
			className={classes.itemGroup}
			color='primary'
			component={RouterLink}
			to={path}
			state={{
				from: location.pathname,
				label: t(returnButton),
			}}
		>
			{label}
		</Link>
	);

	const handleLabelStatus = (status) => {
		return <Label type={contractTypes[status]}>{t(`ui.label.contract.status.${status}`)}</Label>;
	};

	const tableHeaders =
		!loading && isPageEmpty ?
			[]
		:	[
				{ name: 'name', content: t('ui.label.name'), hasSorting: true },
				{ name: 'startDate', content: t('ui.label.startDate'), hasSorting: true },
				{ name: 'endDate', content: t('ui.label.endDate'), hasSorting: true },
				...(isSuperAdmin() ?
					[{ name: 'type', content: t('views.contracts.table.type') }]
				:	[]),
				{ name: 'userName', content: t('ui.label.user'), hasSorting: true },
				{ name: 'category', content: t('ui.label.category') },
				{ name: 'itemName', content: t('ui.label.itemGroup'), hasSorting: true },
				{ name: 'instance', content: t('ui.label.item') },
				...(isSuperAdmin() || sharedContracts ?
					[{ name: 'organisationName', content: t('ui.provider'), hasSorting: true }]
				:	[]),
				{ name: 'status', content: t('ui.label.status') },
			];

	const loadingBody =
		loading ?
			Array(3)
				.fill(Array(tableHeaders?.length).fill())
				.map((arr) => arr.map(() => ({ loading: true })))
		:	null;

	const emptyBody =
		!loading && isPageEmpty ?
			[
				[
					{
						content: <EmptyTable label='views.contracts.table.emptyResults' />,
					},
				],
			]
		:	null;

	const tableBody =
		!loading && isPageFull ?
			contractsData.results.map((contract) => {
				const instance = contract.itemInstance;
				const itme = instance.item;
				const user = contract?.user;
				const organisation = contract?.itemInstance?.hub?.organisation;
				const isProvider = userInfo?.organisation?.id === organisation?.id;
				const isMyUser = userInfo?.organisation?.id === contract?.userOrganisation?.id;
				const isInternal = contract.type === 'internal';

				return [
					{ content: handleLinks(`/contracts/${contract.id}/summary`, contract.name) },
					{ content: commaTimeStrings(contract.start) },
					{ content: contract?.end && commaTimeStrings(contract.end) },
					...(isSuperAdmin() ?
						[{ content: t(`ui.label.${contract.type}`) }]
					:	[]),
					{
						content:
							isMyUser || isInternal || isSuperAdmin() ?
								user && handleLinks(`/user-management/users/${user.id}/summary`, user.name)
							:	user?.name,
					},
					{ content: itme.category?.name },
					{
						content:
							isProvider || isSuperAdmin() ?
								handleLinks(`/item-management/items/${itme.id}/summary`, itme.name)
							:	itme.name,
					},
					{
						content:
							isProvider || isSuperAdmin() ?
								handleLinks(
									`/item-management/${itme.id}/instance/${instance.id}/summary`,
									instance.name,
								)
							:	instance.name,
					},
					...(isSuperAdmin() || sharedContracts ?
						[
							{
								content:
									!isProvider && sharedContracts ?
										organisation.name
									:	handleLinks(`/organisations/${organisation?.id}/summary`, organisation?.name),
							},
						]
					:	[]),
					{ content: handleLabelStatus(contract.status) },
				];
			})
		:	[];

	const extraButtons = [
		{
			text: t('views.contracts.addContract'),
			onClick: () => navigate('/contracts/contract/add'),
			variant: 'contained-primary',
		},
	];

	const mainFilters = [
		...(isSuperAdmin() ?
			[
				{
					isSelectWithLazyLoading: true,
					events: {
						onChange: handleChangeOrganisationFilterSelect,
						searchHandle: contractOrganisationFilter.setValueName,
						filter: 'verified',
					},
					value: contractOrganisationFilter.valueName,
					dataList: organisationsList,
					placeholder: t('ui.filter.organisations.all'),
					onFetchData: onFetchOrganisations,
					listType: 'organisations',
					defaultListItem: {
						id: '',
						name: t('ui.filter.organisations.all'),
					},
				},
			]
		:	[]),
		{
			events: {
				onChange: (e) => handleChangeCategoryFilterSelect(e),
			},
			value: contractCategoryFilter.value,
			selectOptions: [
				{
					value: 'all',
					label: t('views.assets.items.mainFilters.category.all'),
				},
			].concat(
				categoriesReady ?
					categoriesData.map((category) => ({
						value: category.id.toString(),
						label: category.name,
					}))
				:	[],
			),
		},
		{
			events: {
				onChange: (e) => handleChangeStatusFilterSelect(e),
			},
			value: contractStatusFilter.value,
			selectOptions: [
				{
					value: 'all',
					label: t('ui.filter.status.all'),
				},
			].concat(
				contractStatus.map((state) => ({
					value: state,
					label: t(`ui.label.contract.status.${state}`),
				})),
			),
		},
	];

	const handleClearFilters = () => {
		contractCategoryFilter.setValue('all');
		contractStatusFilter.setValue('all');
		contractOrganisationFilter.setValueName('');
		contractOrganisationFilter.setValueId('all');
		contractPerioFilter.setDates({ startDate: null, endDate: null });
		search.events.onClear();
		pagination.resetPagination();
	};

	const clearFilters = {
		clear:
			pagination.pageNumber !== 1 ||
			pagination.pageSize !== 10 ||
			contractCategoryFilter.value !== 'all' ||
			contractStatusFilter.value !== 'all' ||
			periodStart ||
			periodEnd ||
			contractOrganisationFilter.valueId !== 'all',
		btnText: 'ui.button.inline.clearfilters',
		action: handleClearFilters,
	};

	return (
		<>
			<SearchBar
				clearFilters={clearFilters}
				extraButtons={extraButtons}
				hasExtraButtons
				hasMainFilter
				hideMoreFilters
				mainFilters={mainFilters}
				periodFilter={
					<DatePicker
						end={contractPerioFilter.dates.endDate}
						focused={contractPerioFilter.focused}
						handleDatesChange={contractPerioFilter.datesChange}
						handleFocusChange={contractPerioFilter.focusAction}
						start={contractPerioFilter.dates.startDate}
					/>
				}
				placeholder={t('views.devices.heartbeatsList.searchHeartbeats')}
				searchEvents={search.events}
				searchValue={search.value}
			/>
			<Table
				body={loadingBody || emptyBody || tableBody}
				cellStyle={classes.cellStyle}
				data={isPageFull ? contractsData.results : []}
				handlePageChange={pagination.pageNumberChange}
				handleSorting={handleRequestSort}
				header={tableHeaders}
				isNotPaginate={loading || isPageEmpty || !isPageFull || contractsData?.total < 5}
				loading={contractsLoading}
				order={order}
				orderBy={sortByProperty}
				page={pagination.pageNumber}
				rowsPerPage={pagination.pageSize}
				setRowsPerPage={pagination.pageSizeChange}
				title={t('views.contracts.table.title')}
				total={contractsData ? contractsData.total : 0}
			/>
		</>
	);
};

ContractsList.propTypes = {
	sharedContracts: PropTypes.bool,
	onContracts: PropTypes.func,
	onFetchOrganisations: PropTypes.func,
	onFetchCategories: PropTypes.func,
	contracts: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	organisationsList: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	categoriesList: PropTypes.shape({
		data: PropTypes.array,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
};

const mapStateToProps = (state) => {
	return {
		contracts: state.paged.contracts,

		organisationsList: state.paged.organisations,
		categoriesList: state.list.categories,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onContracts: (page, filters) => dispatch(actions.contracts(page, filters)),
		onFetchOrganisations: (page, filters, concat) =>
			dispatch(actions.fetchOrganisations(page, filters, concat)),
		onFetchCategories: () => dispatch(actions.fetchCategories()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ContractsList);
