import { forwardRef, useEffect, useImperativeHandle } from 'react';

import { ajvResolver } from '@hookform/resolvers/ajv';
import { Feedback as FeedbackIcon } from '@mui/icons-material';
import { Button, Chip, MenuItem } from '@mui/material';
import { JSONSchemaType } from 'ajv';
import { useAtomValue } from 'jotai';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { userInfoAtom } from '~atoms';
import { FormContainer, Notification, Select, TextField } from '~components';
import { useFormContainerState } from '~components/dialogs/formContainerProvider';
import { useAuthorize } from '~features/authentication';
import { ItemAutocomplete } from '~features/items';
import { FormWrapperRefProps } from '~interfaces/refProps';

import schema from './createTicketSchema.json';
import DefectSelect from '../../components/selects/defectSelect';
import ServiceTicket, { ServiceTicketNew } from '../../interfaces/serviceTicket';

interface TicketFormProps {
	id?: string;
	onSubmit?: (value: ServiceTicketNew) => void;
}

const TicketForm = forwardRef<FormWrapperRefProps, TicketFormProps>(({ id, onSubmit }, ref) => {
	const { t } = useTranslation('general');
	const { isSuperAdmin } = useAuthorize();
	const userInfo = useAtomValue(userInfoAtom);

	const { setDisabled } = useFormContainerState();
	const { handleSubmit, getValues, control, watch, formState, resetField } = useForm<ServiceTicket>(
		{
			resolver: ajvResolver(schema as JSONSchemaType<any>),
		},
	);

	const watchItem = watch('item');
	const serviceProviders =
		watchItem?.hub != null ? [{ ...watchItem.hub.organisation, isDefault: true }] : [];

	useImperativeHandle(
		ref,
		() => ({
			onSubmit: () => onSubmit?.(getValues()),
		}),
		[onSubmit],
	);

	useEffect(() => {
		setDisabled?.(!formState.isValid);
	}, [formState.isValid, setDisabled]);

	const handleItemChange = (newItem: Item, onChange: (...event: any[]) => void) => {
		resetField('defect');
		resetField('serviceProvider');

		onChange(newItem);
	};

	return (
		<FormContainer>
			<Controller
				name='item'
				control={control}
				render={({ field }) => (
					<ItemAutocomplete
						value={field.value}
						onChange={(_, newValue) => handleItemChange(newValue, field.onChange)}
						organisation={isSuperAdmin() ? userInfo.organisation : undefined}
					/>
				)}
			/>
			{watchItem == null ?
				<Notification
					message={t('views.planboard.addBooking.notification.noItemSelected')}
					startIcon={<FeedbackIcon />}
				/>
			:	<>
					<Controller
						name='defect'
						control={control}
						render={({ field }) => (
							<DefectSelect
								label={t('ui.label.defect')}
								itemGroupId={watchItem.itemGroup.id}
								value={field.value}
								onChange={(e) => field.onChange(e.target.value)}
								required
								sx={{
									'& .MuiSelect-select': {
										display: 'flex',
										justifyContent: 'space-between',
									},
								}}
							/>
						)}
					/>
					<Controller
						name='serviceProvider'
						control={control}
						render={({ field }) => (
							<Select
								label={t('ui.label.serviceProvider')}
								value={field.value ?? ''}
								onChange={(e) => field.onChange(e.target.value)}
								required
								sx={{
									'& .MuiSelect-select': {
										display: 'flex',
										justifyContent: 'space-between',
									},
								}}
							>
								{serviceProviders?.map((el) => (
									<MenuItem key={el.id} value={el.id} sx={{ justifyContent: 'space-between' }}>
										{el?.label}
										{el.isDefault && <Chip label={t('ui.default')} color='info' />}
									</MenuItem>
								))}
							</Select>
						)}
					/>
				</>
			}
			<Controller
				name='description'
				control={control}
				render={({ field }) => (
					<TextField
						label={t('ui.label.description')}
						value={field.value}
						onChange={field.onChange}
						multiline
						minRows={3}
						slotProps={{
							htmlInput: {
								maxLength: schema.properties.description.maxLength,
							},
						}}
					/>
				)}
			/>
		</FormContainer>
	);
});

export default TicketForm;
