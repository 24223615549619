import path from 'path';

import { PagedResults } from '~interfaces';
import { SearchPagedParameters } from '~interfaces/requests';
import { BaseReferenceResponse, PagedResponse } from '~interfaces/responses';
import { TopologyService } from '~services';

import Organisation from '../interfaces/organisation';

class OrganisationsService extends TopologyService {
	public readonly path = 'organisations';

	constructor() {
		super('v1');
	}

	/**
	 * Get a paginated list of organisations
	 * @param page The number of the page
	 * @param pageSize The amount of results of the page
	 * @returns
	 */
	async getOrganisations({
		page = 1,
		pageSize = 10,
		...args
	}: Omit<SearchPagedParameters, 'organisation'>): Promise<PagedResults<Organisation>> {
		const { data } = await this._client.get<PagedResponse<OrganisationResponse>>(this.path, {
			params: {
				pageNumber: page,
				pageSize: pageSize,
				searchTerm: args.searchQuery,
				sortBy: 'name',
			},
		});

		return this.mapPagedResponse(data, OrganisationsService.fromResponse);
	}

	/**
	 * Get the details of a single organisation
	 * @param id
	 * @returns
	 */
	async getOrganisationById(id: string): Promise<Organisation> {
		const { data } = await this._client.get<OrganisationResponse>(path.join(this.path, id));

		return OrganisationsService.fromResponse(data);
	}

	static fromResponse(data: OrganisationResponse): Organisation {
		const { id, name, logo, ...rest } = data;

		return {
			...rest,
			...OrganisationsService.fromBaseReferenceResponse({
				id: id,
				name: name,
			}),
			logoUri: logo,
		};
	}
}

interface OrganisationResponse extends BaseReferenceResponse {
	logo?: string;
	description?: string;
}

export default OrganisationsService;
