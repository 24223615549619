import { useEffect, useState } from 'react';

import { LoadingButton } from '@mui/lab';
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogProps,
	DialogTitle,
	Divider,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import i18n from '~lib/i18n';

interface ConfirmationDialogProps extends DialogProps {
	/**
	 * The title of the dialog
	 */
	title?: string;
	/**
	 * A helper text to provide more information about
	 * the action.
	 */
	subTitle?: string | null;
	onConfirm: () => void;
	loading?: boolean;
	enableWriteConfirmation?: boolean;
	/**
	 * If write confirmation is enable, set as text to confirm
	 * the deletion
	 */
	confirmationText?: string;
	/**
	 * The text to show in the confirm button
	 */
	confirmLabel?: string;
	variant?: 'new' | 'legacy';
}

/**
 * Use a dialog to confirm a certain action
 * @returns
 */
const ConfirmationDialog = ({
	title = i18n.t('ui.delete'),
	subTitle,
	loading = false,
	enableWriteConfirmation = false,
	confirmationText = i18n.t('ui.delete'),
	confirmLabel = i18n.t('ui.confirm'),
	maxWidth = 'sm',
	fullWidth = true,
	variant = 'legacy',
	onConfirm,
	...dialogProps
}: ConfirmationDialogProps) => {
	const { t } = useTranslation('general');

	const [confirmTextValue, setConfirmTextValue] = useState('');

	useEffect(() => {
		return () => {
			setConfirmTextValue('');
		};
	}, []);

	const handleConfirmClick = () => {
		setConfirmTextValue('');
		if (onConfirm) {
			onConfirm();
		}
	};

	return (
		<Dialog {...dialogProps} maxWidth={maxWidth} fullWidth={fullWidth}>
			<DialogTitle variant='h4'>{title}</DialogTitle>
			<DialogContent dividers>
				<DialogContentText>{subTitle}</DialogContentText>
				{enableWriteConfirmation && (
					<TextField
						label={
							<DialogContentText>{`Type "${confirmationText}" to confirm this action`}</DialogContentText>
						}
						value={confirmTextValue}
						onChange={(e) => setConfirmTextValue(e.target.value)}
						variant='standard'
						fullWidth
						sx={{ mt: 1 }}
					/>
				)}
			</DialogContent>
			<DialogActions
				sx={{
					...dialogProps.sx,
					...(variant === 'legacy' &&
					{
						px: 4,
						py: 2,
					})
				}}
			>
				<Button
					variant='outlined'
					onClick={() => dialogProps.onClose?.({}, 'decline')}
					disabled={loading}
					{...(variant === 'legacy' && {
						fullWidth: true,
						variant: 'text',
						size: 'large',
					})}
				>
					{t('ui.cancel')}
				</Button>
				<LoadingButton
					variant='contained'
					loading={loading}
					onClick={handleConfirmClick}
					disabled={enableWriteConfirmation && confirmTextValue !== confirmationText}
					color='secondary'
					{...(variant === 'legacy' && {
						fullWidth: true,
						size: 'large',
					})}
				>
					{confirmLabel}
				</LoadingButton>
			</DialogActions>
		</Dialog >
	);
};

export default ConfirmationDialog;
