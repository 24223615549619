import { IdReference } from '~interfaces';

/**
 * A function to get added and remove entries from one array to another
 * @param initialArray The "base" array, what it was before
 * @param newArray The new array
 * @returns 
 */
export const getMutationDifferences = <T extends IdReference>(initialArray: T[], newArray: T[]) => {
	const addedEntries = newArray.filter(el2 =>
		!initialArray.some(el1 => el1.id === el2.id)
	);

	const removedEntries = initialArray.filter(el1 =>
		!newArray.some(el2 => el2.id === el1.id)
	);

	return {
		added: addedEntries,
		removed: removedEntries
	};
};
