const pagePathSegments = {
	// E.g. for authentication logic. See identity service
	// for relevant paths and information
	AdminRedirect: 'admin-redirect',
	Auth: 'auth',
	Callback: 'callback',
	Confirm: 'confirm',
	Email: 'email',
	Login: 'login',
	Logout: 'logout',
	Password: 'password',
	Register: 'register',
	Reset: 'reset',
	Silent: 'silent',
	Verifications: 'verifications',
	VerificationStatus: 'verification-status',

	// Internal app logic
	Data: 'data',
	Access: 'access',
	Bookings: 'bookings',
	Dashboard: 'dashboard',
	Devices: 'devices',
	Home: '/',
	Organisations: 'organisations',
	Planboard: 'planboard',
	Rules: 'rules',
	Trips: 'trips',
	Sandbox: 'sandbox',
	SandboxPublic: 'sandbox-public',
	Settings: 'settings',
	Users: 'users',
	NfcTags: 'nfc-tags',

	DeviceGroups: 'device-groups',
	Schedules: 'schedules',

	// Used but should be redirected to other path
	Add: 'add',
	Assign: 'assign',
	Assigned: 'assigned',
	Billing: 'billing',
	Booking: 'booking',
	BookingDetail: 'booking-detail',
	Categories: 'categories',
	Co2: 'co2',
	Contract: 'contract',
	Contracts: 'contracts',
	Characteristics: 'characteristics',
	Details: 'details',
	Edit: 'edit',
	Errors: 'errors',
	EmailDomains: 'email-domains',
	Expenses: 'expenses',
	FinanceManagement: 'financeManagement',
	UserManagement: 'user-management',
	Instance: 'instance',
	Invite: 'invite',
	ItemManagement: 'item-management',
	ItemPolicies: 'item-policies',
	Item: 'item',
	Items: 'items',
	Licenses: 'licenses',
	Location: 'location',
	Locations: 'locations',
	LocationManagement: 'location-management',
	Logs: 'logs',
	Management: 'management',
	MapView: 'map-view',
	Open: 'open',
	Organisation: 'organisation',
	OrganisationManagement: 'organisation-management',
	Partner: 'partner',
	Partners: 'partners',
	Partnerships: 'partnerships',
	Pending: 'pending',
	// Yes, this is wrongly typed... Update at an appropiate time
	Personal: 'parsonal',
	Policy: 'policy',
	PolicyManagement: 'policy-management',
	Pricing: 'pricing',
	Request: 'request',
	Revenue: 'revenue',
	ServiceTickets: 'servicetickets',
	Sharing: 'sharing',
	Start: 'start',
	Statistics: 'statistics',
	Summary: 'summary',
	Term: 'term',
	Terms: 'terms',
	TermAndConditions: 'terms-and-conditions',
	TicketDetails: 'ticket-details',
	Ticketing: 'ticketing',
	Unavailability: 'unavailability',
	UserGroup: 'user-group',
	UserGroups: 'user-groups',
	UserInvitations: 'user-invitations',
	UserRedirect: 'user-redirect',
} as const;

export default pagePathSegments;
