import path from 'path';

import { ItemCategoryEnum, PeriodicityEnum } from '~enums';
import { DateRange } from '~interfaces/dateRanges';
import { BasePagedParameters } from '~interfaces/requests';
import { TopologyService } from '~services';
import { fillMissingDatesInArrayOfObjects } from '~utils/dateUtils';

interface BookingListResults<T extends object> {
	results: T[];
	uniqueUsers: number;
	total: number;
}

interface BookingStat {
	timestamp: Date;
	data: {
		bookingCount: number;
		category: ItemCategoryEnum;
		uniqueUsers: number;
	}[];
}

type BookingStat2 = {
	timestamp: Date;
} & Record<'cars' | 'bikes', { bookings: number, uniqueUsers: number }>

class BookingStatisticsService extends TopologyService {
	public readonly path = 'graphs/bookings';

	constructor() {
		super('v1');
	}

	/**
	 * Get the counts and provid it more in the style of a dataset.
	 * See mui charts + dataset
	 */
	async getCounts({
		period,
		...args
	}: {
		organisationId?: string;
		period: DateRange;
		itemId?: string;
		hubId?: string;
		categoryId?: string;
		periodicity?: PeriodicityEnum;
	}): Promise<BookingListResults<BookingStat2>> {
		const { data } = await this._client.get<BookingStatResponse>(this.path, {
			params: {
				itemId: args.itemId,
				hubId: args.hubId,
				organisationId: args.organisationId,
				categoryId: args.categoryId,
				dateAfter: period.start.toISOString(),
				dateBefore: period.end.toISOString(),
				// step: 'day',
				step: args.periodicity === PeriodicityEnum.Monthly ? 'day' : 'month',
			},
		});

		const temp = data.categories.reduce((acc, el) => {
			const { dateTime, categoryType, ...rest } = el;

			acc[dateTime] = acc[dateTime] ?? {};
			acc[dateTime][`${categoryType}Bookings`] = rest.bookings;
			if (!acc[dateTime]['uniqueUsers']) {
				acc[dateTime]['uniqueUsers'] = 0;
			}
			// acc[dateTime][`${categoryType}UniqueUsers`] = rest.uniqueUsers;
			acc[dateTime]['uniqueUsers'] = acc[dateTime]['uniqueUsers'] + rest.uniqueUsers;
			return acc;
		}, []);

		return {
			total: data.bookings,
			uniqueUsers: data.uniqueUsers,
			results: Object.keys(temp).map(el => ({
				timestamp: new Date(el),
				...temp[el]
			}))
		};
	}

	async getCounts2({
		period,
		...args
	}: {
		organisationId?: string;
		period: DateRange;
		itemId?: string;
		hubId?: string;
		categoryId?: string;
		partnerId?: string;
		periodicity?: PeriodicityEnum;
	}): Promise<BookingListResults<BookingStat2>> {
		const { data } = await this._client.get<BookingStatResponse>(this.path, {
			params: {
				organisationId: args.organisationId,
				dateAfter: period.start.toISOString(),
				dateBefore: period.end.toISOString(),
				itemInstanceId: args.itemId,
				hubId: args.hubId,
				categoryId: args.categoryId,
				partnerId: args.partnerId,
				// step: 'day',
				step: args.periodicity === PeriodicityEnum.Monthly ? 'day' : 'month',
			},
		});

		const temp = data.categories.reduce((accumulator, el) => {
			const { dateTime, bookings, ...rest } = el;

			accumulator[dateTime] = accumulator[dateTime] ?? [];
			accumulator[dateTime].push({
				...rest,
				bookingCount: bookings,
			});
			return accumulator;
		}, []);

		const resultsWithGaps = Object.keys(temp).map((el) => ({
			timestamp: new Date(el),
			data: temp[el],
		}));

		return {
			total: data.bookings,
			uniqueUsers: data.uniqueUsers,
			results: resultsWithGaps,
		};
	}

	async getCategoryBookingCounts({
		...args
	}: {
		period: DateRange;
		itemId?: string;
		hubId?: string;
		partnerId?: string;
		categoryId?: string;
	} & BasePagedParameters): Promise<BookingStat[]> {
		const { data } = await this._client.get<BookingSubStatResponse[]>(
			path.join(this.path, 'list'),
			{
				params: {
					organisationId: args.organisationId,
					dateAfter: args.period.start,
					dateBefore: args.period.end,
					itemInstanceId: args.itemId,
					hubId: args.hubId,
					partnerId: args.partnerId,
					categoryId: args.categoryId,
				},
			},
		);

		return data.map((el) => {
			const { categoryType, bookings, durations, ...rest } = el;

			return {
				...rest,
				bookingCount: bookings,
				duration: durations,
				category: categoryType as ItemCategoryEnum,
			};
		});
	}

	static fromResponse(data: BookingSubStatResponse): BookingStat {
		const { ...rest } = data;

		return {
			...rest,
		};
	}
}

interface BookingSubStatResponse {
	categoryType: string;
	dateTime: string;
	uniqueUsers: number;
	extended: number;
	noShow: number;
	cancelled: number;
	durations: number;
	bookings: number;
	average: number;
}

interface BookingStatResponse {
	categories: BookingSubStatResponse[];
	uniqueUsers: number;
	bookings: number;
}

export default BookingStatisticsService;
