import { useMemo, useState } from 'react';

import { GridSortModel } from '@mui/x-data-grid';
import { useSearchParams } from 'react-router-dom';

import { queryParameters } from '~constants';

const useUrlSearchParamsSorting = (
	initialSortModel?: GridSortModel | undefined,
	disableUrlSync = false
): [GridSortModel | undefined, (model: GridSortModel) => void] => {
	const [searchParams, setSearchParams] = useSearchParams();

	const sortingParam = searchParams.get(queryParameters.SortBy);
	const orderParam = searchParams.get(queryParameters.OrderBy);
	const urlSortModel = useMemo<GridSortModel | undefined>(
		() =>
			sortingParam != null ? (
				[
					{
						field: sortingParam,
						sort: orderParam === 'desc' ? 'desc' : 'asc',
					},
				]
			) : initialSortModel,
		[sortingParam, orderParam],
	);

	const [internalSortModel, setInternalSortModel] = useState<GridSortModel | undefined>(
		initialSortModel
	);

	const setSortModel = (model: GridSortModel) => {
		if (disableUrlSync) {
			setInternalSortModel(model);
			return;
		}

		if (!model[0]) {
			searchParams.delete(queryParameters.SortBy);
			searchParams.delete(queryParameters.OrderBy);
		} else {
			searchParams.set(queryParameters.SortBy, model[0].field);

			if (model[0].sort === 'desc' || model[0].sort === 'asc') {
				searchParams.set(queryParameters.OrderBy, model[0].sort);
			} else {
				searchParams.delete(queryParameters.OrderBy);
			}
		}

		setSearchParams(searchParams, { replace: true });
	};

	return [
		!disableUrlSync ? urlSortModel : internalSortModel,
		setSortModel
	];
};

export default useUrlSearchParamsSorting;
