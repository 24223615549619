import path from 'path';

import { TopologyService } from '~services';

import Billing from '../interfaces/billing';

class BillingsService extends TopologyService {
	public readonly path = 'finances/billings';

	constructor() {
		super('v1');
	}

	/**
	 * Get a list of access rules
	 * @param date As date object or date iso string
	 * @returns
	 */
	async getBilling(date: Date | string, organisationId: string): Promise<Billing> {
		const pathParam = typeof date === 'string' ? date : date.toISOString();
		const { data } = await this._client.get<BillingResponse>(path.join(this.path, pathParam), {
			params: {
				providerId: organisationId,
			},
		});

		return BillingsService.fromResponse(data);
	}

	static fromResponse(data: BillingResponse): Billing {
		const { ...rest } = data;

		return {
			...rest,
		};
	}
}

interface BillingResponse {
	start: string;
	end: string;
	title: string;
	open: number;
	status: string;
	amount: object;
	billings: object[];
}

export default BillingsService;
