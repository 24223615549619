import { Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { PieChart, PieChartCenterLabel } from '~components';
import { colorsSlim } from '~themes/chartColors';

import UserStatisticsService from '../../services/userStatisticsService';

const service = new UserStatisticsService();

interface UserCountPieChart {
	organisationId?: string;
}

const UserCountPieChart = ({ organisationId }: UserCountPieChart) => {
	const { t } = useTranslation('general');

	const { data, isLoading } = useSWR(
		[service.basePath, { organisationId: organisationId }],
		([_, args]) => {
			if (isLoading) {
				service.abortCurrentRequest('parameter change');
			}

			return service.getCounts(args);
		}
	);

	return (
		<PieChart
			loading={isLoading}
			series={[
				{
					data: data != null ? Object.keys(data).filter(el => el !== 'total').map((el: string) => ({
						label: () => {
							if (el === 'new') {
								return `${t('new')} (30d)`;
							} else if (el === 'nfc') {
								return el.toUpperCase();
							}

							return t(el);
						},
						value: data[el],
					})) : [],
					outerRadius: '100%',
					innerRadius: '80%',
				},
			]}
		>
			<PieChartCenterLabel primary={t('totalResources', { resource: t('users') })} secondary={data?.total} />
		</PieChart>
	);
};

export default UserCountPieChart;
