import { Card, CardContent, List, ListItem, Radio, Typography, Box } from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { useAuthorize } from '~features/authentication';

import { SelectWithLazyLoading } from '../../../../../components';
import { isInteger, isEmptyString } from '../../../../../shared/utility';
import * as actions from '../../../../../store/actions';
import { useStyles } from '../style';

const Details = (props) => {
	const {
		save,
		contractType,

		organisationsList,
		onFetchOrganisations,

		selectedOrganisation,
		setSelectedOrganisation,
		organisationFilter,
		setOrganisationFilter,
	} = props;

	const { t } = useTranslation('general');
	const { isSuperAdmin } = useAuthorize();

	const classes = useStyles();

	const contracts = [
		{
			id: 1,
			title: 'ui.label.internal',
			description: 'views.contract.add.description.internal',
			type: 'internal',
		},
		{
			id: 2,
			title: 'ui.label.external',
			description: 'views.contract.add.description.external',
			type: 'external',
		},
	];

	const handleChangeRadio = (event, item) => {
		save({ updatedContractType: item.type });
	};
	const onChangeOrganisation = (item) => {
		if (item.id === organisationFilter) {
			return;
		}
		if (isInteger(item.id)) {
			setSelectedOrganisation(item.name);
		} else if (isEmptyString(item.id)) {
			setSelectedOrganisation('');
		}
		setOrganisationFilter(item.id);
	};

	return (
		<Card>
			<CardContent className={classes.mainCard}>
				<Box display='flex' flexDirection='column' pb={3}>
					<Box pb={0.5}>
						<Typography variant={'h3'}>{t('views.contract.add.selectContract.header')}</Typography>
					</Box>
					<Typography variant={'body2'}>
						{t('views.contract.add.selectContract.description')}
					</Typography>
				</Box>
				{isSuperAdmin() ?
					<Box pb={3}>
						<SelectWithLazyLoading
							className={classes.filterInput}
							dataList={organisationsList}
							defaultListItem={{
								id: '',
								name: t('views.planboard.filterbar.filters.organisations.all'),
							}}
							events={{ filter: 'verified' }}
							listType='organisations'
							onFetchData={onFetchOrganisations}
							placeholder={t('views.planboard.filterbar.filters.organisations.all')}
							searchHandle={setSelectedOrganisation}
							setSelected={onChangeOrganisation}
							value={selectedOrganisation}
						/>
					</Box>
				:	null}
				<List>
					{contracts.map((item) => (
						<ListItem
							button={true}
							className={clsx(classes.option, {
								[classes.selectedOption]: contractType === item.type,
							})}
							key={item.id}
							onClick={(event) => handleChangeRadio(event, item)}
						>
							<Radio
								checked={contractType === item.type}
								className={classes.optionRadio}
								onChange={(event) => handleChangeRadio(event, item)}
								value={item.type}
							/>
							<div className={classes.optionDetails}>
								<Typography gutterBottom variant='h5'>
									{t(item.title)}
								</Typography>
								<Typography variant='body1'>{t(item.description)}</Typography>
							</div>
						</ListItem>
					))}
				</List>
			</CardContent>
		</Card>
	);
};

Details.propTypes = {
	save: PropTypes.func,
	contractType: PropTypes.string,

	onFetchOrganisations: PropTypes.func,
	selectedOrganisation: PropTypes.string,
	setSelectedOrganisation: PropTypes.func,
	organisationFilter: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	setOrganisationFilter: PropTypes.func,
	organisationsList: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	bookingTypesList: PropTypes.shape({
		data: PropTypes.array,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
};

const mapStateToProps = (state) => {
	return {
		organisationsList: state.paged.organisations,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchOrganisations: (page, filters, concat) =>
			dispatch(actions.fetchOrganisations(page, filters, concat)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Details);
