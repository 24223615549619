import {
	Card,
	CardHeader,
	CardContent,
	Divider,
	Table,
	TableBody,
	TableRow,
	TableCell,
	Link,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import { commaTimeStrings } from '../../../../../shared/datetime';
import { useStyles } from '../style';
import { useAuthorize } from '~features/authentication';

const AdditionalInfo = (props) => {
	const { contractData, currentUser } = props;
	const { t } = useTranslation('general');
	const location = useLocation();
	const { isSuperAdmin } = useAuthorize();

	const { data: currentUserData } = currentUser;

	const isInternalUser =
		currentUserData?.organisationReference?.id === contractData?.itemInstance.hub.organisation.id;

	const assignedUserInterna =
		currentUserData?.organisationReference?.id === contractData?.userOrganisation?.id;

	const classes = useStyles();

	const isInternalContracts = contractData.type === 'internal';

	const handleLinks = (path, label, returnButton = 'ui.label.contracts') => (
		<Link
			className={classes.itemGroup}
			color='primary'
			component={RouterLink}
			to={path}
			state={{
				from: location.pathname,
				label: t(returnButton),
			}}
		>
			{label}
		</Link>
	);

	const data = [
		{ label: 'ui.dateCreated', value: commaTimeStrings(contractData.creationDate) },
		{
			label: 'views.contract.details.createBy',
			value:
				(
					contractData?.createdByUser &&
					(isInternalContracts || isInternalUser || isSuperAdmin())
				) ?
					handleLinks(
						`/user-management/users/${contractData.createdByUser.id}/summary`,
						contractData.createdByUser.name,
					)
				:	contractData?.createdByUser?.name,
		},
		{
			label: 'views.contract.details.dateUserAssigned',
			value: contractData.userAssignedDate && commaTimeStrings(contractData.userAssignedDate),
		},
		{
			label: 'views.contract.details.assignedBy',
			value:
				(
					contractData?.assignedByUser &&
					(isInternalContracts || isSuperAdmin() || assignedUserInterna)
				) ?
					handleLinks(
						`/user-management/users/${contractData.assignedByUser.id}/summary`,
						contractData.assignedByUser.name,
					)
				:	contractData?.assignedByUser?.name,
		},
		...(contractData?.terminatedDate ?
			[
				{
					label: 'views.contract.details.dateTerminated',
					value: contractData.terminatedDate && commaTimeStrings(contractData.terminatedDate),
				},
			]
		:	[]),
		...(contractData?.terminatedByUser ?
			[
				{
					label: 'views.contract.details.terminatedBy',
					value:
						isInternalContracts || isSuperAdmin() || assignedUserInterna ?
							handleLinks(
								`/user-management/users/${contractData?.terminatedByUser.id}/summary`,
								contractData.terminatedByUser.name,
							)
						:	contractData.terminatedByUser.name,
				},
			]
		:	[]),
		...(contractData?.intendedTerminatedDate ?
			[
				{
					label: 'views.contract.details.intendedTerminatedDate',
					value: commaTimeStrings(contractData.intendedTerminatedDate),
				},
			]
		:	[]),
	];

	return (
		<Card className={classes.cardRoot}>
			<CardHeader title={t('views.contract.details.additionalInfo')} />
			<Divider />
			<CardContent className={classes.content}>
				<Table>
					<TableBody>
						{data.map((contract) => (
							<TableRow key={contract.label}>
								<TableCell>{t(contract.label)}</TableCell>
								<TableCell>{contract?.value ? contract.value : '-'}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</CardContent>
		</Card>
	);
};

AdditionalInfo.propTypes = {
	className: PropTypes.string,

	contractData: PropTypes.object,

	currentUser: PropTypes.PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
};

const mapStateToProps = (state) => {
	return {
		currentUser: state.details.currentUser,
	};
};

export default connect(mapStateToProps, null)(AdditionalInfo);
