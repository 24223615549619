import { useEffect, useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Typography, AccordionDetails } from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { useAuthorize } from '~features/authentication';

import Placeholder from '../../../../../assets/images/ic-placeholder.svg';
import { SidePanel } from '../../../../../components';
import {
	isFullArray,
	isUndefined,
	isEmptyObject,
	isObject,
	isFullString,
	isNull,
	isEmptyString,
} from '../../../../../shared/utility';
import * as actions from '../../../../../store/actions';
import { useStyles, Accordion, AccordionSummary } from '../style';

const SidePanelDetails = (props) => {
	const {
		wizardDetails,
		itemImages,

		itemStep,
		characteristics,
		onFetchLocationImage,
		fetchLocationImage,
		currentUser,
	} = props;
	const { t } = useTranslation('general');
	const { isSuperAdmin } = useAuthorize();

	const { data: characteristicsData } = characteristics;

	const category =
		isObject(wizardDetails.updatedCategoryObject) &&
		isFullString(wizardDetails.updatedCategoryObject.type);

	const { data: currentUserData } = currentUser;
	const {
		data: locationImageData,
		loading: locationImageLoading,
		error: locationImageError,
	} = fetchLocationImage;

	const imageReady = isFullArray(locationImageData) && !locationImageLoading && !locationImageError;

	const [provider, setProvider] = useState('');

	const pricingModel = wizardDetails?.updatedPricingModel;

	const businessPricing = wizardDetails?.updatedBusinessPricing;

	const trailerCharacteristics = {
		axleCount: wizardDetails.updatedAxleCount,
		brakeType: wizardDetails.updatedBrakeType,
		maxWeightInKg: wizardDetails.updatedMaxWeightInKg,
		grossWeightInKg: wizardDetails.updatedGrossWeightInKg,
		hasTrailerCover: wizardDetails.updatedHasTrailerCover,
		cargoSpaceH: wizardDetails.updatedCargoSpaceH,
		cargoSpaceL: wizardDetails.updatedCargoSpaceL,
		cargoSpaceW: wizardDetails.updatedCargoSpaceW,
	};

	const carCharacteristics = {
		numberOfSeats: wizardDetails.updatedNumberOfSeats,
		propulsionType: wizardDetails.updatedPropulsionType,
		transmissionType: wizardDetails.updatedTransmissionType,
		carBodyType: wizardDetails.updatedCarBodyType,
		kmRange: wizardDetails.updatedKmRange,
		numberOfDoors: wizardDetails.updatedNumberOfDoors,
		hasAirconditioning: wizardDetails.updatedHasAirconditioning,
		hasNavigation: wizardDetails.updatedHasNavigation,
		hasTowHook: wizardDetails.updatedHasTowHook,
		hasBluetooth: wizardDetails.updatedHasBluetooth,
	};

	const bikeCharacteristics = {
		bikePropulsionType: wizardDetails.updatedBikePropulsionType,
		bikeSex: wizardDetails.updatedBikeSex,
		hasLuggageCarrier: wizardDetails.updatedHasLuggageCarrier,
		numberOfGears: wizardDetails.updatedNumberOfGears,
		bikeKmRange: wizardDetails.updatedBikeKmRange,
		bikeModel: wizardDetails.updatedBikeModel,
	};

	const officeCharacteristics = {
		numberOfPeople: wizardDetails.updatedNumberOfPeople,
		workplaceType: wizardDetails.updatedWorkplaceType,
		hasMonitor: wizardDetails.updatedHasMonitor,
		hasWiFi: wizardDetails.updatedHasWiFi,
		hasProjector: wizardDetails.updatedHasProjector,
		hasWhiteboard: wizardDetails.updatedHasWhiteboard,
		hasSpeakers: wizardDetails.updatedHasSpeakers,
		hasCoffeeTea: wizardDetails.updatedHasCoffeeTea,
		hasWiredInternet: wizardDetails.updatedHasWiredInternet,
		hasAppleTV: wizardDetails.updatedHasAppleTV,
		hasMicrophone: wizardDetails.updatedHasMicrophone,
		isCateringPossible: wizardDetails.updatedIsCateringPossible,
		hasHostAvailable: wizardDetails.updatedHasHostAvailable,
		hasIncludingLunch: wizardDetails.updatedHasIncludingLunch,
		hasIncludingDrinks: wizardDetails.updatedHasIncludingDrinks,
		hasIncludingFlatAndSparklingWater: wizardDetails.updatedHasIncludingFlatAndSparklingWater,
		hasIncludingPaperAndPencils: wizardDetails.updatedHasIncludingPaperAndPencils,
		hasFreeCopying: wizardDetails.updatedHasFreeCopying,
		hasTechnicalServicesAvailable: wizardDetails.updatedHasTechnicalServicesAvailable,
		hasConferencePhone: wizardDetails.updatedHasConferencePhone,
	};

	const parkingLotsCharacteristics = {
		hasChargingPoint: wizardDetails.updatedHasChargingPoint,
		isCovered: wizardDetails.updatedIsCovered,
		isRoundTheClock: wizardDetails.updatedIsRoundTheClock,
		parkingLotType: wizardDetails.updatedParkingLotType,
	};

	const boatsCharacteristics = {
		numberOfSeats: wizardDetails.updatedNumberOfSeats,
		engineType: wizardDetails.updatedEngineType,
		propulsionType: wizardDetails.updatedPropulsionType,
		...(wizardDetails.updatedPropulsionType === 'fuel' && {
			fuelType: wizardDetails.updatedFuelType,
		}),
		boatBodyType: wizardDetails.updatedBoatBodyType,
		hasBluetooth: wizardDetails.updatedHasBluetooth,
		hasJoystickDocking: wizardDetails.updatedHasJoystickDocking,
		hasLifeVests: wizardDetails.updatedHasLifeVests,
		hasNavigation: wizardDetails.updatedHasNavigation,
		hasRefrigerator: wizardDetails.updatedHasRefrigerator,
		hasSoundbox: wizardDetails.updatedHasSoundbox,
		hasSprayhood: wizardDetails.updatedHasSprayhood,
		...(wizardDetails.updatedPropulsionType === 'electric' && {
			kmRange: wizardDetails.updatedKmRange,
		}),
	};

	const classes = useStyles();

	useEffect(() => {
		if (
			isObject(wizardDetails.updatedHub) &&
			!isEmptyObject(wizardDetails.updatedHub) &&
			itemStep === 3 &&
			!isFullArray(locationImageData)
		) {
			onFetchLocationImage(wizardDetails.updatedHub.id);
		}
	}, [wizardDetails]);

	useEffect(() => {
		if (
			isObject(wizardDetails.updatedServiceProvider) &&
			!isEmptyObject(wizardDetails.updatedServiceProvider)
		) {
			setProvider(wizardDetails.updatedServiceProvider.partnerOrganisation.name);
		} else if (isObject(currentUserData) && !isObject(wizardDetails.updatedServiceProvider)) {
			setProvider(currentUserData.organisationReference.name);
		}
	}, [wizardDetails]);

	const handleBooking = (type) => {
		return t(`views.addItem.bookingType.options.${type}.title`);
	};

	return (
		<SidePanel className={classes.topPanel} title={t('views.addItem.sidepanel.title.itemDetails')}>
			<Box display='flex' flexDirection='row' mb={3}>
				<Box className={classes.imageItem} flexGrow='1'>
					{isFullArray(itemImages) ?
						itemImages.map((image, index) => (
							<img className={classes.imageItem} key={index} src={image.uri} />
						))
					:	<img
							className={clsx({ [classes.imageItem]: true, [classes.imageItem]: itemImages })}
							src={Placeholder}
						/>
					}
				</Box>
				<Box ml={2}>
					<Typography variant='body2'>{`${t('views.addItem.bookingType.title')}: ${handleBooking(wizardDetails.updatedReservationType)}`}</Typography>
					<Typography variant='body2'>
						{`${t('ui.label.category')}: ${isObject(wizardDetails.updatedCategoryObject) ? wizardDetails.updatedCategoryObject.name : ''}`}
					</Typography>
					<Typography
						className={classes.panelName}
						variant='h6'
					>{`${isObject(wizardDetails.name) ? wizardDetails.name.value : ''}`}</Typography>
					{isSuperAdmin() && wizardDetails.organisation ?
						<Typography>{wizardDetails.organisation.value.name}</Typography>
					:	null}
					<Typography>
						{pricingModel?.fromPrice ?
							pricingModel.fromPrice.price === 0 ?
								t('ui.free')
							:	`${pricingModel.fromPrice.currencySymbol}${pricingModel.fromPrice.price} / ${pricingModel.fromPrice.periodType}`

						:	''}
					</Typography>
					<Typography>
						{businessPricing?.fromPrice?.price === 0 ?
							t('ui.free')
						:	businessPricing?.fromPrice?.price &&
							`${businessPricing?.fromPrice?.currencySymbol}${businessPricing?.fromPrice?.price} / ${businessPricing?.fromPrice?.periodType}`
						}
					</Typography>
				</Box>
			</Box>
			{isObject(wizardDetails.content) && isFullString(wizardDetails.content.value) ?
				<Accordion className={classes.panelBorder}>
					<AccordionSummary
						aria-controls='panel1a-content'
						className={classes.panelBorder}
						expandIcon={<ExpandMoreIcon htmlColor='#3f51b5' />}
						id='panel1a-header'
					>
						<Typography variant='h6'>{t('ui.label.description')}</Typography>
					</AccordionSummary>
					<AccordionDetails className={classes.expansionDetails}>
						<Typography variant='body2'>{wizardDetails.content.value}</Typography>
					</AccordionDetails>
				</Accordion>
			:	null}
			{itemStep !== 1 && itemStep !== 0 && (
				<Accordion className={classes.panelBorder}>
					<AccordionSummary
						aria-controls='panel1a-content'
						className={classes.panelBorder}
						expandIcon={<ExpandMoreIcon htmlColor='#3f51b5' />}
						id='panel1a-header'
					>
						<Typography variant='h6'>{t('ui.characteristics.title')}</Typography>
					</AccordionSummary>
					<AccordionDetails className={classes.expansionDetails}>
						<Box display='flex' flexDirection='column'>
							{(
								category &&
								wizardDetails.updatedCategoryObject.type === 'boats' &&
								characteristicsData
							) ?
								characteristicsData.boats.map((boat) =>
									!isUndefined(boatsCharacteristics[boat.name]) && boatsCharacteristics[boat.name] ?
										<Box alignItems='baseline' display='flex' key={boat.order} mb={1}>
											<Typography className={classes.panelSpacing} variant='h6'>
												{t(`ui.characteristics.${boat.name}`)}:
											</Typography>
											<Typography variant='body2'>
												{boat.type === 'enum' ?
													(
														isUndefined(boatsCharacteristics[boat.name]) ||
														isNull(boatsCharacteristics[boat.name])
													) ?
														''
													:	t(`ui.characteristics.${boatsCharacteristics[boat.name]}`)
												: boat.type === 'integer' ?
													boatsCharacteristics[boat.name]
												: boat.type === 'boolean' ?
													(
														isUndefined(boatsCharacteristics[boat.name]) ||
														!boatsCharacteristics[boat.name]
													) ?
														t('ui.no')
													:	t('ui.yes')
												:	''}{' '}
											</Typography>
										</Box>
									:	null,
								)
							:	null}
							{(
								category &&
								wizardDetails.updatedCategoryObject.type === 'parkingLots' &&
								characteristicsData
							) ?
								characteristicsData.parkingLots.map((parking) =>
									(
										!isUndefined(parkingLotsCharacteristics[parking.name]) &&
										parkingLotsCharacteristics[parking.name]
									) ?
										<Box alignItems='baseline' display='flex' key={parking.order} mb={1}>
											<Typography className={classes.panelSpacing} variant='h6'>
												{t(`ui.characteristics.${parking.name}`)}:
											</Typography>
											<Typography variant='body2'>
												{parking.type === 'enum' ?
													(
														isUndefined(parkingLotsCharacteristics[parking.name]) ||
														isNull(parkingLotsCharacteristics[parking.name])
													) ?
														''
													:	t(`ui.characteristics.${parkingLotsCharacteristics[parking.name]}`)
												: parking.type === 'integer' ?
													parkingLotsCharacteristics[parking.name]
												: parking.type === 'boolean' ?
													(
														isUndefined(parkingLotsCharacteristics[parking.name]) ||
														!parkingLotsCharacteristics[parking.name]
													) ?
														t('ui.no')
													:	t('ui.yes')
												:	''}{' '}
											</Typography>
										</Box>
									:	null,
								)
							:	null}
							{(
								category &&
								wizardDetails.updatedCategoryObject.type === 'trailers' &&
								characteristicsData
							) ?
								characteristicsData.trailers.map((trailer) =>
									(
										!isUndefined(trailerCharacteristics[trailer.name]) &&
										trailerCharacteristics[trailer.name]
									) ?
										<Box alignItems='baseline' display='flex' key={trailer.order} mb={1}>
											<Typography className={classes.panelSpacing} variant='h6'>
												{t(`ui.characteristics.${trailer.name}`)}:
											</Typography>
											<Typography variant='body2'>
												{trailer.type === 'enum' ?
													(
														isUndefined(trailerCharacteristics[trailer.name]) ||
														isNull(trailerCharacteristics[trailer.name])
													) ?
														''
													:	t(`ui.characteristics.${trailerCharacteristics[trailer.name]}`)
												: trailer.type === 'integer' ?
													trailerCharacteristics[trailer.name]
												: trailer.type === 'boolean' ?
													(
														isUndefined(trailerCharacteristics[trailer.name]) ||
														!trailerCharacteristics[trailer.name]
													) ?
														t('ui.no')
													:	t('ui.yes')
												:	''}{' '}
											</Typography>
										</Box>
									:	null,
								)
							:	null}
							{(
								category &&
								wizardDetails.updatedCategoryObject.type === 'cars' &&
								characteristicsData
							) ?
								characteristicsData.cars.map((cars) =>
									(
										cars.id !== 17 &&
										cars.id !== 18 &&
										cars.id !== 19 &&
										(carCharacteristics.propulsionType === 'electric' ? true : cars.id !== 4) &&
										(carCharacteristics.propulsionType === 'fuel' ? true : cars.id !== 3) &&
										!isUndefined(carCharacteristics[cars.name]) &&
										carCharacteristics[cars.name]
									) ?
										<Box alignItems='baseline' display='flex' key={cars.order} mb={1}>
											<Typography className={classes.panelSpacing} variant='h6'>
												{t(`ui.characteristics.${cars.name}`)}:
											</Typography>
											<Typography variant='body2'>
												{cars.type === 'enum' ?
													(
														isUndefined(carCharacteristics[cars.name]) ||
														isNull(carCharacteristics[cars.name]) ||
														isEmptyString(carCharacteristics[cars.name])
													) ?
														''
													:	t(`ui.characteristics.${carCharacteristics[cars.name]}`)
												: cars.type === 'integer' ?
													carCharacteristics[cars.name]
												: cars.type === 'boolean' ?
													(
														isUndefined(carCharacteristics[cars.name]) ||
														!carCharacteristics[cars.name]
													) ?
														t('ui.no')
													:	t('ui.yes')
												:	''}{' '}
											</Typography>
										</Box>
									:	null,
								)
							:	null}
							{(
								category &&
								wizardDetails.updatedCategoryObject.type === 'offices' &&
								characteristicsData
							) ?
								characteristicsData.offices.map((office) =>
									(
										!isUndefined(officeCharacteristics[office.name]) &&
										officeCharacteristics[office.name]
									) ?
										<Box alignItems='baseline' display='flex' key={office.order} mb={1}>
											<Typography className={classes.panelSpacing} variant='h6'>
												{t(`ui.characteristics.${office.name}`)}:
											</Typography>
											<Typography variant='body2'>
												{office.type === 'enum' ?
													(
														isUndefined(officeCharacteristics[office.name]) ||
														isNull(officeCharacteristics[office.name])
													) ?
														''
													:	t(`ui.characteristics.${officeCharacteristics[office.name]}`)
												: office.type === 'integer' ?
													officeCharacteristics[office.name]
												: office.type === 'boolean' ?
													(
														isUndefined(officeCharacteristics[office.name]) ||
														!officeCharacteristics[office.name]
													) ?
														t('ui.no')
													:	t('ui.yes')
												:	''}{' '}
											</Typography>
										</Box>
									:	null,
								)
							:	null}
							{(
								category &&
								wizardDetails.updatedCategoryObject.type === 'bikes' &&
								characteristicsData
							) ?
								characteristicsData.bikes.map((bike) =>
									(
										(bikeCharacteristics.bikePropulsionType !== 'noSupport' ?
											true
										:	bike.id !== 102) &&
										!isUndefined(bikeCharacteristics[bike.name]) &&
										bikeCharacteristics[bike.name]
									) ?
										<Box alignItems='baseline' display='flex' key={bike.order} mb={1}>
											<Typography className={classes.panelSpacing} variant='h6'>
												{t(`ui.characteristics.${bike.name}`)}:
											</Typography>
											<Typography variant='body2'>
												{bike.type === 'enum' ?
													(
														isUndefined(bikeCharacteristics[bike.name]) ||
														isNull(bikeCharacteristics[bike.name])
													) ?
														''
													:	t(`ui.characteristics.${bikeCharacteristics[bike.name]}`)
												: bike.type === 'integer' ?
													bikeCharacteristics[bike.name]
												: bike.type === 'boolean' ?
													(
														isUndefined(bikeCharacteristics[bike.name]) ||
														!bikeCharacteristics[bike.name]
													) ?
														t('ui.no')
													:	t('ui.yes')
												:	''}{' '}
											</Typography>
										</Box>
									:	null,
								)
							:	null}
						</Box>
					</AccordionDetails>
				</Accordion>
			)}
			{itemStep !== 0 && itemStep !== 1 && itemStep !== 2 && (
				<Accordion className={classes.panelBorder}>
					<AccordionSummary
						aria-controls='panel1a-content'
						className={classes.panelBorder}
						expandIcon={<ExpandMoreIcon htmlColor='#3f51b5' />}
						id='panel1a-header'
					>
						<Typography variant='h6'>{t('ui.category.itemPolicies')}</Typography>
					</AccordionSummary>
					<AccordionDetails className={classes.expansionDetails}>
						<Box display='flex' flexDirection='column'>
							<Box alignItems='baseline' display='flex' mb={1}>
								<Typography className={classes.panelSpacing} variant='h6'>
									{t('views.addItem.steps.sidePanelDetails.settings.terms')}:
								</Typography>
								<Typography className={classes.panelSpacing} variant='body2'>
									{isObject(wizardDetails.updatedTerms) ? wizardDetails.updatedTerms.title : ''}
								</Typography>
							</Box>
							{wizardDetails.updatedReservationType !== 'nonBookable' && (
								<>
									<Box alignItems='baseline' display='flex' mb={1}>
										<Typography className={classes.panelSpacing} variant='h6'>
											{t('ui.pricing')}:
										</Typography>
										<Typography className={classes.panelSpacing} variant='body2'>
											{isObject(pricingModel) ? pricingModel.name : ''}
										</Typography>
									</Box>
									<Box alignItems='baseline' display='flex' mb={1}>
										<Typography className={classes.panelSpacing} variant='h6'>
											{t('views.addItem.settings.subsection.businessPricing.title')}:
										</Typography>
										<Typography className={classes.panelSpacing} variant='body2'>
											{isObject(wizardDetails.updatedBusinessPricing) ?
												wizardDetails.updatedBusinessPricing.name
											:	''}
										</Typography>
									</Box>
									<Box alignItems='baseline' display='flex' mb={1}>
										<Typography className={classes.panelSpacing} variant='h6'>
											{t('views.addItem.settings.subsection.policy.title')}:
										</Typography>
										<Typography className={classes.panelSpacing} variant='body2'>
											{isObject(wizardDetails.updatedPolicyModel) ?
												wizardDetails.updatedPolicyModel.name
											:	''}
										</Typography>
									</Box>
								</>
							)}
							<Box alignItems='baseline' display='flex' mb={1}>
								<Typography className={classes.panelSpacing} variant='h6'>
									{t('views.addItem.settings.subsection.serviceProvider.title')}:
								</Typography>
								<Typography className={classes.panelSpacing} variant='body2'>
									{provider}
								</Typography>
							</Box>
						</Box>
					</AccordionDetails>
				</Accordion>
			)}
			{isObject(wizardDetails.updatedHub) && !isEmptyObject(wizardDetails.updatedHub) ?
				<Accordion className={classes.panelBorder}>
					<AccordionSummary
						aria-controls='panel1a-content'
						className={classes.panelBorder}
						expandIcon={<ExpandMoreIcon htmlColor='#3f51b5' />}
						id='panel1a-header'
					>
						<Typography variant='h6'>
							{t('views.addItem.settingsCard.locationCard.header')}
						</Typography>
					</AccordionSummary>
					<AccordionDetails className={classes.expansionDetails}>
						<Box display='flex' flexDirection='row' mb={3}>
							<Box className={classes.imageItem} flexGrow='1'>
								{imageReady ?
									<img
										className={clsx({ [classes.imageItem]: true, [classes.imageItem]: itemImages })}
										src={locationImageData[0]}
									/>
								:	<img
										className={clsx({ [classes.imageItem]: true, [classes.imageItem]: itemImages })}
										src={Placeholder}
									/>
								}
							</Box>
							<Box ml={2}>
								<Typography className={classes.panelSpacing} variant='h6'>
									{wizardDetails.updatedHub.name}
								</Typography>
								<Typography className={classes.panelSpacing} variant='body2'>
									{`${!isUndefined(wizardDetails.updatedHub.address.street) ? wizardDetails.updatedHub.address.street : ''} ${!isUndefined(wizardDetails.updatedHub.address.number) ? wizardDetails.updatedHub.address.number : ''}, ${wizardDetails.updatedHub.address.city}`}
								</Typography>
							</Box>
						</Box>
					</AccordionDetails>
				</Accordion>
			:	null}
		</SidePanel>
	);
};

SidePanelDetails.propTypes = {
	wizardDetails: PropTypes.object,
	itemImages: PropTypes.array,

	itemStep: PropTypes.number,
	onFetchLocationImage: PropTypes.func,
	characteristics: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	fetchLocationImage: PropTypes.shape({
		data: PropTypes.array,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	currentUser: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
};

const mapStateToProps = (state) => {
	return {
		characteristics: state.details.characteristics,
		fetchLocationImage: state.list.fetchLocationImage,
		currentUser: state.details.currentUser,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchLocationImage: (locationId) => dispatch(actions.fetchLocationImage(locationId)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SidePanelDetails);
