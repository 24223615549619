import {
	Paper,
	ToggleButtonGroup as MuiToggleButtonGroup,
	ToggleButtonGroupProps,
	toggleButtonGroupClasses,
} from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledMuiToggleButtonGroup = styled(MuiToggleButtonGroup)(({ theme }) => ({
	gridGap: '5px',
	[`& .${toggleButtonGroupClasses.grouped}`]: {
		border: 0,
		// margin: theme.spacing(0.5),
		borderRadius: theme.shape.borderRadius,
		[`&.${toggleButtonGroupClasses.selected}`]: {
			color: 'white',
			backgroundColor: '#15263E',
			'&:hover': {
				backgroundColor: '#1F3A5B',
			},
		},
	},
}));

/**
 * Wrapper aroun
 */
const ToggleButtonGroup = ({ ...props }: ToggleButtonGroupProps) => {
	return (
		<Paper
			elevation={1}
			sx={{
				display: 'flex',
				flexWrap: 'wrap',
				padding: 0.5,
				width: 'fit-content',
			}}
		>
			<StyledMuiToggleButtonGroup {...props} />
		</Paper>
	);
};

export default ToggleButtonGroup;
