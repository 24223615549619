/**
 * As quoted from the backend:
 * - The status of the license verification (verifying the user images by superadmin) process
 */
enum UserLicenseVerificationStatusEnum {
	NotApplicable = 'notApplicable',
	Pending = 'pending',
	Verified = 'verified',
	Rejected = 'rejected',
	// What does blocked mean for a verification status?
	Blocked = 'blocked',
	Expired = 'expired',
}

export default UserLicenseVerificationStatusEnum;
