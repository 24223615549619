import { useEffect, useState } from 'react';

import { Box, Link } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { useAuthorize } from '~features/authentication';

import { useStyles } from './style';
import { Table, Label } from '../../../../../components';
import {
	isObject,
	decimalAmount,
	getFinanceTableHeaders,
} from '../../../../../shared/utility';
import * as actions from '../../../../../store/actions';

const localizeTime = (value) => {
	const date = new Date(value);

	return `${date.getUTCDate()}-${date.getUTCMonth() + 1}-${date.getUTCFullYear()}`;
};

const FinanceCurrent = (props) => {
	const { date, title, name, id, onFetchPersonalRevenue, personalRevenue, setMonthlyTotal } = props;
	const { t } = useTranslation('general');
	const { isSuperAdmin } = useAuthorize();

	const classes = useStyles();
	const [shouldDoInitialFetch, setShouldDoInitialFetch] = useState(true);

	const { data: personalRevenueData, loading: personalRevenueLoading } = personalRevenue;

	useEffect(() => {
		if (shouldDoInitialFetch && !personalRevenueLoading) {
			onFetchPersonalRevenue(date, isSuperAdmin() ? id : null);
			setShouldDoInitialFetch(false);
		}
	}, [personalRevenueLoading]);

	useEffect(() => {
		if (isObject(personalRevenueData) && !personalRevenueLoading) {
			setMonthlyTotal(personalRevenueData.amount.subtotal - personalRevenueData.amount.discount);
		}
	}, [personalRevenueLoading]);

	const tableHeader = getFinanceTableHeaders();

	const statusColor = {
		open: 'default',
		paid: 'success',
	};

	const statusText = {
		open: t('ui.open'),
		paid: t('ui.label.paid'),
	};

	const tableBody =
		isObject(personalRevenueData) && !personalRevenueLoading ?
			personalRevenueData.billings.map((item) => [
				{
					content: (
						<Box className={classes.cell}>
							<Link
								className={classes.link}
								color='inherit'
								component={RouterLink}
								to={`/financeManagement/revenue/${item.start}/${title}/partner/${id}/${name}/parsonal`}
								variant='h6'
							>
								{`${localizeTime(item.start)} ${t('ui.till')} ${localizeTime(item.end)}`}
							</Link>
						</Box>
					),
				},
				{ content: decimalAmount(item.amount.subtotal, item.amount) },
				{ content: decimalAmount(item.amount.discount, item.amount) },
				{ content: decimalAmount(item.amount.subtotal - item.amount.discount, item.amount) },
				{ content: decimalAmount(item.amount.priceVat, item.amount) },
				{ content: decimalAmount(item.amount.refund, item.amount) },
				{ content: decimalAmount(item.amount.totalPrice, item.amount) },
				{ content: decimalAmount(item.open, item.amount) },
				{
					content: (
						<Box>
							<Label type={statusColor[item.status]}>{statusText[item.status]}</Label>
						</Box>
					),
				},
			])
		:	Array(2)
				.fill(Array(tableHeader.length).fill())
				.map((arr) => arr.map(() => ({ loading: true })));

	const titleWithInformation =
		isObject(personalRevenueData) ?
			{
				header: t('ui.label.personalFinance'),
				sideHeader: decimalAmount(
					personalRevenueData.amount.subtotal - personalRevenueData.amount.discount,
					personalRevenueData.amount,
				),
			}
		:	null;

	return (
		<div className={classes.root}>
			<Table
				body={tableBody}
				cellStyle={classes.cellStyle}
				header={tableHeader}
				headerLabel={t('ui.label.finance.totaExclVat')}
				isNotPaginate={true}
				loading={personalRevenueLoading}
				titleWithInformation={titleWithInformation}
				withoutPages={true}
			/>
		</div>
	);
};

FinanceCurrent.propTypes = {
	financeType: PropTypes.string,
	date: PropTypes.string,
	title: PropTypes.string,
	name: PropTypes.string,
	id: PropTypes.string,
	currentFinanceEvents: PropTypes.object,

	onFetchCurrentRevenue: PropTypes.func,
	onFetchPersonalRevenue: PropTypes.func,
	personalRevenue: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),

	setMonthlyTotal: PropTypes.func,
};

const mapStateToProps = (state) => {
	return {
		personalRevenue: state.details.personalRevenue,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchPersonalRevenue: (date, id) => dispatch(actions.fetchPersonalRevenue(date, id)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(FinanceCurrent);
