import { CloudDownload as CloudDownloadIcon } from '@mui/icons-material';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useAtom, useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import { Navigate, Outlet, RouteObject } from 'react-router-dom';

import { organisationAtom, userInfoAtom } from '~atoms';
import { pagePathSegments } from '~constants';
import { UserRoleEnum } from '~enums';
import { ProtectedRoute, useAuthorize } from '~features/authentication';
import { OrganisationAutocomplete } from '~features/organisations';
import { OverviewStatisticsLayout } from '~features/statistics';
import { PageLayout } from '~layouts';
import i18n from '~lib/i18n';
import { BookingStatisticsPage, UserLicenseStatisticsPage } from '~pages';
import RevenueStatisticsPage from '~pages/statistics/revenueStatisticsPage';

const isHigherThanAcceptance =
	import.meta.env.VITE_APP_ENV === 'accept' || import.meta.env.VITE_APP_ENV === 'production';

const TestFilterUrlSyncComp = () => {
	const { t } = useTranslation('general');
	const { isSuperAdmin } = useAuthorize();
	const [organisation, setOrganisation] = useAtom(organisationAtom);

	return (
		<Stack
			direction='row'
			spacing={1}
			sx={{ justifyContent: 'space-between', alignItems: 'center' }}
		>
			<Stack direction='row' spacing={3} sx={{ alignItems: 'center' }}>
				<Typography variant='h3'>Data</Typography>
				{isSuperAdmin() && (
					<OrganisationAutocomplete
						value={organisation}
						onChange={(_, newValue) => setOrganisation(newValue)}
						sx={{ width: 250 }}
					/>
				)}
			</Stack>
		</Stack>
	);
};

const statisticsRoutes = (): RouteObject[] => [
	{
		path: pagePathSegments.Data,
		element: <TempNonSkopeiAdminRedirect />,
		children: [
			{
				path: '',
				element: (
					<Box>
						<Stack spacing={3} sx={{ px: 3, pt: 3 }}>
							<TestFilterUrlSyncComp />
							<OverviewStatisticsLayout />
						</Stack>
						<PageLayout
							pages={[
								{ label: i18n.t('ui.category.bookings'), path: pagePathSegments.Bookings },
								{ label: i18n.t('nav.financialReports.revenue'), path: pagePathSegments.Revenue },
								// { label: i18n.t('tickets'), path: pagePathSegments.ServiceTickets },
								{
									label: i18n.t('nav.category.licenses'),
									path: pagePathSegments.Verifications,
									roles: [UserRoleEnum.SuperAdmin],
								},
							]}
						/>
					</Box>
				),
				children: [
					{
						index: true,
						element: <Navigate to={pagePathSegments.Bookings} />,
					},
					{
						path: pagePathSegments.Bookings,
						element: <BookingStatisticsPage />,
					},
					{
						path: pagePathSegments.Revenue,
						element: <RevenueStatisticsPage />,
					},
					{
						path: pagePathSegments.ServiceTickets,
						element: <div>ServiceTickets</div>,
					},
					{
						path: pagePathSegments.Verifications,
						element: <ProtectedRoute roles={[UserRoleEnum.SuperAdmin]} />,
						children: [
							{
								index: true,
								element: <UserLicenseStatisticsPage />,
							},
						],
					},
				],
			},
		],
	},
];

/**
 * Introduced to scope access to superadmins and skopei on production.
 * Should be remove ASAP (created: 2024-10-25)
 * @returns
 */
const TempNonSkopeiAdminRedirect = () => {
	const userInfo = useAtomValue(userInfoAtom);
	const { isSuperAdmin } = useAuthorize();

	const enableAccess =
		!isHigherThanAcceptance ||
		(isHigherThanAcceptance && (isSuperAdmin() || userInfo.organisation?.id == '2'));

	if (!enableAccess) {
		return <Navigate to={`/${pagePathSegments.Dashboard}`} />;
	}

	return <Outlet />;
};

export default statisticsRoutes;
