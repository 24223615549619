import { BaseReference } from '~interfaces';
import { BaseReferenceResponse, IdReferenceResponse } from '~interfaces/responses';

/**
 * A base service class to e.g. give the ability to cancel requests
 */
abstract class BaseService {
	protected _controller = new AbortController();

	/**
	 * Abort the current get requests.
	 * TODO: should also do this for the others (post, put etc)?
	 * @param reason The reason for the abort
	 */
	public abortCurrentRequest(reason?: string) {
		this._controller.abort(reason);
		this._controller = new AbortController();
	}

	static fromIdReferenceResponse<T extends IdReferenceResponse | undefined>(
		response: T
	): T extends undefined ? undefined : T extends IdReferenceResponse ? BaseReference : never {
		if (response === undefined) {
			return undefined;
		}

		return {
			id: response.id.toString(),
		};
	}

	static fromBaseReferenceResponse<T extends BaseReferenceResponse | undefined>(
		response: T
	): T extends undefined ? undefined : T extends BaseReferenceResponse ? BaseReference : never {
		if (response === undefined) {
			return undefined;
		}
		
		return {
			label: response.name,
			id: response.id.toString(),
		};
	}
}

export default BaseService;
