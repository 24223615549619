import { MenuItem, SelectProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useSWRImmutable from 'swr/immutable';

import { AsyncSelect, Select } from '~components';
import { ItemCategoryEnum } from '~enums';
import i18n from '~lib/i18n';

import ItemCategory from '../../interfaces/itemCategory';
import ItemCategoryService from '../../services/itemCategoryService';
import { useAtomValue } from 'jotai';
import { userInfoAtom } from '~atoms';

type ItemCategorySelectProps = {
	value?: ItemCategory | null
} & SelectProps;

const service = new ItemCategoryService();

const ItemCategorySelect = ({
	// label = i18n.t('ui.label.category'),
	value = null,
	...props
}: ItemCategorySelectProps) => {
	const { t } = useTranslation('general');
	const userInfo = useAtomValue(userInfoAtom);

	const { data, isLoading } = useSWRImmutable(
		[service.basePath, userInfo?.preferences.preferredCultureInfo],
		([_, args]) => service.getCategories(args ?? 'en-US')
	);

	return (
		<AsyncSelect
			{...props}
			clearable
			allLabel={t('allResources', { resource: t('categories') })}
			// label={label}
			loading={isLoading}
			value={value}
			options={data ?? []}
		/>
	);
};

export default ItemCategorySelect;
