import { useState, useEffect } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import {
	CircularProgress,
	Card,
	CardContent,
	Avatar,
	Typography,
	IconButton,
	Slider,
	TextField,
	MenuItem,
} from '@mui/material';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { useAuthorize } from '~features/authentication';

import { useStyles } from './style';
import {
	ActionDialog,
	Box,
	FormField,
	SearchAutocomplete,
	LoadingBar,
} from '../../../../components';
import { useWizardFormField } from '../../../../shared/hooks';
import {
	isFullArray,
	getInitials,
	isNull,
	isObject,
	isFullString,
	isUndefined,
	isFunction,
	isEmptyObject,
	isInteger,
} from '../../../../shared/utility';
import * as actions from '../../../../store/actions';

function valuetext(value) {
	return `${value}°C`;
}

const TripEdit = (props) => {
	const {
		openDialog,
		setOpenDialog,
		tripEditData,
		setShouldSort,
		setTripEditData,
		onFetchUsers,
		userObject,
		onPatchTrip,
		patchTrip,

		className,
		usersList,
		onResetStateCondition,
		onResetState,
	} = props;
	const { t } = useTranslation('general');
	const { isSuperAdmin } = useAuthorize();

	const { data: userData } = userObject;

	const { success: patchTripSuccess, loading: patchTripLoading, error: patchTripError } = patchTrip;

	const [user, setUser] = useState(null);

	const [selectedUsageType, setSelectedUsageType] = useState(tripEditData?.usageType);

	const [distancePercentage, setDistancePercentage] = useState(
		tripEditData?.distancePercentage || tripEditData?.distancePercentage === 0 ?
			tripEditData?.distancePercentage
		:	100,
	);

	const bookingId = useWizardFormField('', { isNumeric: true });

	const classes = useStyles();

	const { enqueueSnackbar } = useSnackbar();

	const extraFilter = { name: 'hideAnonymous', value: true };

	const currentValue = distancePercentage;
	const businessPercent = currentValue;
	const privatePercent = 100.0 - businessPercent;
	const getUpdatedDistance = (distance, percent) => distance * (percent / 100);
	const privateDistance = getUpdatedDistance(tripEditData?.distance, privatePercent);
	const businessDistance = getUpdatedDistance(tripEditData?.distance, businessPercent);

	useEffect(() => {
		if (patchTripSuccess) {
			handleCloseTrip();
			enqueueSnackbar(t('views.devices.trip.editTrip.success'), { variant: 'success' });
			onResetStateCondition('patchTrip', false);
			if (isFunction(setShouldSort)) {
				setShouldSort(true);
			}
		} else if (patchTripError) {
			enqueueSnackbar(
				patchTripError.UpdateTrip ? patchTripError.UpdateTrip.map((error) => error) : 'Error',
				{ variant: 'error' },
			);
			onResetStateCondition('patchTrip', false);
		}
	}, [patchTrip, patchTripError]);

	useEffect(() => {
		if (isObject(userData)) {
			setUser({
				lastName: userData.lastName,
				firstName: userData.firstName,
				email: userData.emailAddress,
				image: userData.imagesReference,
				id: userData.id,
			});
		}
	}, [userData]);

	useEffect(() => {
		if (tripEditData?.bookingId) {
			bookingId.setValue(tripEditData.bookingId);
		}
	}, [tripEditData]);

	const setSelectedUser = (user) => {
		setUser({
			lastName: user.lastName,
			firstName: user.firstName,
			email: user.emailAddress,
			image: user.imagesReference,
			id: user.id,
		});
	};

	const handleClearUser = () => setUser(null);

	const handleChange = (event, newValue) => setDistancePercentage(newValue);

	const editUser =
		isObject(user) && (user.id !== tripEditData?.userId || isUndefined(tripEditData?.userId));
	const booking =
		(isFullString(bookingId.value) &&
			tripEditData?.bookingId &&
			`${tripEditData?.bookingId}` !== bookingId.value) ||
		(!tripEditData?.bookingId && isFullString(bookingId.value));
	const removeBooking = tripEditData?.bookingId && !isInteger(bookingId.intValue);

	const patchTripBody = {
		...((editUser || (isNull(user) && tripEditData?.userId)) && {
			userId: isNull(user) && tripEditData?.userId ? null : user.id,
		}),
		...((booking || removeBooking) && {
			bookingId: removeBooking ? null : parseInt(bookingId.value, 10),
		}),
		...(distancePercentage !== tripEditData.distancePercentage && {
			distancePercentage: distancePercentage,
		}),
		...(tripEditData?.usageType !== selectedUsageType && { usageType: selectedUsageType }),
	};

	const handleEditTrip = () => onPatchTrip(tripEditData.deviceId, tripEditData.id, patchTripBody);

	const handleCloseTrip = () => {
		setOpenDialog(false);
		handleClearUser();
		onResetState('user');
		setTripEditData(null);
		setDistancePercentage(100);
		bookingId.setValue('');
	};

	const handleUsageTypeChange = (event) => {
		setSelectedUsageType(event.target.value);
	};

	const usageTypes = [
		{ key: 1, value: 'business' },
		{ key: 2, value: 'personal' },
		{ key: 3, value: 'commute' },
	];

	const isValidCommute = distancePercentage === 0 && selectedUsageType === 'commute';

	const isValidPersonal = distancePercentage === 100 && selectedUsageType === 'personal';

	const isValidBusiness = distancePercentage === 0 && selectedUsageType === 'business';

	const handleHelperText = () => {
		let helperText = false;
		if (isValidCommute) {
			helperText = 'edit.trip.error.helperText.commute';
		} else if (isValidPersonal) {
			helperText = 'edit.trip.error.helperText.personal';
		} else if (isValidBusiness) {
			helperText = 'edit.trip.error.helperText.business';
		} else {
			return true;
		}
		return t(helperText);
	};

	return (
		<>
			<ActionDialog
				actionButtonProps={{
					action: handleEditTrip,
					text: patchTripLoading ? <CircularProgress disableShrink size={24} /> : t('ui.add'),
				}}
				handleClose={handleCloseTrip}
				loading={
					isEmptyObject(patchTripBody) ||
					patchTripLoading ||
					isValidCommute ||
					isValidPersonal ||
					isValidBusiness
				}
				open={openDialog}
				title={t('views.devices.trip.editTrip.label.edit')}
			>
				<Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 2.5 }}>
					{isSuperAdmin() && (
						<>
							<Box maxHeight='65px' minHeight='65px'>
								{user ?
									<Card className={clsx(classes.userCard, className)}>
										<CardContent className={classes.content}>
											<Box mr={1.5}>
												{isFullArray(user.image) ?
													<Avatar src={user.image[0]} />
												:	<Avatar>{getInitials(`${user.firstName} ${user.lastName}`)}</Avatar>}
											</Box>
											<Box>
												<Typography variant='h5'>
													{isNull(user) ?
														<LoadingBar />
													:	`${user.firstName} ${user.lastName}`}
												</Typography>
												<Typography className={classes.body3}>
													{isNull(user) ?
														<LoadingBar />
													:	user.email}
												</Typography>
											</Box>
										</CardContent>
										<IconButton
											className={classes.closeButton}
											onClick={handleClearUser}
											size='small'
										>
											<CloseIcon />
										</IconButton>
									</Card>
								:	<SearchAutocomplete
										dataList={usersList}
										emptyStateText={t('views.planboard.addBooking.emptyResultsText.users')}
										extraFilter={extraFilter}
										icon={true}
										label={t('ui.category.users')}
										listType={'users'}
										onFetchData={onFetchUsers}
										placeholder={t('ui.placeholders.search.user')}
										popperSize='large'
										setSelected={setSelectedUser}
									/>
								}
							</Box>
							<FormField
								label={t('views.devices.trip.editTrip.label.bookingID')}
								name='bookingId'
								variable={bookingId}
							/>
						</>
					)}
					<TextField
						FormHelperTextProps={{
							style: { position: 'absolute', top: '100%', marginLeft: '20px' },
						}}
						InputLabelProps={{ shrink: true }}
						error={isValidCommute || isValidPersonal || isValidBusiness}
						fullWidth={true}
						helperText={handleHelperText()}
						label={t('views.addItem.bookingType.title')}
						onChange={handleUsageTypeChange}
						required
						select
						size='medium'
						value={selectedUsageType || ''}
						variant='outlined'
					>
						{usageTypes.map((usage) => (
							<MenuItem key={usage.key} value={usage.value}>
								{' '}
								{t(`ui.label.select.${usage.value}`)}
							</MenuItem>
						))}
					</TextField>
					<Box display='flex'>
						<Typography>{`${t('ui.label.carDistance')}:`}</Typography>
						<Typography ml={1}>{`${tripEditData?.distance}km`}</Typography>
					</Box>
					<Box display='flex' justifyContent='space-between'>
						<Box>
							<Typography>{t('ui.label.privateUse')}</Typography>
							<Typography>{`${tripEditData?.distance === privateDistance ? tripEditData?.distance : privateDistance.toFixed(2)}km`}</Typography>
						</Box>
						<Box>
							<Typography>
								{selectedUsageType === 'commute' ?
									t('ui.label.select.commute')
								:	t('ui.label.businessUse')}
							</Typography>
							<Typography>{`${tripEditData?.distance === businessDistance ? tripEditData?.distance : businessDistance.toFixed(2)}km`}</Typography>
						</Box>
					</Box>
					<Box ml={3} mr={3.5}>
						<Slider
							getAriaValueText={valuetext}
							onChange={handleChange}
							track={false}
							value={distancePercentage}
							valueLabelDisplay='auto'
							valueLabelFormat={(val) => `${val}%`}
						/>
					</Box>
				</Box>
			</ActionDialog>
		</>
	);
};

TripEdit.propTypes = {
	openDialog: PropTypes.bool,
	setOpenDialog: PropTypes.func,
	tripEditData: PropTypes.object,
	setShouldSort: PropTypes.func,
	setTripEditData: PropTypes.func,
	className: PropTypes.string,

	onFetchUsers: PropTypes.func,
	onPatchTrip: PropTypes.func,
	onResetStateCondition: PropTypes.func,
	onResetState: PropTypes.func,

	patchTrip: PropTypes.shape({
		success: PropTypes.bool,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	userObject: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	usersList: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
};

const mapStateToProps = (state) => {
	return {
		usersList: state.paged.users,
		patchTrip: state.condition.patchTrip,
		userObject: state.details.user,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchUsers: (page, filters, concat) =>
			dispatch(actions.fetchUsers(page, filters, concat, concat)),
		onPatchTrip: (deviceId, tripId, properties) =>
			dispatch(actions.patchTrip(deviceId, tripId, properties)),
		onResetStateCondition: (state, value) => dispatch(actions.resetStateCondition(state, value)),
		onResetState: (state) => dispatch(actions.resetState(state)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(TripEdit);
