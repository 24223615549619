import { useState, useEffect } from 'react';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
	Tabs,
	Tab,
	Divider,
	Box,
	Typography,
	AccordionDetails,
	CircularProgress,
} from '@mui/material';
import clsx from 'clsx';
import { useAtomValue } from 'jotai';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';

import { userInfoAtom } from '~atoms';
import { useAuthorize } from '~features/authentication';

import { AccessDevices } from './AccessDevices';
import Activities from './Activities';
import Header from './Header';
import ItemInstances from './ItemInstances';
import { useStyles } from './style';
import Summary from './Summary/Summary';
import { Page, SidePanel, StyledButton } from '../../../../components';
import { categories } from '../../../../constantsOld';
import { useErrorAcceptedRejected } from '../../../../shared/hooks';
import { isObject, isBoolean, isFullArray, isNull } from '../../../../shared/utility';
import * as actions from '../../../../store/actions';
import AddInstance from '../../ActionFlows/AddItem/AddInstance';
import { Accordion, AccordionSummary } from '../../ActionFlows/AddItem/style';

const ItemDetails = (props) => {
	const {
		className,
		itemDetails,
		onFetchItem,
		onFetchOrganisation,
		organisation,

		fetchPrice,
		onFetchPrice,
		addInstances,
		onAddInstances,

		onResetState,
		onBusinessPricing,
		businessPricing,
	} = props;

	const { t } = useTranslation('general');
	const navigate = useNavigate();
	const location = useLocation();
	const classes = useStyles();
	const userInfo = useAtomValue(userInfoAtom);
	const { isSuperAdmin } = useAuthorize();

	const { id, tab } = useParams();

	const [showAddInstance, setShowAddInstance] = useState(false);

	const [instances, setInstances] = useState([]);

	const [, setItemInstanceValid] = useState(false);
	const showBottomPanel = true;
	const {
		data: itemDetailsData,
		loading: itemDetailsLoading,
		error: itemDetailsError,
	} = itemDetails;
	const itemDetailsReady = isObject(itemDetailsData) && !itemDetailsLoading && !itemDetailsError;
	const priceId =
		(
			isObject(itemDetailsData) &&
			`${itemDetailsData.id}` === id &&
			isObject(itemDetailsData.pricingModelReference)
		) ?
			itemDetailsData.pricingModelReference.id
		:	null;
	const businessPricingId =
		(
			isObject(itemDetailsData) &&
			`${itemDetailsData.id}` === id &&
			isObject(itemDetailsData.businessPricingModelReference)
		) ?
			itemDetailsData.businessPricingModelReference.id
		:	null;

	const {
		data: organisationData,
		loading: organisationLoading,
		error: organisationError,
	} = organisation;
	const orgDetailsReady = isObject(organisationData) && !organisationLoading && !organisationError;

	const isAbleToAddItemInstances =
		orgDetailsReady &&
		organisationData.subscription &&
		organisationData.totalBookableItemInstances + instances.length <=
			organisationData.subscription.maxItemInstances;

	const isOwnItem =
		itemDetailsReady &&
		userInfo.organisation.label === itemDetailsData.hubReference.organisationReference.name;

	const [isSupplier, setIsSupplier] = useState(null);
	const [shouldSubmitInstances, setShouldSubmitInstances] = useState(false);
	const [submittingInstances, setSubmittingInstances] = useState(false);

	const [addedInstances, setAddedInstances] = useState([]);

	const { data: addInstancesData, loading: addInstancesLoading } = addInstances;

	const { data: addedInstance, error: addedInstanceError } = props.addedInstance;

	const { data: fetchPriceData, loading: fetchPriceLoading, error: fetchPriceError } = fetchPrice;
	const fetchPriceReady = isObject(fetchPriceData) && !fetchPriceLoading && !fetchPriceError;
	const priceData = fetchPriceReady && isObject(fetchPriceData) ? fetchPriceData : null;

	const { data: businessPricingData, loading: businessPricingLoading } = businessPricing;

	const selectedCategory =
		isObject(itemDetailsData) &&
		isObject(itemDetailsData.categoryReference) &&
		itemDetailsData.categoryReference.id;
	const organisationDataId = orgDetailsReady && organisationData.id;
	const selectedHub = itemDetailsReady && itemDetailsData.hubReference;

	const loadingDetails =
		(isBoolean(isSupplier) &&
			itemDetailsReady &&
			orgDetailsReady &&
			!organisationLoading &&
			!itemDetailsLoading) ||
		(itemDetailsReady && orgDetailsReady && itemDetailsData?.reservationType === 'nonBookable');

	const isProvider =
		userInfo.organisation.id === itemDetailsData?.hubReference?.organisationReference.id;

	const addedInstanceErrorMessage = useErrorAcceptedRejected({
		value: addInstances,
		message: { positive: t('views.addItem.message.success.addItemInstance'), negative: 'Error' },
	});

	const handleTabsChange = (event, value) =>
		navigate(`../${value}`, { relative: 'path', state: { ...location.state } });

	useEffect(() => {
		if (`${id}` !== `${itemDetailsData?.id}`) {
			onResetState('itemDetails');
		}
	}, []);

	useEffect(() => {
		if (!isNull(priceId) && !fetchPriceLoading) {
			onFetchPrice(priceId);
		}
	}, [priceId]);

	useEffect(() => {
		if (!isNull(businessPricingId) && !businessPricingLoading) {
			onBusinessPricing(businessPricingId);
		}
	}, [businessPricingId]);

	useEffect(() => {
		if (isObject(itemDetailsData)) {
			setIsSupplier(isProvider);
		} else {
			setIsSupplier(null);
		}
	}, [itemDetailsData]);

	useEffect(() => {
		if (!itemDetailsLoading) {
			onFetchItem(id);
		}
	}, [id]);

	useEffect(() => {
		if (itemDetailsReady && !orgDetailsReady) {
			onFetchOrganisation(itemDetailsData.hubReference.organisationReference.id);
		}
	}, [itemDetailsReady, orgDetailsReady]);

	const tabs = [
		{ value: 'summary', label: t('ui.summary') },
		{ value: 'itemInstances', label: t('ui.category.itemInstances') },
		...(isProvider || isSuperAdmin() ?
			[
				{ value: 'activity', label: t('ui.activity') },
				{ value: 'accessDevices', label: t('ui.tab.accessDevices') },
			]
		:	[]),
	];

	const headerButtons = [
		{ key: 'summary' },
		{ key: 'itemInstances' },
		{ key: 'activity' },
		{ key: 'accessDevices' },
	];

	const addItemInstanceLocally = (itemInstanceFields) => {
		if (isFullArray(itemInstanceFields)) {
			setInstances([...instances, itemInstanceFields]);
		}
	};

	//clear item instances when coming back from 'add instances' page.
	useEffect(() => {
		if (!showAddInstance && isFullArray(instances)) {
			setInstances([]);
		}
	}, [showAddInstance]);

	const callback = (data) => {
		const { updatedItemInstanceValid } = data;
		if (isBoolean(updatedItemInstanceValid)) {
			setItemInstanceValid(updatedItemInstanceValid);
		}
	};

	const handleCancel = () => setShowAddInstance(false);

	const handleConfirm = () => setShouldSubmitInstances(true);

	const handleCheckFieldUnique = (fieldName, value) => {
		let isUniqueField = true;
		instances.forEach((instance) => {
			instance.forEach((field) => {
				if (field.name === fieldName && field.value.value.toLowerCase() === value.toLowerCase()) {
					isUniqueField = false;
				}
			});
		});
		return isUniqueField;
	};

	const category = [
		{ selected: selectedCategory === categories.BIKES, name: 'bike' },
		{ selected: selectedCategory === categories.OFFICES, name: 'office' },
		{ selected: selectedCategory === categories.CARS, name: 'car' },
		{ selected: selectedCategory === categories.TRAILERS, name: 'trailer' },
		{ selected: selectedCategory === categories.PARKINGLOTS, name: 'parkingLot' },
		{ selected: selectedCategory === categories.ASSETS, name: 'asset' },
		{ selected: selectedCategory === categories.BOATS, name: 'boat' },
	];

	const instanceCategory = category.find((item) => item.selected);

	const instancesBody = instances.map((item) => {
		const hubId = item.find((hub) => hub.name === 'itemInstanceLocation');
		return {
			hubId: hubId.value.id,
			isPublic: false,
			[instanceCategory.name]: item.reduce(
				(instance, field) => ({
					...instance,
					...(field.name !== 'itemInstanceLocation' && { [field.name]: field.value.value }),
				}),
				{},
			),
		};
	});

	useEffect(() => {
		if (shouldSubmitInstances && !submittingInstances) {
			setShouldSubmitInstances(false);
			setSubmittingInstances(true);
			onAddInstances(itemDetailsData.id, { instances: instancesBody });
			addedInstanceErrorMessage.setStartAction(true);
		}
	}, [shouldSubmitInstances]);

	useEffect(() => {
		if (addedInstance && submittingInstances) {
			setAddedInstances([...addedInstances, { ...addedInstance }]);
		}
	}, [addedInstance, submittingInstances]);

	useEffect(() => {
		if (!addInstancesLoading && addInstancesData?.accepted) {
			setSubmittingInstances(false);
			setInstances([]);
			setShowAddInstance(false);
		}
	}, [addInstancesData]);

	// TODO: decide what should be shown if part of the instance have been added when something goes
	// wrong.

	// Temporary solution:
	useEffect(() => {
		if (addedInstanceError) {
			setSubmittingInstances(false);
			setInstances(instances.slice(addedInstances.length));
		}
	}, addedInstanceError);

	const handleRemoveInstance = (instanceId) =>
		setInstances(instances.filter((instance, index) => index !== instanceId));

	const [showAllInstances, setShowAllInstances] = useState(false);
	const [instancesToBeDisplayed, setInstancesToBeDisplayed] = useState(instances);

	useEffect(() => {
		setInstancesToBeDisplayed(showAllInstances ? instances : instances.slice(0, 10));
	}, [showAllInstances, instances.length]);

	if (!tab) {
		return <Navigate to={'/item-management/items'} />;
	} else if (!tabs.find((t) => t.value === tab)) {
		return <Navigate to='/errors/error-404' />;
	}

	//Show only 10 instances initially

	const handleShowAllInstances = () => setShowAllInstances(!showAllInstances);

	return (
		<Page className={clsx(classes.root, className)} title={'Items page'}>
			{showAddInstance ?
				<div className={classes.wizardContentWrapper}>
					<div className={classes.columns}>
						<div className={classes.mainColumn}>
							<AddInstance
								addItemInstance={addItemInstanceLocally}
								handleCheckFieldUniqueLocally={handleCheckFieldUnique}
								isAbleToAddItemInstances={isAbleToAddItemInstances}
								isItemDetailPage
								itemId={id}
								itemStep={6}
								reservationType={itemDetailsData?.reservationType}
								save={callback}
								selectHub={selectedHub}
								selectedCategory={selectedCategory}
								selectedOrganisationId={organisationDataId}
							/>
						</div>
						{isFullArray(instances) && showBottomPanel ?
							<div className={classes.sidebarColumn}>
								<SidePanel
									className={classes.panel}
									title={t('views.addItem.sidepanel.title.instances')}
								>
									{instancesToBeDisplayed.map((instance, iIndex) => (
										<Accordion className={classes.panelBorder} key={iIndex}>
											{instance.map((field, fIndex) => (
												<AccordionSummary
													className={classes.panelBorder}
													key={field.name}
													{...(fIndex === 0 && {
														expandIcon: <ExpandMoreIcon htmlColor='#3f51b5' />,
													})}
													aria-controls='panel1a-content'
													id='panel1a-header'
												>
													<AccordionDetails className={classes.expansionDetails}>
														<div className={classes.expansion}>
															<Box display='flex' flexDirection='column' mr={4}>
																<Typography className={classes.panelText}>{field.label}</Typography>
															</Box>
															<Box display='flex' flexDirection='column'>
																<Typography className={classes.panelText} variant='body2'>
																	{field.value.value}
																</Typography>
															</Box>
														</div>
													</AccordionDetails>
												</AccordionSummary>
											))}
											<StyledButton
												className={classes.removeButton}
												fullWidth
												onClick={() => handleRemoveInstance(iIndex)}
												size='small'
												variant='contained-tertiary'
											>
												{t('ui.button.contained.remove')}
											</StyledButton>
										</Accordion>
									))}
									{instances?.length > 10 && (
										<StyledButton
											className={classes.showAllButton}
											fullWidth
											onClick={handleShowAllInstances}
											startIcon={showAllInstances ? <ExpandLessIcon /> : <ExpandMoreIcon />}
											variant='contained-tertiary'
										>
											{showAllInstances ?
												`${t('ui.hideAll')} ${t('ui.category.itemInstances')}`
											:	`${t('ui.showAll')} (+${instances.length - 10} ${t('ui.category.itemInstances')})`
											}
										</StyledButton>
									)}
								</SidePanel>
							</div>
						:	null}
					</div>
					<div className={clsx(classes.stepActionFooterContainer, classes.columns)}>
						<Box
							className={clsx(classes.stepActionFooter, classes.mainColumn)}
							display='flex'
							justifyContent='space-between'
						>
							<StyledButton
								className={classes.button}
								onClick={handleCancel}
								variant='contained-tertiary'
								// disabled={loading}
							>
								{t('ui.button.contained.cancel')}
							</StyledButton>
							<StyledButton
								className={classes.button}
								disabled={!isFullArray(instances)}
								onClick={handleConfirm}
								variant='contained-primary'
							>
								{submittingInstances ?
									<CircularProgress className={classes.progress} disableShrink size={25} />
								:	t('ui.button.contained.confirm')}
							</StyledButton>
						</Box>
					</div>
				</div>
			:	<>
					{headerButtons.map(
						(item) =>
							item.key === tab && (
								<Header
									isOwnItem={isOwnItem}
									itemData={itemDetailsData}
									key={item.key}
									loading={!itemDetailsReady || !orgDetailsReady}
									{...item}
								/>
							),
					)}
					<Tabs
						className={classes.tabs}
						indicatorColor='primary'
						onChange={handleTabsChange}
						scrollButtons='auto'
						value={tab}
						variant='scrollable'
					>
						{tabs.map((tab) => (
							<Tab key={tab.value} label={tab.label} value={tab.value} />
						))}
					</Tabs>
					<Divider className={classes.divider} />
					<div className={classes.content}>
						{tab === 'summary' && (
							<Summary
								businessPricingData={businessPricingData}
								isOwnItem={isOwnItem}
								itemData={itemDetailsData}
								itemLoading={loadingDetails}
								priceData={priceData}
							/>
						)}
						{tab === 'itemInstances' && (
							<ItemInstances
								categoryType={itemDetailsData ? itemDetailsData.categoryReference.type : null}
								id={id}
								instances
								isOwnItem={isOwnItem}
								itemData={itemDetailsData}
								setShowAddInstance={setShowAddInstance}
							/>
						)}
						{tab === 'activity' && <Activities id={id} />}
						{tab === 'accessDevices' && <AccessDevices />}
					</div>
				</>
			}
		</Page>
	);
};

ItemDetails.propTypes = {
	className: PropTypes.string,

	onBusinessPricing: PropTypes.func,

	onResetState: PropTypes.func,
	businessPricing: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	itemDetails: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	fetchPrice: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	fetchTerm: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	deleteItemInstance: PropTypes.shape({
		success: PropTypes.bool,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	addedInstance: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	organisation: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	addInstances: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),

	onFetchItem: PropTypes.func,
	onFetchItemImages: PropTypes.func,
	onFetchPrice: PropTypes.func,
	onFetchTerm: PropTypes.func,
	onAddItemInstance: PropTypes.func,
	onDeleteItemInstance: PropTypes.func,
	onFetchOrganisation: PropTypes.func,
	onAddInstances: PropTypes.func,
};

const mapStateToProps = (state) => {
	return {
		itemDetails: state.details.itemDetails,

		hubDetails: state.details.hubDetails,
		fetchPrice: state.details.fetchPrice,
		fetchTerm: state.details.fetchTerm,
		organisation: state.details.organisation,
		deleteItemInstance: state.condition.deleteItemInstance,
		addedInstance: state.details.addInstance,
		addInstances: state.details.addInstances,

		businessPricing: state.details.businessPricing,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchItem: (id) => dispatch(actions.fetchItem(id)),
		onFetchPrice: (priceId) => dispatch(actions.fetchPrice(priceId)),
		onBusinessPricing: (priceId) => dispatch(actions.businessPricing(priceId)),
		onFetchTerm: (id) => dispatch(actions.fetchTerm(id)),
		onAddInstances: (itemId, body) => dispatch(actions.addInstances(itemId, body)),
		onDeleteItemInstance: (itemId, itemInstanceId) =>
			dispatch(actions.deleteItemInstance(itemId, itemInstanceId)),
		onFetchOrganisation: (id) => dispatch(actions.fetchOrganisation(id)),
		onResetState: (state) => dispatch(actions.resetState(state)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemDetails);
