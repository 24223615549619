import { useEffect, useRef, useState } from 'react';

import { Circle as CircleIcon, Refresh as RefreshIcon } from '@mui/icons-material';
import {
	Autocomplete,
	Box,
	Button,
	Card,
	CardContent,
	CardHeader,
	Icon,
	MenuItem,
	Skeleton,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import { BarChart } from '@mui/x-charts';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { userInfoAtom } from '~atoms';
import { UserRoleEnum } from '~enums';
import { useAuthorize } from '~features/authentication';
import { OrganisationAutocomplete } from '~features/organisations';
import { BaseReference } from '~interfaces';
import { DateRange } from '~interfaces/dateRanges';

import Trip from '../../interfaces/trip';
import TripsService from '../../services/tripsService';

const service = new TripsService();

type NullIdReference = {
	id: null;
	label: string;
};
type FilterOption = BaseReference | NullIdReference | null;

interface ChartDataModel {
	label: string;
	tripCount: number;
	km: number;
	co2Kg: number; // kg
}

interface MainFilter {
	organisation?: BaseReference | null;
	// period: Required<Period<Dayjs>>;
	period: {
		start: Dayjs;
		end: Dayjs;
	};
}

/**
 * Null meaning only get without a value
 */
interface SubFilter {
	user?: FilterOption;
	item?: FilterOption;
}

interface PeriodicData {
	period: { start: Date; end: Date };
	data: Trip[];
}

const endThisMonth = dayjs().endOf('month');
const defaultSubFilter: SubFilter = {
	user: null,
	item: null,
};

interface EmissionsChartProps {
	tripCountColor?: string;
	distanceColor?: string;
	co2Color?: string;
}

const EmmissionsChart = ({
	tripCountColor = '#0b1d78',
	distanceColor = '#0069c0',
	co2Color = '#1fe074',
}: EmissionsChartProps) => {
	const { t } = useTranslation('general');
	const { isSuperAdmin } = useAuthorize();

	const userInfo = useAtomValue(userInfoAtom);

	const defaultMainFilter = useRef<MainFilter>({
		organisation: isSuperAdmin() ? null : userInfo.organisation,
		period: {
			start: dayjs().startOf('month').subtract(11, 'month'),
			end: endThisMonth,
		},
	});

	const [uniqueUsers, setUniqueUsers] = useState<FilterOption[]>();
	const [uniqueItems, setUniqueItems] = useState<FilterOption[]>();

	const [mainFilter, setMainFilter] = useState<MainFilter>(defaultMainFilter.current);
	const [subFilter, setSubFilter] = useState<SubFilter>(defaultSubFilter);

	/**
	 * We use this as kind of cache data layer. This is only updated if the main filters
	 * change
	 */
	const monthlyData = useRef<PeriodicData[]>(null!);
	// const [monthlyData, setMonthlyData] = useState<PeriodicData>(null!);
	const [chartData, setChartData] = useState<ChartDataModel[] | null>(null);
	const [accumulatedValues, setAccumulatedValues] = useState<ChartDataModel | null>(null);

	const isMainFilterValid = () =>
		mainFilter.organisation != null &&
		mainFilter.period.start != null &&
		mainFilter.period.end != null;

	const refreshString = `${service.basePath}.${mainFilter.organisation?.id}.${JSON.stringify(mainFilter.period)}`;
	const { data, isLoading, isValidating, error } = useSWR(
		isMainFilterValid() && refreshString,
		() =>
			service.getTripsInsights(
				{
					start: mainFilter.period.start.toDate(),
					end: mainFilter.period.end.toDate(),
				},
				mainFilter.organisation!.id,
			),
		{
			revalidateOnFocus: false,
		},
	);

	useEffect(() => {
		if (data) {
			// The data enters, first
			monthlyData.current = groupDataByMonth(data);

			const chartData = mapForChart(monthlyData.current);
			setChartData(chartData);
			setAccumulatedValues(getAccumulatedChartData(chartData));

			setUniqueUsers(
				getUniqueFilterOptions(
					data.map((el) => el.user),
					t('ui.label.withoutUser'),
				),
			);
			setUniqueItems(
				getUniqueFilterOptions(
					data.map((el) => el.item),
					t('ui.label.withoutInstance'),
				),
			);
		}
	}, [data]);

	useEffect(() => {
		if (data) {
			const newChartData = mapForChart(monthlyData.current);
			setChartData(newChartData);
			setAccumulatedValues(getAccumulatedChartData(newChartData));
		}
	}, [subFilter]);

	const handleMainFilterChange = (newValue: MainFilter) => {
		if (newValue.organisation?.id !== mainFilter.organisation?.id) {
			setSubFilter(defaultSubFilter);
		}

		setMainFilter(newValue);
	};

	/**
	 * Group the data by month
	 * @returns
	 */
	const groupDataByMonth = (data: Trip[]): PeriodicData[] => {
		const monthCount = mainFilter.period.end.diff(mainFilter.period.start, 'month');

		const monthlyData: PeriodicData[] = [];
		for (let i = 0; i <= monthCount; i++) {
			const selectedMonth = mainFilter.period.start.add(i, 'month');
			const period: DateRange = {
				start: selectedMonth.startOf('month').toDate(),
				end: selectedMonth.endOf('month').toDate(),
			};

			monthlyData.push({
				period: period,
				data: data.filter((el) => el.period.end >= period.start && el.period.end <= period.end),
			});
		}

		return monthlyData;
	};

	/**
	 * Determine from a list of data the unique filter options
	 * @param data
	 * @returns
	 */
	const getUniqueFilterOptions = (
		data: (BaseReference | undefined)[],
		noValueLabel = 'No value',
	) => {
		const unique: FilterOption[] = [
			...new Map(data.map((el) => [el?.id, el ?? { id: null, label: noValueLabel }])).values(),
		].sort((a, b) => (!a?.id || !b.id || a?.label > b?.label ? 1 : -1));
		unique.unshift(null);

		return unique;
	};

	/**
	 * Map and filter the data suitable for the chart
	 * @returns
	 */
	const mapForChart = (data: PeriodicData[]): ChartDataModel[] => {
		const chartData = data.map((month) => {
			const filtered = month.data.filter((el) => {
				if (subFilter.user?.id === null) {
					if (el.user != null) {
						return false;
					}
				} else if (subFilter.user !== null && subFilter.user?.id !== el.user?.id) {
					return false;
				}

				if (subFilter.item?.id === null) {
					if (el.item != null) {
						return false;
					}
				} else if (subFilter.item !== null && subFilter.item?.id !== el.item?.id) {
					return false;
				}

				return true;
			});

			return {
				label: month.period.start.toLocaleString('default', { month: 'short' }),
				tripCount: filtered.length,
				km: Math.ceil(filtered.reduce((acc, el) => acc + (el.distanceKm ?? 0), 0)),
				co2Kg: Math.ceil(filtered.reduce((acc, el) => acc + (el.co2Kg ?? 0), 0)),
			};
		});
		return chartData;
	};

	const getAccumulatedChartData = (data: ChartDataModel[]): ChartDataModel => ({
		label: t('ui.label.filteredTotal'),
		tripCount: data.reduce((acc, el) => acc + el.tripCount, 0),
		km: data.reduce((acc, el) => acc + el.km, 0),
		co2Kg: data.reduce((acc, el) => acc + el.co2Kg, 0),
	});

	const areDefaultFilters = () =>
		mainFilter.organisation?.id === defaultMainFilter.current.organisation?.id &&
		mainFilter.period.start === defaultMainFilter.current.period.start &&
		mainFilter.period.end === defaultMainFilter.current.period.end &&
		subFilter.user?.id === defaultSubFilter.user?.id &&
		subFilter.item?.id === defaultSubFilter.item?.id;

	const resetFilters = (): void => {
		setMainFilter(defaultMainFilter.current);
		setSubFilter(defaultSubFilter);
	};

	const ChartToolbar = () => (
		<Stack direction='row' spacing={1}>
			<DatePicker
				value={mainFilter.period.start}
				label='Start'
				openTo='month'
				views={['month', 'year']}
				onChange={(newValue) =>
					setMainFilter((prev) => ({
						...prev,
						period: { ...prev.period, start: newValue?.startOf('month') },
					}))
				}
				minDate={mainFilter.period.end.subtract(1, 'year').startOf('month')}
				maxDate={mainFilter.period.end.subtract(1, 'month')}
			/>
			<DatePicker
				value={mainFilter.period.end}
				label='End'
				openTo='month'
				views={['month', 'year']}
				onChange={(newValue) =>
					setMainFilter((prev) => ({
						...prev,
						period: { ...prev.period, end: newValue?.endOf('month') },
					}))
				}
				minDate={mainFilter.period.start.add(1, 'month')}
				maxDate={endThisMonth}
			/>
			{isSuperAdmin() && (
				<OrganisationAutocomplete
					label={`${t('organisations')}`}
					value={mainFilter.organisation}
					// onChange={(_, value) => setMainFilter((prev) => ({ ...prev, organisation: value }))}
					onChange={(_, value) => handleMainFilterChange({ ...mainFilter, organisation: value })}
					enableInfiniteScroll
					sx={{ minWidth: 200 }}
				/>
			)}
			<Autocomplete
				value={subFilter.user}
				options={uniqueUsers ?? []}
				getOptionLabel={(option) =>
					option?.label ?? t('views.usersAndOrganisations.users.table.title')
				}
				renderInput={(params) => (
					<TextField {...params} placeholder={t('views.usersAndOrganisations.users.table.title')} />
				)}
				renderOption={(props, option) => (
					<MenuItem {...props} value={option}>
						{option?.id ? option.label : <b>{option?.label ?? props.key}</b>}
					</MenuItem>
				)}
				onChange={(_, newVal) => setSubFilter((prev) => ({ ...prev, user: newVal }))}
				onReset={() => setSubFilter((prev) => ({ ...prev, user: null }))}
				disabled={!isMainFilterValid()}
				sx={{ width: 200 }}
			/>
			<Autocomplete
				value={subFilter.item}
				options={uniqueItems ?? []}
				getOptionLabel={(option) => option?.label ?? t('ui.label.allInstances')}
				renderInput={(params) => <TextField {...params} placeholder={t('ui.label.allInstances')} />}
				renderOption={(props, option) => (
					<MenuItem {...props} value={option}>
						{option?.id ? option.label : <b>{option?.label ?? props.key}</b>}
					</MenuItem>
				)}
				onChange={(_, newVal) => setSubFilter((prev) => ({ ...prev, item: newVal }))}
				onReset={() => setSubFilter((prev) => ({ ...prev, item: null }))}
				disabled={!isMainFilterValid()}
				sx={{ width: 200 }}
			/>
			{/* TODO: translate */}
			{!areDefaultFilters() && (
				<Button startIcon={<RefreshIcon />} onClick={resetFilters}>
					Clear filters
				</Button>
			)}
		</Stack>
	);

	return (
		<>
			<ChartToolbar />
			{!isMainFilterValid() || !chartData ?
				<Box
					sx={{
						display: 'flex',
						height: 1,
						alignItems: 'center',
						justifyContent: 'center',
						minHeight: 200,
					}}
				>
					<Typography variant='body1'>Please select an organisation</Typography>
				</Box>
			: isLoading || isValidating || !accumulatedValues ?
				<Stack
					spacing={2}
					sx={{
						p: 2,
					}}
				>
					<Skeleton variant='rectangular' width='500px' />
					<Skeleton variant='rectangular' width='300px' />
					<Skeleton variant='rectangular' width='600px' />
				</Stack>
			:	<Stack
					direction='row'
					sx={{
						justifyContent: 'space-between',
						pt: 2,
					}}
				>
					<Box
						sx={{
							width: 1,
							height: 500,
						}}
					>
						<BarChart
							dataset={chartData ?? []}
							xAxis={[{ scaleType: 'band', dataKey: 'label' }]}
							// yAxis={[{ valueFormatter: val => val.toLocaleString() }]}
							series={[
								{
									color: tripCountColor,
									dataKey: 'tripCount',
									label: 'Trips',
									valueFormatter: (value) => `${value}`,
								},
								{
									color: distanceColor,
									dataKey: 'km',
									label: t('ui.label.carDistance'),
									valueFormatter: (value) => `${value}km`,
								},
								{
									color: co2Color,
									dataKey: 'co2Kg',
									label: t('ui.header.co2'),
									valueFormatter: (value) => `${value}kg`,
								},
							]}
							slotProps={{
								legend: {
									hidden: true,
								},
							}}
						/>
					</Box>
					<Box>
						<Card sx={{ minWidth: '250px' }}>
							<CardHeader title={accumulatedValues.label} />
							<CardContent>
								<Stack>
									<Stack
										direction='row'
										spacing={1}
										sx={{
											alignItems: 'center',
										}}
									>
										<CircleIcon sx={{ color: tripCountColor }} />
										<Typography>
											{`${t('ui.label.trips')}: ${accumulatedValues.tripCount}`}
										</Typography>
									</Stack>
									<Stack
										direction='row'
										spacing={1}
										sx={{
											alignItems: 'center',
										}}
									>
										<CircleIcon sx={{ color: distanceColor }} />
										<Typography>
											{`${t('ui.label.carDistance')}: ${accumulatedValues.km}km`}
										</Typography>
									</Stack>
									<Stack
										direction='row'
										spacing={1}
										sx={{
											alignItems: 'center',
										}}
									>
										<CircleIcon sx={{ color: co2Color }} />
										<Typography>{`${t('ui.header.co2')}: ${accumulatedValues.co2Kg}kg`}</Typography>
									</Stack>
									<Stack
										direction='row'
										spacing={1}
										sx={{
											alignItems: 'center',
										}}
									>
										<Icon />
										<Typography>
											{`${t('ui.label.average')}: ${!accumulatedValues.km ? 0 : Math.ceil((accumulatedValues.co2Kg / accumulatedValues.km) * 1000) / 1000}kg/km`}
										</Typography>
									</Stack>
								</Stack>
							</CardContent>
						</Card>
					</Box>
				</Stack>
			}
		</>
	);
};

export default EmmissionsChart;
