import path from 'path';

import { AxiosResponse } from 'axios';
import { AddPatch, RemovePatch } from 'json-patch';

import { IdReference, PagedResults } from '~interfaces';
import { SearchPagedParameters } from '~interfaces/requests';
import {
	BaseReferenceResponse,
	IdReferenceResponse,
	SkcPagedResponse,
	SkcSingleResponse,
} from '~interfaces/responses';
import { TopologyProxiedSkopeiConnectService } from '~services';
import { getMutationDifferences } from '~utils/arrayUtils';

import SkcUserGroup from '../interfaces/skcUserGroup';

// Delete me, because of merge conflict

/**
 * A service that does calls to the Skopei Connect API through
 * the Topology backend
 */
class SkcUserGroupsService extends TopologyProxiedSkopeiConnectService {
	public readonly path = 'user-groups';

	async getUserGroups({
		page = 1,
		pageSize = 10,
		...args
	}: SearchPagedParameters): Promise<PagedResults<SkcUserGroup>> {
		const { data } = await this._client.get<SkcPagedResponse<UserGroupResponse>>(this.path, {
			params: {
				'page-number': page,
				'page-size': pageSize,
				organisationId: args.organisationId,
				textQuery: args.searchQuery || undefined,
			},
		});

		return this.mapPagedResponse(data, SkcUserGroupsService.fromResponse);
	}

	async getUserGroupById(id: string): Promise<SkcUserGroup> {
		const { data } = await this._client.get<SkcSingleResponse<UserGroupResponse>>(
			path.join(this.path, id),
			{
				headers: {
					prefer: 'return=representation',
				},
			},
		);

		return SkcUserGroupsService.fromResponse(data.data);
	}

	/**
	 * Create a user group in Skopei Connect. Is not assigning users or tags!
	 * @param data
	 * @returns
	 */
	async createUserGroup(data: SkcUserGroup): Promise<IdReference> {
		const content = SkcUserGroupsService.toRequest(data);

		const response = await this._client.post<
			null,
			AxiosResponse<SkcSingleResponse<IdReferenceResponse>>,
			SkcUserGroupRequest
		>(this.path, content, {
			params: {
				organisationId: data.organisation.id,
			},
		});

		return this.mapIdResponse(response.data);
	}

	/**
	 * Use this function to not only create a user group, but also assign users and/or nfc tags
	 * @param data
	 * @returns
	 */
	async createCompleteUserGroup(data: SkcUserGroup): Promise<null> {
		const response = await this.createUserGroup(data);

		this.patchUsers(response.id, data);
		if (data.participants?.nfcTags && data.participants.nfcTags.length > 0) {
			await this.assignNfcTags(response.id, data.participants.nfcTags);
		}

		return null;
	}

	/**
	 * Update the name and description of the user group
	 * @param groupId
	 * @param data
	 */
	async updateUserGroup(groupId: string, data: SkcUserGroup): Promise<null> {
		const content = SkcUserGroupsService.toRequest(data);
		await this._client.put(path.join(this.path, groupId), content);

		return null;
	}

	/**
	 * Updating the group details, assigning and deleting users and nfc are all together
	 * five different calls. Combine it here
	 * @param groupId
	 * @param newData
	 * @param oldData
	 */
	async updateCompleteUserGroup(
		groupId: string,
		newData: SkcUserGroup,
		oldData: SkcUserGroup,
	): Promise<null> {
		await this.updateUserGroup(groupId, newData);

		await this.patchUsers(groupId, newData, oldData);

		const nfcTagChanges = getMutationDifferences(
			oldData.participants?.nfcTags ?? [],
			newData.participants?.nfcTags ?? [],
		);

		if (nfcTagChanges.added.length > 0) {
			await this.assignNfcTags(groupId, nfcTagChanges.added);
		}
		if (nfcTagChanges.removed.length > 0) {
			await this.removeNfcTags(groupId, nfcTagChanges.removed);
		}

		return null;
	}

	/**
	 * 
	 * @param groupId 
	 * @param users 
	 * @returns 
	 * @deprecated
	 */
	async assignUsers(groupId: string, users: IdReference[]): Promise<null> {
		const { data } = await this._client.post<null>(
			path.join(this.path, groupId, 'users'),
			users.map((el) => Number(el.id)),
		);

		return null;
	}

	/**
	 * 
	 * @param groupId 
	 * @param users 
	 * @returns 
	 * @deprecated
	 */
	async removeUsers(groupId: string, users: IdReference[]): Promise<null> {
		const { data } = await this._client.delete<null>(path.join(this.path, groupId, 'users'), {
			data: users.map((el) => Number(el.id)),
		});

		return null;
	}

	async assignNfcTags(groupId: string, nfcTags: IdReference[]): Promise<null> {
		const { data } = await this._client.post<null>(
			path.join(this.path, groupId, 'cards'),
			nfcTags.map((el) => el.id),
		);

		return null;
	}

	async removeNfcTags(groupId: string, nfcTags: IdReference[]): Promise<null> {
		const { data } = await this._client.delete<null>(path.join(this.path, groupId, 'cards'), {
			data: nfcTags.map((el) => el.id),
		});

		return null;
	}

	async patchUsers(groupId: string, newData: SkcUserGroup, oldData?: SkcUserGroup) {
		const userChanges = getMutationDifferences(
			oldData?.participants?.users ?? [],
			newData.participants?.users ?? [],
		);

		const addPatches: AddPatch[] = userChanges.added.map((el) => ({
			op: 'add',
			path: '',
			value: Number(el.id),
		}));
		const removePatches: RemovePatch[] = userChanges.removed.map((el) => ({
			op: 'remove',
			path: '',
			value: Number(el.id),
		}));

		const patches = [...addPatches, ...removePatches];
		if (patches.length <= 0) {
			console.warn('No changes for the users');
			return;
		}

		const { data } = await this._client.patch<
			null,
			AxiosResponse<UserGroupResponse>,
			(RemovePatch | AddPatch)[]
		>(path.join(this.path, groupId, 'users'), patches);

		return data;
	}

	async deleteUserGroup(id: string): Promise<null> {
		const { data } = await this._client.delete(path.join(this.path, id));

		return null;
	}

	static toRequest(data: SkcUserGroup): SkcUserGroupRequest {
		const { label, description } = data;

		return {
			name: label,
			// Prevent empty strings
			description: description || undefined,
		};
	}

	static fromResponse(data: UserGroupResponse): SkcUserGroup {
		const { id, name, users, cards, ...rest } = data;

		return {
			...rest,
			id: id.toString(),
			label: name,
			participants: {
				nfcTags:
					cards.map((el) => ({
						...el,
						id: el.id.toString(),
						label: el.name,
						tagNumber: el.cardNumber,
					})) ?? [],
				users:
					users.map((el) => ({
						...el,
						id: el.id.toString(),
						label: el.name,
					})) ?? [],
			},
		};
	}
}

interface SkcUserGroupRequest extends UserGroupDto {}

interface CardResponse extends BaseReferenceResponse {
	cardNumber: string;
	type: string;
}

interface UserResponse {
	id?: number;
	skcId: number;
	name: string;
	description?: string;
	email?: string;
}

interface UserGroupResponse extends UserGroupDto {
	id: number;
	cards: IdReferenceResponse[] | CardResponse[];
	users: IdReferenceResponse[] | UserResponse[];
}

type PreferReturnResponse<Minimal extends object, Representation extends object> =
	| {
			minimal: true;
			response: Minimal;
	  }
	| {
			minimal: false;
			response: Representation;
	  };

type UserGroupResponseMinimal = {
	cards: IdReferenceResponse[];
	users: IdReferenceResponse[];
} & UserGroupDto;

type UserGroupResponseRepresentation = {
	cards: CardResponse[];
	users: UserResponse[];
} & UserGroupDto;

interface UserGroupDto {
	name: string;
	description?: string;
}

export default SkcUserGroupsService;
