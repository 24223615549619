import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { SkcUserGroupDetailsLayout } from '~features/access';

import PageNotFoundPage from '../feedbacks/pageNotFoundPage';
import Page from '../page';

const SkcUserGroupDetailsPage = () => {
	const { t } = useTranslation('general');
	const { id } = useParams();

	if (!id) {
		return <PageNotFoundPage />;
	}

	return (
		<Page title={`${t('views.userGroupDetail.page.title')} ${id}`}>
			<SkcUserGroupDetailsLayout id={id} />
		</Page>
	);
};

export default SkcUserGroupDetailsPage;
