import { Card, CardContent, CardHeader } from '@mui/material';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';

import { organisationAtom } from '~atoms';
import { DeviceCountPieChart } from '~features/devices';
import { ItemCountBarChart } from '~features/items';
import { UserCountPieChart } from '~features/users';
import { DetailsGridLayout } from '~layouts';

const rowHeight = '300px';

const dateLongMonthAndYearFormatter = new Intl.DateTimeFormat('nl', {
	day: 'numeric',
	month: 'long',
	year: 'numeric',
});

const OverviewStatisticsLayout = () => {
	const { t } = useTranslation('general');
	const organisation = useAtomValue(organisationAtom);

	return (
		<DetailsGridLayout>
			<Card sx={{ height: 1, pb: 1 }}>
				<CardHeader
					title={t('ui.category.users')}
					subheader={dateLongMonthAndYearFormatter.format(new Date())}
				/>
				<CardContent sx={{ width: '100%', height: rowHeight, pb: 1, '&:last-child': { pb: 1 } }}>
					<UserCountPieChart organisationId={organisation?.id} />
				</CardContent>
			</Card>
			<Card sx={{ height: 1, pb: 1 }}>
				<CardHeader
					title={t('ui.category.items')}
					subheader={dateLongMonthAndYearFormatter.format(new Date())}
				/>
				<CardContent sx={{ height: rowHeight, pb: 1, '&:last-child': { pb: 1 } }}>
					<ItemCountBarChart organisationId={organisation?.id} />
				</CardContent>
			</Card>
			<Card sx={{ height: 1, pb: 1 }}>
				<CardHeader
					title={t('nav.category.devices')}
					subheader={dateLongMonthAndYearFormatter.format(new Date())}
				/>
				<CardContent sx={{ height: rowHeight, '&:last-child': { pb: 1 } }}>
					<DeviceCountPieChart organisationId={organisation?.id} />
				</CardContent>
			</Card>
		</DetailsGridLayout>
	);
};

export default OverviewStatisticsLayout;
