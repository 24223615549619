import { Chip, ChipOwnProps, ChipProps } from '@mui/material';
import { useTranslation } from 'react-i18next';

import ServiceTicketStatusEnum from '../../enum/serviceTicketStatusEnum';

interface ServiceTicketStatusChipProps extends ChipProps {
	status: ServiceTicketStatusEnum;
}

const ServiceTicketStatusChip = ({
	status,
	...chipProps
}: ServiceTicketStatusChipProps) => {
	const { t } = useTranslation('general');

	const getStatusColor = (): ChipOwnProps['color'] => {
		let color: ChipOwnProps['color'];

		switch (status) {
			case ServiceTicketStatusEnum.Open:
				color = 'error';
				break;
			case ServiceTicketStatusEnum.Ready:
				color = 'success';
				break;
			case ServiceTicketStatusEnum.Pending:
			case ServiceTicketStatusEnum.Planned:
			case ServiceTicketStatusEnum.Closed:
				color = 'info';
				break;
			default:
				color = 'default';
				break;
		}

		return color;
	};

	return (
		<Chip
			{...chipProps}
			label={t(`ui.status.${status}`)}
			color={getStatusColor()}
		/>
	);
};

export default ServiceTicketStatusChip;
