import ApartmentIcon from '@mui/icons-material/Apartment';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import {
	Card,
	CardContent,
	Table,
	TableBody,
	TableRow,
	TableCell,
	Box,
	Typography,
	Divider,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAuthorize } from '~features/authentication';
import { BookingStatusChip } from '~features/bookings';

import WarningIcon from '../../../../../assets/icons/label-filled-icon-warning@3x.png';
import { ItemCard, IconInfoLabel } from '../../../../../components';
import { commaTimeStrings } from '../../../../../shared/datetime';
import { handleReservationType } from '../../../../../shared/functionality';
import { isFullArray, handleHubReference } from '../../../../../shared/utility';
import { useStyles } from '../../../style';

const BookingInfo = (props) => {
	const { bookingData } = props;

	const { t } = useTranslation('general');
	const navigate = useNavigate();
	const location = useLocation();
	const { isSuperAdmin } = useAuthorize();

	const classes = useStyles();

	const itemReference = bookingData.itemReference;

	const instance = bookingData.itemInstance;

	const handleItemView = () =>
		navigate(`/item-management/${itemReference.id}/instance/${instance.id}/summary`, {
			state: { from: location.pathname, showBackButton: true },
		});

	const bookingTableData = [
		{
			label: 'views.ItemDetail.activities.start',
			value: bookingData?.periodStart && commaTimeStrings(bookingData.periodStart),
			key: 1,
		},
		{
			label: 'views.ItemDetail.activities.end',
			value:
				itemReference?.bookingTypes?.includes('scanNfc') && bookingData.status !== 'completed' ?
					'-'
				:	commaTimeStrings(bookingData.periodEnd),
			key: 2,
		},
		{ label: 'ui.label.category', value: bookingData.itemInstance.categoryReference.name, key: 3 },
		{
			label: 'views.addItem.bookingType.title',
			value: handleReservationType(bookingData.itemReference),
			key: 4,
		},
		{
			label: 'ui.dateCreated',
			value: bookingData?.created && commaTimeStrings(bookingData.created),
			key: 5,
		},
		{ label: 'ui.provider', value: bookingData.hubReference.organisationReference.name, key: 6 },
	];

	return (
		<Card className={classes.card}>
			<Box
				alignItems='center'
				display='flex'
				justifyContent='space-between'
				pb={2}
				pl={3}
				pr={3}
				pt={2}
			>
				<Typography variant='h5'>{t('views.bookingDetail.bookingInfo.header')}</Typography>
				<Box alignItems='center' display='flex' maxHeight='20px'>
					{isSuperAdmin() && !bookingData.attentionStates.includes('none') ?
						<Box mr={1}>
							<IconInfoLabel
								icon={<img alt='warning-icon' height='16' src={WarningIcon} width='16' />}
								infoTitle={t('views.bookings.bookingIngo.tooltip.attention')}
								showIcon={true}
							/>
						</Box>
					:	null}
					<BookingStatusChip
						status={bookingData.status}
						usageState={bookingData.usageState}
						approvalState={bookingData.approvalState}
						itemCategory={bookingData.itemInstance.categoryReference.type}
					/>
				</Box>
			</Box>
			<Divider />
			<CardContent className={classes.cardConten}>
				<Box p={1.5} sx={{ color: 'yellow' }}>
					<ItemCard
						address={handleHubReference(bookingData.hubReference)}
						addressIcon={<RoomOutlinedIcon fontSize='small' />}
						handleItemView={handleItemView}
						iconButton={true}
						image={
							isFullArray(itemReference.imagesReference) ? itemReference.imagesReference[0] : null
						}
						instanceName={instance.name}
						locationIcon={<ApartmentIcon fontSize='small' />}
						locationName={bookingData.hubReference.name}
						name={itemReference.name}
					/>
				</Box>
				<Table>
					<TableBody>
						{bookingTableData.map((item) => (
							<TableRow key={item.key}>
								<TableCell>{t(item.label)}</TableCell>
								<TableCell>{item.value}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</CardContent>
		</Card>
	);
};

BookingInfo.propTypes = {
	bookingData: PropTypes.object.isRequired,
};

export default BookingInfo;
