import { useState } from 'react';

import {
	Card,
	CardHeader,
	CardContent,
	Divider,
	Table,
	TableBody,
	TableRow,
	TableCell,
	Box,
	Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { useAuthorize } from '~features/authentication';

import { StaticMap, SelectWithLazyLoading } from '../../../../../components';
import { useError } from '../../../../../shared/hooks';
import { handleHubReference, isObject } from '../../../../../shared/utility';
import * as actions from '../../../../../store/actions/index';
import { useStyles } from '../../../style';

const BookingInformation = (props) => {
	const {
		bookingData,

		currentUser,
		onFetchHubs,
		hubsList,
		updatedBookingLocation,
		onUpdatedBookingLocation,
	} = props;
	const { t } = useTranslation('general');

	const classes = useStyles();
	const { isSuperAdmin } = useAuthorize();

	const { data: currentUserData } = currentUser;

	const personalItem =
		isObject(currentUserData) &&
		isObject(bookingData) &&
		currentUserData.organisationReference.id === bookingData.hubReference.organisationReference.id;

	const hubReference = bookingData.hubReference;

	const [locationFilter, setLocationFilter] = useState({
		id: hubReference.id,
		value: hubReference.name,
	});

	const [filter] = useState({
		name: 'organisationId',
		value: bookingData.hubReference.organisationReference.id,
	});

	const [extraFilter] = useState({ name: 'filters', value: 'none' });

	const updatedDataError = useError({
		value: updatedBookingLocation,
		message: `${t('ui.success.message.updated')} ${t('views.addItem.settingsCard.locationCard.header')}`,
		variant: 'success',
	});

	const handleChangeLocation = (location) => {
		if (location.id === locationFilter.id) {
			return;
		}
		const bodyData = isObject(bookingData) ? { value: location.id } : null;

		setLocationFilter({ id: location.id, value: location.name });
		onUpdatedBookingLocation(bookingData.id, bodyData);
		updatedDataError.setStartAction(true);
	};

	return (
		<Card className={classes.card}>
			<CardHeader title={`${t('ui.label.location')} ${t('ui.information.label')}`} />
			<Divider />
			<CardContent className={classes.cardConten}>
				<Box display='flex' flexDirection='column' p={2}>
					{(
						bookingData.status !== 'completed' &&
						bookingData.status !== 'cancelled' &&
						(personalItem || isSuperAdmin())
					) ?
						<>
							<Typography pb={2}>
								{t('views.itemManagement.details.instanceDetails.locationInfo.assigned')}
							</Typography>
							<Box pb={2}>
								<SelectWithLazyLoading
									dataList={hubsList}
									extraFilter={extraFilter}
									filter={filter}
									listType='organisations'
									onFetchData={onFetchHubs}
									placeholder={t('ui.filter.locations.all')}
									setSelected={handleChangeLocation}
									value={locationFilter.value}
								/>
							</Box>
						</>
					:	null}
					<div>
						<StaticMap isImage lat={hubReference.latitude} lng={hubReference.longitude} />
					</div>
				</Box>
				<Table>
					<TableBody>
						<TableRow>
							<TableCell>{t('ui.pickUpReturn')}</TableCell>
							<TableCell>{handleHubReference(bookingData.hubReference)}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>{t('ui.label.gpsCoordinates')}</TableCell>
							<TableCell>{`${hubReference.latitude}, ${hubReference.longitude}`}</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</CardContent>
		</Card>
	);
};

BookingInformation.propTypes = {
	className: PropTypes.string,
	bookingData: PropTypes.object.isRequired,

	currentUser: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	hubsList: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	updatedBookingLocation: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	onFetchHubs: PropTypes.func,
	onUpdatedBookingLocation: PropTypes.func,
};

const mapStateToProps = (state) => {
	return {
		currentUser: state.details.currentUser,
		hubsList: state.paged.hubs,
		updatedBookingLocation: state.details.updatedBookingLocation,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchHubs: (page, filters, concat) => dispatch(actions.fetchHubs(page, filters, concat)),
		onUpdatedBookingLocation: (bookingId, bodyData) =>
			dispatch(actions.updatedBookingLocation(bookingId, bodyData)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingInformation);
