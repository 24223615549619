import { createContext, PropsWithChildren, useCallback, useContext, useMemo, useRef, useState } from 'react';

interface FormContainerProps {
	disabled?: boolean;
	setDisabled?: React.Dispatch<React.SetStateAction<boolean>>;
}

const FormContainerContext = createContext<FormContainerProps>({
	disabled: true
});

const useFormContainerState = () => {
	const formContainerContext = useContext(FormContainerContext);
	if (formContainerContext === undefined) {
		throw new Error('useOnboardingContext must be inside a OnboardingProvider');
	}

	return formContainerContext;
};

interface FormContainerProviderProps extends PropsWithChildren {
	onSubmit: () => void;
	
}

const FormContainerProvider = ({children}: PropsWithChildren) => {
	const [disabled, setDisabled] = useState(false);

	const value = useMemo<FormContainerProps>(() => ({
		disabled: disabled,
		setDisabled: setDisabled,
	}), [disabled, setDisabled]);

	return (
		<FormContainerContext.Provider value={value}>
			{children}
		</FormContainerContext.Provider>
	);
};

export type { FormContainerProps };
export { useFormContainerState };
export default FormContainerContext;
