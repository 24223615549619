import { Business as BusinessIcon } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const SelectOrganisationOverlay = () => {
	const { t } = useTranslation('general');
	
	return (
		<Box
			sx={{
				display: 'flex',
				height: 1,
				alignItems: 'center',
				justifyContent: 'center',
			}}
		>
			<Stack spacing={1} sx={{ alignItems: 'center' }}>
				<BusinessIcon fontSize='large' />
				<Typography variant='body1'>
					{t('views.users.userGroups.placeholder.organisationFilter')}
				</Typography>
			</Stack>
		</Box>
	);
};

export default SelectOrganisationOverlay;
