import { useState, useEffect } from 'react';

import {
	Card,
	CardContent,
	Divider,
	Typography,
	Box,
	Table,
	TableBody,
	TableRow,
	TableCell,
	Switch,
	CircularProgress,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { useAuthorize } from '~features/authentication';

import * as actions from '../../../../../../store/actions/index';
import { useStyles } from '../style';

const DeviceSettings = (props) => {
	const {
		instanceData,
		onPatchInstance,
		patchInstance,
		onUpdateDetailsState,

		isBoat,
		onResetState,
	} = props;
	const { t } = useTranslation('general');
	const { isSuperAdmin } = useAuthorize();

	const { data: updateData, loading: updateLoading, error: updateError } = patchInstance;
	const validationSet = new Set(instanceData.validations);

	const [loading, setLoading] = useState({
		immobiliser: false,
		key: false,
		tankCard: false,
		locked: false,
		doorRequired: false,
		dropoffRequired: false,
	});

	const itemId = instanceData?.itemReference?.id;

	const instanceId = instanceData?.id;

	const classes = useStyles();

	const { enqueueSnackbar } = useSnackbar();

	const handleResetLoading = () => {
		setLoading({
			immobilizerRequired: false,
			keyRequired: false,
			cardRequired: false,
			lockRequired: false,
			doors: false,
			location: false,
		});
	};

	const handleMessage = () => {
		const key = Object.keys(loading).find((value) => loading[value]);

		const enabledOrDisabled =
			updateData.validations.includes(key) ?
				t('ui.enabled').toLowerCase()
			:	t('ui.characteristics.disabled').toLowerCase();

		return `${t(`ui.label.${key}`)} ${enabledOrDisabled}`;
	};

	useEffect(() => {
		if (!updateLoading && (updateData || updateError) && Object.values(loading).includes(true)) {
			if (updateData) {
				onUpdateDetailsState('fetchInstance', updateData);
				enqueueSnackbar(handleMessage(), { variant: 'success' });
				handleResetLoading();
				onResetState('patchInstance');
			} else if (updateError) {
				enqueueSnackbar('Error', { variant: 'error' });
				handleResetLoading();
				onResetState('patchInstance');
			}
		}
	}, [patchInstance]);

	const handleToggle = (key) => {
		setLoading({ ...loading, [key]: true });
		if (validationSet.has(key)) {
			validationSet.delete(key);
		} else {
			validationSet.add(key);
		}

		onPatchInstance(itemId, instanceId, {
			validations: validationSet.size ? Array.from(validationSet.values()).join(',') : 'none',
		});
	};

	const settings = [
		...(isBoat ?
			[
				{
					label: 'views.itemManagement.details.instanceDetails.deviceSettings.immobiliserRequired',
					checked: validationSet.has('immobiliser'),
					key: 'immobiliser',
				},
				{
					label: 'views.itemManagement.details.instanceDetails.deviceSettings.charging',
					checked: validationSet.has('charging'),
					key: 'charging',
				},
				...(isSuperAdmin() ?
					[
						{
							label: 'views.itemManagement.details.instanceDetails.deviceSettings.dropoffRequired',
							checked: validationSet.has('location'),
							key: 'location',
						},
					]
				:	[]),
			]
		:	[
				{
					label: 'views.itemManagement.details.instanceDetails.deviceSettings.immobiliserRequired',
					checked: validationSet.has('immobiliser'),
					key: 'immobiliser',
				},
				{
					label: 'views.itemManagement.details.instanceDetails.deviceSettings.keyRequired',
					checked: validationSet.has('key'),
					key: 'key',
				},
				...(isSuperAdmin() ?
					[
						{
							label: 'views.itemManagement.details.instanceDetails.deviceSettings.tankCardRequired',
							checked: validationSet.has('tankCard'),
							key: 'tankCard',
						},
						{
							label: 'views.itemManagement.details.instanceDetails.deviceSettings.lockRequired',
							checked: validationSet.has('locked'),
							key: 'locked',
						},
						{
							label: 'views.itemManagement.details.instanceDetails.deviceSettings.doorRequired',
							checked: validationSet.has('doors'),
							key: 'doors',
						},
						{
							label: 'views.itemManagement.details.instanceDetails.deviceSettings.dropoffRequired',
							checked: validationSet.has('location'),
							key: 'location',
						},
					]
				:	[]),
			]),
	];

	return (
		<Card className={classes.maiCard}>
			<Box display='flex' justifyContent='space-between' pb={2} pl={3} pr={3} pt={2}>
				<Typography variant='h5'>{t('ui.label.header.deviceSettings')}</Typography>
			</Box>
			<Divider />
			<CardContent className={classes.content}>
				<Table>
					<TableBody>
						{settings.map((setting) => (
							<TableRow key={setting.label}>
								<TableCell>{t(setting.label)}</TableCell>
								<TableCell>
									{loading[setting.key] ?
										<CircularProgress
											color='inherit'
											disableShrink
											size={19}
											sx={{ width: '71.93', height: '23.98' }}
										/>
									:	<Switch
											checked={setting.checked}
											color='primary'
											onChange={() => handleToggle(setting.key)}
											size='small'
										/>
									}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</CardContent>
		</Card>
	);
};

DeviceSettings.propTypes = {
	instanceData: PropTypes.object,

	onPatchInstance: PropTypes.func,
	onUpdateDetailsState: PropTypes.func,
	isBoat: PropTypes.bool,
	onResetState: PropTypes.func,
	patchInstance: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
};

const mapStateToProps = (state) => {
	return {
		patchInstance: state.details.patchInstance,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onPatchInstance: (itemId, instanceId, properties) =>
			dispatch(actions.patchInstance(itemId, instanceId, properties)),
		onUpdateDetailsState: (state, data) => dispatch(actions.updateDetailsState(state, data)),
		onResetState: (state) => dispatch(actions.resetState(state)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceSettings);
