import { useEffect, useState } from 'react';

import path from 'path';

import { DeleteOutlined as DeleteOutlinedIcon, Edit as EditIcon } from '@mui/icons-material';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';

import { ConfirmationDialog, FormDialog, ResourceNotFoundPaper } from '~components';
import { useSnackbar } from '~hooks';
import { DetailsGridLayout, usePageHeader } from '~layouts';

import selectedOrganisationAtom from '../atoms/selectedOrganisationAtom';
import AccessRuleGeneralInfoCard from '../components/cards/ruleGeneralInfoCard';
import SkcAccessRuleForm from '../forms/skcRuleForm/skcAccessRuleForm';
import SkcAccessRulesService from '../services/skcAccessRulesService';

const service = new SkcAccessRulesService();

type UserAction = {
	type: 'delete' | 'edit';
	label?: string;
	id: string;
};

interface AccessRuleDetailsLayoutProps {
	id: string;
}

const AccessRuleDetailsLayout = ({ id }: AccessRuleDetailsLayoutProps) => {
	const { t } = useTranslation('general');
	const navigate = useNavigate();
	const { enqueueSuccessSnackbar, enqueueErrorSnackbar } = useSnackbar();
	const { setPageTitle, setPageButtons } = usePageHeader();
	const selectedOrganisation = useAtomValue(selectedOrganisationAtom);

	const [userAction, setUserAction] = useState<UserAction | null>(null);

	const { data, isLoading, error } = useSWR(
		[service.basePath, id],
		([_, args]) => service.getAccessRuleById(args)
	);

	const { trigger: triggerEdit, isMutating: isEditMutating } = useSWRMutation(
		[service.basePath, id],
		([_, id], { arg }) => service.updateAccessRule(id, arg),
		{
			onSuccess: () => {
				enqueueSuccessSnackbar(`${t('ui.success.message.updated')} ${t('rule').toLowerCase()}`);
				setUserAction(null);
			},
			onError: (error) => enqueueErrorSnackbar(error),
		},
	);

	const { trigger: triggerDelete, isMutating: isDeleteMutating } = useSWRMutation(
		[service.basePath, id],
		([_, args]) => service.deleteAccessRule(args),
		{
			onSuccess: () => {
				enqueueSuccessSnackbar(`${t('ui.successfullyDeleted')} ${t('rule').toLowerCase()}`);
				navigate('..', { relative: 'path' });
			},
			onError: (error) => enqueueErrorSnackbar(error),
		},
	);

	useEffect(() => {
		if (data) {
			setPageTitle(data.label);
			
			setPageButtons([
				{
					children: t('ui.edit'),
					startIcon: <EditIcon />,
					disabled: selectedOrganisation == null,
					onClick: () => setUserAction({ type: 'edit', id: id }),
				},
				{
					children: t('ui.delete'),
					color: 'error',
					startIcon: <DeleteOutlinedIcon />,
					onClick: () => setUserAction({ type: 'delete', id: id }),
				},
			]);
		}
	}, [data]);

	if (error?.status === 404) {
		return <ResourceNotFoundPaper label={id} type={t('rule')} />;
	}

	return (
		<>
			<DetailsGridLayout>
				<AccessRuleGeneralInfoCard id={id} />
			</DetailsGridLayout>
			<FormDialog
				open={userAction?.type === 'edit'}
				loading={isEditMutating}
				maxWidth='md'
				fullWidth
				onClose={() => setUserAction(null)}
			>
				<SkcAccessRuleForm
					id={userAction?.id}
					onSubmit={async (data) => await triggerEdit(data)}
				/>
			</FormDialog>
			<ConfirmationDialog
				open={userAction?.type === 'delete'}
				loading={isDeleteMutating}
				subTitle={t('thisWillDeleteResourceWithName', {
					resource: t('rule'),
					name: userAction?.label ?? userAction?.id,
				})}
				onConfirm={async () => await triggerDelete()}
				onClose={() => setUserAction(null)}
			/>
		</>
	);
};

export default AccessRuleDetailsLayout;
