// TODO: notice, these are case sensitive!!
// If changed, at the moment of writing (2024-06-13) these
// should be camel cased
enum UserRoleEnum {
	User = 'user',
	Admin = 'admin',
	SuperAdmin = 'superadmin'
}

export default UserRoleEnum;
