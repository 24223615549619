import { useEffect } from 'react';

import {
	Add as AddIcon,
	DeleteOutlined as DeleteOutlinedIcon,
	Remove as RemoveIcon,
} from '@mui/icons-material';
import {
	Divider,
	FormControlLabel,
	Grid,
	IconButton,
	Stack,
	Switch,
	// Switch as SwitchButton,
	Typography,
} from '@mui/material';
import { TimePicker, TimePickerProps } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';

import { DayOfWeekEnum } from '~enums';
import { DayOfWeekSchedule } from '~interfaces';
import { DateRange } from '~interfaces/dateRanges';

const defaultPeriod: DateRange<Dayjs> = {
	start: dayjs().hour(8).minute(0).second(0),
	end: dayjs().hour(17).minute(0).second(0),
};

const defaultDay: Omit<DayOfWeekSchedule<Dayjs>, 'dayOfWeek'> = {
	isOpen: true,
	isOpenAllDay: false,
	periods: [defaultPeriod],
};

const defaultWeek: DayOfWeekSchedule<Dayjs>[] = Object.values(DayOfWeekEnum).map(
	(el: DayOfWeekEnum) => {
		const isWeekend = el === DayOfWeekEnum.Saturday || el === DayOfWeekEnum.Sunday;

		return {
			...defaultDay,
			dayOfWeek: el,
			isOpen: !isWeekend,
		};
	},
);

interface DayOfWeekEntryProps<T = Date> {
	value?: DayOfWeekSchedule<T>;
	onChange?: (value: DayOfWeekSchedule<T>) => void;
	maxPeriods?: number;
	slotProps?: {
		timePicker: Partial<TimePickerProps<T>>;
	};
	minDurationMinutes?: number;
}

const DayOfWeekEntry = <T extends Dayjs & Date>({
	value = defaultDay,
	onChange,
	slotProps = {
		timePicker: {
			minutesStep: 5,
			ampm: false,
			sx: { width: 120 },
		},
	},
	maxPeriods = 3,
	minDurationMinutes = 15,
}: DayOfWeekEntryProps<T>) => {
	const { t } = useTranslation('general');

	/**
	 * We always keep one period as the last chosen value.
	 * If the isOpen value is false, we first set it to true. Only after
	 * that we add another entry.	 *
	 */
	const handleAddTimeSlot = () => {
		let newValue: DayOfWeekSchedule<T>;
		if (!value?.isOpen) {
			newValue = {
				...value,
				isOpen: true,
			};
		} else {
			newValue = {
				...value,
				periods: [...value?.periods, defaultPeriod],
			};
		}

		onChange?.(newValue);
	};

	/**
	 * Remove a period.
	 * If a period is removed, but theres is only one period left, we
	 * set the isOpen to false. So we keep the last and only period.
	 */
	const handleRemovePeriod = (index: number) => {
		let newValue: DayOfWeekSchedule<T>;
		if (value?.periods != null && value.periods.length <= 1) {
			newValue = {
				...value,
				isOpen: false,
			};
		} else {
			const newPeriods = value?.periods?.filter((_, i) => i !== index);
			newValue = {
				...value,
				periods: newPeriods,
			};
		}

		onChange?.(newValue);
	};

	const handleChange = (newValue: DayOfWeekSchedule<T>) => {
		onChange?.(newValue);
	};

	const handlePeriodChange = (index: number, period: DateRange<T>) => {
		const newPeriods = value?.periods?.map((el, i) => (i !== index ? el : period));

		const newValue: DayOfWeekSchedule<T> = {
			...value,
			periods: newPeriods,
		};

		onChange?.(newValue);
	};

	return (
		<Grid container spacing={1}>
			{(value?.isOpenAllDay ? [value?.periods[0]] : value?.periods)?.map((el, i) => (
				<Grid key={i} container item spacing={1} alignItems='center'>
					<Grid item xs={1.5}>
						{i === 0 && <Typography>{t(value.dayOfWeek)}</Typography>}
					</Grid>
					{value.isOpen || value?.isOpenAllDay ?
						<Grid container item xs='auto' spacing={1} alignItems='center'>
							<Grid item>
								<TimePicker
									{...slotProps.timePicker}
									// label={t('ui.label.openingAt')}
									value={value.isOpenAllDay ? dayjs().startOf('day') : dayjs(el.start)}
									maxTime={el.end.subtract(minDurationMinutes, 'minute')}
									disabled={value.isOpenAllDay}
									onChange={(val) => val != null && handlePeriodChange(i, { ...el, start: val })}
								/>
							</Grid>
							<Grid item>
								<Typography>-</Typography>
							</Grid>
							<Grid item>
								<TimePicker
									{...slotProps.timePicker}
									// label={t('ui.label.closingAt')}
									value={value.isOpenAllDay ? dayjs().endOf('day') : dayjs(el.end)}
									minTime={dayjs(el.start).subtract(minDurationMinutes, 'minute')}
									disabled={value.isOpenAllDay}
									onChange={(val) => val != null && handlePeriodChange(i, { ...el, end: val })}
								/>
							</Grid>
							<Grid item>
								<IconButton disabled={value?.isOpenAllDay} onClick={() => handleRemovePeriod(i)}>
									<DeleteOutlinedIcon />
								</IconButton>
							</Grid>
						</Grid>
					:	<Grid item>
							<Typography>{t('noTimeSlots')}</Typography>
						</Grid>
					}
					{i === 0 && (
						<>
							<Grid xs />
							<Grid item xs='auto'>
								<FormControlLabel
									control={
										<Switch
											checked={value.isOpenAllDay}
											onChange={(e) => handleChange({ ...value, isOpenAllDay: e.target.checked })}
										/>
									}
									label={t('ui.allDay')}
									labelPlacement='start'
									sx={{ mx: 0, px: 0 }}
								/>
							</Grid>
							<Grid item xs='auto'>
								<IconButton
									disabled={value?.isOpenAllDay || value?.periods.length >= maxPeriods}
									onClick={handleAddTimeSlot}
								>
									<AddIcon />
								</IconButton>
							</Grid>
						</>
					)}
				</Grid>
			))}
		</Grid>
	);
};

interface DayOfWeekOpeningHoursFormProps {
	value: DayOfWeekSchedule[];
	onChange?: (value: DayOfWeekSchedule[]) => void;
	minutesStep?: number;
}

/**
 * A component to select a list of openingshours for a week
 */
const DayOfWeekOpeningHoursForm = ({
	value = defaultWeek,
	onChange,
	minutesStep = 5,
}: DayOfWeekOpeningHoursFormProps) => {
	const { t } = useTranslation('general');

	useEffect(() => {
		// Basically return the default value
		if (value === defaultWeek && onChange) {
			onChange(defaultWeek);
		}
	}, []);

	const handleChange = (index: number, newValue: DayOfWeekSchedule) => {
		if (onChange) {
			const temp = value.map((el, i) => (i !== index ? el : newValue));
			onChange(temp);
		}
	};

	return (
		<Stack divider={<Divider />} spacing={1}>
			{Object.values(DayOfWeekEnum).map((dayOfWeek, i) => (
				<DayOfWeekEntry
					key={i}
					value={value[i]}
					onChange={(newValue) => handleChange(i, newValue)}
				/>
			))}
		</Stack>
	);
};

export default DayOfWeekOpeningHoursForm;
