import { useRef, useState } from 'react';

import { Button, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useSWRMutation from 'swr/mutation';

import { ConfirmationDialog, Dialog } from '~components';
import { pagePathSegments } from '~constants';
import { useSnackbar } from '~hooks';

import { UserGroupsDataGrid, UserGroupsService } from '..';

const service = new UserGroupsService();

type UserAction =
	| {
			type: 'create';
			id?: never;
	  }
	| {
			type: 'delete';
			id: string;
	  };

const UserGroupsOverviewLayout = () => {
	const { t } = useTranslation('general');
	const navigate = useNavigate();
	const { enqueueSuccessSnackbar, enqueueErrorSnackbar } = useSnackbar();
	const dataGridRef = useRef();

	const [userAction, setUserAction] = useState<UserAction | null>(null);

	const {
		trigger: triggerDelete,
		isMutating: isDeleteMutating,
		error: deleteError,
	} = useSWRMutation(
		userAction?.id ? [service.basePath, userAction.id] : null,
		([_, id]) => service.deleteUserGroup(id),
		{
			onSuccess: () => {
				enqueueSuccessSnackbar(t('deleted'));
				setUserAction(null);
				dataGridRef.current.mutate();
			},
			onError: (error) => enqueueErrorSnackbar(error),
		},
	);

	return (
		<>
			<UserGroupsDataGrid
				ref={dataGridRef}
				onCreate={() => navigate(pagePathSegments.Add)}
				onDelete={(id) => setUserAction({ type: 'delete', id: id })}
			/>
			<ConfirmationDialog
				open={userAction?.type === 'delete'}
				loading={isDeleteMutating}
				onConfirm={async () => await triggerDelete()}
				onClose={() => setUserAction(null)}
			/>
		</>
	);
};

export default UserGroupsOverviewLayout;
