import { Business as BusinessIcon } from '@mui/icons-material';
import {
	Avatar,
	CircularProgress,
	Stack,
	SvgIcon,
	SvgIconTypeMap,
	Typography,
} from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

import i18n from '~lib/i18n';

import Page from '../page';

// TODO: part of theme?
const color = '#304FFE';

interface LoadingPageProps {
	title?: string;
	icon?: OverridableComponent<SvgIconTypeMap<object, 'svg'>>;
	description?: string;
	hideProgress?: boolean;
}

const LoadingPage = ({
	title = i18n.t('loading'),
	hideProgress = false,
	...props
}: LoadingPageProps) => {
	return (
		<Page title={title}>
			<Stack spacing={2} alignItems='center' height={1}>
				{props.icon != null && (
					<Avatar
						sx={{
							color: color,
							bgcolor: '#E4EEFB',
							width: '91px',
							height: '91px',
						}}
					>
						<SvgIcon component={props.icon} sx={{ fontSize: '55px' }} />
					</Avatar>
				)}
				<Typography variant='h4'>{title}</Typography>
				<Typography>{props.description}</Typography>
				{!hideProgress && <CircularProgress sx={{ color: color }} />}
			</Stack>
		</Page>
	);
};

export default LoadingPage;
