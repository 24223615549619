/**
 * Get the dimensions of a hypothecially rendered piece of text.
 * E.g. used when rendered text in a svg where text cannot be aligned with
 * respect to eachother.
 * @param label 
 * @param fontFamily 
 * @param fontSize 
 * @returns 
 */
export const getRenderedTextSize = (label: string, fontFamily: string, fontSize: string) => {
	const el = document.createElement('span');
	document.body.appendChild(el);

	el.style.fontFamily = fontFamily;
	el.style.fontSize = fontSize;
	el.innerHTML = label;

	const bbox = el.getBoundingClientRect();
	document.body.removeChild(el);

	return {
		width: bbox.width,
		height: bbox.height
	};
};
