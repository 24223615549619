import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import { Grid, Typography, Hidden, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import { useAuthorize } from '~features/authentication';
import { DeviceCommandButtonGroup } from '~features/devices';

import { AdjustedSkeleton, StyledButton } from '../../../../../components';
import { useDetailPageBackButton } from '../../../../../shared/hooks';
import { instanceName, isObject } from '../../../../../shared/utility';

const PREFIX = 'Header';

const classes = {
	root: `${PREFIX}-root`,
	buttonSpacing: `${PREFIX}-buttonSpacing`,
};

const Root = styled('div')(() => ({
	[`& .${classes.root}`]: {},

	[`& .${classes.buttonSpacing}`]: {
		maxHeight: '56px',
	},
}));

export {};

const Header = (props) => {
	const { className, data, loading, currentUser } = props;
	const location = useLocation();
	const { t } = useTranslation('general');
	const { isSuperAdmin } = useAuthorize();

	const { data: currentUserData } = currentUser;

	const isProvider =
		isObject(currentUserData) &&
		isObject(data) &&
		currentUserData?.organisationReference.id ===
			data?.itemReference.hubReference.organisationReference.id;

	const navigate = useNavigate();

	const returnButton = useDetailPageBackButton(location);
	const handleBackButtonChange = () => {
		if (location.state?.backPathname) {
			navigate(location.state.backPathname);
		} else if (location?.state?.from) {
			navigate(location.state.from);
		} else {
			navigate('/item-management/items');
		}
	};

	const handleBackButton = () => {
		if (returnButton.isBackButton || !location.state?.label) {
			return t('ui.button.inline.back');
		} else {
			return location.state.label;
		}
	};

	return (
		<Root className={className}>
			<Grid alignItems='flex-end' container justifyContent='space-between' spacing={3}>
				<Grid item>
					<Hidden only={['xs', 'sm']}>
						<StyledButton
							className={classes.backButton}
							onClick={handleBackButtonChange}
							startIcon={<KeyboardArrowLeftOutlinedIcon />}
							variant='inline-default'
						>
							{handleBackButton()}
						</StyledButton>
					</Hidden>
					<Typography component='h1' variant='h2'>
						{loading ?
							<AdjustedSkeleton animation='wave' width={200} />
						:	isObject(data) && instanceName(data)}
					</Typography>
				</Grid>
				{loading ?
					<AdjustedSkeleton animation='wave' width={200} />
				:	isObject(data) &&
					['bmwCar', 'vehicleTrackerCan', 'cpacBoat'].includes(data.deviceType) &&
					(isProvider || isSuperAdmin()) && (
						<Grid className={classes.buttonSpacing} item>
							<DeviceCommandButtonGroup
								device={{ id: data.deviceId }}
								type={data.deviceType}
								sx={{ mr: 2 }}
							/>
						</Grid>
					)
				}
			</Grid>
		</Root>
	);
};

Header.propTypes = {
	className: PropTypes.string,

	buttonPlaceholder: PropTypes.string,

	action: PropTypes.func,
	href: PropTypes.string,

	data: PropTypes.object,
	loading: PropTypes.bool,

	currentUser: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
};

const mapStateToProps = (state) => {
	return {
		currentUser: state.details.currentUser,
	};
};

export default connect(mapStateToProps, null)(Header);
