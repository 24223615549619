import { ItemCategoryEnum } from '~enums';
import { TopologyService } from '~services';

interface ItemStat {
	category: ItemCategoryEnum;
	activeCount: number;
	inactiveCount: number;
}

class ItemStatisticsService extends TopologyService {
	public readonly path = 'graphs/items';

	constructor() {
		super('v1');
	}

	async getCounts({ organisationId }: { organisationId?: string }): Promise<ItemStat[]> {
		const { data } = await this._client.get<ItemStatResponse>(this.path, {
			params: {
				organisationId: organisationId
			}
		});

		return data.categories.map((el) => ({
			category: el.categoryType as ItemCategoryEnum,
			activeCount: el.active,
			inactiveCount: el.inactive,
		}));
	}
}

interface ItemStatResponse {
	categories: {
		categoryType: string;
		active: number;
		inactive: number;
	}[];
	total: number;
}

export default ItemStatisticsService;
