import {
	SET_GLOBAL_STATE,
	UPDATE_REDIRECT_OBJECT,
	SET_WINDOW_DIMENSIONS,
} from './actionTypes';

/* * * * * * * * * * *
 * SET GLOBAL STATE  *
 * * * * * * * * * * */
export const setGlobalState = (identifier = null, value) => {
	return {
		type: SET_GLOBAL_STATE,
		identifier: identifier,
		value: value,
	};
};

export const updateRedirect = (pathname = null, search = null) => {
	return {
		type: UPDATE_REDIRECT_OBJECT,
		pathname: pathname,
		search: search,
	};
};

/* * * * * * * *
 * UI METHODS  *
 * * * * * * * */
export const setWindowDimensions = () => ({
	type: SET_WINDOW_DIMENSIONS,
	windowDimensions: {
		width: window.innerWidth,
		height: window.innerHeight,
	},
});
