import { useMemo } from 'react';

import { AxiosError } from 'axios';
import { useSnackbar as useNotistackSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import i18n from '~lib/i18n';

type Method =
  | 'get'
  | 'options'
  | 'post'
  | 'put'
  | 'patch'
  | 'delete'

interface ErrorMessage {
	httpStatusCode: number | 'all';
	message: string;
	method?: Method;
}


/**
 * A snackbar wrapper to handle common use case, like a rest call that
 * went ok or error handling.
 * @param overrides Overwrite some default error messages per hook
 * @returns 
 */
const useSnackbar = (overrides?: ErrorMessage[]) => {
	const snackbar = useNotistackSnackbar();
	const { t } = useTranslation('general');

	const messages = useMemo(() => {
		const defaultErrorMessages: ErrorMessage[] = [
			{
				httpStatusCode: 401,
				message: t('unauthorized'),
			},
			{
				httpStatusCode: 403,
				message: t('forbidden'),
			},
			{
				httpStatusCode: 404,
				message: t('notFound'),
			},
			{
				httpStatusCode: 409,
				message: t('thisNameAlreadyExists'),
				method: 'post'
			},
			{
				// TODO: need to makes this more generic than just access rules
				httpStatusCode: 409,
				message: t('deletionPartOfAccessRuleErrorMessage'),
				method: 'delete'
			},
			{
				httpStatusCode: 500,
				message: t('somethingWentWrong'),
			},
			{
				httpStatusCode: 502,
				message: t('somethingWentWrong'),
			},
		];
		
		if (!overrides) {
			return defaultErrorMessages;
		}

		return overrides.concat(defaultErrorMessages.filter(
			el1 => !overrides.some(el2 => el2.httpStatusCode == el1.httpStatusCode)
		));
	}, [overrides]);

	const enqueueSuccessSnackbar = (message: string) => {
		snackbar.enqueueSnackbar(message, { variant: 'success' });
	};

	const enqueueErrorSnackbar = (error: AxiosError, message?: string) => {
		console.error(error);

		const method = error.response?.config.method;
		const errorMessage: ErrorMessage | undefined = messages.find(
			(el) => el.httpStatusCode === error?.status && (el.method == null || el.method === method),
		);
		snackbar.enqueueSnackbar(errorMessage?.message ?? t('somethingWentWrong'), {
			variant: 'error',
		});
	};

	return {
		...snackbar,
		enqueueSuccessSnackbar: enqueueSuccessSnackbar,
		enqueueErrorSnackbar: enqueueErrorSnackbar,
	};
};

export default useSnackbar;
