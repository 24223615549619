import path from 'path';

import { PagedResults } from '~interfaces';
import { DateRange } from '~interfaces/dateRanges';
import { SearchPagedParameters } from '~interfaces/requests';
import { PagedResponse } from '~interfaces/responses';
import { TopologyService } from '~services';

import Trip from '../interfaces/trip';

class TripsService extends TopologyService {
	public readonly path = 'trips';

	constructor() {
		super('v1');
	}

	/**
	 * Get a paginated list of trips
	 * @param page The number of the page
	 * @param pageSize The amount of results of the page
	 * @returns
	 */
	async getTrips({
		page = 1,
		pageSize = 10,
		...args
	}: SearchPagedParameters): Promise<PagedResults<Trip>> {
		const { data } = await this._client.get<PagedResponse<TripResponse>>(this.path, {
			params: {
				pageNumber: page,
				pageSize: pageSize,
				organisationId: args.organisationId,
				...(args.searchQuery && { searchTerm: args.searchQuery }),
			},
		});

		return this.mapPagedResponse(data, TripsService.fromResponse);
	}

	async getTripsInsights(period: DateRange, organisationId: string): Promise<Trip[]> {
		const { data } = await this._client.get<TripResponse[]>(path.join(this.path, 'insights'), {
			params: {
				dateAfter: period.start.toISOString(),
				dateBefore: period.end?.toISOString(),
				organisationId: organisationId,
			},
		});

		return data.map((el) => TripsService.fromResponse(el));
	}

	static fromResponse(data: TripResponse): Trip {
		const {
			distance,
			duration,
			userId,
			userFullName,
			itemInstanceId,
			itemInstanceName,
			co2,
			end,
			bookingId,
			...rest
		} = data;

		return {
			...rest,
			distanceKm: distance,
			period: {
				end: new Date(end),
			},
			co2Kg: co2 ? co2 * 0.001 : undefined,
			...(bookingId && {
				booking: { id: bookingId.toString() },
			}),

			...(itemInstanceId &&
				itemInstanceName && {
					item: {
						id: itemInstanceId.toString(),
						label: itemInstanceName,
					},
				}),
			...(userId &&
				userFullName && {
					user: {
						id: userId.toString(),
						label: userFullName,
					},
				}),
		};
	}
}

// This is validated for the tripsinsights
// Different for the normal trip?
interface TripResponse {
	id: string;
	deviceId: string;
	distance: number;
	duration: number;
	usageTyp: 'business';
	distancePercentage: number;
	personalDistance: number;
	itemInstanceId: number;
	itemInstanceName: string;
	end: string;
	userId?: number;
	userFullName?: string;
	co2?: number;
	bookingId?: number;
}

export default TripsService;
