import i18n from '~lib/i18n';

import {
	weekOptions,
	weekdays,
	weekend,
	changeDateTimeToUTCTime,
	modifyDate,
	unsetTime,
} from './datetime';

/* * * * * * * * * * * * *
 * DEVELOPMENT UTILITIES *
 * * * * * * * * * * * * */
export const isUndefined = (value) => typeof value === 'undefined';
export const isDefined = (value) => !isUndefined(value);
export const isNull = (value) => !value && typeof value === 'object';
export const isNumber = (value) => typeof value === 'number';
// No support for Number.isInteger by IE
// export const isInteger = (value) => (Number.isInteger(value));
export const isInteger = (value) =>
	isNumber(value) && isFinite(value) && Math.floor(value) === value;
export const isBoolean = (value) => typeof value === 'boolean';
export const isString = (value) => typeof value === 'string';
export const isFullString = (value) => isString(value) && !!value.trim().length;
export const isEmptyString = (value) => isString(value) && !value.trim().length;
export const isArray = (value) => typeof value === 'object' && Array.isArray(value);
export const isFullArray = (value) => isArray(value) && !!value.length;
export const isEmptyArray = (value) => isArray(value) && !value.length;
export const isObject = (value) => typeof value === 'object' && !isNull(value) && !isArray(value);
export const isEmptyObject = (value) => isObject(value) && Object.keys(value).length === 0;
export const isFunction = (value) => typeof value === 'function';
export const areEqual = (first, second) => JSON.stringify(first) === JSON.stringify(second);

export const capitalizeFirstCharacter = (value, othersLowercase = true) =>
	isFullString(value) ?
		`${value.slice(0, 1).toUpperCase()}${
			value.length > 1 ?
				othersLowercase ? value.slice(1).toLowerCase()
				:	value.slice(1)
			:	''
		}`
	:	'';
	
export const isOdd = (number) => Math.abs(number % 2) === 1;

export const decimalBase = (value, currencySymbol) =>
	isNumber(value) && value >= 0 ? `${currencySymbol}${value.toFixed(2)}` : '-';
export const decimalAmount = (value, amount) =>
	isObject(amount) && isNumber(value) && amount.subtotal > 0 ?
		`${amount.currencySymbol}${value.toFixed(2)}`
	:	'-';
export const getExtension = (extension) => extension.split('.').pop();

//biteToSize
export const bytesToSize = (bytes, decimals = 2) => {
	if (bytes === 0) {
		return '0 Bytes';
	}

	const k = 1024;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

	const i = Math.floor(Math.log(bytes) / Math.log(k));

	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

//dataURItoFile
export const dataURItoFile = (dataURI, fileName) => {
	const arr = dataURI.split(',');
	const mime = arr[0].match(/:(.*?);/)[1];
	const bstr = atob(arr[1]);
	let n = bstr.length;
	const u8arr = new Uint8Array(n);

	while (n--) {
		u8arr[n] = bstr.charCodeAt(n);
	}
	return new File([u8arr], fileName, { type: mime });
};

/* * * * * * * * * * * *
 * USER DATA UTILITIES *
 * * * * * * * * * * * */
export const getLanguageCode = () => localStorage.getItem('languageCode');

export const getInitials = (name = '') =>
	name
		.replace(/\s+/, ' ')
		.split(' ')
		.slice(0, 2)
		.map((v) => v && v[0].toUpperCase())
		.join('');

export const stringifyQueryParams = (params = {}) => {
	if (isObject(params)) {
		return Object.entries(params)
			.map((param, index) => `${!index ? '?' : '&'}${param[0]}=${param[1]}`)
			.join('');
	}

	return params;
};

export const uploadImages = (id = null, images = [], onUpload = () => {}) => {
	onUpload(
		id,
		images.map((image) => dataURItoFile(image.uri, image.name)),
	);
};

export const createArray = (obj) =>
	obj ? Object.entries(obj).map((item) => ({ id: item[0], config: item[1] })) : [];
export const stringToArray = (input) =>
	isArray(input) ? input
	: isString(input) ? input.split(' ')
	: [];
export const addToArray = (newItems, currentArray) =>
	stringToArray(currentArray)
		.concat(stringToArray(newItems))
		.filter((value, index, outputArray) => value && outputArray.indexOf(value) === index);

export const ignoreKeyClick = (event, keyName) => {
	event.persist();
	if (event.key === keyName) {
		event.preventDefault();
	}
};

export const castMinutesToTimeString = (minutes, short = true) => {
	const days = Math.floor(minutes / 1440);
	const hours = Math.floor((minutes % 1440) / 60);
	const leftOverMinutes = minutes % 60;
	const dayString = days > 0 ? `${days}${i18n.t('ui.daysShort')}` : '';
	const hoursString = hours > 0 ? `${hours}${i18n.t('ui.hoursShort')}` : '';
	const leftOverMinutesString =
		leftOverMinutes > 0 ? `${leftOverMinutes}${i18n.t('ui.minutesShort')}` : '';
	const daysTime =
		days > 0 ? `${days} ${days === 1 ? i18n.t('ui.dayLong') : i18n.t('ui.daysLong')}` : '';
	const hoursTime = days > 0 && hours > 0 ? ' ' : '';
	const hoursDuration =
		hours > 0 ? `${hours} ${hours === 1 ? i18n.t('ui.hour') : i18n.t('ui.hoursDuration')}` : '';
	const leftOverMinutesTime =
		(hours > 0 && leftOverMinutes > 0) || (hours === 0 && days > 0 && leftOverMinutes > 0) ?
			' '
		:	'';
	const minutesTime =
		leftOverMinutes > 0 ?
			`${leftOverMinutes} ${leftOverMinutes === 1 ? i18n.t('ui.minute') : i18n.t('ui.minutes')}`
		:	'';
	return short ?
			`${dayString}${hoursString}${leftOverMinutesString}`
		:	`${daysTime}${hoursTime}${hoursDuration}${leftOverMinutesTime}${minutesTime}`;
};

export const createSummaryPeriod = (periodsArray, isSummary = false) => {
	const newPeriodArray = periodsArray.map((period) => {
		const dayStringsArray = period.days.split(', ');
		const days = dayStringsArray.map(
			(dayString) => weekOptions.filter((option) => option.value === dayString)[0],
		);

		const endDate = changeDateTimeToUTCTime(new Date(period.end));

		return {
			...period,
			days,
			start: changeDateTimeToUTCTime(new Date(period.start)),
			end:
				endDate.getHours() === 23 && endDate.getMinutes() === 59 && endDate.getSeconds() === 59 ?
					modifyDate(unsetTime(endDate), { date: '+1' })
				:	endDate,
		};
	});

	return isSummary ? newPeriodArray : createEditableDaysArrayInPricingModel(newPeriodArray);
};

export const createEditableDaysArrayInPricingModel = (pricingModel) => {
	const newPricingModel = pricingModel.map((entry) => {
		return {
			...entry,
			days: entry.days.reduce((acc, cur) => {
				return [
					...acc,
					...(cur.value === 'weekdays' ? weekdays : []),
					...(cur.value === 'weekend' ? weekend : []),
					...(cur.value === 'allWeek' ? [...weekdays, ...weekend] : []),
					...(cur.value !== 'weekdays' && cur.value !== 'weekend' && cur.value !== 'allWeek' ?
						[cur]
					:	[]),
				];
			}, []),
		};
	});

	return newPricingModel;
};

export const timeConvert = (value) => ({
	minutes: value % 60,
	hours: Math.floor(value / 60) % 24,
	day: Math.floor(value / (60 * 24)),
});

export const getUrlParams = (queryString) => {
	const decoded = decodeURIComponent(decodeURIComponent(unescape(queryString)));
	const hashes = decoded.slice(decoded.indexOf('?') + 1).split('&');
	const params = {};
	hashes.map((hash) => {
		// const [key, val] = hash.split('=');
		const key = hash.slice(0, hash.indexOf('='));
		const val = hash.slice(hash.indexOf('=') + 1);
		params[key] = decodeURIComponent(val);
	});
	return params;
};

export const instanceName = (value) => {
	const category = value.categoryReference.type;
	if (category === 'cars') {
		return value.itemCar.licensePlate;
	} else if (category === 'bikes') {
		return value.itemBike.bikeNumber;
	} else if (category === 'offices') {
		return value.itemOffice.roomNumber;
	} else if (category === 'trailers') {
		return value.itemTrailer.trailerNumber;
	} else if (category === 'parkingLots') {
		return value.itemParkingLot.parkingNumber;
	} else if (category === 'assets') {
		return value.itemAsset.assetNumber;
	} else if (category === 'boats') {
		return value.name;
	} else {
		return '-';
	}
};

export const instanceLabel = (value) => {
	if (value === 'cars') {
		return i18n.t('ui.label.licensePlate');
	} else if (value === 'bikes') {
		return i18n.t('ui.label.bikeNumber');
	} else if (value === 'offices') {
		return i18n.t('ui.label.roomNumberInstance');
	} else if (value === 'trailers') {
		return i18n.t('ui.label.trailerNumber');
	} else if (value === 'parkingLots') {
		return i18n.t('ui.label.parking.parkingNumber');
	} else if (value === 'assets') {
		return i18n.t('ui.label.assetNumber.placeholder');
	} else if (value === 'boats') {
		return i18n.t('ui.label.registrationNumber');
	} else {
		return '-';
	}
};

export const handleLockType = (value) => {
	const deviceType = {
		none: i18n.t('ui.label.device.none'),
		bikeLock: i18n.t('ui.label.bikeLock'),
		doorLock: i18n.t('ui.label.doorLock'),
		carLock: i18n.t('ui.label.carLock'),
		bmwCar: 'BMW',
		trailerLock: i18n.t('ui.label.trailerLock'),
		batteryBackupSystem: i18n.t('ui.label.batteryBackupSystem'),
		borderRouter: i18n.t('ui.label.borderRouter'),
		vehicleTracker: i18n.t('ui.label.vehicleTracker'),
		vehicleTrackerCan: i18n.t('ui.label.vehicleTrackerCan'),
		vehicleTrackerObd: i18n.t('ui.label.vehicleTrackerOBD'),
		vehicleTrackerPro: i18n.t('ui.label.vehicleTrackerPro'),
		cpacBoat: i18n.t('ui.label.cpacBoat'),
	};
	return isFullString(value) ? deviceType[value] : null;
};

export const handleDeviceTypes = (value) => {
	const unlockType = {
		None: 'None',
		totp: 'Keycode',
		rfid: 'NFC',
		ble: 'BLE',
		lte: '4G',
		thread: 'Thread',
		offline: 'Offline',
		server: 'Server',
	};
	return unlockType[value];
};

export const handleHubReference = (value, isFirst = false) => {
	const getValid = (value, isFirst = false) =>
		!isUndefined(value) ? `${isFirst ? '' : ' '}${value}` : '';
	if (isObject(value) && isObject(value.address)) {
		const { street, number, city, numberAddition } = value.address;
		if (isObject(value.address)) {
			return `${getValid(street, isFirst)}${getValid(number)}${getValid(numberAddition)},${getValid(city)}`;
		}
	} else {
		return '-';
	}
};

export const handleCultureCode = (value) => {
	const shortCode = value.slice(0, 2);
	if (shortCode === 'nl') {
		return i18n.t('ui.dutch');
	} else if (shortCode === 'de') {
		return i18n.t('ui.german');
	} else if (shortCode === 'en') {
		return i18n.t('ui.english');
	}
};

/**
 * Generates an array of finance table headers based on the specified parameters.
 *
 * @param {boolean} [hidePeriod=false] - Whether to hide the 'period' header.
 * @param {boolean} [hideOpen=false] - Whether to hide the 'open' header.
 * @param {boolean} [hideStatus=false] - Whether to hide the 'status' header.
 * @returns {Array} An array of finance table headers.
 */

export const getFinanceTableHeaders = (
	hidePeriod = false,
	hideOpen = false,
	hideStatus = false,
) => [
	...(hidePeriod ? [] : [{ name: 'period', content: i18n.t('ui.period') }]),
	{ name: 'subtotal', content: i18n.t('ui.label.finance.subTotal') },
	{ name: 'discount', content: i18n.t('ui.discount') },
	{ name: 'totaExclVat', content: i18n.t('ui.label.finance.totaExclVat') },
	{ name: 'vat', content: i18n.t('ui.label.finance.Vat') },
	{ name: 'refund', content: i18n.t('ui.refund') },
	{ name: 'totalInclVat', content: i18n.t('ui.label.finance.totalInclVat') },
	...(hideOpen ? [] : [{ name: 'open', content: i18n.t('ui.open') }]),
	...(hideStatus ? [] : [{ name: 'status', content: i18n.t('ui.label.status') }]),
];

/**
 * Map the battery level enum to translation keys
 * @param level 
 * @returns 
 */
export const mapBatteryLevelEnum = (
	level: 'high' | 'medium' | 'low' | 'critical'
) => {
	const translationKey = (() => {
		switch (level) {
			case 'high':
				return 'full';
			case 'medium':
				return 'ok';
			default:
				return level;
		}
	})();

	return i18n.t(`ui.label.${translationKey}`);
};	

/**
 * An enum is introduced in the backend to map the non-linear doorlock battery
 * levels. This is not implemented in all endpoints. Once implemented, move to
 * mapBatteryLevelEnum
 * @param level 
 * @returns 
 * @deprecated
 */
export const convertBatteryLevelToEnum = (level: number) => {
	const key = `${
		level >= 90 ? 'full'
		: level >= 60 ? 'ok'
		: level >= 30 ? 'low'
		: level >= 0 ? 'critical'
		: 'unknown'
	}`;
	return i18n.t(`ui.label.${key}`);
};
