import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { Navigate, Outlet } from 'react-router-dom';
import useSWR from 'swr';

import { pagePaths } from '~constants';
import { UserRoleEnum } from '~enums';
import { OrganisationVerificationService } from '~features/organisations';
import { GenericLayout } from '~layouts';
import { LoadingPage } from '~pages';

const service = new OrganisationVerificationService();

interface ProtectedRouteProps {
	/**
	 * Roles allowed in the protected route. Admin as default
	 * For now give a warning if the user is added. Shouldn't be
	 */
	roles?: (UserRoleEnum.Admin | UserRoleEnum.SuperAdmin)[];
	/**
	 * If true, only allow exactly the role defined. If false, the
	 * natural hiearchy will be followed. For example, superadmin can
	 * do everything an admin can do
	 */
	exclusive?: boolean;
}

/**
 * A route that is only available when a user is logged in
 * @returns
 */
const ProtectedRoute = ({
	roles = [UserRoleEnum.Admin],
	exclusive = false,
}: ProtectedRouteProps) => {
	const { t } = useTranslation('general');
	const auth = useAuth();

	// TODO: can role be not defined?
	const role = (auth.user?.profile.role as string)?.toLowerCase() as UserRoleEnum;

	const isAuthorized = (): boolean => {
		if (exclusive) {
			//
			return roles.includes(role);
		}

		if (role === UserRoleEnum.SuperAdmin) {
			// Superadmin can do everything
			return true;
		}

		return roles.includes(UserRoleEnum.Admin) && role === UserRoleEnum.Admin;
	};

	if (auth.isLoading) {
		// In dev provide a bit information if the protected or public route is triggered
		return (
			<GenericLayout>
				<LoadingPage
					description={`${t('authenticating')}${import.meta.env.DEV && ' - protected'}`}
				/>
			</GenericLayout>
		);
	} else if (!auth.isAuthenticated) {
		return <Navigate to={`/${pagePaths.AuthLogin}`} state={{ from: location.pathname }} replace />;
	} else if (auth.isAuthenticated && role === UserRoleEnum.User) {
		return <UserRoute />;
	}

	// Check authorization
	if (!isAuthorized()) {
		return import.meta.env.DEV ?
				<Box>Unauthorized</Box>
			:	<Navigate to={`/${pagePaths.AuthLogin}`} />;
	}

	return <Outlet />;
};

const UserRoute = () => {
	const { data, isLoading, isValidating, error } = useSWR('verifyOrganisation', () =>
		service.getOrganisationVerificationStatus(),
	);

	if (isLoading || isValidating) {
		return <LoadingPage />;
	} else if (data?.verified === false) {
		return <Navigate to={`/${pagePaths.RegisterVerificationStatus}`} />;
	}

	// TODO: enum
	return <Navigate to={`/${pagePaths.AuthUserRedirect}`} />;
};

export default ProtectedRoute;
