import { useState, useEffect } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import {
	Card,
	CardHeader,
	CardContent,
	Divider,
	Table,
	TableBody,
	TableRow,
	TableCell,
	Box,
	Typography,
	CardActions,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { useAuthorize } from '~features/authentication';

import LocationEdit from './LocationEdit';
import { useStyles } from './style';
import Placeholder from '../../../../../../assets/images/ic-placeholder.svg';
import { ImageUpload, StyledButton } from '../../../../../../components';
import { isObject, isFullArray } from '../../../../../../shared/utility';
import * as actions from '../../../../../../store/actions';

const LocationInfo = (props) => {
	const {
		hub,

		updateHubDetails,
		onResetStateCondition,

		currentUser,
		onUpdateLocationImage,
		updateLocationImage,
		onUpdateDetailsState,
	} = props;
	const { t } = useTranslation('general');
	const { isSuperAdmin } = useAuthorize();

	const { data: currentUserData } = currentUser;

	const { success: updateLocationSuccess, loading: updateLocationLoading } = updateHubDetails;

	const {
		data: updateImageData,
		loading: updateImageLoading,
		error: updateImageError,
	} = updateLocationImage;

	const { enqueueSnackbar } = useSnackbar();

	const isProviderLocation =
		isObject(currentUserData) &&
		isObject(hub) &&
		hub.organisationReference.id === currentUserData.organisationReference.id;

	const classes = useStyles();

	const [openEdit, setOpenEdit] = useState(false);

	const defaultImage =
		isObject(hub) && isFullArray(hub.imagesReference) ? hub.imagesReference[0] : Placeholder;

	const [previewImage, setPreviewImage] = useState(defaultImage);

	useEffect(() => {
		if (!updateImageLoading) {
			if (isFullArray(updateImageData)) {
				onUpdateDetailsState('hubDetails', { ...hub, imagesReference: updateImageData });
			} else if (isObject(updateImageError)) {
				const initialState =
					isObject(hub) && isFullArray(hub.imagesReference) ? hub.imagesReference[0] : '';
				setPreviewImage(initialState);
			}
		}
	}, [updateLocationImage]);

	useEffect(() => {
		if (updateLocationSuccess) {
			enqueueSnackbar(t('views.locationDetail.locationInfo.edit.success.message'), {
				variant: 'success',
			});
			onResetStateCondition('updateHubDetails', false);
		}
	}, [updateLocationLoading]);

	const handleOnChange = (e, results) => {
		const [progressEvent, file] = results[0];
		setPreviewImage(progressEvent.target.result);
		onUpdateLocationImage(hub.id, [file]);
	};

	const handleEditOpen = () => {
		setOpenEdit(true);
	};

	const handleEditClose = () => {
		setOpenEdit(false);
	};

	return (
		<Card className={classes.root}>
			<CardHeader title={t('views.locationDetail.locationInfo.locationInfo')} />
			<Divider />
			<CardContent className={classes.content}>
				<Box>
					<ImageUpload
						isOwnItem={isSuperAdmin() || isProviderLocation}
						message={updateImageError?.message}
						onChange={handleOnChange}
						previewImage={previewImage}
					/>
				</Box>
				<Table>
					<TableBody>
						<TableRow>
							<TableCell>{t('views.locationDetail.locationInfo.locationName')}</TableCell>
							<TableCell>{hub.name}</TableCell>
						</TableRow>
					</TableBody>
				</Table>
				<Box display='flex' flexDirection='column' pl={1.5} pt={1.5}>
					<Typography color='primary' gutterBottom={true} variant='body2'>
						{t('views.locationDetail.locationInfo.locationDescription')}
					</Typography>
					<Box pt={1}>
						<Typography variant='body2'>{hub.description ? hub.description : '-'}</Typography>
					</Box>
				</Box>
			</CardContent>
			<CardActions className={classes.actions}>
				<StyledButton onClick={handleEditOpen} startIcon={<EditIcon />} variant='inline-default'>
					{t('ui.button.inline.edit')}
				</StyledButton>
				{openEdit ?
					<LocationEdit location={hub} onClose={handleEditClose} open={openEdit} />
				:	null}
			</CardActions>
		</Card>
	);
};

LocationInfo.propTypes = {
	className: PropTypes.string,
	hub: PropTypes.object,

	onResetStateCondition: PropTypes.func,
	onUpdateLocationImage: PropTypes.func,
	onUpdateDetailsState: PropTypes.func,

	updateLocationImage: PropTypes.shape({
		data: PropTypes.array,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	updateHubDetails: PropTypes.shape({
		success: PropTypes.bool,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	currentUser: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
};

const mapStateToProps = (state) => {
	return {
		updateHubDetails: state.condition.updateHubDetails,
		currentUser: state.details.currentUser,
		updateLocationImage: state.details.updateLocationImage,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onResetStateCondition: (state, value) => dispatch(actions.resetStateCondition(state, value)),
		onUpdateLocationImage: (locationId, imageArray) =>
			dispatch(actions.updateLocationImage(locationId, imageArray)),
		onUpdateDetailsState: (identifier, data) =>
			dispatch(actions.updateDetailsState(identifier, data)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationInfo);
