import { useEffect, useState } from 'react';

import path from 'path';

import {
	AdminPanelSettings as AdminPanelSettingsIcon,
	Block as BlockIcon,
	DeleteOutlined as DeleteOutlinedIcon,
	Edit as EditIcon,
} from '@mui/icons-material';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import useSWRMutation from 'swr/mutation';

import { userInfoAtom } from '~atoms';
import { ConfirmationDialog, Dialog, FormDialog } from '~components';
import { pagePathSegments } from '~constants';
import { UserRoleEnum } from '~enums';
import { useAuthorize } from '~features/authentication';
import { NfcGeneralInfoCard, NfcTag, NfcTagsService } from '~features/nfc';
import { useSnackbar } from '~hooks';
import { DetailsGridLayout, usePageHeader } from '~layouts';

import UserGeneralInfoCard from '../components/cards/userGeneralInfoCard';
import UserLicenseCard from '../components/cards/userLicenseCard';
import UserOrganisationInfoCard from '../components/cards/userOrganisationInfoCard';
import LinkNfcTagToUserForm from '../forms/linkNfcTagToUser/linkNfcTagToUserForm';
import useUserData from '../hooks/useUserData';
import UserLicensesService from '../services/userLicensesService';

const nfcTagsService = new NfcTagsService();
const userLicensesService = new UserLicensesService();

type UserAction =
	| {
			type: 'link';
			id?: never;
	  }
	| {
			type: 'unlink';
			id: string;
	  };

interface UserDetailsLayoutProps {
	id: string;
}

const UserDetailsLayout = ({ id }: UserDetailsLayoutProps) => {
	const { t } = useTranslation('general');
	const { setPageButtons } = usePageHeader();
	const { enqueueSuccessSnackbar, enqueueErrorSnackbar } = useSnackbar();
	const { isSuperAdmin } = useAuthorize();

	const userInfo = useAtomValue(userInfoAtom);

	const [userAction, setUserAction] = useState<UserAction | null>(null);

	const { trigger: linkNfcTrigger, isMutating: isLinkNfcMutating } = useSWRMutation(
		[nfcTagsService.basePath, id],
		([_, id], { arg }) => nfcTagsService.updateNfcTag(id, arg),
		{
			onSuccess: () => {
				enqueueSuccessSnackbar(t('linked'));
				setUserAction(null);
				// dataGridRef.current.mutate();
			},
			onError: (error) => enqueueErrorSnackbar(error),
		},
	);

	const { trigger: unlinkNfcTrigger, isMutating: isUnlinkNfcMutating } = useSWRMutation(
		userAction?.id ? [nfcTagsService.basePath, userAction.id] : undefined,
		([_, id]) => nfcTagsService.unlinkNfcTag(id),
		{
			onSuccess: () => {
				enqueueSuccessSnackbar(t('linked'));
				setUserAction(null);
				// dataGridRef.current.mutate();
			},
			onError: (error) => enqueueErrorSnackbar(error),
		},
	);

	const { data, isLoading, error, mutate } = useUserData(id);

	useEffect(() => {
		if (isSuperAdmin()) {
			setPageButtons([
				{
					children: t('changeRole'),
					startIcon: <AdminPanelSettingsIcon />,
				},
				{
					children: t('deactivate'),
					startIcon: <BlockIcon />,
					color: 'error',
				},
				{
					children: t('delete'),
					startIcon: <DeleteOutlinedIcon />,
					color: 'error',
				},
			]);
		}
	}, []);

	return (
		<>
			<DetailsGridLayout>
				<UserGeneralInfoCard userId={id} />
				<UserOrganisationInfoCard userId={id} />
				<UserLicenseCard userId={data?.hasUserLicense ? id : undefined} />
				<NfcGeneralInfoCard
					id={data?.cards[0]?.id}
					onUnlinkClick={(nfcId) => setUserAction({ type: 'unlink', id: nfcId })}
					onLinkClick={() => setUserAction({ type: 'link' })}
				/>
			</DetailsGridLayout>
			<FormDialog
				open={userAction?.type === 'link'}
				loading={isLinkNfcMutating}
				onClose={() => setUserAction(null)}
			>
				<LinkNfcTagToUserForm
					userId={id}
					onSubmit={async (value) => linkNfcTrigger(value)}
				/>
			</FormDialog>
			<ConfirmationDialog
				open={userAction?.type === 'unlink'}
				loading={isUnlinkNfcMutating}
				title={t('unlink')}
				subTitle={'TODO'}
				onConfirm={async () => unlinkNfcTrigger()}
				onClose={() => setUserAction(null)}
			/>
		</>
	);
};

export default UserDetailsLayout;
