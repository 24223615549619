import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { BarChart, ChartsLegendWithValues } from '~components';
import { DateRange } from '~interfaces/dateRanges';

import BillingPeriodStatisticsService from '../../services/billingPeriodStatisticsService';

const service = new BillingPeriodStatisticsService();

interface BillingPeriodCountBarChartProps {
	organisationId?: string;
	period: DateRange;
}

const BillingPeriodCountBarChart = ({
	organisationId,
	period,
}: BillingPeriodCountBarChartProps) => {
	const { t } = useTranslation('general');

	const { data, isLoading } = useSWR(
		[service.basePath, { organisationId: organisationId, period: period }],
		([_, args]) => {
			if (isLoading) {
				service.abortCurrentRequest('parameter change');
			}

			return service.getBillingPeriodCounts(args);
		},
	);

	return (
		<BarChart
			loading={isLoading}
			xAxis={[
				{
					scaleType: 'band',
					data: ['payments'],
				},
			]}
			series={[
				{
					label: t('ui.status.open'),
					stack: 'open',
					data: data != null ? [data.open - data.revoked] : []
				},
				{
					label: t('ui.status.revoked'),
					stack: 'open',
					data: data != null ? [data.revoked] : []
				},
				{
					label: t('ui.status.closed'),
					data: data != null ? [data.closed] : []
				},
			]}
			slots={{
				legend: ChartsLegendWithValues
			}}
			slotProps={{
				legend: {
					type: 'bar',
					values: data != null ? [data.open - data.revoked, data.revoked, data.closed] : [],
					direction: 'row',
					hidden: false,
				},
			}}
		/>
	);
};

export default BillingPeriodCountBarChart;
