import { Chip, ChipOwnProps } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ItemCategoryEnum } from '~enums';

import ApprovalStateEnum from '../../enums/approvalStateEnum';
import BookingStatusEnum from '../../enums/bookingStatusEnum';
import UsageStateEnum from '../../enums/usageStateEnum';

interface BookingStatusChip {
	status: BookingStatusEnum; // A general status
	usageState: UsageStateEnum;
	approvalState: ApprovalStateEnum;
	/**
	 * This is mainly used to determined if this booking has some kind of
	 * onboarding.
	 * TODO: this should come from the backend in some way
	 */
	itemCategory: ItemCategoryEnum;
}

const BookingStatusChip = ({
	status,
	usageState,
	approvalState,
	itemCategory,
}: BookingStatusChip) => {
	const { t } = useTranslation('general');

	const hasOnboarding = [
		ItemCategoryEnum.Cars,
		ItemCategoryEnum.Trailers,
		ItemCategoryEnum.Boats,
	].includes(itemCategory);

	const getStatusLabel = (): string => {
		let translationKey: string;

		switch (status) {
			case BookingStatusEnum.Completed:
				if (
					usageState === UsageStateEnum.NotApplicable &&
					approvalState !== ApprovalStateEnum.Declined &&
					hasOnboarding
				) {
					translationKey = 'ui.status.notShownUp';
					break;
				}

				translationKey = 'ui.status.completed';
				break;
			case BookingStatusEnum.Cancelled:
				translationKey =
					approvalState === ApprovalStateEnum.Declined ?
						'ui.status.rejected'
					:	'ui.status.cancelled';
				break;
			case BookingStatusEnum.Upcoming:
				translationKey =
					approvalState === ApprovalStateEnum.Pending ? 'ui.status.pending' : 'ui.status.upcoming';
				break;
			case BookingStatusEnum.Active:
				if (usageState === UsageStateEnum.Ready) {
					translationKey = 'ui.status.readyToStart';
					break;
				} else if (usageState === UsageStateEnum.Started) {
					translationKey = 'ui.status.inUse';
					break;
				}

				translationKey = 'ui.label.active';
				break;
			case BookingStatusEnum.Overtime:
				translationKey = 'ui.status.overtime';
				break;
			default:
				translationKey = 'unknown';
				console.warn('Unknown booking status');
				break;
		}

		return t(translationKey);
	};

	const getStatusColor = (): ChipOwnProps['color'] => {
		let color: ChipOwnProps['color'];

		switch (status) {
			case BookingStatusEnum.Completed:
				if (
					(usageState === UsageStateEnum.NotApplicable && hasOnboarding) ||
					approvalState === ApprovalStateEnum.Declined ||
					approvalState === ApprovalStateEnum.Pending
				) {
					color = 'error';
					break;
				}

				color = 'primary'; // TODO
				break;
			case BookingStatusEnum.Active:
				color = 'success';
				break;
			case BookingStatusEnum.Upcoming:
				color = 'info';
				break;
			case BookingStatusEnum.Overtime:
				color = 'error';
				break;
			case BookingStatusEnum.Cancelled:
				color = 'error';
				break;
			default:
				color = 'default';
				break;
		}

		return color;
	};

	return <Chip size='small' label={getStatusLabel()} color={getStatusColor()} />;
};

export default BookingStatusChip;
