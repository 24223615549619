import { Divider, Typography, Box, TextField, MenuItem } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useStyles } from './style';
import { SidepanelContainer, SidePanel } from '../../../../../components';
import { commaTimeStrings } from '../../../../../shared/datetime';
import { isObject } from '../../../../../shared/utility';
import { useAtomValue } from 'jotai';
import { userInfoAtom } from '~atoms';
import { useAuthorize } from '~features/authentication';

const SidePanelCard = (props) => {
	const { getTicketData, status, setStatus, setDialogStatus, statusOfTicket } = props;
	const { t } = useTranslation('general');
	const userInfo = useAtomValue(userInfoAtom);
	const { isSuperAdmin } = useAuthorize();

	const classes = useStyles();

	const updateOption = (name) => {
		setStatus(name);
		setDialogStatus(true);
	};

	const handleStatusChange = (event) => {
		const newValue = event.target.value;
		updateOption(newValue);
	};

	const closed = status === 'closed';

	return (
		<SidepanelContainer className={classes.sideCard} sticky={true}>
			<SidePanel>
				<Box display='flex' flexDirection='column'>
					<Typography variant='h4'>
						{`${t('ui.ticket.labelCapitalize')} ${getTicketData.id}`}
					</Typography>
					<Typography variant='body2'>
						{`${t('views.ticketsDetails.lastUpdated')}: ${commaTimeStrings(getTicketData.dateModified)}`}
					</Typography>
				</Box>
				<Box pb={2} pt={2}>
					<Typography gutterBottom={true} variant='h6'>{`${t('ui.label.status')}:`}</Typography>
					<TextField
						SelectProps={{ value: status || '' }}
						disabled={(getTicketData.status === 'closed' || closed) || (!isSuperAdmin() && userInfo.organisation.id !=
							getTicketData?.itemInstanceReference?.hubReference?.organisationReference.id.toString())}
						fullWidth={true}
						onChange={handleStatusChange}
						select
						size='small'
						variant='outlined'
					>
						{statusOfTicket.map((tStatus) => (
							<MenuItem key={tStatus.label} value={tStatus.label}>
								{tStatus.name}
							</MenuItem>
						))}
					</TextField>
				</Box>
				<Divider />
				<Box pb={1} pt={1}>
					<Typography variant='h6'>{`${t('views.ticketsDetails.itemDefect')}:`}</Typography>
					<Typography variant='body2'>{getTicketData.defectReference.title}</Typography>
				</Box>
				<Divider />
				<Box pb={1} pt={1}>
					<Typography>{`${t('ui.label.serviceProvider')}:`}</Typography>
					<Typography variant='body2'>
						{isObject(getTicketData.serviceReference) ? getTicketData.serviceReference.name : '-'}
					</Typography>
				</Box>
			</SidePanel>
		</SidepanelContainer>
	);
};

SidePanelCard.propTypes = {
	getTicketData: PropTypes.object,
	status: PropTypes.string,
	setStatus: PropTypes.func,
	setDialogStatus: PropTypes.func,
	statusOfTicket: PropTypes.array,
};

export default SidePanelCard;
