import { useMemo } from 'react';

import { AxisValueFormatterContext } from '@mui/x-charts/internals';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { ChartsLegendWithValues, LineChart } from '~components';
import { PeriodicityEnum } from '~enums';
import { DateRange } from '~interfaces/dateRanges';
import { longMonthAndYearFormatter, shortMonthAndYearFormatter } from '~utils/dateUtils';

import FinanceStatisticsService from '../../services/financeStatisticsService';

const service = new FinanceStatisticsService();

interface RevenuesLineChartProps {
	organisationId?: string;
	period: DateRange;
	itemId?: string;
	hubId?: string;
	partnerId?: string;
	periodicity?: PeriodicityEnum;
}

dayjs.extend(weekOfYear);

const now = dayjs();

const RevenuesLineChart = ({
	period = {
		start: now.subtract(3, 'month').toDate(),
		end: now.toDate(),
	},
	...props
}: RevenuesLineChartProps) => {
	const { t } = useTranslation('general');

	const fetchParameters = useMemo(
		() => ({
			period: period,
			...props,
		}),
		[props, period],
	);

	const { data, isLoading } = useSWR([service.basePath, fetchParameters], ([_, args]) => {
		if (isLoading) {
			service.abortCurrentRequest('parameter change');
		}

		return service.getRevenues(args);
	});

	/**
	 * Scope the results for the current selected periodicity.
	 */
	const selectedPeriodResults = useMemo(
		() =>
			data?.results.filter(
				(el) =>
					el.timestamp <= period.end &&
					el.timestamp >
						dayjs(period.end)
							.startOf(props.periodicity === PeriodicityEnum.Monthly ? 'month' : 'year')
							.toDate(),
			),
		[data],
	);

	const xAxisValueFormatter = (value: Date, context: AxisValueFormatterContext) => {
		if (context.location !== 'tick' && props.periodicity === PeriodicityEnum.Monthly) {
			return value.toLocaleDateString();
		} else if (context.location !== 'tick') {
			return longMonthAndYearFormatter.format(value);
		}

		return shortMonthAndYearFormatter.format(value);
	};

	return (
		<LineChart
			loading={isLoading}
			xAxis={[
				{
					min: period.start,
					max: dayjs(period.end).startOf(
						props.periodicity === PeriodicityEnum.Yearly ? 'month' : 'day'
					).toDate(),
					scaleType: 'time',
					data: data?.results.map((el) => el.timestamp) ?? [],
					valueFormatter: xAxisValueFormatter,
					tickNumber: 3,
				},
			]}
			yAxis={[{ valueFormatter: (value: number) => `€${value.toLocaleString()}` }]}
			series={[
				{
					curve: 'linear',
					data: data?.results.map((el) => el.revenue) ?? [],
					label: t('nav.financialReports.revenue'),
					showMark: false,
					valueFormatter: (value: number | null) =>
						`€${value != null ? value.toLocaleString() : '-'}`,
				},
			]}
			slots={{
				legend: ChartsLegendWithValues,
			}}
			slotProps={{
				legend: {
					values: [selectedPeriodResults?.reduce((total, el) => (total += el.revenue), 0)],
				},
			}}
			margin={{ left: 50 }}
		/>
	);
};

export default RevenuesLineChart;
