import {
	Card,
	CardContent,
	Divider,
	Table,
	TableBody,
	TableRow,
	TableCell,
	Box,
	Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { useAuthorize } from '~features/authentication';

import { Label } from '../../../../../components';
import { commaTimeStrings, localizeDateTime } from '../../../../../shared/datetime';
import { usePaymentDetails } from '../../../../../shared/hooks';
import { isObject, isNumber } from '../../../../../shared/utility';
import { useStyles } from '../../../style';

const handleDate = (value) => {
	return localizeDateTime(value, undefined, { day: '2-digit', month: '2-digit', year: 'numeric' });
};

const nonZeroNumber = (value) => {
	return isNumber(value) && !!value;
};

const InvoicesBilling = (props) => {
	const { bookingData, currentUser } = props;
	const { t } = useTranslation('general');
	const { isSuperAdmin } = useAuthorize();

	const classes = useStyles();
	const cardDetails = usePaymentDetails(
		isObject(bookingData.billingPeriodReference) &&
			isObject(bookingData.billingPeriodReference.paymentMethodReference) &&
			bookingData.billingPeriodReference.paymentMethodReference,
	);

	const billingReference = bookingData?.billingPeriodReference;
	const priceDetails = bookingData?.price;
	const userOrganisation = bookingData.userReference.organisationReference;

	const { data: currentUserData } = currentUser;
	const provider =
		currentUserData?.organisationReference.id ===
		bookingData?.hubReference.organisationReference.id;

	const statusColors = {
		paid: 'success',
		active: 'default',
		overdue: 'warning',
		upcoming: 'default',
	};

	const handlePaymentStatus = () => {
		if (billingReference.price.isFree) {
			return t('ui.free');
		} else if (bookingData.paymentStatus === 'refunded') {
			return t('ui.label.billingPeriod.status.refunded');
		} else if (bookingData.paymentStatus === 'notCollected') {
			return t('ui.label.notCharged');
		} else {
			return t(`ui.label.billingPeriod.status.${billingReference.status}`);
		}
	};

	const handleCardDetails = () => (
		<Box alignItems='center' display='flex' maxHeight='19px'>
			{cardDetails.logo && <img src={cardDetails.logo} />}
			<Typography>{cardDetails.name}</Typography>
		</Box>
	);
	const businessBilling = bookingData?.billingType === 'business';

	const billingData = [
		{
			lable: 'views.planboard.addBooking.usage.sectionTitle',
			value:
				businessBilling ?
					t('views.bookingDetail.invoicesBiling.organizational')
				:	t('views.bookingDetail.invoicesBiling.personal'),
			key: 1,
		},
		{
			lable: 'views.bookingDetail.invoicesBiling.billingPeriod',
			value:
				isObject(billingReference) &&
				`${handleDate(billingReference?.periodStart)} - ${handleDate(billingReference?.periodEnd)}`,
			key: 2,
		},
		...(isSuperAdmin() && businessBilling ?
			[{ lable: 'ui.organisation', value: userOrganisation.name, key: 3 }]
		:	[]),
		...(isSuperAdmin() && !businessBilling ?
			[
				{ lable: 'ui.billing.lable', value: handleCardDetails(), key: 4 },
				{ lable: 'views.bookingDetail.invoicesBiling.card', value: cardDetails.bank, key: 5 },
			]
		:	[]),
	];

	return (
		<Card className={classes.card}>
			<Box display='flex' justifyContent='space-between' pb={2} pl={3} pr={3} pt={2}>
				<Typography variant='h5'>
					{t('views.userDetails.summary.cardHeaders.invoicesAndBilling')}
				</Typography>
				{isObject(bookingData.billingPeriodReference) ?
					<Label type={statusColors[billingReference.status]}>{handlePaymentStatus()} </Label>
				:	null}
			</Box>
			<Divider />
			<CardContent className={classes.cardConten}>
				<Table>
					<TableBody>
						{billingData.map((billing) => (
							<TableRow key={billing.key}>
								<TableCell>{t(billing.lable)}</TableCell>
								<TableCell>{billing.value}</TableCell>
							</TableRow>
						))}
						<TableRow>
							<TableCell>{`${t('views.bookingDetail.invoicesBiling.reservationPricing')} (${t('ui.incl')}. ${priceDetails?.vatPercentage.toFixed(2)}% ${t('ui.vat')})`}</TableCell>
							<TableCell>
								{isObject(priceDetails?.priceBreakdownIncludingVat) ?
									`${priceDetails.currencySymbol}${priceDetails.priceBreakdownIncludingVat.initialDuration.toFixed(2)}`
								:	'-'}
							</TableCell>
						</TableRow>
						{(nonZeroNumber(priceDetails?.priceBreakdownIncludingVat?.extraDuration) ||
							bookingData.status === 'overtime') && (
							<TableRow>
								<TableCell>{`${t('views.bookingDetail.invoicesBiling.overtime')} (${t('ui.incl')}. ${priceDetails?.vatPercentage?.toFixed(2)}% ${t('ui.vat')})`}</TableCell>
								<TableCell>
									{bookingData.status === 'overtime' ?
										'-'
									:	`${priceDetails.currencySymbol}${priceDetails.priceBreakdownIncludingVat.extraDuration.toFixed(2)}`
									}
								</TableCell>
							</TableRow>
						)}
						{nonZeroNumber(priceDetails?.priceBreakdownIncludingVat?.extraDistance) && (
							<TableRow>
								<TableCell>{`${t('views.bookingDetail.invoicesBiling.chargedkms')} (${t('ui.incl')}. ${priceDetails?.vatPercentage.toFixed(2)}% ${t('ui.vat')})`}</TableCell>
								<TableCell>
									{priceDetails?.currencySymbol}
									{priceDetails.priceBreakdownIncludingVat.extraDistance.toFixed(2)}
								</TableCell>
							</TableRow>
						)}
						{nonZeroNumber(priceDetails?.priceBreakdownIncludingVat?.codeDiscount) && (
							<TableRow>
								<TableCell>{t('views.bookingDetail.invoicesBiling.promoDiscount')}</TableCell>
								<TableCell>{`-${priceDetails?.currencySymbol}${priceDetails.priceBreakdownIncludingVat.codeDiscount.toFixed(2)}`}</TableCell>
							</TableRow>
						)}
						{nonZeroNumber(priceDetails?.priceBreakdownIncludingVat?.adminDiscount) && (
							<TableRow>
								<TableCell>{t('views.bookingDetail.invoicesBiling.adminDiscount')}</TableCell>
								<TableCell>{`-${priceDetails?.currencySymbol}${priceDetails.priceBreakdownIncludingVat.adminDiscount.toFixed(2)}`}</TableCell>
							</TableRow>
						)}
						{nonZeroNumber(priceDetails?.total?.refund) && (
							<TableRow>
								<TableCell>{t('ui.refund')}</TableCell>
								<TableCell>{`-${priceDetails?.currencySymbol}${priceDetails.total.refund.toFixed(2)}`}</TableCell>
							</TableRow>
						)}
						<TableRow>
							<TableCell>{`${t('ui.label.total')} (${t('ui.incl')} ${priceDetails?.vatPercentage.toFixed(2)}% ${t('ui.vat')})`}</TableCell>
							<TableCell>
								{isNumber(priceDetails?.total?.total) ?
									`${priceDetails?.currencySymbol}${priceDetails.total.total.toFixed(2)}`
								:	'-'}
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>
								{provider && bookingData.billingType === 'personal' ? 'Date' : 'Due date'}
							</TableCell>
							<TableCell>
								{isObject(billingReference) && billingReference?.periodEnd ?
									commaTimeStrings(billingReference.periodEnd)
								:	'-'}
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</CardContent>
		</Card>
	);
};

InvoicesBilling.propTypes = {
	bookingData: PropTypes.object.isRequired,

	currentUser: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
};

const mapStateToProps = (state) => {
	return {
		currentUser: state.details.currentUser,
	};
};

export default connect(mapStateToProps, null)(InvoicesBilling);
